/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../../google/protobuf/timestamp';
import { Money } from '../fx/types';
import { ColourStyling, Icon } from './visuals';

export const protobufPackage = 'utils';

/** Type of an ETA, typically states the timescale of the ETA. */
export enum ETAType {
  ETA_TYPE_INVALID = 0,
  ETA_TYPE_INSTANT = 1,
  ETA_TYPE_EXACT = 2,
  ETA_TYPE_LATER_DAY = 3,
  ETA_TYPE_LATER_HOUR = 4,
  ETA_TYPE_LATER_MINUTE = 5,
  ETA_TYPE_RANGE = 6,
  UNRECOGNIZED = -1,
}

export function eTATypeFromJSON(object: any): ETAType {
  switch (object) {
    case 0:
    case 'ETA_TYPE_INVALID':
      return ETAType.ETA_TYPE_INVALID;
    case 1:
    case 'ETA_TYPE_INSTANT':
      return ETAType.ETA_TYPE_INSTANT;
    case 2:
    case 'ETA_TYPE_EXACT':
      return ETAType.ETA_TYPE_EXACT;
    case 3:
    case 'ETA_TYPE_LATER_DAY':
      return ETAType.ETA_TYPE_LATER_DAY;
    case 4:
    case 'ETA_TYPE_LATER_HOUR':
      return ETAType.ETA_TYPE_LATER_HOUR;
    case 5:
    case 'ETA_TYPE_LATER_MINUTE':
      return ETAType.ETA_TYPE_LATER_MINUTE;
    case 6:
    case 'ETA_TYPE_RANGE':
      return ETAType.ETA_TYPE_RANGE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ETAType.UNRECOGNIZED;
  }
}

export function eTATypeToJSON(object: ETAType): string {
  switch (object) {
    case ETAType.ETA_TYPE_INVALID:
      return 'ETA_TYPE_INVALID';
    case ETAType.ETA_TYPE_INSTANT:
      return 'ETA_TYPE_INSTANT';
    case ETAType.ETA_TYPE_EXACT:
      return 'ETA_TYPE_EXACT';
    case ETAType.ETA_TYPE_LATER_DAY:
      return 'ETA_TYPE_LATER_DAY';
    case ETAType.ETA_TYPE_LATER_HOUR:
      return 'ETA_TYPE_LATER_HOUR';
    case ETAType.ETA_TYPE_LATER_MINUTE:
      return 'ETA_TYPE_LATER_MINUTE';
    case ETAType.ETA_TYPE_RANGE:
      return 'ETA_TYPE_RANGE';
    case ETAType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Reliability of an ETA */
export enum ETAReliabilityType {
  /** ETA_RELIABILITY_TYPE_INVALID - Indicates ETA reliability was not checked */
  ETA_RELIABILITY_TYPE_INVALID = 0,
  /** ETA_RELIABILITY_TYPE_RELIABLE - Indicates ETA returned by provider is reliable */
  ETA_RELIABILITY_TYPE_RELIABLE = 1,
  /** ETA_RELIABILITY_TYPE_UNRELIABLE - Indicates ETA returned by provider is unreliable and/or delays should be expected */
  ETA_RELIABILITY_TYPE_UNRELIABLE = 2,
  UNRECOGNIZED = -1,
}

export function eTAReliabilityTypeFromJSON(object: any): ETAReliabilityType {
  switch (object) {
    case 0:
    case 'ETA_RELIABILITY_TYPE_INVALID':
      return ETAReliabilityType.ETA_RELIABILITY_TYPE_INVALID;
    case 1:
    case 'ETA_RELIABILITY_TYPE_RELIABLE':
      return ETAReliabilityType.ETA_RELIABILITY_TYPE_RELIABLE;
    case 2:
    case 'ETA_RELIABILITY_TYPE_UNRELIABLE':
      return ETAReliabilityType.ETA_RELIABILITY_TYPE_UNRELIABLE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ETAReliabilityType.UNRECOGNIZED;
  }
}

export function eTAReliabilityTypeToJSON(object: ETAReliabilityType): string {
  switch (object) {
    case ETAReliabilityType.ETA_RELIABILITY_TYPE_INVALID:
      return 'ETA_RELIABILITY_TYPE_INVALID';
    case ETAReliabilityType.ETA_RELIABILITY_TYPE_RELIABLE:
      return 'ETA_RELIABILITY_TYPE_RELIABLE';
    case ETAReliabilityType.ETA_RELIABILITY_TYPE_UNRELIABLE:
      return 'ETA_RELIABILITY_TYPE_UNRELIABLE';
    case ETAReliabilityType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Availability of a service. */
export enum ServiceAvailability {
  SERVICE_AVAILABILITY_INVALID = 0,
  SERVICE_AVAILABILITY_AVAILABLE = 1,
  SERVICE_AVAILABILITY_UNAVAILABLE = 2,
  UNRECOGNIZED = -1,
}

export function serviceAvailabilityFromJSON(object: any): ServiceAvailability {
  switch (object) {
    case 0:
    case 'SERVICE_AVAILABILITY_INVALID':
      return ServiceAvailability.SERVICE_AVAILABILITY_INVALID;
    case 1:
    case 'SERVICE_AVAILABILITY_AVAILABLE':
      return ServiceAvailability.SERVICE_AVAILABILITY_AVAILABLE;
    case 2:
    case 'SERVICE_AVAILABILITY_UNAVAILABLE':
      return ServiceAvailability.SERVICE_AVAILABILITY_UNAVAILABLE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ServiceAvailability.UNRECOGNIZED;
  }
}

export function serviceAvailabilityToJSON(object: ServiceAvailability): string {
  switch (object) {
    case ServiceAvailability.SERVICE_AVAILABILITY_INVALID:
      return 'SERVICE_AVAILABILITY_INVALID';
    case ServiceAvailability.SERVICE_AVAILABILITY_AVAILABLE:
      return 'SERVICE_AVAILABILITY_AVAILABLE';
    case ServiceAvailability.SERVICE_AVAILABILITY_UNAVAILABLE:
      return 'SERVICE_AVAILABILITY_UNAVAILABLE';
    case ServiceAvailability.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum LimitLevel {
  LIMIT_LEVEL_INVALID = 0,
  LIMIT_LEVEL_LOW = 1,
  LIMIT_LEVEL_MEDIUM = 2,
  LIMIT_LEVEL_HIGH = 3,
  LIMIT_LEVEL_BUCKET_1 = 4,
  LIMIT_LEVEL_BUCKET_2 = 5,
  LIMIT_LEVEL_BUCKET_3 = 6,
  LIMIT_LEVEL_BUCKET_4 = 7,
  LIMIT_LEVEL_BUCKET_5 = 8,
  LIMIT_LEVEL_BUCKET_6 = 9,
  LIMIT_LEVEL_BUCKET_7 = 10,
  LIMIT_LEVEL_BUCKET_8 = 11,
  LIMIT_LEVEL_BUCKET_9 = 12,
  LIMIT_LEVEL_BUCKET_10 = 13,
  UNRECOGNIZED = -1,
}

export function limitLevelFromJSON(object: any): LimitLevel {
  switch (object) {
    case 0:
    case 'LIMIT_LEVEL_INVALID':
      return LimitLevel.LIMIT_LEVEL_INVALID;
    case 1:
    case 'LIMIT_LEVEL_LOW':
      return LimitLevel.LIMIT_LEVEL_LOW;
    case 2:
    case 'LIMIT_LEVEL_MEDIUM':
      return LimitLevel.LIMIT_LEVEL_MEDIUM;
    case 3:
    case 'LIMIT_LEVEL_HIGH':
      return LimitLevel.LIMIT_LEVEL_HIGH;
    case 4:
    case 'LIMIT_LEVEL_BUCKET_1':
      return LimitLevel.LIMIT_LEVEL_BUCKET_1;
    case 5:
    case 'LIMIT_LEVEL_BUCKET_2':
      return LimitLevel.LIMIT_LEVEL_BUCKET_2;
    case 6:
    case 'LIMIT_LEVEL_BUCKET_3':
      return LimitLevel.LIMIT_LEVEL_BUCKET_3;
    case 7:
    case 'LIMIT_LEVEL_BUCKET_4':
      return LimitLevel.LIMIT_LEVEL_BUCKET_4;
    case 8:
    case 'LIMIT_LEVEL_BUCKET_5':
      return LimitLevel.LIMIT_LEVEL_BUCKET_5;
    case 9:
    case 'LIMIT_LEVEL_BUCKET_6':
      return LimitLevel.LIMIT_LEVEL_BUCKET_6;
    case 10:
    case 'LIMIT_LEVEL_BUCKET_7':
      return LimitLevel.LIMIT_LEVEL_BUCKET_7;
    case 11:
    case 'LIMIT_LEVEL_BUCKET_8':
      return LimitLevel.LIMIT_LEVEL_BUCKET_8;
    case 12:
    case 'LIMIT_LEVEL_BUCKET_9':
      return LimitLevel.LIMIT_LEVEL_BUCKET_9;
    case 13:
    case 'LIMIT_LEVEL_BUCKET_10':
      return LimitLevel.LIMIT_LEVEL_BUCKET_10;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LimitLevel.UNRECOGNIZED;
  }
}

export function limitLevelToJSON(object: LimitLevel): string {
  switch (object) {
    case LimitLevel.LIMIT_LEVEL_INVALID:
      return 'LIMIT_LEVEL_INVALID';
    case LimitLevel.LIMIT_LEVEL_LOW:
      return 'LIMIT_LEVEL_LOW';
    case LimitLevel.LIMIT_LEVEL_MEDIUM:
      return 'LIMIT_LEVEL_MEDIUM';
    case LimitLevel.LIMIT_LEVEL_HIGH:
      return 'LIMIT_LEVEL_HIGH';
    case LimitLevel.LIMIT_LEVEL_BUCKET_1:
      return 'LIMIT_LEVEL_BUCKET_1';
    case LimitLevel.LIMIT_LEVEL_BUCKET_2:
      return 'LIMIT_LEVEL_BUCKET_2';
    case LimitLevel.LIMIT_LEVEL_BUCKET_3:
      return 'LIMIT_LEVEL_BUCKET_3';
    case LimitLevel.LIMIT_LEVEL_BUCKET_4:
      return 'LIMIT_LEVEL_BUCKET_4';
    case LimitLevel.LIMIT_LEVEL_BUCKET_5:
      return 'LIMIT_LEVEL_BUCKET_5';
    case LimitLevel.LIMIT_LEVEL_BUCKET_6:
      return 'LIMIT_LEVEL_BUCKET_6';
    case LimitLevel.LIMIT_LEVEL_BUCKET_7:
      return 'LIMIT_LEVEL_BUCKET_7';
    case LimitLevel.LIMIT_LEVEL_BUCKET_8:
      return 'LIMIT_LEVEL_BUCKET_8';
    case LimitLevel.LIMIT_LEVEL_BUCKET_9:
      return 'LIMIT_LEVEL_BUCKET_9';
    case LimitLevel.LIMIT_LEVEL_BUCKET_10:
      return 'LIMIT_LEVEL_BUCKET_10';
    case LimitLevel.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum UserLimitType {
  USER_LIMIT_TYPE_INVALID = 0,
  USER_LIMIT_TYPE_PROVIDER = 1,
  USER_LIMIT_TYPE_ACCOUNT = 2,
  UNRECOGNIZED = -1,
}

export function userLimitTypeFromJSON(object: any): UserLimitType {
  switch (object) {
    case 0:
    case 'USER_LIMIT_TYPE_INVALID':
      return UserLimitType.USER_LIMIT_TYPE_INVALID;
    case 1:
    case 'USER_LIMIT_TYPE_PROVIDER':
      return UserLimitType.USER_LIMIT_TYPE_PROVIDER;
    case 2:
    case 'USER_LIMIT_TYPE_ACCOUNT':
      return UserLimitType.USER_LIMIT_TYPE_ACCOUNT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return UserLimitType.UNRECOGNIZED;
  }
}

export function userLimitTypeToJSON(object: UserLimitType): string {
  switch (object) {
    case UserLimitType.USER_LIMIT_TYPE_INVALID:
      return 'USER_LIMIT_TYPE_INVALID';
    case UserLimitType.USER_LIMIT_TYPE_PROVIDER:
      return 'USER_LIMIT_TYPE_PROVIDER';
    case UserLimitType.USER_LIMIT_TYPE_ACCOUNT:
      return 'USER_LIMIT_TYPE_ACCOUNT';
    case UserLimitType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum LimitType {
  LIMIT_TYPE_INVALID = 0,
  LIMIT_TYPE_SUM = 1,
  LIMIT_TYPE_COUNT = 2,
  LIMIT_TYPE_MAX_AMOUNT = 3,
  UNRECOGNIZED = -1,
}

export function limitTypeFromJSON(object: any): LimitType {
  switch (object) {
    case 0:
    case 'LIMIT_TYPE_INVALID':
      return LimitType.LIMIT_TYPE_INVALID;
    case 1:
    case 'LIMIT_TYPE_SUM':
      return LimitType.LIMIT_TYPE_SUM;
    case 2:
    case 'LIMIT_TYPE_COUNT':
      return LimitType.LIMIT_TYPE_COUNT;
    case 3:
    case 'LIMIT_TYPE_MAX_AMOUNT':
      return LimitType.LIMIT_TYPE_MAX_AMOUNT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LimitType.UNRECOGNIZED;
  }
}

export function limitTypeToJSON(object: LimitType): string {
  switch (object) {
    case LimitType.LIMIT_TYPE_INVALID:
      return 'LIMIT_TYPE_INVALID';
    case LimitType.LIMIT_TYPE_SUM:
      return 'LIMIT_TYPE_SUM';
    case LimitType.LIMIT_TYPE_COUNT:
      return 'LIMIT_TYPE_COUNT';
    case LimitType.LIMIT_TYPE_MAX_AMOUNT:
      return 'LIMIT_TYPE_MAX_AMOUNT';
    case LimitType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum BankAccountType {
  BANK_ACCOUNT_TYPE_INVALID = 0,
  BANK_ACCOUNT_TYPE_CURRENT = 1,
  BANK_ACCOUNT_TYPE_SAVINGS = 2,
  UNRECOGNIZED = -1,
}

export function bankAccountTypeFromJSON(object: any): BankAccountType {
  switch (object) {
    case 0:
    case 'BANK_ACCOUNT_TYPE_INVALID':
      return BankAccountType.BANK_ACCOUNT_TYPE_INVALID;
    case 1:
    case 'BANK_ACCOUNT_TYPE_CURRENT':
      return BankAccountType.BANK_ACCOUNT_TYPE_CURRENT;
    case 2:
    case 'BANK_ACCOUNT_TYPE_SAVINGS':
      return BankAccountType.BANK_ACCOUNT_TYPE_SAVINGS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return BankAccountType.UNRECOGNIZED;
  }
}

export function bankAccountTypeToJSON(object: BankAccountType): string {
  switch (object) {
    case BankAccountType.BANK_ACCOUNT_TYPE_INVALID:
      return 'BANK_ACCOUNT_TYPE_INVALID';
    case BankAccountType.BANK_ACCOUNT_TYPE_CURRENT:
      return 'BANK_ACCOUNT_TYPE_CURRENT';
    case BankAccountType.BANK_ACCOUNT_TYPE_SAVINGS:
      return 'BANK_ACCOUNT_TYPE_SAVINGS';
    case BankAccountType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum BankAccountDetailsType {
  BANK_ACCOUNT_DETAILS_TYPE_INVALID = 0,
  BANK_ACCOUNT_DETAILS_TYPE_SCAN = 1,
  BANK_ACCOUNT_DETAILS_TYPE_RNAN = 2,
  BANK_ACCOUNT_DETAILS_TYPE_INTERNAL = 3,
  BANK_ACCOUNT_DETAILS_TYPE_IBAN = 4,
  BANK_ACCOUNT_DETAILS_TYPE_SWIFT = 5,
  UNRECOGNIZED = -1,
}

export function bankAccountDetailsTypeFromJSON(
  object: any,
): BankAccountDetailsType {
  switch (object) {
    case 0:
    case 'BANK_ACCOUNT_DETAILS_TYPE_INVALID':
      return BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_INVALID;
    case 1:
    case 'BANK_ACCOUNT_DETAILS_TYPE_SCAN':
      return BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_SCAN;
    case 2:
    case 'BANK_ACCOUNT_DETAILS_TYPE_RNAN':
      return BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_RNAN;
    case 3:
    case 'BANK_ACCOUNT_DETAILS_TYPE_INTERNAL':
      return BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_INTERNAL;
    case 4:
    case 'BANK_ACCOUNT_DETAILS_TYPE_IBAN':
      return BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_IBAN;
    case 5:
    case 'BANK_ACCOUNT_DETAILS_TYPE_SWIFT':
      return BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_SWIFT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return BankAccountDetailsType.UNRECOGNIZED;
  }
}

export function bankAccountDetailsTypeToJSON(
  object: BankAccountDetailsType,
): string {
  switch (object) {
    case BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_INVALID:
      return 'BANK_ACCOUNT_DETAILS_TYPE_INVALID';
    case BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_SCAN:
      return 'BANK_ACCOUNT_DETAILS_TYPE_SCAN';
    case BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_RNAN:
      return 'BANK_ACCOUNT_DETAILS_TYPE_RNAN';
    case BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_INTERNAL:
      return 'BANK_ACCOUNT_DETAILS_TYPE_INTERNAL';
    case BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_IBAN:
      return 'BANK_ACCOUNT_DETAILS_TYPE_IBAN';
    case BankAccountDetailsType.BANK_ACCOUNT_DETAILS_TYPE_SWIFT:
      return 'BANK_ACCOUNT_DETAILS_TYPE_SWIFT';
    case BankAccountDetailsType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** OrderingDirection is the direction of ordering. */
export enum OrderingDirection {
  ORDERING_DIRECTION_INVALID = 0,
  ORDERING_DIRECTION_ASC = 1,
  ORDERING_DIRECTION_DESC = 2,
  UNRECOGNIZED = -1,
}

export function orderingDirectionFromJSON(object: any): OrderingDirection {
  switch (object) {
    case 0:
    case 'ORDERING_DIRECTION_INVALID':
      return OrderingDirection.ORDERING_DIRECTION_INVALID;
    case 1:
    case 'ORDERING_DIRECTION_ASC':
      return OrderingDirection.ORDERING_DIRECTION_ASC;
    case 2:
    case 'ORDERING_DIRECTION_DESC':
      return OrderingDirection.ORDERING_DIRECTION_DESC;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OrderingDirection.UNRECOGNIZED;
  }
}

export function orderingDirectionToJSON(object: OrderingDirection): string {
  switch (object) {
    case OrderingDirection.ORDERING_DIRECTION_INVALID:
      return 'ORDERING_DIRECTION_INVALID';
    case OrderingDirection.ORDERING_DIRECTION_ASC:
      return 'ORDERING_DIRECTION_ASC';
    case OrderingDirection.ORDERING_DIRECTION_DESC:
      return 'ORDERING_DIRECTION_DESC';
    case OrderingDirection.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface TimeRange {
  min: Timestamp | undefined;
  max: Timestamp | undefined;
  minInclusive: boolean;
  maxInclusive: boolean;
}

/** NoticeStyling allows specifying a Notice's colours and icons. */
export interface NoticeStyling {
  /** Optional. If not specified, frontend should use default styling. */
  text: ColourStyling | undefined;
  /** If empty, no icon should be displayed. */
  primaryIconStack: Icon[];
  /** If empty, no icon should be displayed. */
  descriptionIconStack: Icon[];
}

/**
 * A generic, localised notice to display to the user.
 * The difference to a BannerItem is that Notice is more lightweight.
 * A Notice can be considered empty if the title is empty.
 */
export interface Notice {
  /** Mandatory */
  title: string;
  subtitle: string;
  /** Description should be shown in a pop-up when the notice is opened. */
  description: string;
  /** Optional */
  styling: NoticeStyling | undefined;
}

/** ETA contains the estimated time of arrival for a transaction. */
export interface ETA {
  type: ETAType;
  time: Timestamp | undefined;
  maxTime: Timestamp | undefined;
  reliability: ETAReliabilityType;
  reliabilityNotice: Notice | undefined;
}

/** ServiceAvailability & its Notice. */
export interface ServiceAvailabilityNotice {
  availability: ServiceAvailability;
  /** Optional */
  notice: Notice | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Limit {
  sum: string;
  count: number;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface IntervalLimit {
  block: Limit | undefined;
  hold: Limit | undefined;
  alert: Limit | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface SizeLimit {
  block: string;
  hold: string;
  alert: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface CurrencyLimits {
  intervalLimits: { [key: string]: IntervalLimit };
}

export interface CurrencyLimits_IntervalLimitsEntry {
  key: string;
  value: IntervalLimit | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Limits {
  velocity: { [key: string]: CurrencyLimits };
  maxAmount: { [key: string]: SizeLimit };
}

export interface Limits_VelocityEntry {
  key: string;
  value: CurrencyLimits | undefined;
}

export interface Limits_MaxAmountEntry {
  key: string;
  value: SizeLimit | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface UserAmountLimit {
  type: UserLimitType;
  max: Money | undefined;
  current: Money | undefined;
  remaining: Money | undefined;
  min: Money | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface UserLimit {
  type: UserLimitType;
  max: number;
  current: number;
  remaining: number;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface UserLimits {
  amount: UserAmountLimit | undefined;
  count: UserLimit | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Stats {
  velocity: { [key: string]: CurrencyStats };
  computedAt: Timestamp | undefined;
  from: Timestamp | undefined;
}

export interface Stats_VelocityEntry {
  key: string;
  value: CurrencyStats | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface IntervalStat {
  sum: string;
  count: number;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface CurrencyStats {
  intervalStats: { [key: string]: IntervalStat };
}

export interface CurrencyStats_IntervalStatsEntry {
  key: string;
  value: IntervalStat | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface BankAccountDetails {
  type: BankAccountDetailsType;
  accountName: string;
  institution: string;
  scan?: BankAccountDetails_SCAN | undefined;
  rnan?: BankAccountDetails_RNAN | undefined;
  internal?: BankAccountDetails_Internal | undefined;
  iban?: BankAccountDetails_IBAN | undefined;
  swift?: BankAccountDetails_SWIFT | undefined;
  branchName: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface BankAccountDetails_RNAN {
  routingNumber: string;
  accountNumber: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface BankAccountDetails_SCAN {
  sortCode: string;
  accountNumber: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface BankAccountDetails_Internal {
  bankCode: string;
  branchCode: string;
  accountNumber: string;
}

/** IBAN represents a bank account's International Bank Account Number and an optional Business Identifier Code (BIC). */
export interface BankAccountDetails_IBAN {
  iban: string;
  /** bic is optional */
  bic: string;
}

/** SWIFT represents an international bank account for the SWIFT network. */
export interface BankAccountDetails_SWIFT {
  accountType: BankAccountType;
  bankAddress: string;
  swiftReference: string;
  accountNumber: string;
}

/** PagePagination contains pagination information for a page. */
export interface PagePagination {
  page: number;
  perPage: number;
  total: number;
  totalPages: number;
  count: number;
}

/** PagePaginationParams contains page pagination params. */
export interface PagePaginationParams {
  page: number;
  perPage: number;
}

function createBaseTimeRange(): TimeRange {
  return {
    min: undefined,
    max: undefined,
    minInclusive: false,
    maxInclusive: false,
  };
}

export const TimeRange = {
  encode(
    message: TimeRange,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.min !== undefined) {
      Timestamp.encode(message.min, writer.uint32(10).fork()).ldelim();
    }
    if (message.max !== undefined) {
      Timestamp.encode(message.max, writer.uint32(18).fork()).ldelim();
    }
    if (message.minInclusive === true) {
      writer.uint32(24).bool(message.minInclusive);
    }
    if (message.maxInclusive === true) {
      writer.uint32(32).bool(message.maxInclusive);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TimeRange {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimeRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.min = Timestamp.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.max = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.minInclusive = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxInclusive = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TimeRange {
    return {
      min: isSet(object.min) ? fromJsonTimestamp(object.min) : undefined,
      max: isSet(object.max) ? fromJsonTimestamp(object.max) : undefined,
      minInclusive: isSet(object.minInclusive)
        ? Boolean(object.minInclusive)
        : false,
      maxInclusive: isSet(object.maxInclusive)
        ? Boolean(object.maxInclusive)
        : false,
    };
  },

  toJSON(message: TimeRange): unknown {
    const obj: any = {};
    message.min !== undefined &&
      (obj.min = fromTimestamp(message.min).toISOString());
    message.max !== undefined &&
      (obj.max = fromTimestamp(message.max).toISOString());
    message.minInclusive !== undefined &&
      (obj.minInclusive = message.minInclusive);
    message.maxInclusive !== undefined &&
      (obj.maxInclusive = message.maxInclusive);
    return obj;
  },

  create(base?: DeepPartial<TimeRange>): TimeRange {
    return TimeRange.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TimeRange>): TimeRange {
    const message = createBaseTimeRange();
    message.min =
      object.min !== undefined && object.min !== null
        ? Timestamp.fromPartial(object.min)
        : undefined;
    message.max =
      object.max !== undefined && object.max !== null
        ? Timestamp.fromPartial(object.max)
        : undefined;
    message.minInclusive = object.minInclusive ?? false;
    message.maxInclusive = object.maxInclusive ?? false;
    return message;
  },
};

function createBaseNoticeStyling(): NoticeStyling {
  return { text: undefined, primaryIconStack: [], descriptionIconStack: [] };
}

export const NoticeStyling = {
  encode(
    message: NoticeStyling,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.text !== undefined) {
      ColourStyling.encode(message.text, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.primaryIconStack) {
      Icon.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.descriptionIconStack) {
      Icon.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NoticeStyling {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNoticeStyling();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.text = ColourStyling.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.primaryIconStack.push(Icon.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.descriptionIconStack.push(
            Icon.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NoticeStyling {
    return {
      text: isSet(object.text)
        ? ColourStyling.fromJSON(object.text)
        : undefined,
      primaryIconStack: Array.isArray(object?.primaryIconStack)
        ? object.primaryIconStack.map((e: any) => Icon.fromJSON(e))
        : [],
      descriptionIconStack: Array.isArray(object?.descriptionIconStack)
        ? object.descriptionIconStack.map((e: any) => Icon.fromJSON(e))
        : [],
    };
  },

  toJSON(message: NoticeStyling): unknown {
    const obj: any = {};
    message.text !== undefined &&
      (obj.text = message.text
        ? ColourStyling.toJSON(message.text)
        : undefined);
    if (message.primaryIconStack) {
      obj.primaryIconStack = message.primaryIconStack.map((e) =>
        e ? Icon.toJSON(e) : undefined,
      );
    } else {
      obj.primaryIconStack = [];
    }
    if (message.descriptionIconStack) {
      obj.descriptionIconStack = message.descriptionIconStack.map((e) =>
        e ? Icon.toJSON(e) : undefined,
      );
    } else {
      obj.descriptionIconStack = [];
    }
    return obj;
  },

  create(base?: DeepPartial<NoticeStyling>): NoticeStyling {
    return NoticeStyling.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<NoticeStyling>): NoticeStyling {
    const message = createBaseNoticeStyling();
    message.text =
      object.text !== undefined && object.text !== null
        ? ColourStyling.fromPartial(object.text)
        : undefined;
    message.primaryIconStack =
      object.primaryIconStack?.map((e) => Icon.fromPartial(e)) || [];
    message.descriptionIconStack =
      object.descriptionIconStack?.map((e) => Icon.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNotice(): Notice {
  return { title: '', subtitle: '', description: '', styling: undefined };
}

export const Notice = {
  encode(
    message: Notice,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.title !== '') {
      writer.uint32(10).string(message.title);
    }
    if (message.subtitle !== '') {
      writer.uint32(18).string(message.subtitle);
    }
    if (message.description !== '') {
      writer.uint32(26).string(message.description);
    }
    if (message.styling !== undefined) {
      NoticeStyling.encode(message.styling, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Notice {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subtitle = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.styling = NoticeStyling.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Notice {
    return {
      title: isSet(object.title) ? String(object.title) : '',
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : '',
      description: isSet(object.description) ? String(object.description) : '',
      styling: isSet(object.styling)
        ? NoticeStyling.fromJSON(object.styling)
        : undefined,
    };
  },

  toJSON(message: Notice): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.description !== undefined &&
      (obj.description = message.description);
    message.styling !== undefined &&
      (obj.styling = message.styling
        ? NoticeStyling.toJSON(message.styling)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<Notice>): Notice {
    return Notice.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Notice>): Notice {
    const message = createBaseNotice();
    message.title = object.title ?? '';
    message.subtitle = object.subtitle ?? '';
    message.description = object.description ?? '';
    message.styling =
      object.styling !== undefined && object.styling !== null
        ? NoticeStyling.fromPartial(object.styling)
        : undefined;
    return message;
  },
};

function createBaseETA(): ETA {
  return {
    type: 0,
    time: undefined,
    maxTime: undefined,
    reliability: 0,
    reliabilityNotice: undefined,
  };
}

export const ETA = {
  encode(message: ETA, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.time !== undefined) {
      Timestamp.encode(message.time, writer.uint32(18).fork()).ldelim();
    }
    if (message.maxTime !== undefined) {
      Timestamp.encode(message.maxTime, writer.uint32(26).fork()).ldelim();
    }
    if (message.reliability !== 0) {
      writer.uint32(32).int32(message.reliability);
    }
    if (message.reliabilityNotice !== undefined) {
      Notice.encode(
        message.reliabilityNotice,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ETA {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseETA();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.time = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.maxTime = Timestamp.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.reliability = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.reliabilityNotice = Notice.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ETA {
    return {
      type: isSet(object.type) ? eTATypeFromJSON(object.type) : 0,
      time: isSet(object.time) ? fromJsonTimestamp(object.time) : undefined,
      maxTime: isSet(object.maxTime)
        ? fromJsonTimestamp(object.maxTime)
        : undefined,
      reliability: isSet(object.reliability)
        ? eTAReliabilityTypeFromJSON(object.reliability)
        : 0,
      reliabilityNotice: isSet(object.reliabilityNotice)
        ? Notice.fromJSON(object.reliabilityNotice)
        : undefined,
    };
  },

  toJSON(message: ETA): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = eTATypeToJSON(message.type));
    message.time !== undefined &&
      (obj.time = fromTimestamp(message.time).toISOString());
    message.maxTime !== undefined &&
      (obj.maxTime = fromTimestamp(message.maxTime).toISOString());
    message.reliability !== undefined &&
      (obj.reliability = eTAReliabilityTypeToJSON(message.reliability));
    message.reliabilityNotice !== undefined &&
      (obj.reliabilityNotice = message.reliabilityNotice
        ? Notice.toJSON(message.reliabilityNotice)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<ETA>): ETA {
    return ETA.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ETA>): ETA {
    const message = createBaseETA();
    message.type = object.type ?? 0;
    message.time =
      object.time !== undefined && object.time !== null
        ? Timestamp.fromPartial(object.time)
        : undefined;
    message.maxTime =
      object.maxTime !== undefined && object.maxTime !== null
        ? Timestamp.fromPartial(object.maxTime)
        : undefined;
    message.reliability = object.reliability ?? 0;
    message.reliabilityNotice =
      object.reliabilityNotice !== undefined &&
      object.reliabilityNotice !== null
        ? Notice.fromPartial(object.reliabilityNotice)
        : undefined;
    return message;
  },
};

function createBaseServiceAvailabilityNotice(): ServiceAvailabilityNotice {
  return { availability: 0, notice: undefined };
}

export const ServiceAvailabilityNotice = {
  encode(
    message: ServiceAvailabilityNotice,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.availability !== 0) {
      writer.uint32(8).int32(message.availability);
    }
    if (message.notice !== undefined) {
      Notice.encode(message.notice, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ServiceAvailabilityNotice {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceAvailabilityNotice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.availability = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.notice = Notice.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServiceAvailabilityNotice {
    return {
      availability: isSet(object.availability)
        ? serviceAvailabilityFromJSON(object.availability)
        : 0,
      notice: isSet(object.notice) ? Notice.fromJSON(object.notice) : undefined,
    };
  },

  toJSON(message: ServiceAvailabilityNotice): unknown {
    const obj: any = {};
    message.availability !== undefined &&
      (obj.availability = serviceAvailabilityToJSON(message.availability));
    message.notice !== undefined &&
      (obj.notice = message.notice ? Notice.toJSON(message.notice) : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<ServiceAvailabilityNotice>,
  ): ServiceAvailabilityNotice {
    return ServiceAvailabilityNotice.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ServiceAvailabilityNotice>,
  ): ServiceAvailabilityNotice {
    const message = createBaseServiceAvailabilityNotice();
    message.availability = object.availability ?? 0;
    message.notice =
      object.notice !== undefined && object.notice !== null
        ? Notice.fromPartial(object.notice)
        : undefined;
    return message;
  },
};

function createBaseLimit(): Limit {
  return { sum: '', count: 0 };
}

export const Limit = {
  encode(message: Limit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sum !== '') {
      writer.uint32(10).string(message.sum);
    }
    if (message.count !== 0) {
      writer.uint32(16).int32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Limit {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sum = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Limit {
    return {
      sum: isSet(object.sum) ? String(object.sum) : '',
      count: isSet(object.count) ? Number(object.count) : 0,
    };
  },

  toJSON(message: Limit): unknown {
    const obj: any = {};
    message.sum !== undefined && (obj.sum = message.sum);
    message.count !== undefined && (obj.count = Math.round(message.count));
    return obj;
  },

  create(base?: DeepPartial<Limit>): Limit {
    return Limit.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Limit>): Limit {
    const message = createBaseLimit();
    message.sum = object.sum ?? '';
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseIntervalLimit(): IntervalLimit {
  return { block: undefined, hold: undefined, alert: undefined };
}

export const IntervalLimit = {
  encode(
    message: IntervalLimit,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.block !== undefined) {
      Limit.encode(message.block, writer.uint32(10).fork()).ldelim();
    }
    if (message.hold !== undefined) {
      Limit.encode(message.hold, writer.uint32(18).fork()).ldelim();
    }
    if (message.alert !== undefined) {
      Limit.encode(message.alert, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IntervalLimit {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIntervalLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.block = Limit.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hold = Limit.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.alert = Limit.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IntervalLimit {
    return {
      block: isSet(object.block) ? Limit.fromJSON(object.block) : undefined,
      hold: isSet(object.hold) ? Limit.fromJSON(object.hold) : undefined,
      alert: isSet(object.alert) ? Limit.fromJSON(object.alert) : undefined,
    };
  },

  toJSON(message: IntervalLimit): unknown {
    const obj: any = {};
    message.block !== undefined &&
      (obj.block = message.block ? Limit.toJSON(message.block) : undefined);
    message.hold !== undefined &&
      (obj.hold = message.hold ? Limit.toJSON(message.hold) : undefined);
    message.alert !== undefined &&
      (obj.alert = message.alert ? Limit.toJSON(message.alert) : undefined);
    return obj;
  },

  create(base?: DeepPartial<IntervalLimit>): IntervalLimit {
    return IntervalLimit.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<IntervalLimit>): IntervalLimit {
    const message = createBaseIntervalLimit();
    message.block =
      object.block !== undefined && object.block !== null
        ? Limit.fromPartial(object.block)
        : undefined;
    message.hold =
      object.hold !== undefined && object.hold !== null
        ? Limit.fromPartial(object.hold)
        : undefined;
    message.alert =
      object.alert !== undefined && object.alert !== null
        ? Limit.fromPartial(object.alert)
        : undefined;
    return message;
  },
};

function createBaseSizeLimit(): SizeLimit {
  return { block: '', hold: '', alert: '' };
}

export const SizeLimit = {
  encode(
    message: SizeLimit,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.block !== '') {
      writer.uint32(10).string(message.block);
    }
    if (message.hold !== '') {
      writer.uint32(18).string(message.hold);
    }
    if (message.alert !== '') {
      writer.uint32(26).string(message.alert);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SizeLimit {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSizeLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.block = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hold = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.alert = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SizeLimit {
    return {
      block: isSet(object.block) ? String(object.block) : '',
      hold: isSet(object.hold) ? String(object.hold) : '',
      alert: isSet(object.alert) ? String(object.alert) : '',
    };
  },

  toJSON(message: SizeLimit): unknown {
    const obj: any = {};
    message.block !== undefined && (obj.block = message.block);
    message.hold !== undefined && (obj.hold = message.hold);
    message.alert !== undefined && (obj.alert = message.alert);
    return obj;
  },

  create(base?: DeepPartial<SizeLimit>): SizeLimit {
    return SizeLimit.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SizeLimit>): SizeLimit {
    const message = createBaseSizeLimit();
    message.block = object.block ?? '';
    message.hold = object.hold ?? '';
    message.alert = object.alert ?? '';
    return message;
  },
};

function createBaseCurrencyLimits(): CurrencyLimits {
  return { intervalLimits: {} };
}

export const CurrencyLimits = {
  encode(
    message: CurrencyLimits,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.intervalLimits).forEach(([key, value]) => {
      CurrencyLimits_IntervalLimitsEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyLimits {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyLimits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = CurrencyLimits_IntervalLimitsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry1.value !== undefined) {
            message.intervalLimits[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CurrencyLimits {
    return {
      intervalLimits: isObject(object.intervalLimits)
        ? Object.entries(object.intervalLimits).reduce<{
            [key: string]: IntervalLimit;
          }>((acc, [key, value]) => {
            acc[key] = IntervalLimit.fromJSON(value);
            return acc;
          }, {})
        : {},
    };
  },

  toJSON(message: CurrencyLimits): unknown {
    const obj: any = {};
    obj.intervalLimits = {};
    if (message.intervalLimits) {
      Object.entries(message.intervalLimits).forEach(([k, v]) => {
        obj.intervalLimits[k] = IntervalLimit.toJSON(v);
      });
    }
    return obj;
  },

  create(base?: DeepPartial<CurrencyLimits>): CurrencyLimits {
    return CurrencyLimits.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CurrencyLimits>): CurrencyLimits {
    const message = createBaseCurrencyLimits();
    message.intervalLimits = Object.entries(
      object.intervalLimits ?? {},
    ).reduce<{ [key: string]: IntervalLimit }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = IntervalLimit.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseCurrencyLimits_IntervalLimitsEntry(): CurrencyLimits_IntervalLimitsEntry {
  return { key: '', value: undefined };
}

export const CurrencyLimits_IntervalLimitsEntry = {
  encode(
    message: CurrencyLimits_IntervalLimitsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      IntervalLimit.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CurrencyLimits_IntervalLimitsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyLimits_IntervalLimitsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = IntervalLimit.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CurrencyLimits_IntervalLimitsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? IntervalLimit.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(message: CurrencyLimits_IntervalLimitsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? IntervalLimit.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<CurrencyLimits_IntervalLimitsEntry>,
  ): CurrencyLimits_IntervalLimitsEntry {
    return CurrencyLimits_IntervalLimitsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CurrencyLimits_IntervalLimitsEntry>,
  ): CurrencyLimits_IntervalLimitsEntry {
    const message = createBaseCurrencyLimits_IntervalLimitsEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? IntervalLimit.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseLimits(): Limits {
  return { velocity: {}, maxAmount: {} };
}

export const Limits = {
  encode(
    message: Limits,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.velocity).forEach(([key, value]) => {
      Limits_VelocityEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    Object.entries(message.maxAmount).forEach(([key, value]) => {
      Limits_MaxAmountEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Limits {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLimits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = Limits_VelocityEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.velocity[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = Limits_MaxAmountEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.maxAmount[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Limits {
    return {
      velocity: isObject(object.velocity)
        ? Object.entries(object.velocity).reduce<{
            [key: string]: CurrencyLimits;
          }>((acc, [key, value]) => {
            acc[key] = CurrencyLimits.fromJSON(value);
            return acc;
          }, {})
        : {},
      maxAmount: isObject(object.maxAmount)
        ? Object.entries(object.maxAmount).reduce<{ [key: string]: SizeLimit }>(
            (acc, [key, value]) => {
              acc[key] = SizeLimit.fromJSON(value);
              return acc;
            },
            {},
          )
        : {},
    };
  },

  toJSON(message: Limits): unknown {
    const obj: any = {};
    obj.velocity = {};
    if (message.velocity) {
      Object.entries(message.velocity).forEach(([k, v]) => {
        obj.velocity[k] = CurrencyLimits.toJSON(v);
      });
    }
    obj.maxAmount = {};
    if (message.maxAmount) {
      Object.entries(message.maxAmount).forEach(([k, v]) => {
        obj.maxAmount[k] = SizeLimit.toJSON(v);
      });
    }
    return obj;
  },

  create(base?: DeepPartial<Limits>): Limits {
    return Limits.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Limits>): Limits {
    const message = createBaseLimits();
    message.velocity = Object.entries(object.velocity ?? {}).reduce<{
      [key: string]: CurrencyLimits;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = CurrencyLimits.fromPartial(value);
      }
      return acc;
    }, {});
    message.maxAmount = Object.entries(object.maxAmount ?? {}).reduce<{
      [key: string]: SizeLimit;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = SizeLimit.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseLimits_VelocityEntry(): Limits_VelocityEntry {
  return { key: '', value: undefined };
}

export const Limits_VelocityEntry = {
  encode(
    message: Limits_VelocityEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      CurrencyLimits.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Limits_VelocityEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLimits_VelocityEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = CurrencyLimits.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Limits_VelocityEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? CurrencyLimits.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(message: Limits_VelocityEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? CurrencyLimits.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<Limits_VelocityEntry>): Limits_VelocityEntry {
    return Limits_VelocityEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Limits_VelocityEntry>): Limits_VelocityEntry {
    const message = createBaseLimits_VelocityEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? CurrencyLimits.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseLimits_MaxAmountEntry(): Limits_MaxAmountEntry {
  return { key: '', value: undefined };
}

export const Limits_MaxAmountEntry = {
  encode(
    message: Limits_MaxAmountEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      SizeLimit.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Limits_MaxAmountEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLimits_MaxAmountEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = SizeLimit.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Limits_MaxAmountEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? SizeLimit.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Limits_MaxAmountEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? SizeLimit.toJSON(message.value) : undefined);
    return obj;
  },

  create(base?: DeepPartial<Limits_MaxAmountEntry>): Limits_MaxAmountEntry {
    return Limits_MaxAmountEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<Limits_MaxAmountEntry>,
  ): Limits_MaxAmountEntry {
    const message = createBaseLimits_MaxAmountEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? SizeLimit.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseUserAmountLimit(): UserAmountLimit {
  return {
    type: 0,
    max: undefined,
    current: undefined,
    remaining: undefined,
    min: undefined,
  };
}

export const UserAmountLimit = {
  encode(
    message: UserAmountLimit,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.max !== undefined) {
      Money.encode(message.max, writer.uint32(18).fork()).ldelim();
    }
    if (message.current !== undefined) {
      Money.encode(message.current, writer.uint32(26).fork()).ldelim();
    }
    if (message.remaining !== undefined) {
      Money.encode(message.remaining, writer.uint32(34).fork()).ldelim();
    }
    if (message.min !== undefined) {
      Money.encode(message.min, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAmountLimit {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAmountLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.max = Money.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.current = Money.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.remaining = Money.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.min = Money.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserAmountLimit {
    return {
      type: isSet(object.type) ? userLimitTypeFromJSON(object.type) : 0,
      max: isSet(object.max) ? Money.fromJSON(object.max) : undefined,
      current: isSet(object.current)
        ? Money.fromJSON(object.current)
        : undefined,
      remaining: isSet(object.remaining)
        ? Money.fromJSON(object.remaining)
        : undefined,
      min: isSet(object.min) ? Money.fromJSON(object.min) : undefined,
    };
  },

  toJSON(message: UserAmountLimit): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = userLimitTypeToJSON(message.type));
    message.max !== undefined &&
      (obj.max = message.max ? Money.toJSON(message.max) : undefined);
    message.current !== undefined &&
      (obj.current = message.current
        ? Money.toJSON(message.current)
        : undefined);
    message.remaining !== undefined &&
      (obj.remaining = message.remaining
        ? Money.toJSON(message.remaining)
        : undefined);
    message.min !== undefined &&
      (obj.min = message.min ? Money.toJSON(message.min) : undefined);
    return obj;
  },

  create(base?: DeepPartial<UserAmountLimit>): UserAmountLimit {
    return UserAmountLimit.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UserAmountLimit>): UserAmountLimit {
    const message = createBaseUserAmountLimit();
    message.type = object.type ?? 0;
    message.max =
      object.max !== undefined && object.max !== null
        ? Money.fromPartial(object.max)
        : undefined;
    message.current =
      object.current !== undefined && object.current !== null
        ? Money.fromPartial(object.current)
        : undefined;
    message.remaining =
      object.remaining !== undefined && object.remaining !== null
        ? Money.fromPartial(object.remaining)
        : undefined;
    message.min =
      object.min !== undefined && object.min !== null
        ? Money.fromPartial(object.min)
        : undefined;
    return message;
  },
};

function createBaseUserLimit(): UserLimit {
  return { type: 0, max: 0, current: 0, remaining: 0 };
}

export const UserLimit = {
  encode(
    message: UserLimit,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.max !== 0) {
      writer.uint32(16).int64(message.max);
    }
    if (message.current !== 0) {
      writer.uint32(24).int64(message.current);
    }
    if (message.remaining !== 0) {
      writer.uint32(32).int64(message.remaining);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserLimit {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.max = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.current = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.remaining = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserLimit {
    return {
      type: isSet(object.type) ? userLimitTypeFromJSON(object.type) : 0,
      max: isSet(object.max) ? Number(object.max) : 0,
      current: isSet(object.current) ? Number(object.current) : 0,
      remaining: isSet(object.remaining) ? Number(object.remaining) : 0,
    };
  },

  toJSON(message: UserLimit): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = userLimitTypeToJSON(message.type));
    message.max !== undefined && (obj.max = Math.round(message.max));
    message.current !== undefined &&
      (obj.current = Math.round(message.current));
    message.remaining !== undefined &&
      (obj.remaining = Math.round(message.remaining));
    return obj;
  },

  create(base?: DeepPartial<UserLimit>): UserLimit {
    return UserLimit.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UserLimit>): UserLimit {
    const message = createBaseUserLimit();
    message.type = object.type ?? 0;
    message.max = object.max ?? 0;
    message.current = object.current ?? 0;
    message.remaining = object.remaining ?? 0;
    return message;
  },
};

function createBaseUserLimits(): UserLimits {
  return { amount: undefined, count: undefined };
}

export const UserLimits = {
  encode(
    message: UserLimits,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.amount !== undefined) {
      UserAmountLimit.encode(message.amount, writer.uint32(26).fork()).ldelim();
    }
    if (message.count !== undefined) {
      UserLimit.encode(message.count, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserLimits {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserLimits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.amount = UserAmountLimit.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.count = UserLimit.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserLimits {
    return {
      amount: isSet(object.amount)
        ? UserAmountLimit.fromJSON(object.amount)
        : undefined,
      count: isSet(object.count) ? UserLimit.fromJSON(object.count) : undefined,
    };
  },

  toJSON(message: UserLimits): unknown {
    const obj: any = {};
    message.amount !== undefined &&
      (obj.amount = message.amount
        ? UserAmountLimit.toJSON(message.amount)
        : undefined);
    message.count !== undefined &&
      (obj.count = message.count ? UserLimit.toJSON(message.count) : undefined);
    return obj;
  },

  create(base?: DeepPartial<UserLimits>): UserLimits {
    return UserLimits.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UserLimits>): UserLimits {
    const message = createBaseUserLimits();
    message.amount =
      object.amount !== undefined && object.amount !== null
        ? UserAmountLimit.fromPartial(object.amount)
        : undefined;
    message.count =
      object.count !== undefined && object.count !== null
        ? UserLimit.fromPartial(object.count)
        : undefined;
    return message;
  },
};

function createBaseStats(): Stats {
  return { velocity: {}, computedAt: undefined, from: undefined };
}

export const Stats = {
  encode(message: Stats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.velocity).forEach(([key, value]) => {
      Stats_VelocityEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    if (message.computedAt !== undefined) {
      Timestamp.encode(message.computedAt, writer.uint32(18).fork()).ldelim();
    }
    if (message.from !== undefined) {
      Timestamp.encode(message.from, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Stats {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = Stats_VelocityEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.velocity[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.computedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.from = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Stats {
    return {
      velocity: isObject(object.velocity)
        ? Object.entries(object.velocity).reduce<{
            [key: string]: CurrencyStats;
          }>((acc, [key, value]) => {
            acc[key] = CurrencyStats.fromJSON(value);
            return acc;
          }, {})
        : {},
      computedAt: isSet(object.computedAt)
        ? fromJsonTimestamp(object.computedAt)
        : undefined,
      from: isSet(object.from) ? fromJsonTimestamp(object.from) : undefined,
    };
  },

  toJSON(message: Stats): unknown {
    const obj: any = {};
    obj.velocity = {};
    if (message.velocity) {
      Object.entries(message.velocity).forEach(([k, v]) => {
        obj.velocity[k] = CurrencyStats.toJSON(v);
      });
    }
    message.computedAt !== undefined &&
      (obj.computedAt = fromTimestamp(message.computedAt).toISOString());
    message.from !== undefined &&
      (obj.from = fromTimestamp(message.from).toISOString());
    return obj;
  },

  create(base?: DeepPartial<Stats>): Stats {
    return Stats.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Stats>): Stats {
    const message = createBaseStats();
    message.velocity = Object.entries(object.velocity ?? {}).reduce<{
      [key: string]: CurrencyStats;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = CurrencyStats.fromPartial(value);
      }
      return acc;
    }, {});
    message.computedAt =
      object.computedAt !== undefined && object.computedAt !== null
        ? Timestamp.fromPartial(object.computedAt)
        : undefined;
    message.from =
      object.from !== undefined && object.from !== null
        ? Timestamp.fromPartial(object.from)
        : undefined;
    return message;
  },
};

function createBaseStats_VelocityEntry(): Stats_VelocityEntry {
  return { key: '', value: undefined };
}

export const Stats_VelocityEntry = {
  encode(
    message: Stats_VelocityEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      CurrencyStats.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Stats_VelocityEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStats_VelocityEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = CurrencyStats.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Stats_VelocityEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? CurrencyStats.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(message: Stats_VelocityEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? CurrencyStats.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<Stats_VelocityEntry>): Stats_VelocityEntry {
    return Stats_VelocityEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Stats_VelocityEntry>): Stats_VelocityEntry {
    const message = createBaseStats_VelocityEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? CurrencyStats.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseIntervalStat(): IntervalStat {
  return { sum: '', count: 0 };
}

export const IntervalStat = {
  encode(
    message: IntervalStat,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.sum !== '') {
      writer.uint32(10).string(message.sum);
    }
    if (message.count !== 0) {
      writer.uint32(16).int32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IntervalStat {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIntervalStat();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sum = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IntervalStat {
    return {
      sum: isSet(object.sum) ? String(object.sum) : '',
      count: isSet(object.count) ? Number(object.count) : 0,
    };
  },

  toJSON(message: IntervalStat): unknown {
    const obj: any = {};
    message.sum !== undefined && (obj.sum = message.sum);
    message.count !== undefined && (obj.count = Math.round(message.count));
    return obj;
  },

  create(base?: DeepPartial<IntervalStat>): IntervalStat {
    return IntervalStat.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<IntervalStat>): IntervalStat {
    const message = createBaseIntervalStat();
    message.sum = object.sum ?? '';
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseCurrencyStats(): CurrencyStats {
  return { intervalStats: {} };
}

export const CurrencyStats = {
  encode(
    message: CurrencyStats,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.intervalStats).forEach(([key, value]) => {
      CurrencyStats_IntervalStatsEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyStats {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = CurrencyStats_IntervalStatsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry1.value !== undefined) {
            message.intervalStats[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CurrencyStats {
    return {
      intervalStats: isObject(object.intervalStats)
        ? Object.entries(object.intervalStats).reduce<{
            [key: string]: IntervalStat;
          }>((acc, [key, value]) => {
            acc[key] = IntervalStat.fromJSON(value);
            return acc;
          }, {})
        : {},
    };
  },

  toJSON(message: CurrencyStats): unknown {
    const obj: any = {};
    obj.intervalStats = {};
    if (message.intervalStats) {
      Object.entries(message.intervalStats).forEach(([k, v]) => {
        obj.intervalStats[k] = IntervalStat.toJSON(v);
      });
    }
    return obj;
  },

  create(base?: DeepPartial<CurrencyStats>): CurrencyStats {
    return CurrencyStats.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CurrencyStats>): CurrencyStats {
    const message = createBaseCurrencyStats();
    message.intervalStats = Object.entries(object.intervalStats ?? {}).reduce<{
      [key: string]: IntervalStat;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = IntervalStat.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseCurrencyStats_IntervalStatsEntry(): CurrencyStats_IntervalStatsEntry {
  return { key: '', value: undefined };
}

export const CurrencyStats_IntervalStatsEntry = {
  encode(
    message: CurrencyStats_IntervalStatsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      IntervalStat.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CurrencyStats_IntervalStatsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyStats_IntervalStatsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = IntervalStat.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CurrencyStats_IntervalStatsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? IntervalStat.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(message: CurrencyStats_IntervalStatsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? IntervalStat.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<CurrencyStats_IntervalStatsEntry>,
  ): CurrencyStats_IntervalStatsEntry {
    return CurrencyStats_IntervalStatsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CurrencyStats_IntervalStatsEntry>,
  ): CurrencyStats_IntervalStatsEntry {
    const message = createBaseCurrencyStats_IntervalStatsEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? IntervalStat.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseBankAccountDetails(): BankAccountDetails {
  return {
    type: 0,
    accountName: '',
    institution: '',
    scan: undefined,
    rnan: undefined,
    internal: undefined,
    iban: undefined,
    swift: undefined,
    branchName: '',
  };
}

export const BankAccountDetails = {
  encode(
    message: BankAccountDetails,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.accountName !== '') {
      writer.uint32(18).string(message.accountName);
    }
    if (message.institution !== '') {
      writer.uint32(26).string(message.institution);
    }
    if (message.scan !== undefined) {
      BankAccountDetails_SCAN.encode(
        message.scan,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.rnan !== undefined) {
      BankAccountDetails_RNAN.encode(
        message.rnan,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.internal !== undefined) {
      BankAccountDetails_Internal.encode(
        message.internal,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.iban !== undefined) {
      BankAccountDetails_IBAN.encode(
        message.iban,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.swift !== undefined) {
      BankAccountDetails_SWIFT.encode(
        message.swift,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    if (message.branchName !== '') {
      writer.uint32(58).string(message.branchName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BankAccountDetails {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAccountDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.institution = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.scan = BankAccountDetails_SCAN.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.rnan = BankAccountDetails_RNAN.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.internal = BankAccountDetails_Internal.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.iban = BankAccountDetails_IBAN.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.swift = BankAccountDetails_SWIFT.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.branchName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BankAccountDetails {
    return {
      type: isSet(object.type)
        ? bankAccountDetailsTypeFromJSON(object.type)
        : 0,
      accountName: isSet(object.accountName) ? String(object.accountName) : '',
      institution: isSet(object.institution) ? String(object.institution) : '',
      scan: isSet(object.scan)
        ? BankAccountDetails_SCAN.fromJSON(object.scan)
        : undefined,
      rnan: isSet(object.rnan)
        ? BankAccountDetails_RNAN.fromJSON(object.rnan)
        : undefined,
      internal: isSet(object.internal)
        ? BankAccountDetails_Internal.fromJSON(object.internal)
        : undefined,
      iban: isSet(object.iban)
        ? BankAccountDetails_IBAN.fromJSON(object.iban)
        : undefined,
      swift: isSet(object.swift)
        ? BankAccountDetails_SWIFT.fromJSON(object.swift)
        : undefined,
      branchName: isSet(object.branchName) ? String(object.branchName) : '',
    };
  },

  toJSON(message: BankAccountDetails): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = bankAccountDetailsTypeToJSON(message.type));
    message.accountName !== undefined &&
      (obj.accountName = message.accountName);
    message.institution !== undefined &&
      (obj.institution = message.institution);
    message.scan !== undefined &&
      (obj.scan = message.scan
        ? BankAccountDetails_SCAN.toJSON(message.scan)
        : undefined);
    message.rnan !== undefined &&
      (obj.rnan = message.rnan
        ? BankAccountDetails_RNAN.toJSON(message.rnan)
        : undefined);
    message.internal !== undefined &&
      (obj.internal = message.internal
        ? BankAccountDetails_Internal.toJSON(message.internal)
        : undefined);
    message.iban !== undefined &&
      (obj.iban = message.iban
        ? BankAccountDetails_IBAN.toJSON(message.iban)
        : undefined);
    message.swift !== undefined &&
      (obj.swift = message.swift
        ? BankAccountDetails_SWIFT.toJSON(message.swift)
        : undefined);
    message.branchName !== undefined && (obj.branchName = message.branchName);
    return obj;
  },

  create(base?: DeepPartial<BankAccountDetails>): BankAccountDetails {
    return BankAccountDetails.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<BankAccountDetails>): BankAccountDetails {
    const message = createBaseBankAccountDetails();
    message.type = object.type ?? 0;
    message.accountName = object.accountName ?? '';
    message.institution = object.institution ?? '';
    message.scan =
      object.scan !== undefined && object.scan !== null
        ? BankAccountDetails_SCAN.fromPartial(object.scan)
        : undefined;
    message.rnan =
      object.rnan !== undefined && object.rnan !== null
        ? BankAccountDetails_RNAN.fromPartial(object.rnan)
        : undefined;
    message.internal =
      object.internal !== undefined && object.internal !== null
        ? BankAccountDetails_Internal.fromPartial(object.internal)
        : undefined;
    message.iban =
      object.iban !== undefined && object.iban !== null
        ? BankAccountDetails_IBAN.fromPartial(object.iban)
        : undefined;
    message.swift =
      object.swift !== undefined && object.swift !== null
        ? BankAccountDetails_SWIFT.fromPartial(object.swift)
        : undefined;
    message.branchName = object.branchName ?? '';
    return message;
  },
};

function createBaseBankAccountDetails_RNAN(): BankAccountDetails_RNAN {
  return { routingNumber: '', accountNumber: '' };
}

export const BankAccountDetails_RNAN = {
  encode(
    message: BankAccountDetails_RNAN,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.routingNumber !== '') {
      writer.uint32(10).string(message.routingNumber);
    }
    if (message.accountNumber !== '') {
      writer.uint32(18).string(message.accountNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): BankAccountDetails_RNAN {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAccountDetails_RNAN();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.routingNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BankAccountDetails_RNAN {
    return {
      routingNumber: isSet(object.routingNumber)
        ? String(object.routingNumber)
        : '',
      accountNumber: isSet(object.accountNumber)
        ? String(object.accountNumber)
        : '',
    };
  },

  toJSON(message: BankAccountDetails_RNAN): unknown {
    const obj: any = {};
    message.routingNumber !== undefined &&
      (obj.routingNumber = message.routingNumber);
    message.accountNumber !== undefined &&
      (obj.accountNumber = message.accountNumber);
    return obj;
  },

  create(base?: DeepPartial<BankAccountDetails_RNAN>): BankAccountDetails_RNAN {
    return BankAccountDetails_RNAN.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<BankAccountDetails_RNAN>,
  ): BankAccountDetails_RNAN {
    const message = createBaseBankAccountDetails_RNAN();
    message.routingNumber = object.routingNumber ?? '';
    message.accountNumber = object.accountNumber ?? '';
    return message;
  },
};

function createBaseBankAccountDetails_SCAN(): BankAccountDetails_SCAN {
  return { sortCode: '', accountNumber: '' };
}

export const BankAccountDetails_SCAN = {
  encode(
    message: BankAccountDetails_SCAN,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.sortCode !== '') {
      writer.uint32(10).string(message.sortCode);
    }
    if (message.accountNumber !== '') {
      writer.uint32(18).string(message.accountNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): BankAccountDetails_SCAN {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAccountDetails_SCAN();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sortCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BankAccountDetails_SCAN {
    return {
      sortCode: isSet(object.sortCode) ? String(object.sortCode) : '',
      accountNumber: isSet(object.accountNumber)
        ? String(object.accountNumber)
        : '',
    };
  },

  toJSON(message: BankAccountDetails_SCAN): unknown {
    const obj: any = {};
    message.sortCode !== undefined && (obj.sortCode = message.sortCode);
    message.accountNumber !== undefined &&
      (obj.accountNumber = message.accountNumber);
    return obj;
  },

  create(base?: DeepPartial<BankAccountDetails_SCAN>): BankAccountDetails_SCAN {
    return BankAccountDetails_SCAN.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<BankAccountDetails_SCAN>,
  ): BankAccountDetails_SCAN {
    const message = createBaseBankAccountDetails_SCAN();
    message.sortCode = object.sortCode ?? '';
    message.accountNumber = object.accountNumber ?? '';
    return message;
  },
};

function createBaseBankAccountDetails_Internal(): BankAccountDetails_Internal {
  return { bankCode: '', branchCode: '', accountNumber: '' };
}

export const BankAccountDetails_Internal = {
  encode(
    message: BankAccountDetails_Internal,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.bankCode !== '') {
      writer.uint32(10).string(message.bankCode);
    }
    if (message.branchCode !== '') {
      writer.uint32(18).string(message.branchCode);
    }
    if (message.accountNumber !== '') {
      writer.uint32(26).string(message.accountNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): BankAccountDetails_Internal {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAccountDetails_Internal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bankCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.branchCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BankAccountDetails_Internal {
    return {
      bankCode: isSet(object.bankCode) ? String(object.bankCode) : '',
      branchCode: isSet(object.branchCode) ? String(object.branchCode) : '',
      accountNumber: isSet(object.accountNumber)
        ? String(object.accountNumber)
        : '',
    };
  },

  toJSON(message: BankAccountDetails_Internal): unknown {
    const obj: any = {};
    message.bankCode !== undefined && (obj.bankCode = message.bankCode);
    message.branchCode !== undefined && (obj.branchCode = message.branchCode);
    message.accountNumber !== undefined &&
      (obj.accountNumber = message.accountNumber);
    return obj;
  },

  create(
    base?: DeepPartial<BankAccountDetails_Internal>,
  ): BankAccountDetails_Internal {
    return BankAccountDetails_Internal.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<BankAccountDetails_Internal>,
  ): BankAccountDetails_Internal {
    const message = createBaseBankAccountDetails_Internal();
    message.bankCode = object.bankCode ?? '';
    message.branchCode = object.branchCode ?? '';
    message.accountNumber = object.accountNumber ?? '';
    return message;
  },
};

function createBaseBankAccountDetails_IBAN(): BankAccountDetails_IBAN {
  return { iban: '', bic: '' };
}

export const BankAccountDetails_IBAN = {
  encode(
    message: BankAccountDetails_IBAN,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.iban !== '') {
      writer.uint32(10).string(message.iban);
    }
    if (message.bic !== '') {
      writer.uint32(18).string(message.bic);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): BankAccountDetails_IBAN {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAccountDetails_IBAN();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.iban = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bic = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BankAccountDetails_IBAN {
    return {
      iban: isSet(object.iban) ? String(object.iban) : '',
      bic: isSet(object.bic) ? String(object.bic) : '',
    };
  },

  toJSON(message: BankAccountDetails_IBAN): unknown {
    const obj: any = {};
    message.iban !== undefined && (obj.iban = message.iban);
    message.bic !== undefined && (obj.bic = message.bic);
    return obj;
  },

  create(base?: DeepPartial<BankAccountDetails_IBAN>): BankAccountDetails_IBAN {
    return BankAccountDetails_IBAN.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<BankAccountDetails_IBAN>,
  ): BankAccountDetails_IBAN {
    const message = createBaseBankAccountDetails_IBAN();
    message.iban = object.iban ?? '';
    message.bic = object.bic ?? '';
    return message;
  },
};

function createBaseBankAccountDetails_SWIFT(): BankAccountDetails_SWIFT {
  return {
    accountType: 0,
    bankAddress: '',
    swiftReference: '',
    accountNumber: '',
  };
}

export const BankAccountDetails_SWIFT = {
  encode(
    message: BankAccountDetails_SWIFT,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.accountType !== 0) {
      writer.uint32(8).int32(message.accountType);
    }
    if (message.bankAddress !== '') {
      writer.uint32(18).string(message.bankAddress);
    }
    if (message.swiftReference !== '') {
      writer.uint32(26).string(message.swiftReference);
    }
    if (message.accountNumber !== '') {
      writer.uint32(34).string(message.accountNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): BankAccountDetails_SWIFT {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAccountDetails_SWIFT();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accountType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bankAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.swiftReference = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BankAccountDetails_SWIFT {
    return {
      accountType: isSet(object.accountType)
        ? bankAccountTypeFromJSON(object.accountType)
        : 0,
      bankAddress: isSet(object.bankAddress) ? String(object.bankAddress) : '',
      swiftReference: isSet(object.swiftReference)
        ? String(object.swiftReference)
        : '',
      accountNumber: isSet(object.accountNumber)
        ? String(object.accountNumber)
        : '',
    };
  },

  toJSON(message: BankAccountDetails_SWIFT): unknown {
    const obj: any = {};
    message.accountType !== undefined &&
      (obj.accountType = bankAccountTypeToJSON(message.accountType));
    message.bankAddress !== undefined &&
      (obj.bankAddress = message.bankAddress);
    message.swiftReference !== undefined &&
      (obj.swiftReference = message.swiftReference);
    message.accountNumber !== undefined &&
      (obj.accountNumber = message.accountNumber);
    return obj;
  },

  create(
    base?: DeepPartial<BankAccountDetails_SWIFT>,
  ): BankAccountDetails_SWIFT {
    return BankAccountDetails_SWIFT.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<BankAccountDetails_SWIFT>,
  ): BankAccountDetails_SWIFT {
    const message = createBaseBankAccountDetails_SWIFT();
    message.accountType = object.accountType ?? 0;
    message.bankAddress = object.bankAddress ?? '';
    message.swiftReference = object.swiftReference ?? '';
    message.accountNumber = object.accountNumber ?? '';
    return message;
  },
};

function createBasePagePagination(): PagePagination {
  return { page: 0, perPage: 0, total: 0, totalPages: 0, count: 0 };
}

export const PagePagination = {
  encode(
    message: PagePagination,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.page !== 0) {
      writer.uint32(8).int32(message.page);
    }
    if (message.perPage !== 0) {
      writer.uint32(16).int32(message.perPage);
    }
    if (message.total !== 0) {
      writer.uint32(24).int32(message.total);
    }
    if (message.totalPages !== 0) {
      writer.uint32(32).int32(message.totalPages);
    }
    if (message.count !== 0) {
      writer.uint32(40).int32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PagePagination {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePagePagination();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.page = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.perPage = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.total = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.totalPages = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.count = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PagePagination {
    return {
      page: isSet(object.page) ? Number(object.page) : 0,
      perPage: isSet(object.perPage) ? Number(object.perPage) : 0,
      total: isSet(object.total) ? Number(object.total) : 0,
      totalPages: isSet(object.totalPages) ? Number(object.totalPages) : 0,
      count: isSet(object.count) ? Number(object.count) : 0,
    };
  },

  toJSON(message: PagePagination): unknown {
    const obj: any = {};
    message.page !== undefined && (obj.page = Math.round(message.page));
    message.perPage !== undefined &&
      (obj.perPage = Math.round(message.perPage));
    message.total !== undefined && (obj.total = Math.round(message.total));
    message.totalPages !== undefined &&
      (obj.totalPages = Math.round(message.totalPages));
    message.count !== undefined && (obj.count = Math.round(message.count));
    return obj;
  },

  create(base?: DeepPartial<PagePagination>): PagePagination {
    return PagePagination.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PagePagination>): PagePagination {
    const message = createBasePagePagination();
    message.page = object.page ?? 0;
    message.perPage = object.perPage ?? 0;
    message.total = object.total ?? 0;
    message.totalPages = object.totalPages ?? 0;
    message.count = object.count ?? 0;
    return message;
  },
};

function createBasePagePaginationParams(): PagePaginationParams {
  return { page: 0, perPage: 0 };
}

export const PagePaginationParams = {
  encode(
    message: PagePaginationParams,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.page !== 0) {
      writer.uint32(8).int32(message.page);
    }
    if (message.perPage !== 0) {
      writer.uint32(16).int32(message.perPage);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PagePaginationParams {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePagePaginationParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.page = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.perPage = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PagePaginationParams {
    return {
      page: isSet(object.page) ? Number(object.page) : 0,
      perPage: isSet(object.perPage) ? Number(object.perPage) : 0,
    };
  },

  toJSON(message: PagePaginationParams): unknown {
    const obj: any = {};
    message.page !== undefined && (obj.page = Math.round(message.page));
    message.perPage !== undefined &&
      (obj.perPage = Math.round(message.perPage));
    return obj;
  },

  create(base?: DeepPartial<PagePaginationParams>): PagePaginationParams {
    return PagePaginationParams.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PagePaginationParams>): PagePaginationParams {
    const message = createBasePagePaginationParams();
    message.page = object.page ?? 0;
    message.perPage = object.perPage ?? 0;
    return message;
  },
};

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error(
      'Value is larger than Number.MAX_SAFE_INTEGER',
    );
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
