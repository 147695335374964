import { grpc } from '@improbable-eng/grpc-web';
import { Metadata } from '@improbable-eng/grpc-web/dist/typings/metadata';
import { BrowserHeaders } from 'browser-headers';

import { AnonymousConfigClientImpl } from '../grpc-web/anonymousapi/config/proto/anonymousconfig';
import { AnonymousUsersClientImpl } from '../grpc-web/anonymousapi/users/proto/anonymoususers';
import { ApiKeysClientImpl } from '../grpc-web/api/apikeys/proto/apikeys';
import { DocumentsClientImpl } from '../grpc-web/api/documents/proto/documents';
import { FeedClientImpl } from '../grpc-web/api/feed/proto/feed';
import { FXClientImpl } from '../grpc-web/api/fx/proto/fx';
import { KYCClientImpl } from '../grpc-web/api/kyc/proto/kyc';
import { NotificationsClientImpl } from '../grpc-web/api/notifications/proto/notifications';
import { TransactionsClientImpl } from '../grpc-web/api/transactions/proto/transactions';
import {
  GrpcWebImpl,
  UsersClientImpl,
} from '../grpc-web/api/users/proto/users';
import { WalletsClientImpl } from '../grpc-web/api/wallets/proto/wallets';
import packageJson from '../package.json';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type sessionSlice from '../slices/sessionSlice';
import { TypeOfClassMethods } from './types';

type Intersect<T> = T extends { [K in keyof T]: infer E } ? E : T;

type GetKeys<U> = U extends Record<infer K, any> ? K : never;

type UnionToIntersection<U extends object> = {
  [K in GetKeys<U>]: U extends Record<K, infer T> ? T : never;
};

type TMP = {
  [Name in keyof Pick<NalaGrpc, 'anonymousUsers'>]: TypeOfClassMethods<
    NalaGrpc[Name]
  >;
};
type TMP2 = Intersect<TMP>;

export type GrpcCalls = keyof UnionToIntersection<TMP2>;

class NalaGrpc {
  public metadata: grpc.Metadata;

  public users: UsersClientImpl;

  public anonymousUsers: AnonymousUsersClientImpl;

  public transactions: TransactionsClientImpl;

  public feed: FeedClientImpl;

  public fx: FXClientImpl;

  public kyc: KYCClientImpl;

  public wallet: WalletsClientImpl;

  public config: AnonymousConfigClientImpl;

  public documents: DocumentsClientImpl;

  public apiKeys: ApiKeysClientImpl;

  public notifications: NotificationsClientImpl;

  constructor() {
    this.metadata = new BrowserHeaders({
      app_version: `${packageJson.version}-web`,
      /** This is set in {@link sessionSlice} */
      account_id: [],
    });

    if (process.env.NEXT_PUBLIC_BYPASS_RATE_LIMIT === 'true') {
      this.metadata.set('bypass_rate_limit', 'true');
    }

    const impl = new GrpcWebImpl(process.env.NEXT_PUBLIC_GRPC_HOST!, {
      metadata: this.metadata,
    });

    this.users = new UsersClientImpl(impl);
    this.anonymousUsers = new AnonymousUsersClientImpl(impl);
    this.transactions = new TransactionsClientImpl(impl);
    this.feed = new FeedClientImpl(impl);
    this.fx = new FXClientImpl(impl);
    this.kyc = new KYCClientImpl(impl);
    this.wallet = new WalletsClientImpl(impl);
    this.config = new AnonymousConfigClientImpl(impl);
    this.documents = new DocumentsClientImpl(impl);
    this.apiKeys = new ApiKeysClientImpl(impl);
    this.notifications = new NotificationsClientImpl(impl);
  }
}

export const grpcErrorCodeToHuman = (code: grpc.Code) => {
  switch (code) {
    case grpc.Code.OK:
      return 'OK';
    case grpc.Code.Canceled:
      return 'Canceled';
    case grpc.Code.Unknown:
      return 'Unknown';
    case grpc.Code.InvalidArgument:
      return 'InvalidArgument';
    case grpc.Code.DeadlineExceeded:
      return 'DeadlineExceeded';
    case grpc.Code.NotFound:
      return 'NotFound';
    case grpc.Code.AlreadyExists:
      return 'AlreadyExists';
    case grpc.Code.PermissionDenied:
      return 'PermissionDenied';
    case grpc.Code.ResourceExhausted:
      return 'ResourceExhausted';
    case grpc.Code.FailedPrecondition:
      return 'FailedPrecondition';
    case grpc.Code.Aborted:
      return 'Aborted';
    case grpc.Code.OutOfRange:
      return 'OutOfRange';
    case grpc.Code.Unimplemented:
      return 'Unimplemented';
    case grpc.Code.Internal:
      return 'Internal';
    case grpc.Code.Unavailable:
      return 'Unavailable';
    case grpc.Code.DataLoss:
      return 'DataLoss';
    case grpc.Code.Unauthenticated:
      return 'Unauthenticated';
    default: {
      const missingErrorString: never = code;
      console.warn(`Unknown grpc error code: ${missingErrorString}`);
      return 'Unknown';
    }
  }
};

export interface GrpcError extends Error {
  (message: string): Error;
  status: number;
  code: grpc.Code;
  metadata: Metadata;
}

export default new NalaGrpc();
