/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import _m0 from 'protobufjs/minimal';
import { ApiKey } from '../../../common/proto/apikeys/types';
import { Timestamp } from '../../../google/protobuf/timestamp';

export const protobufPackage = 'public';

export interface GenerateApiKeyRequest {
  title: string;
  scopes: string[];
  expiresAt: Timestamp | undefined;
}

export interface GenerateApiKeyReply {
  key: ApiKey | undefined;
  value: string;
}

export interface ListApiKeysRequest {}

export interface ListApiKeysReply {
  keys: ApiKey[];
}

export interface SoftDeleteApiKeyRequest {
  keyId: string;
}

export interface SoftDeleteApiKeyReply {}

export interface ListApiKeyScopesRequest {}

export interface ListApiKeyScopesReply {
  scopes: string[];
}

function createBaseGenerateApiKeyRequest(): GenerateApiKeyRequest {
  return { title: '', scopes: [], expiresAt: undefined };
}

export const GenerateApiKeyRequest = {
  encode(
    message: GenerateApiKeyRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.title !== '') {
      writer.uint32(10).string(message.title);
    }
    for (const v of message.scopes) {
      writer.uint32(18).string(v!);
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GenerateApiKeyRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateApiKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scopes.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateApiKeyRequest {
    return {
      title: isSet(object.title) ? String(object.title) : '',
      scopes: Array.isArray(object?.scopes)
        ? object.scopes.map((e: any) => String(e))
        : [],
      expiresAt: isSet(object.expiresAt)
        ? fromJsonTimestamp(object.expiresAt)
        : undefined,
    };
  },

  toJSON(message: GenerateApiKeyRequest): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.scopes) {
      obj.scopes = message.scopes.map((e) => e);
    } else {
      obj.scopes = [];
    }
    message.expiresAt !== undefined &&
      (obj.expiresAt = fromTimestamp(message.expiresAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<GenerateApiKeyRequest>): GenerateApiKeyRequest {
    return GenerateApiKeyRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GenerateApiKeyRequest>,
  ): GenerateApiKeyRequest {
    const message = createBaseGenerateApiKeyRequest();
    message.title = object.title ?? '';
    message.scopes = object.scopes?.map((e) => e) || [];
    message.expiresAt =
      object.expiresAt !== undefined && object.expiresAt !== null
        ? Timestamp.fromPartial(object.expiresAt)
        : undefined;
    return message;
  },
};

function createBaseGenerateApiKeyReply(): GenerateApiKeyReply {
  return { key: undefined, value: '' };
}

export const GenerateApiKeyReply = {
  encode(
    message: GenerateApiKeyReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== undefined) {
      ApiKey.encode(message.key, writer.uint32(10).fork()).ldelim();
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateApiKeyReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateApiKeyReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = ApiKey.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateApiKeyReply {
    return {
      key: isSet(object.key) ? ApiKey.fromJSON(object.key) : undefined,
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: GenerateApiKeyReply): unknown {
    const obj: any = {};
    message.key !== undefined &&
      (obj.key = message.key ? ApiKey.toJSON(message.key) : undefined);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(base?: DeepPartial<GenerateApiKeyReply>): GenerateApiKeyReply {
    return GenerateApiKeyReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GenerateApiKeyReply>): GenerateApiKeyReply {
    const message = createBaseGenerateApiKeyReply();
    message.key =
      object.key !== undefined && object.key !== null
        ? ApiKey.fromPartial(object.key)
        : undefined;
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseListApiKeysRequest(): ListApiKeysRequest {
  return {};
}

export const ListApiKeysRequest = {
  encode(
    _: ListApiKeysRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListApiKeysRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListApiKeysRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListApiKeysRequest {
    return {};
  },

  toJSON(_: ListApiKeysRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<ListApiKeysRequest>): ListApiKeysRequest {
    return ListApiKeysRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<ListApiKeysRequest>): ListApiKeysRequest {
    const message = createBaseListApiKeysRequest();
    return message;
  },
};

function createBaseListApiKeysReply(): ListApiKeysReply {
  return { keys: [] };
}

export const ListApiKeysReply = {
  encode(
    message: ListApiKeysReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.keys) {
      ApiKey.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListApiKeysReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListApiKeysReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keys.push(ApiKey.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListApiKeysReply {
    return {
      keys: Array.isArray(object?.keys)
        ? object.keys.map((e: any) => ApiKey.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListApiKeysReply): unknown {
    const obj: any = {};
    if (message.keys) {
      obj.keys = message.keys.map((e) => (e ? ApiKey.toJSON(e) : undefined));
    } else {
      obj.keys = [];
    }
    return obj;
  },

  create(base?: DeepPartial<ListApiKeysReply>): ListApiKeysReply {
    return ListApiKeysReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ListApiKeysReply>): ListApiKeysReply {
    const message = createBaseListApiKeysReply();
    message.keys = object.keys?.map((e) => ApiKey.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSoftDeleteApiKeyRequest(): SoftDeleteApiKeyRequest {
  return { keyId: '' };
}

export const SoftDeleteApiKeyRequest = {
  encode(
    message: SoftDeleteApiKeyRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.keyId !== '') {
      writer.uint32(10).string(message.keyId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SoftDeleteApiKeyRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSoftDeleteApiKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SoftDeleteApiKeyRequest {
    return { keyId: isSet(object.keyId) ? String(object.keyId) : '' };
  },

  toJSON(message: SoftDeleteApiKeyRequest): unknown {
    const obj: any = {};
    message.keyId !== undefined && (obj.keyId = message.keyId);
    return obj;
  },

  create(base?: DeepPartial<SoftDeleteApiKeyRequest>): SoftDeleteApiKeyRequest {
    return SoftDeleteApiKeyRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SoftDeleteApiKeyRequest>,
  ): SoftDeleteApiKeyRequest {
    const message = createBaseSoftDeleteApiKeyRequest();
    message.keyId = object.keyId ?? '';
    return message;
  },
};

function createBaseSoftDeleteApiKeyReply(): SoftDeleteApiKeyReply {
  return {};
}

export const SoftDeleteApiKeyReply = {
  encode(
    _: SoftDeleteApiKeyReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SoftDeleteApiKeyReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSoftDeleteApiKeyReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SoftDeleteApiKeyReply {
    return {};
  },

  toJSON(_: SoftDeleteApiKeyReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<SoftDeleteApiKeyReply>): SoftDeleteApiKeyReply {
    return SoftDeleteApiKeyReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<SoftDeleteApiKeyReply>): SoftDeleteApiKeyReply {
    const message = createBaseSoftDeleteApiKeyReply();
    return message;
  },
};

function createBaseListApiKeyScopesRequest(): ListApiKeyScopesRequest {
  return {};
}

export const ListApiKeyScopesRequest = {
  encode(
    _: ListApiKeyScopesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListApiKeyScopesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListApiKeyScopesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListApiKeyScopesRequest {
    return {};
  },

  toJSON(_: ListApiKeyScopesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<ListApiKeyScopesRequest>): ListApiKeyScopesRequest {
    return ListApiKeyScopesRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<ListApiKeyScopesRequest>,
  ): ListApiKeyScopesRequest {
    const message = createBaseListApiKeyScopesRequest();
    return message;
  },
};

function createBaseListApiKeyScopesReply(): ListApiKeyScopesReply {
  return { scopes: [] };
}

export const ListApiKeyScopesReply = {
  encode(
    message: ListApiKeyScopesReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.scopes) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListApiKeyScopesReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListApiKeyScopesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.scopes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListApiKeyScopesReply {
    return {
      scopes: Array.isArray(object?.scopes)
        ? object.scopes.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: ListApiKeyScopesReply): unknown {
    const obj: any = {};
    if (message.scopes) {
      obj.scopes = message.scopes.map((e) => e);
    } else {
      obj.scopes = [];
    }
    return obj;
  },

  create(base?: DeepPartial<ListApiKeyScopesReply>): ListApiKeyScopesReply {
    return ListApiKeyScopesReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListApiKeyScopesReply>,
  ): ListApiKeyScopesReply {
    const message = createBaseListApiKeyScopesReply();
    message.scopes = object.scopes?.map((e) => e) || [];
    return message;
  },
};

export interface ApiKeys {
  /** Generate a new API key */
  GenerateApiKey(
    request: DeepPartial<GenerateApiKeyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GenerateApiKeyReply>;
  /** List all API keys */
  ListApiKeys(
    request: DeepPartial<ListApiKeysRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListApiKeysReply>;
  /** SoftDelete an API key */
  SoftDeleteApiKey(
    request: DeepPartial<SoftDeleteApiKeyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SoftDeleteApiKeyReply>;
  /** List all scopes available for API keys */
  ListApiKeyScopes(
    request: DeepPartial<ListApiKeyScopesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListApiKeyScopesReply>;
}

export class ApiKeysClientImpl implements ApiKeys {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GenerateApiKey = this.GenerateApiKey.bind(this);
    this.ListApiKeys = this.ListApiKeys.bind(this);
    this.SoftDeleteApiKey = this.SoftDeleteApiKey.bind(this);
    this.ListApiKeyScopes = this.ListApiKeyScopes.bind(this);
  }

  GenerateApiKey(
    request: DeepPartial<GenerateApiKeyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GenerateApiKeyReply> {
    return this.rpc.unary(
      ApiKeysGenerateApiKeyDesc,
      GenerateApiKeyRequest.fromPartial(request),
      metadata,
    );
  }

  ListApiKeys(
    request: DeepPartial<ListApiKeysRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListApiKeysReply> {
    return this.rpc.unary(
      ApiKeysListApiKeysDesc,
      ListApiKeysRequest.fromPartial(request),
      metadata,
    );
  }

  SoftDeleteApiKey(
    request: DeepPartial<SoftDeleteApiKeyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SoftDeleteApiKeyReply> {
    return this.rpc.unary(
      ApiKeysSoftDeleteApiKeyDesc,
      SoftDeleteApiKeyRequest.fromPartial(request),
      metadata,
    );
  }

  ListApiKeyScopes(
    request: DeepPartial<ListApiKeyScopesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListApiKeyScopesReply> {
    return this.rpc.unary(
      ApiKeysListApiKeyScopesDesc,
      ListApiKeyScopesRequest.fromPartial(request),
      metadata,
    );
  }
}

export const ApiKeysDesc = { serviceName: 'public.ApiKeys' };

export const ApiKeysGenerateApiKeyDesc: UnaryMethodDefinitionish = {
  methodName: 'GenerateApiKey',
  service: ApiKeysDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GenerateApiKeyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GenerateApiKeyReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ApiKeysListApiKeysDesc: UnaryMethodDefinitionish = {
  methodName: 'ListApiKeys',
  service: ApiKeysDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListApiKeysRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListApiKeysReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ApiKeysSoftDeleteApiKeyDesc: UnaryMethodDefinitionish = {
  methodName: 'SoftDeleteApiKey',
  service: ApiKeysDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SoftDeleteApiKeyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SoftDeleteApiKeyReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ApiKeysListApiKeyScopesDesc: UnaryMethodDefinitionish = {
  methodName: 'ListApiKeyScopes',
  service: ApiKeysDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListApiKeyScopesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListApiKeyScopesReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined
          ? { transport: this.options.transport }
          : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(
    message: string,
    public code: grpc.Code,
    public metadata: grpc.Metadata,
  ) {
    super(message);
  }
}
