/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import _m0 from 'protobufjs/minimal';
import {
  AdditionalPaymentMethod,
  CardData,
  FundingOption,
  LastUsedPaymentMethod,
  PaymentCapabilities,
  PaymentMethod,
  PaymentMethodBankAccount,
  PaymentMethodBankTransfer,
  PaymentMethodCard,
  PaymentMethodType,
  paymentMethodTypeFromJSON,
  paymentMethodTypeToJSON,
  PaymentMethodWallet,
  PaymentSource,
  PaymentSummary,
} from '../../../common/proto/collections/types';
import {
  Bank,
  Recipient,
  RecipientAccount,
} from '../../../common/proto/disbursements/types';
import {
  ClientQuote,
  Money,
  QuoteType,
  quoteTypeFromJSON,
  quoteTypeToJSON,
} from '../../../common/proto/fx/types';
import { Address, Document, Name } from '../../../common/proto/kyc/types';
import { Summary } from '../../../common/proto/transactions/summary';
import {
  StatementOutputFormat,
  statementOutputFormatFromJSON,
  statementOutputFormatToJSON,
  Transaction,
  TransactionReview,
  TransactionsFilter,
  TransactionType,
  transactionTypeFromJSON,
  transactionTypeToJSON,
  TransactionUpdate,
} from '../../../common/proto/transactions/types';
import { Transaction as Transaction1 } from '../../../common/proto/transactions/v2/types';
import {
  ETA,
  OrderingDirection,
  orderingDirectionFromJSON,
  orderingDirectionToJSON,
  PagePagination,
  PagePaginationParams,
  ServiceAvailabilityNotice,
  UserLimits,
} from '../../../common/proto/utils/types';
import { Timestamp } from '../../../google/protobuf/timestamp';

export const protobufPackage = 'public';

/** create recipient */
export interface CreateRecipientRequest {
  recipient: Recipient | undefined;
}

export interface CreateRecipientReply {
  recipient: Recipient | undefined;
}

/** delete recipient account */
export interface DeleteRecipientAccountRequest {
  recipientAccountId: string;
}

export interface DeleteRecipientAccountReply {}

/** delete recipient */
export interface DeleteRecipientRequest {
  recipientId: string;
}

export interface DeleteRecipientReply {}

/** update recipient */
export interface UpdateRecipientRequest {
  /** @deprecated */
  deprecatedRecipient: Recipient | undefined;
  recipientId: string;
  name: Name | undefined;
  displayName: string;
}

export interface UpdateRecipientReply {}

/** update recipient account */
export interface UpdateRecipientAccountRequest {
  recipientAccountId: string;
  name: Name | undefined;
  displayName: string;
  purpose: string;
  address: Address | undefined;
  defaultReference: string;
}

export interface UpdateRecipientAccountReply {}

/** list recipients */
export interface ListRecipientsRequest {}

export interface ListRecipientsReply {
  recipients: Recipient[];
}

/** create transaction */
export interface CreateTransactionRequest {
  id: string;
  quoteId: string;
  recipientId: string;
  recipientMessage: string;
  clientQuote: ClientQuote | undefined;
  recipientAccountId: string;
  reference: string;
  fundingAccountId: string;
  /** Purpose of the transaction itself */
  purpose: string;
}

export interface CreateTransactionReply {
  transaction: Transaction | undefined;
}

export interface GetTransactionDetailsRequest {
  transactionId: string;
}

export interface GetTransactionDetailsReply {
  transaction: Transaction | undefined;
  updates: TransactionUpdate[];
  quoteType: QuoteType;
  paymentMethod: PaymentMethod | undefined;
}

/** set transaction details */
export interface SetTransactionDetailsRequest {
  transactionId: string;
  memo: string;
  purpose: string;
}

export interface SetTransactionDetailsReply {}

export interface ListPaymentMethodsRequest {
  /** Mandatory if no transaction_id is provided. */
  amount: Money | undefined;
  transactionType: TransactionType;
  /** Optional. */
  toWalletId: string;
  /** Optional. */
  fromWalletId: string;
  /** Optional. If set, the transaction type and amount will be inferred. */
  transactionId: string;
  /** Optional. */
  recipientAccountId: string;
}

/**
 * Clients should first check common_availability_status for service availability.
 * Then each payment method has a combined ETA that contains Reliability and
 * a Notice to be shown if not empty.
 */
export interface ListPaymentMethodsReply {
  bankTransfers: PaymentMethodBankTransfer[];
  cards: PaymentMethodCard[];
  additionalPaymentMethods: AdditionalPaymentMethod[];
  lastUsedPaymentMethod: LastUsedPaymentMethod | undefined;
  bankAccounts: PaymentMethodBankAccount[];
  wallets: PaymentMethodWallet[];
  /**
   * Deprecated: Call GetSummary instead.
   *
   * @deprecated
   */
  paymentSummary: PaymentSummary | undefined;
  /** To be used to initiate an Apple Pay payment. */
  paymentCapabilities: PaymentCapabilities | undefined;
  /** The common service availability status, irrespective of the payment method. */
  commonAvailabilityStatus: ServiceAvailabilityNotice | undefined;
}

/** payment methods cards */
export interface ListPaymentMethodCardsRequest {}

export interface ListPaymentMethodCardsReply {
  cards: PaymentMethodCard[];
}

/** MarkTransactionAsPaid */
export interface MarkTransactionAsPaidRequest {
  transactionId: string;
}

export interface MarkTransactionAsPaidReply {}

/** GetCardPaymentDetails */
export interface GetCardPaymentDetailsRequest {
  transactionId: string;
  cardId: string;
  cardData: CardData | undefined;
}

export interface GetCardPaymentDetailsReply {
  providerName: string;
  transactionId: string;
  cardId: string;
  providerData: { [key: string]: string };
  attemptId: string;
}

export interface GetCardPaymentDetailsReply_ProviderDataEntry {
  key: string;
  value: string;
}

/** GetExternalPaymentDetails */
export interface GetExternalPaymentDetailsRequest {
  transactionId: string;
  provider: string;
  bankAccountId: string;
  /** For backwards compatibility, if this field is not set, PAYMENT_METHOD_TYPE_EXTERNAL is assumed. */
  paymentMethodType?: PaymentMethodType | undefined;
}

export interface GetExternalPaymentDetailsReply {
  providerName: string;
  transactionId: string;
  providerData: { [key: string]: string };
  attemptId: string;
}

export interface GetExternalPaymentDetailsReply_ProviderDataEntry {
  key: string;
  value: string;
}

/** DeletePaymentMethodCard */
export interface DeletePaymentMethodCardRequest {
  cardId: string;
}

export interface DeletePaymentMethodCardReply {}

/** CancelPaymentAttempt */
export interface CancelPaymentAttemptRequest {
  attemptId: string;
}

export interface CancelPaymentAttemptReply {}

/** Store card payment details */
export interface StoreCardPaymentDetailsRequest {
  cardData: CardData | undefined;
}

export interface StoreCardPaymentDetailsReply {
  url: string;
}

/**
 * Use PayWithExternalWallet instead
 *
 * @deprecated
 */
export interface GetApplePayPaymentDetailsRequest {
  transactionId: string;
}

/** @deprecated */
export interface GetApplePayPaymentDetailsReply {
  providerName: string;
  transactionId: string;
  providerData: { [key: string]: string };
  attemptId: string;
}

export interface GetApplePayPaymentDetailsReply_ProviderDataEntry {
  key: string;
  value: string;
}

/** get account limits */
export interface GetAccountLimitsRequest {
  fromCurrencyCode: string;
}

export interface GetAccountLimitsReply {
  accountLimits: { [key: string]: UserLimits };
}

export interface GetAccountLimitsReply_AccountLimitsEntry {
  key: string;
  value: UserLimits | undefined;
}

/** get transaction limits */
export interface GetTransactionLimitsRequest {
  fromCurrencyCode: string;
  recipientId: string;
  recipientAccountId: string;
  transactionType: TransactionType;
  walletId: string;
}

export interface GetTransactionLimitsReply {
  senderLimits: UserLimits | undefined;
  recipientLimits: UserLimits | undefined;
}

export interface CreateRecipientAccountRequest {
  recipientAccount: RecipientAccount | undefined;
}

export interface CreateRecipientAccountReply {
  recipient: Recipient | undefined;
  recipientAccount: RecipientAccount | undefined;
}

export interface GetBanksRequest {
  country: string;
}

export interface GetBanksReply {
  banks: Bank[];
}

export interface GetETARequest {
  recipientAccountId: string;
  amount: Money | undefined;
}

export interface GetETAReply {
  eta: ETA | undefined;
}

export interface GetCollectionETARequest {
  paymentMethodType: PaymentMethodType;
  providerName: string;
}

export interface GetCollectionETAReply {
  eta: ETA | undefined;
}

export interface PayWithWalletRequest {
  transactionId: string;
  walletId: string;
}

export interface PayWithWalletReply {}

/** Use for paying with Apple Pay and Google Pay only */
export interface PayWithExternalWalletRequest {
  transactionId: string;
  type: PaymentMethodType;
  /** Optional. UTF-8 serialization of a plaintext JSON dictionary as given by Apple & Google Pay. */
  tokenData: string;
  /** Optional. Raw result from the client's payment initiation. */
  clientResult: string;
}

export interface PayWithExternalWalletReply {
  attemptId: string;
  nextStepUrl: string;
  providerName: string;
}

export interface GetRecipientAccountByIDRequest {
  recipientAccountId: string;
}

export interface GetRecipientAccountByIDReply {
  recipientAccount: RecipientAccount | undefined;
}

export interface GetRecipientByIDRequest {
  recipientId: string;
}

export interface GetRecipientByIDReply {
  recipient: Recipient | undefined;
}

export interface ExportStatementToFileRequest {
  /**
   * Deprecated: Use month and year instead to avoid invalid ranges and timezone issues.
   *
   * @deprecated
   */
  fromDate: Timestamp | undefined;
  /**
   * Deprecated: Use month and year instead to avoid invalid ranges and timezone issues.
   *
   * @deprecated
   */
  toDate: Timestamp | undefined;
  outputFormat: StatementOutputFormat;
  walletId: string;
  /** month in numeric format (January=1, ...) */
  month: number;
  year: number;
}

export interface ExportStatementToFileReply {
  statementFile: Uint8Array;
}

export interface GetStatementDownloadURLRequest {
  outputFormat: StatementOutputFormat;
  walletId: string;
  /** month in numeric format (January=1, ...) */
  month: number;
  year: number;
}

export interface GetStatementDownloadURLReply {
  url: string;
}

export interface GetReceiptDownloadURLRequest {
  transactionId: string;
}

export interface GetReceiptDownloadURLReply {
  url: string;
}

export interface GetSummaryRequest {
  transactionType: TransactionType;
  /** Optional. */
  recipientAccountId: string;
  clientQuote: ClientQuote | undefined;
  quoteId: string;
  /** Optional. */
  fromWalletId: string;
  /** Optional. */
  toWalletId: string;
}

export interface GetSummaryReply {
  summary: Summary | undefined;
  /** To be used with AdditionalPaymentMethods (Apple/Google Pay) */
  paymentSummary: PaymentSummary | undefined;
}

export interface ProcessBulkPaymentFileRequest {
  bulkPaymentFile: Uint8Array;
  quoteId: string;
}

export interface ProcessBulkPaymentFileReply {
  transactionReviews: TransactionReview[];
}

export interface CreateBulkPaymentFromReviewsRequest {
  fromWalletId: string;
  name: string;
  transactionReviews: TransactionReview[];
  quoteId: string;
  purpose: string;
}

export interface CreateBulkPaymentFromReviewsReply {
  bulkPaymentId: string;
}

export interface ListTransactionsRequest {
  walletId: string;
  orderBy: ListTransactionsRequest_OrderBy;
  orderDirection: OrderingDirection;
  pagination: PagePaginationParams | undefined;
  searchTerm: string;
  transactionsFilter: TransactionsFilter | undefined;
}

/** Order by */
export enum ListTransactionsRequest_OrderBy {
  ORDER_BY_INVALID = 0,
  ORDER_BY_CREATED_AT = 1,
  ORDER_BY_STATE = 2,
  ORDER_BY_AMOUNT = 3,
  UNRECOGNIZED = -1,
}

export function listTransactionsRequest_OrderByFromJSON(
  object: any,
): ListTransactionsRequest_OrderBy {
  switch (object) {
    case 0:
    case 'ORDER_BY_INVALID':
      return ListTransactionsRequest_OrderBy.ORDER_BY_INVALID;
    case 1:
    case 'ORDER_BY_CREATED_AT':
      return ListTransactionsRequest_OrderBy.ORDER_BY_CREATED_AT;
    case 2:
    case 'ORDER_BY_STATE':
      return ListTransactionsRequest_OrderBy.ORDER_BY_STATE;
    case 3:
    case 'ORDER_BY_AMOUNT':
      return ListTransactionsRequest_OrderBy.ORDER_BY_AMOUNT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ListTransactionsRequest_OrderBy.UNRECOGNIZED;
  }
}

export function listTransactionsRequest_OrderByToJSON(
  object: ListTransactionsRequest_OrderBy,
): string {
  switch (object) {
    case ListTransactionsRequest_OrderBy.ORDER_BY_INVALID:
      return 'ORDER_BY_INVALID';
    case ListTransactionsRequest_OrderBy.ORDER_BY_CREATED_AT:
      return 'ORDER_BY_CREATED_AT';
    case ListTransactionsRequest_OrderBy.ORDER_BY_STATE:
      return 'ORDER_BY_STATE';
    case ListTransactionsRequest_OrderBy.ORDER_BY_AMOUNT:
      return 'ORDER_BY_AMOUNT';
    case ListTransactionsRequest_OrderBy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface ListTransactionsReply {
  transactions: Transaction1[];
  pagination: PagePagination | undefined;
}

export interface ExportTransactionsToFileRequest {
  request: ListTransactionsRequest | undefined;
  outputFormat: StatementOutputFormat;
}

export interface ExportTransactionsToFileReply {
  downloadUrl: string;
}

export interface GetTransactionRequest {
  transactionId: string;
}

export interface GetTransactionReply {
  transaction: Transaction1 | undefined;
}

export interface ListTransactionUpdatesRequest {
  transactionId: string;
}

export interface ListTransactionUpdatesReply {
  transactionUpdates: TransactionUpdate[];
}

export interface CancelTransactionRequest {
  transactionId: string;
}

export interface CancelTransactionReply {}

export interface GetFundingDetailsRequest {
  transactionId: string;
}

export interface GetFundingDetailsReply {
  accountName: string;
  accountNumber: string;
  accountType: string;
  bankName: string;
  swift: string;
  bankAddress: string;
  uniqueReference: string;
}

export interface ListOffPlatformFundingOptionsRequest {
  fromCurrency: string;
}

export interface ListOffPlatformFundingOptionsReply {
  fundingOptions: FundingOption[];
}

export interface ListPaymentSourcesRequest {}

export interface ListPaymentSourcesReply {
  sources: PaymentSource[];
}

export interface ListTransactionPurposesRequest {}

export interface ListTransactionPurposesReply {
  purposes: string[];
}

export interface SubmitPurposeProofRequest {
  transactionId: string;
  document: Document | undefined;
}

export interface SubmitPurposeProofReply {}

export interface ApproveTransactionRequest {
  transactionId: string;
}

export interface ApproveTransactionReply {}

export interface RejectTransactionRequest {
  transactionId: string;
}

export interface RejectTransactionReply {}

function createBaseCreateRecipientRequest(): CreateRecipientRequest {
  return { recipient: undefined };
}

export const CreateRecipientRequest = {
  encode(
    message: CreateRecipientRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipient !== undefined) {
      Recipient.encode(message.recipient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateRecipientRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRecipientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipient = Recipient.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRecipientRequest {
    return {
      recipient: isSet(object.recipient)
        ? Recipient.fromJSON(object.recipient)
        : undefined,
    };
  },

  toJSON(message: CreateRecipientRequest): unknown {
    const obj: any = {};
    message.recipient !== undefined &&
      (obj.recipient = message.recipient
        ? Recipient.toJSON(message.recipient)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<CreateRecipientRequest>): CreateRecipientRequest {
    return CreateRecipientRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateRecipientRequest>,
  ): CreateRecipientRequest {
    const message = createBaseCreateRecipientRequest();
    message.recipient =
      object.recipient !== undefined && object.recipient !== null
        ? Recipient.fromPartial(object.recipient)
        : undefined;
    return message;
  },
};

function createBaseCreateRecipientReply(): CreateRecipientReply {
  return { recipient: undefined };
}

export const CreateRecipientReply = {
  encode(
    message: CreateRecipientReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipient !== undefined) {
      Recipient.encode(message.recipient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateRecipientReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRecipientReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipient = Recipient.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRecipientReply {
    return {
      recipient: isSet(object.recipient)
        ? Recipient.fromJSON(object.recipient)
        : undefined,
    };
  },

  toJSON(message: CreateRecipientReply): unknown {
    const obj: any = {};
    message.recipient !== undefined &&
      (obj.recipient = message.recipient
        ? Recipient.toJSON(message.recipient)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<CreateRecipientReply>): CreateRecipientReply {
    return CreateRecipientReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CreateRecipientReply>): CreateRecipientReply {
    const message = createBaseCreateRecipientReply();
    message.recipient =
      object.recipient !== undefined && object.recipient !== null
        ? Recipient.fromPartial(object.recipient)
        : undefined;
    return message;
  },
};

function createBaseDeleteRecipientAccountRequest(): DeleteRecipientAccountRequest {
  return { recipientAccountId: '' };
}

export const DeleteRecipientAccountRequest = {
  encode(
    message: DeleteRecipientAccountRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipientAccountId !== '') {
      writer.uint32(10).string(message.recipientAccountId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteRecipientAccountRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRecipientAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientAccountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteRecipientAccountRequest {
    return {
      recipientAccountId: isSet(object.recipientAccountId)
        ? String(object.recipientAccountId)
        : '',
    };
  },

  toJSON(message: DeleteRecipientAccountRequest): unknown {
    const obj: any = {};
    message.recipientAccountId !== undefined &&
      (obj.recipientAccountId = message.recipientAccountId);
    return obj;
  },

  create(
    base?: DeepPartial<DeleteRecipientAccountRequest>,
  ): DeleteRecipientAccountRequest {
    return DeleteRecipientAccountRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<DeleteRecipientAccountRequest>,
  ): DeleteRecipientAccountRequest {
    const message = createBaseDeleteRecipientAccountRequest();
    message.recipientAccountId = object.recipientAccountId ?? '';
    return message;
  },
};

function createBaseDeleteRecipientAccountReply(): DeleteRecipientAccountReply {
  return {};
}

export const DeleteRecipientAccountReply = {
  encode(
    _: DeleteRecipientAccountReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteRecipientAccountReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRecipientAccountReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteRecipientAccountReply {
    return {};
  },

  toJSON(_: DeleteRecipientAccountReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<DeleteRecipientAccountReply>,
  ): DeleteRecipientAccountReply {
    return DeleteRecipientAccountReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<DeleteRecipientAccountReply>,
  ): DeleteRecipientAccountReply {
    const message = createBaseDeleteRecipientAccountReply();
    return message;
  },
};

function createBaseDeleteRecipientRequest(): DeleteRecipientRequest {
  return { recipientId: '' };
}

export const DeleteRecipientRequest = {
  encode(
    message: DeleteRecipientRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipientId !== '') {
      writer.uint32(10).string(message.recipientId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteRecipientRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRecipientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteRecipientRequest {
    return {
      recipientId: isSet(object.recipientId) ? String(object.recipientId) : '',
    };
  },

  toJSON(message: DeleteRecipientRequest): unknown {
    const obj: any = {};
    message.recipientId !== undefined &&
      (obj.recipientId = message.recipientId);
    return obj;
  },

  create(base?: DeepPartial<DeleteRecipientRequest>): DeleteRecipientRequest {
    return DeleteRecipientRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<DeleteRecipientRequest>,
  ): DeleteRecipientRequest {
    const message = createBaseDeleteRecipientRequest();
    message.recipientId = object.recipientId ?? '';
    return message;
  },
};

function createBaseDeleteRecipientReply(): DeleteRecipientReply {
  return {};
}

export const DeleteRecipientReply = {
  encode(
    _: DeleteRecipientReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteRecipientReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteRecipientReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteRecipientReply {
    return {};
  },

  toJSON(_: DeleteRecipientReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<DeleteRecipientReply>): DeleteRecipientReply {
    return DeleteRecipientReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<DeleteRecipientReply>): DeleteRecipientReply {
    const message = createBaseDeleteRecipientReply();
    return message;
  },
};

function createBaseUpdateRecipientRequest(): UpdateRecipientRequest {
  return {
    deprecatedRecipient: undefined,
    recipientId: '',
    name: undefined,
    displayName: '',
  };
}

export const UpdateRecipientRequest = {
  encode(
    message: UpdateRecipientRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.deprecatedRecipient !== undefined) {
      Recipient.encode(
        message.deprecatedRecipient,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.recipientId !== '') {
      writer.uint32(18).string(message.recipientId);
    }
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(26).fork()).ldelim();
    }
    if (message.displayName !== '') {
      writer.uint32(34).string(message.displayName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateRecipientRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRecipientRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deprecatedRecipient = Recipient.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.displayName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateRecipientRequest {
    return {
      deprecatedRecipient: isSet(object.deprecatedRecipient)
        ? Recipient.fromJSON(object.deprecatedRecipient)
        : undefined,
      recipientId: isSet(object.recipientId) ? String(object.recipientId) : '',
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
      displayName: isSet(object.displayName) ? String(object.displayName) : '',
    };
  },

  toJSON(message: UpdateRecipientRequest): unknown {
    const obj: any = {};
    message.deprecatedRecipient !== undefined &&
      (obj.deprecatedRecipient = message.deprecatedRecipient
        ? Recipient.toJSON(message.deprecatedRecipient)
        : undefined);
    message.recipientId !== undefined &&
      (obj.recipientId = message.recipientId);
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    message.displayName !== undefined &&
      (obj.displayName = message.displayName);
    return obj;
  },

  create(base?: DeepPartial<UpdateRecipientRequest>): UpdateRecipientRequest {
    return UpdateRecipientRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<UpdateRecipientRequest>,
  ): UpdateRecipientRequest {
    const message = createBaseUpdateRecipientRequest();
    message.deprecatedRecipient =
      object.deprecatedRecipient !== undefined &&
      object.deprecatedRecipient !== null
        ? Recipient.fromPartial(object.deprecatedRecipient)
        : undefined;
    message.recipientId = object.recipientId ?? '';
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    message.displayName = object.displayName ?? '';
    return message;
  },
};

function createBaseUpdateRecipientReply(): UpdateRecipientReply {
  return {};
}

export const UpdateRecipientReply = {
  encode(
    _: UpdateRecipientReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateRecipientReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRecipientReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateRecipientReply {
    return {};
  },

  toJSON(_: UpdateRecipientReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<UpdateRecipientReply>): UpdateRecipientReply {
    return UpdateRecipientReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<UpdateRecipientReply>): UpdateRecipientReply {
    const message = createBaseUpdateRecipientReply();
    return message;
  },
};

function createBaseUpdateRecipientAccountRequest(): UpdateRecipientAccountRequest {
  return {
    recipientAccountId: '',
    name: undefined,
    displayName: '',
    purpose: '',
    address: undefined,
    defaultReference: '',
  };
}

export const UpdateRecipientAccountRequest = {
  encode(
    message: UpdateRecipientAccountRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipientAccountId !== '') {
      writer.uint32(10).string(message.recipientAccountId);
    }
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    if (message.displayName !== '') {
      writer.uint32(26).string(message.displayName);
    }
    if (message.purpose !== '') {
      writer.uint32(34).string(message.purpose);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(42).fork()).ldelim();
    }
    if (message.defaultReference !== '') {
      writer.uint32(50).string(message.defaultReference);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateRecipientAccountRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRecipientAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientAccountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.purpose = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.defaultReference = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateRecipientAccountRequest {
    return {
      recipientAccountId: isSet(object.recipientAccountId)
        ? String(object.recipientAccountId)
        : '',
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
      displayName: isSet(object.displayName) ? String(object.displayName) : '',
      purpose: isSet(object.purpose) ? String(object.purpose) : '',
      address: isSet(object.address)
        ? Address.fromJSON(object.address)
        : undefined,
      defaultReference: isSet(object.defaultReference)
        ? String(object.defaultReference)
        : '',
    };
  },

  toJSON(message: UpdateRecipientAccountRequest): unknown {
    const obj: any = {};
    message.recipientAccountId !== undefined &&
      (obj.recipientAccountId = message.recipientAccountId);
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    message.displayName !== undefined &&
      (obj.displayName = message.displayName);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    message.address !== undefined &&
      (obj.address = message.address
        ? Address.toJSON(message.address)
        : undefined);
    message.defaultReference !== undefined &&
      (obj.defaultReference = message.defaultReference);
    return obj;
  },

  create(
    base?: DeepPartial<UpdateRecipientAccountRequest>,
  ): UpdateRecipientAccountRequest {
    return UpdateRecipientAccountRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<UpdateRecipientAccountRequest>,
  ): UpdateRecipientAccountRequest {
    const message = createBaseUpdateRecipientAccountRequest();
    message.recipientAccountId = object.recipientAccountId ?? '';
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    message.displayName = object.displayName ?? '';
    message.purpose = object.purpose ?? '';
    message.address =
      object.address !== undefined && object.address !== null
        ? Address.fromPartial(object.address)
        : undefined;
    message.defaultReference = object.defaultReference ?? '';
    return message;
  },
};

function createBaseUpdateRecipientAccountReply(): UpdateRecipientAccountReply {
  return {};
}

export const UpdateRecipientAccountReply = {
  encode(
    _: UpdateRecipientAccountReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): UpdateRecipientAccountReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRecipientAccountReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateRecipientAccountReply {
    return {};
  },

  toJSON(_: UpdateRecipientAccountReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<UpdateRecipientAccountReply>,
  ): UpdateRecipientAccountReply {
    return UpdateRecipientAccountReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<UpdateRecipientAccountReply>,
  ): UpdateRecipientAccountReply {
    const message = createBaseUpdateRecipientAccountReply();
    return message;
  },
};

function createBaseListRecipientsRequest(): ListRecipientsRequest {
  return {};
}

export const ListRecipientsRequest = {
  encode(
    _: ListRecipientsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListRecipientsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRecipientsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListRecipientsRequest {
    return {};
  },

  toJSON(_: ListRecipientsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<ListRecipientsRequest>): ListRecipientsRequest {
    return ListRecipientsRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<ListRecipientsRequest>): ListRecipientsRequest {
    const message = createBaseListRecipientsRequest();
    return message;
  },
};

function createBaseListRecipientsReply(): ListRecipientsReply {
  return { recipients: [] };
}

export const ListRecipientsReply = {
  encode(
    message: ListRecipientsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.recipients) {
      Recipient.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRecipientsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRecipientsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipients.push(Recipient.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRecipientsReply {
    return {
      recipients: Array.isArray(object?.recipients)
        ? object.recipients.map((e: any) => Recipient.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListRecipientsReply): unknown {
    const obj: any = {};
    if (message.recipients) {
      obj.recipients = message.recipients.map((e) =>
        e ? Recipient.toJSON(e) : undefined,
      );
    } else {
      obj.recipients = [];
    }
    return obj;
  },

  create(base?: DeepPartial<ListRecipientsReply>): ListRecipientsReply {
    return ListRecipientsReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ListRecipientsReply>): ListRecipientsReply {
    const message = createBaseListRecipientsReply();
    message.recipients =
      object.recipients?.map((e) => Recipient.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateTransactionRequest(): CreateTransactionRequest {
  return {
    id: '',
    quoteId: '',
    recipientId: '',
    recipientMessage: '',
    clientQuote: undefined,
    recipientAccountId: '',
    reference: '',
    fundingAccountId: '',
    purpose: '',
  };
}

export const CreateTransactionRequest = {
  encode(
    message: CreateTransactionRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.quoteId !== '') {
      writer.uint32(18).string(message.quoteId);
    }
    if (message.recipientId !== '') {
      writer.uint32(26).string(message.recipientId);
    }
    if (message.recipientMessage !== '') {
      writer.uint32(34).string(message.recipientMessage);
    }
    if (message.clientQuote !== undefined) {
      ClientQuote.encode(
        message.clientQuote,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.recipientAccountId !== '') {
      writer.uint32(50).string(message.recipientAccountId);
    }
    if (message.reference !== '') {
      writer.uint32(58).string(message.reference);
    }
    if (message.fundingAccountId !== '') {
      writer.uint32(66).string(message.fundingAccountId);
    }
    if (message.purpose !== '') {
      writer.uint32(74).string(message.purpose);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateTransactionRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTransactionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.quoteId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.recipientId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.recipientMessage = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.clientQuote = ClientQuote.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.recipientAccountId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.reference = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.fundingAccountId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.purpose = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateTransactionRequest {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : '',
      recipientId: isSet(object.recipientId) ? String(object.recipientId) : '',
      recipientMessage: isSet(object.recipientMessage)
        ? String(object.recipientMessage)
        : '',
      clientQuote: isSet(object.clientQuote)
        ? ClientQuote.fromJSON(object.clientQuote)
        : undefined,
      recipientAccountId: isSet(object.recipientAccountId)
        ? String(object.recipientAccountId)
        : '',
      reference: isSet(object.reference) ? String(object.reference) : '',
      fundingAccountId: isSet(object.fundingAccountId)
        ? String(object.fundingAccountId)
        : '',
      purpose: isSet(object.purpose) ? String(object.purpose) : '',
    };
  },

  toJSON(message: CreateTransactionRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    message.recipientId !== undefined &&
      (obj.recipientId = message.recipientId);
    message.recipientMessage !== undefined &&
      (obj.recipientMessage = message.recipientMessage);
    message.clientQuote !== undefined &&
      (obj.clientQuote = message.clientQuote
        ? ClientQuote.toJSON(message.clientQuote)
        : undefined);
    message.recipientAccountId !== undefined &&
      (obj.recipientAccountId = message.recipientAccountId);
    message.reference !== undefined && (obj.reference = message.reference);
    message.fundingAccountId !== undefined &&
      (obj.fundingAccountId = message.fundingAccountId);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    return obj;
  },

  create(
    base?: DeepPartial<CreateTransactionRequest>,
  ): CreateTransactionRequest {
    return CreateTransactionRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateTransactionRequest>,
  ): CreateTransactionRequest {
    const message = createBaseCreateTransactionRequest();
    message.id = object.id ?? '';
    message.quoteId = object.quoteId ?? '';
    message.recipientId = object.recipientId ?? '';
    message.recipientMessage = object.recipientMessage ?? '';
    message.clientQuote =
      object.clientQuote !== undefined && object.clientQuote !== null
        ? ClientQuote.fromPartial(object.clientQuote)
        : undefined;
    message.recipientAccountId = object.recipientAccountId ?? '';
    message.reference = object.reference ?? '';
    message.fundingAccountId = object.fundingAccountId ?? '';
    message.purpose = object.purpose ?? '';
    return message;
  },
};

function createBaseCreateTransactionReply(): CreateTransactionReply {
  return { transaction: undefined };
}

export const CreateTransactionReply = {
  encode(
    message: CreateTransactionReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transaction !== undefined) {
      Transaction.encode(
        message.transaction,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateTransactionReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTransactionReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transaction = Transaction.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateTransactionReply {
    return {
      transaction: isSet(object.transaction)
        ? Transaction.fromJSON(object.transaction)
        : undefined,
    };
  },

  toJSON(message: CreateTransactionReply): unknown {
    const obj: any = {};
    message.transaction !== undefined &&
      (obj.transaction = message.transaction
        ? Transaction.toJSON(message.transaction)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<CreateTransactionReply>): CreateTransactionReply {
    return CreateTransactionReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateTransactionReply>,
  ): CreateTransactionReply {
    const message = createBaseCreateTransactionReply();
    message.transaction =
      object.transaction !== undefined && object.transaction !== null
        ? Transaction.fromPartial(object.transaction)
        : undefined;
    return message;
  },
};

function createBaseGetTransactionDetailsRequest(): GetTransactionDetailsRequest {
  return { transactionId: '' };
}

export const GetTransactionDetailsRequest = {
  encode(
    message: GetTransactionDetailsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetTransactionDetailsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTransactionDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTransactionDetailsRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: GetTransactionDetailsRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(
    base?: DeepPartial<GetTransactionDetailsRequest>,
  ): GetTransactionDetailsRequest {
    return GetTransactionDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetTransactionDetailsRequest>,
  ): GetTransactionDetailsRequest {
    const message = createBaseGetTransactionDetailsRequest();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseGetTransactionDetailsReply(): GetTransactionDetailsReply {
  return {
    transaction: undefined,
    updates: [],
    quoteType: 0,
    paymentMethod: undefined,
  };
}

export const GetTransactionDetailsReply = {
  encode(
    message: GetTransactionDetailsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transaction !== undefined) {
      Transaction.encode(
        message.transaction,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    for (const v of message.updates) {
      TransactionUpdate.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.quoteType !== 0) {
      writer.uint32(24).int32(message.quoteType);
    }
    if (message.paymentMethod !== undefined) {
      PaymentMethod.encode(
        message.paymentMethod,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetTransactionDetailsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTransactionDetailsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transaction = Transaction.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updates.push(
            TransactionUpdate.decode(reader, reader.uint32()),
          );
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.quoteType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.paymentMethod = PaymentMethod.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTransactionDetailsReply {
    return {
      transaction: isSet(object.transaction)
        ? Transaction.fromJSON(object.transaction)
        : undefined,
      updates: Array.isArray(object?.updates)
        ? object.updates.map((e: any) => TransactionUpdate.fromJSON(e))
        : [],
      quoteType: isSet(object.quoteType)
        ? quoteTypeFromJSON(object.quoteType)
        : 0,
      paymentMethod: isSet(object.paymentMethod)
        ? PaymentMethod.fromJSON(object.paymentMethod)
        : undefined,
    };
  },

  toJSON(message: GetTransactionDetailsReply): unknown {
    const obj: any = {};
    message.transaction !== undefined &&
      (obj.transaction = message.transaction
        ? Transaction.toJSON(message.transaction)
        : undefined);
    if (message.updates) {
      obj.updates = message.updates.map((e) =>
        e ? TransactionUpdate.toJSON(e) : undefined,
      );
    } else {
      obj.updates = [];
    }
    message.quoteType !== undefined &&
      (obj.quoteType = quoteTypeToJSON(message.quoteType));
    message.paymentMethod !== undefined &&
      (obj.paymentMethod = message.paymentMethod
        ? PaymentMethod.toJSON(message.paymentMethod)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetTransactionDetailsReply>,
  ): GetTransactionDetailsReply {
    return GetTransactionDetailsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetTransactionDetailsReply>,
  ): GetTransactionDetailsReply {
    const message = createBaseGetTransactionDetailsReply();
    message.transaction =
      object.transaction !== undefined && object.transaction !== null
        ? Transaction.fromPartial(object.transaction)
        : undefined;
    message.updates =
      object.updates?.map((e) => TransactionUpdate.fromPartial(e)) || [];
    message.quoteType = object.quoteType ?? 0;
    message.paymentMethod =
      object.paymentMethod !== undefined && object.paymentMethod !== null
        ? PaymentMethod.fromPartial(object.paymentMethod)
        : undefined;
    return message;
  },
};

function createBaseSetTransactionDetailsRequest(): SetTransactionDetailsRequest {
  return { transactionId: '', memo: '', purpose: '' };
}

export const SetTransactionDetailsRequest = {
  encode(
    message: SetTransactionDetailsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.memo !== '') {
      writer.uint32(18).string(message.memo);
    }
    if (message.purpose !== '') {
      writer.uint32(26).string(message.purpose);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetTransactionDetailsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTransactionDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.memo = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.purpose = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetTransactionDetailsRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      memo: isSet(object.memo) ? String(object.memo) : '',
      purpose: isSet(object.purpose) ? String(object.purpose) : '',
    };
  },

  toJSON(message: SetTransactionDetailsRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.memo !== undefined && (obj.memo = message.memo);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    return obj;
  },

  create(
    base?: DeepPartial<SetTransactionDetailsRequest>,
  ): SetTransactionDetailsRequest {
    return SetTransactionDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SetTransactionDetailsRequest>,
  ): SetTransactionDetailsRequest {
    const message = createBaseSetTransactionDetailsRequest();
    message.transactionId = object.transactionId ?? '';
    message.memo = object.memo ?? '';
    message.purpose = object.purpose ?? '';
    return message;
  },
};

function createBaseSetTransactionDetailsReply(): SetTransactionDetailsReply {
  return {};
}

export const SetTransactionDetailsReply = {
  encode(
    _: SetTransactionDetailsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetTransactionDetailsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTransactionDetailsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetTransactionDetailsReply {
    return {};
  },

  toJSON(_: SetTransactionDetailsReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<SetTransactionDetailsReply>,
  ): SetTransactionDetailsReply {
    return SetTransactionDetailsReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<SetTransactionDetailsReply>,
  ): SetTransactionDetailsReply {
    const message = createBaseSetTransactionDetailsReply();
    return message;
  },
};

function createBaseListPaymentMethodsRequest(): ListPaymentMethodsRequest {
  return {
    amount: undefined,
    transactionType: 0,
    toWalletId: '',
    fromWalletId: '',
    transactionId: '',
    recipientAccountId: '',
  };
}

export const ListPaymentMethodsRequest = {
  encode(
    message: ListPaymentMethodsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.amount !== undefined) {
      Money.encode(message.amount, writer.uint32(10).fork()).ldelim();
    }
    if (message.transactionType !== 0) {
      writer.uint32(16).int32(message.transactionType);
    }
    if (message.toWalletId !== '') {
      writer.uint32(26).string(message.toWalletId);
    }
    if (message.fromWalletId !== '') {
      writer.uint32(34).string(message.fromWalletId);
    }
    if (message.transactionId !== '') {
      writer.uint32(42).string(message.transactionId);
    }
    if (message.recipientAccountId !== '') {
      writer.uint32(50).string(message.recipientAccountId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListPaymentMethodsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListPaymentMethodsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.amount = Money.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.transactionType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.toWalletId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fromWalletId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.recipientAccountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListPaymentMethodsRequest {
    return {
      amount: isSet(object.amount) ? Money.fromJSON(object.amount) : undefined,
      transactionType: isSet(object.transactionType)
        ? transactionTypeFromJSON(object.transactionType)
        : 0,
      toWalletId: isSet(object.toWalletId) ? String(object.toWalletId) : '',
      fromWalletId: isSet(object.fromWalletId)
        ? String(object.fromWalletId)
        : '',
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      recipientAccountId: isSet(object.recipientAccountId)
        ? String(object.recipientAccountId)
        : '',
    };
  },

  toJSON(message: ListPaymentMethodsRequest): unknown {
    const obj: any = {};
    message.amount !== undefined &&
      (obj.amount = message.amount ? Money.toJSON(message.amount) : undefined);
    message.transactionType !== undefined &&
      (obj.transactionType = transactionTypeToJSON(message.transactionType));
    message.toWalletId !== undefined && (obj.toWalletId = message.toWalletId);
    message.fromWalletId !== undefined &&
      (obj.fromWalletId = message.fromWalletId);
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.recipientAccountId !== undefined &&
      (obj.recipientAccountId = message.recipientAccountId);
    return obj;
  },

  create(
    base?: DeepPartial<ListPaymentMethodsRequest>,
  ): ListPaymentMethodsRequest {
    return ListPaymentMethodsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListPaymentMethodsRequest>,
  ): ListPaymentMethodsRequest {
    const message = createBaseListPaymentMethodsRequest();
    message.amount =
      object.amount !== undefined && object.amount !== null
        ? Money.fromPartial(object.amount)
        : undefined;
    message.transactionType = object.transactionType ?? 0;
    message.toWalletId = object.toWalletId ?? '';
    message.fromWalletId = object.fromWalletId ?? '';
    message.transactionId = object.transactionId ?? '';
    message.recipientAccountId = object.recipientAccountId ?? '';
    return message;
  },
};

function createBaseListPaymentMethodsReply(): ListPaymentMethodsReply {
  return {
    bankTransfers: [],
    cards: [],
    additionalPaymentMethods: [],
    lastUsedPaymentMethod: undefined,
    bankAccounts: [],
    wallets: [],
    paymentSummary: undefined,
    paymentCapabilities: undefined,
    commonAvailabilityStatus: undefined,
  };
}

export const ListPaymentMethodsReply = {
  encode(
    message: ListPaymentMethodsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.bankTransfers) {
      PaymentMethodBankTransfer.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.cards) {
      PaymentMethodCard.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.additionalPaymentMethods) {
      AdditionalPaymentMethod.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.lastUsedPaymentMethod !== undefined) {
      LastUsedPaymentMethod.encode(
        message.lastUsedPaymentMethod,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    for (const v of message.bankAccounts) {
      PaymentMethodBankAccount.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.wallets) {
      PaymentMethodWallet.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.paymentSummary !== undefined) {
      PaymentSummary.encode(
        message.paymentSummary,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.paymentCapabilities !== undefined) {
      PaymentCapabilities.encode(
        message.paymentCapabilities,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.commonAvailabilityStatus !== undefined) {
      ServiceAvailabilityNotice.encode(
        message.commonAvailabilityStatus,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListPaymentMethodsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListPaymentMethodsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bankTransfers.push(
            PaymentMethodBankTransfer.decode(reader, reader.uint32()),
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cards.push(PaymentMethodCard.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.additionalPaymentMethods.push(
            AdditionalPaymentMethod.decode(reader, reader.uint32()),
          );
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastUsedPaymentMethod = LastUsedPaymentMethod.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.bankAccounts.push(
            PaymentMethodBankAccount.decode(reader, reader.uint32()),
          );
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.wallets.push(
            PaymentMethodWallet.decode(reader, reader.uint32()),
          );
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.paymentSummary = PaymentSummary.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.paymentCapabilities = PaymentCapabilities.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.commonAvailabilityStatus = ServiceAvailabilityNotice.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListPaymentMethodsReply {
    return {
      bankTransfers: Array.isArray(object?.bankTransfers)
        ? object.bankTransfers.map((e: any) =>
            PaymentMethodBankTransfer.fromJSON(e),
          )
        : [],
      cards: Array.isArray(object?.cards)
        ? object.cards.map((e: any) => PaymentMethodCard.fromJSON(e))
        : [],
      additionalPaymentMethods: Array.isArray(object?.additionalPaymentMethods)
        ? object.additionalPaymentMethods.map((e: any) =>
            AdditionalPaymentMethod.fromJSON(e),
          )
        : [],
      lastUsedPaymentMethod: isSet(object.lastUsedPaymentMethod)
        ? LastUsedPaymentMethod.fromJSON(object.lastUsedPaymentMethod)
        : undefined,
      bankAccounts: Array.isArray(object?.bankAccounts)
        ? object.bankAccounts.map((e: any) =>
            PaymentMethodBankAccount.fromJSON(e),
          )
        : [],
      wallets: Array.isArray(object?.wallets)
        ? object.wallets.map((e: any) => PaymentMethodWallet.fromJSON(e))
        : [],
      paymentSummary: isSet(object.paymentSummary)
        ? PaymentSummary.fromJSON(object.paymentSummary)
        : undefined,
      paymentCapabilities: isSet(object.paymentCapabilities)
        ? PaymentCapabilities.fromJSON(object.paymentCapabilities)
        : undefined,
      commonAvailabilityStatus: isSet(object.commonAvailabilityStatus)
        ? ServiceAvailabilityNotice.fromJSON(object.commonAvailabilityStatus)
        : undefined,
    };
  },

  toJSON(message: ListPaymentMethodsReply): unknown {
    const obj: any = {};
    if (message.bankTransfers) {
      obj.bankTransfers = message.bankTransfers.map((e) =>
        e ? PaymentMethodBankTransfer.toJSON(e) : undefined,
      );
    } else {
      obj.bankTransfers = [];
    }
    if (message.cards) {
      obj.cards = message.cards.map((e) =>
        e ? PaymentMethodCard.toJSON(e) : undefined,
      );
    } else {
      obj.cards = [];
    }
    if (message.additionalPaymentMethods) {
      obj.additionalPaymentMethods = message.additionalPaymentMethods.map((e) =>
        e ? AdditionalPaymentMethod.toJSON(e) : undefined,
      );
    } else {
      obj.additionalPaymentMethods = [];
    }
    message.lastUsedPaymentMethod !== undefined &&
      (obj.lastUsedPaymentMethod = message.lastUsedPaymentMethod
        ? LastUsedPaymentMethod.toJSON(message.lastUsedPaymentMethod)
        : undefined);
    if (message.bankAccounts) {
      obj.bankAccounts = message.bankAccounts.map((e) =>
        e ? PaymentMethodBankAccount.toJSON(e) : undefined,
      );
    } else {
      obj.bankAccounts = [];
    }
    if (message.wallets) {
      obj.wallets = message.wallets.map((e) =>
        e ? PaymentMethodWallet.toJSON(e) : undefined,
      );
    } else {
      obj.wallets = [];
    }
    message.paymentSummary !== undefined &&
      (obj.paymentSummary = message.paymentSummary
        ? PaymentSummary.toJSON(message.paymentSummary)
        : undefined);
    message.paymentCapabilities !== undefined &&
      (obj.paymentCapabilities = message.paymentCapabilities
        ? PaymentCapabilities.toJSON(message.paymentCapabilities)
        : undefined);
    message.commonAvailabilityStatus !== undefined &&
      (obj.commonAvailabilityStatus = message.commonAvailabilityStatus
        ? ServiceAvailabilityNotice.toJSON(message.commonAvailabilityStatus)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<ListPaymentMethodsReply>): ListPaymentMethodsReply {
    return ListPaymentMethodsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListPaymentMethodsReply>,
  ): ListPaymentMethodsReply {
    const message = createBaseListPaymentMethodsReply();
    message.bankTransfers =
      object.bankTransfers?.map((e) =>
        PaymentMethodBankTransfer.fromPartial(e),
      ) || [];
    message.cards =
      object.cards?.map((e) => PaymentMethodCard.fromPartial(e)) || [];
    message.additionalPaymentMethods =
      object.additionalPaymentMethods?.map((e) =>
        AdditionalPaymentMethod.fromPartial(e),
      ) || [];
    message.lastUsedPaymentMethod =
      object.lastUsedPaymentMethod !== undefined &&
      object.lastUsedPaymentMethod !== null
        ? LastUsedPaymentMethod.fromPartial(object.lastUsedPaymentMethod)
        : undefined;
    message.bankAccounts =
      object.bankAccounts?.map((e) =>
        PaymentMethodBankAccount.fromPartial(e),
      ) || [];
    message.wallets =
      object.wallets?.map((e) => PaymentMethodWallet.fromPartial(e)) || [];
    message.paymentSummary =
      object.paymentSummary !== undefined && object.paymentSummary !== null
        ? PaymentSummary.fromPartial(object.paymentSummary)
        : undefined;
    message.paymentCapabilities =
      object.paymentCapabilities !== undefined &&
      object.paymentCapabilities !== null
        ? PaymentCapabilities.fromPartial(object.paymentCapabilities)
        : undefined;
    message.commonAvailabilityStatus =
      object.commonAvailabilityStatus !== undefined &&
      object.commonAvailabilityStatus !== null
        ? ServiceAvailabilityNotice.fromPartial(object.commonAvailabilityStatus)
        : undefined;
    return message;
  },
};

function createBaseListPaymentMethodCardsRequest(): ListPaymentMethodCardsRequest {
  return {};
}

export const ListPaymentMethodCardsRequest = {
  encode(
    _: ListPaymentMethodCardsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListPaymentMethodCardsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListPaymentMethodCardsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListPaymentMethodCardsRequest {
    return {};
  },

  toJSON(_: ListPaymentMethodCardsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<ListPaymentMethodCardsRequest>,
  ): ListPaymentMethodCardsRequest {
    return ListPaymentMethodCardsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<ListPaymentMethodCardsRequest>,
  ): ListPaymentMethodCardsRequest {
    const message = createBaseListPaymentMethodCardsRequest();
    return message;
  },
};

function createBaseListPaymentMethodCardsReply(): ListPaymentMethodCardsReply {
  return { cards: [] };
}

export const ListPaymentMethodCardsReply = {
  encode(
    message: ListPaymentMethodCardsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.cards) {
      PaymentMethodCard.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListPaymentMethodCardsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListPaymentMethodCardsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cards.push(PaymentMethodCard.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListPaymentMethodCardsReply {
    return {
      cards: Array.isArray(object?.cards)
        ? object.cards.map((e: any) => PaymentMethodCard.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListPaymentMethodCardsReply): unknown {
    const obj: any = {};
    if (message.cards) {
      obj.cards = message.cards.map((e) =>
        e ? PaymentMethodCard.toJSON(e) : undefined,
      );
    } else {
      obj.cards = [];
    }
    return obj;
  },

  create(
    base?: DeepPartial<ListPaymentMethodCardsReply>,
  ): ListPaymentMethodCardsReply {
    return ListPaymentMethodCardsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListPaymentMethodCardsReply>,
  ): ListPaymentMethodCardsReply {
    const message = createBaseListPaymentMethodCardsReply();
    message.cards =
      object.cards?.map((e) => PaymentMethodCard.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMarkTransactionAsPaidRequest(): MarkTransactionAsPaidRequest {
  return { transactionId: '' };
}

export const MarkTransactionAsPaidRequest = {
  encode(
    message: MarkTransactionAsPaidRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MarkTransactionAsPaidRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkTransactionAsPaidRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MarkTransactionAsPaidRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: MarkTransactionAsPaidRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(
    base?: DeepPartial<MarkTransactionAsPaidRequest>,
  ): MarkTransactionAsPaidRequest {
    return MarkTransactionAsPaidRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<MarkTransactionAsPaidRequest>,
  ): MarkTransactionAsPaidRequest {
    const message = createBaseMarkTransactionAsPaidRequest();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseMarkTransactionAsPaidReply(): MarkTransactionAsPaidReply {
  return {};
}

export const MarkTransactionAsPaidReply = {
  encode(
    _: MarkTransactionAsPaidReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MarkTransactionAsPaidReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkTransactionAsPaidReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): MarkTransactionAsPaidReply {
    return {};
  },

  toJSON(_: MarkTransactionAsPaidReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<MarkTransactionAsPaidReply>,
  ): MarkTransactionAsPaidReply {
    return MarkTransactionAsPaidReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<MarkTransactionAsPaidReply>,
  ): MarkTransactionAsPaidReply {
    const message = createBaseMarkTransactionAsPaidReply();
    return message;
  },
};

function createBaseGetCardPaymentDetailsRequest(): GetCardPaymentDetailsRequest {
  return { transactionId: '', cardId: '', cardData: undefined };
}

export const GetCardPaymentDetailsRequest = {
  encode(
    message: GetCardPaymentDetailsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.cardId !== '') {
      writer.uint32(18).string(message.cardId);
    }
    if (message.cardData !== undefined) {
      CardData.encode(message.cardData, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetCardPaymentDetailsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCardPaymentDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cardId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cardData = CardData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCardPaymentDetailsRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      cardId: isSet(object.cardId) ? String(object.cardId) : '',
      cardData: isSet(object.cardData)
        ? CardData.fromJSON(object.cardData)
        : undefined,
    };
  },

  toJSON(message: GetCardPaymentDetailsRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.cardId !== undefined && (obj.cardId = message.cardId);
    message.cardData !== undefined &&
      (obj.cardData = message.cardData
        ? CardData.toJSON(message.cardData)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetCardPaymentDetailsRequest>,
  ): GetCardPaymentDetailsRequest {
    return GetCardPaymentDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetCardPaymentDetailsRequest>,
  ): GetCardPaymentDetailsRequest {
    const message = createBaseGetCardPaymentDetailsRequest();
    message.transactionId = object.transactionId ?? '';
    message.cardId = object.cardId ?? '';
    message.cardData =
      object.cardData !== undefined && object.cardData !== null
        ? CardData.fromPartial(object.cardData)
        : undefined;
    return message;
  },
};

function createBaseGetCardPaymentDetailsReply(): GetCardPaymentDetailsReply {
  return {
    providerName: '',
    transactionId: '',
    cardId: '',
    providerData: {},
    attemptId: '',
  };
}

export const GetCardPaymentDetailsReply = {
  encode(
    message: GetCardPaymentDetailsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.providerName !== '') {
      writer.uint32(10).string(message.providerName);
    }
    if (message.transactionId !== '') {
      writer.uint32(18).string(message.transactionId);
    }
    if (message.cardId !== '') {
      writer.uint32(26).string(message.cardId);
    }
    Object.entries(message.providerData).forEach(([key, value]) => {
      GetCardPaymentDetailsReply_ProviderDataEntry.encode(
        { key: key as any, value },
        writer.uint32(34).fork(),
      ).ldelim();
    });
    if (message.attemptId !== '') {
      writer.uint32(42).string(message.attemptId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetCardPaymentDetailsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCardPaymentDetailsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cardId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = GetCardPaymentDetailsReply_ProviderDataEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry4.value !== undefined) {
            message.providerData[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.attemptId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCardPaymentDetailsReply {
    return {
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      cardId: isSet(object.cardId) ? String(object.cardId) : '',
      providerData: isObject(object.providerData)
        ? Object.entries(object.providerData).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      attemptId: isSet(object.attemptId) ? String(object.attemptId) : '',
    };
  },

  toJSON(message: GetCardPaymentDetailsReply): unknown {
    const obj: any = {};
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.cardId !== undefined && (obj.cardId = message.cardId);
    obj.providerData = {};
    if (message.providerData) {
      Object.entries(message.providerData).forEach(([k, v]) => {
        obj.providerData[k] = v;
      });
    }
    message.attemptId !== undefined && (obj.attemptId = message.attemptId);
    return obj;
  },

  create(
    base?: DeepPartial<GetCardPaymentDetailsReply>,
  ): GetCardPaymentDetailsReply {
    return GetCardPaymentDetailsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetCardPaymentDetailsReply>,
  ): GetCardPaymentDetailsReply {
    const message = createBaseGetCardPaymentDetailsReply();
    message.providerName = object.providerName ?? '';
    message.transactionId = object.transactionId ?? '';
    message.cardId = object.cardId ?? '';
    message.providerData = Object.entries(object.providerData ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.attemptId = object.attemptId ?? '';
    return message;
  },
};

function createBaseGetCardPaymentDetailsReply_ProviderDataEntry(): GetCardPaymentDetailsReply_ProviderDataEntry {
  return { key: '', value: '' };
}

export const GetCardPaymentDetailsReply_ProviderDataEntry = {
  encode(
    message: GetCardPaymentDetailsReply_ProviderDataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetCardPaymentDetailsReply_ProviderDataEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCardPaymentDetailsReply_ProviderDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCardPaymentDetailsReply_ProviderDataEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: GetCardPaymentDetailsReply_ProviderDataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<GetCardPaymentDetailsReply_ProviderDataEntry>,
  ): GetCardPaymentDetailsReply_ProviderDataEntry {
    return GetCardPaymentDetailsReply_ProviderDataEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetCardPaymentDetailsReply_ProviderDataEntry>,
  ): GetCardPaymentDetailsReply_ProviderDataEntry {
    const message = createBaseGetCardPaymentDetailsReply_ProviderDataEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseGetExternalPaymentDetailsRequest(): GetExternalPaymentDetailsRequest {
  return {
    transactionId: '',
    provider: '',
    bankAccountId: '',
    paymentMethodType: undefined,
  };
}

export const GetExternalPaymentDetailsRequest = {
  encode(
    message: GetExternalPaymentDetailsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.provider !== '') {
      writer.uint32(18).string(message.provider);
    }
    if (message.bankAccountId !== '') {
      writer.uint32(26).string(message.bankAccountId);
    }
    if (message.paymentMethodType !== undefined) {
      writer.uint32(32).int32(message.paymentMethodType);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetExternalPaymentDetailsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetExternalPaymentDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.provider = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.bankAccountId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.paymentMethodType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetExternalPaymentDetailsRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      provider: isSet(object.provider) ? String(object.provider) : '',
      bankAccountId: isSet(object.bankAccountId)
        ? String(object.bankAccountId)
        : '',
      paymentMethodType: isSet(object.paymentMethodType)
        ? paymentMethodTypeFromJSON(object.paymentMethodType)
        : undefined,
    };
  },

  toJSON(message: GetExternalPaymentDetailsRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.provider !== undefined && (obj.provider = message.provider);
    message.bankAccountId !== undefined &&
      (obj.bankAccountId = message.bankAccountId);
    message.paymentMethodType !== undefined &&
      (obj.paymentMethodType =
        message.paymentMethodType !== undefined
          ? paymentMethodTypeToJSON(message.paymentMethodType)
          : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetExternalPaymentDetailsRequest>,
  ): GetExternalPaymentDetailsRequest {
    return GetExternalPaymentDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetExternalPaymentDetailsRequest>,
  ): GetExternalPaymentDetailsRequest {
    const message = createBaseGetExternalPaymentDetailsRequest();
    message.transactionId = object.transactionId ?? '';
    message.provider = object.provider ?? '';
    message.bankAccountId = object.bankAccountId ?? '';
    message.paymentMethodType = object.paymentMethodType ?? undefined;
    return message;
  },
};

function createBaseGetExternalPaymentDetailsReply(): GetExternalPaymentDetailsReply {
  return {
    providerName: '',
    transactionId: '',
    providerData: {},
    attemptId: '',
  };
}

export const GetExternalPaymentDetailsReply = {
  encode(
    message: GetExternalPaymentDetailsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.providerName !== '') {
      writer.uint32(10).string(message.providerName);
    }
    if (message.transactionId !== '') {
      writer.uint32(18).string(message.transactionId);
    }
    Object.entries(message.providerData).forEach(([key, value]) => {
      GetExternalPaymentDetailsReply_ProviderDataEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    if (message.attemptId !== '') {
      writer.uint32(34).string(message.attemptId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetExternalPaymentDetailsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetExternalPaymentDetailsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 =
            GetExternalPaymentDetailsReply_ProviderDataEntry.decode(
              reader,
              reader.uint32(),
            );
          if (entry3.value !== undefined) {
            message.providerData[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.attemptId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetExternalPaymentDetailsReply {
    return {
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      providerData: isObject(object.providerData)
        ? Object.entries(object.providerData).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      attemptId: isSet(object.attemptId) ? String(object.attemptId) : '',
    };
  },

  toJSON(message: GetExternalPaymentDetailsReply): unknown {
    const obj: any = {};
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    obj.providerData = {};
    if (message.providerData) {
      Object.entries(message.providerData).forEach(([k, v]) => {
        obj.providerData[k] = v;
      });
    }
    message.attemptId !== undefined && (obj.attemptId = message.attemptId);
    return obj;
  },

  create(
    base?: DeepPartial<GetExternalPaymentDetailsReply>,
  ): GetExternalPaymentDetailsReply {
    return GetExternalPaymentDetailsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetExternalPaymentDetailsReply>,
  ): GetExternalPaymentDetailsReply {
    const message = createBaseGetExternalPaymentDetailsReply();
    message.providerName = object.providerName ?? '';
    message.transactionId = object.transactionId ?? '';
    message.providerData = Object.entries(object.providerData ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.attemptId = object.attemptId ?? '';
    return message;
  },
};

function createBaseGetExternalPaymentDetailsReply_ProviderDataEntry(): GetExternalPaymentDetailsReply_ProviderDataEntry {
  return { key: '', value: '' };
}

export const GetExternalPaymentDetailsReply_ProviderDataEntry = {
  encode(
    message: GetExternalPaymentDetailsReply_ProviderDataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetExternalPaymentDetailsReply_ProviderDataEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseGetExternalPaymentDetailsReply_ProviderDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetExternalPaymentDetailsReply_ProviderDataEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: GetExternalPaymentDetailsReply_ProviderDataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<GetExternalPaymentDetailsReply_ProviderDataEntry>,
  ): GetExternalPaymentDetailsReply_ProviderDataEntry {
    return GetExternalPaymentDetailsReply_ProviderDataEntry.fromPartial(
      base ?? {},
    );
  },

  fromPartial(
    object: DeepPartial<GetExternalPaymentDetailsReply_ProviderDataEntry>,
  ): GetExternalPaymentDetailsReply_ProviderDataEntry {
    const message =
      createBaseGetExternalPaymentDetailsReply_ProviderDataEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseDeletePaymentMethodCardRequest(): DeletePaymentMethodCardRequest {
  return { cardId: '' };
}

export const DeletePaymentMethodCardRequest = {
  encode(
    message: DeletePaymentMethodCardRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.cardId !== '') {
      writer.uint32(10).string(message.cardId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeletePaymentMethodCardRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePaymentMethodCardRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cardId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeletePaymentMethodCardRequest {
    return { cardId: isSet(object.cardId) ? String(object.cardId) : '' };
  },

  toJSON(message: DeletePaymentMethodCardRequest): unknown {
    const obj: any = {};
    message.cardId !== undefined && (obj.cardId = message.cardId);
    return obj;
  },

  create(
    base?: DeepPartial<DeletePaymentMethodCardRequest>,
  ): DeletePaymentMethodCardRequest {
    return DeletePaymentMethodCardRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<DeletePaymentMethodCardRequest>,
  ): DeletePaymentMethodCardRequest {
    const message = createBaseDeletePaymentMethodCardRequest();
    message.cardId = object.cardId ?? '';
    return message;
  },
};

function createBaseDeletePaymentMethodCardReply(): DeletePaymentMethodCardReply {
  return {};
}

export const DeletePaymentMethodCardReply = {
  encode(
    _: DeletePaymentMethodCardReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeletePaymentMethodCardReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePaymentMethodCardReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeletePaymentMethodCardReply {
    return {};
  },

  toJSON(_: DeletePaymentMethodCardReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<DeletePaymentMethodCardReply>,
  ): DeletePaymentMethodCardReply {
    return DeletePaymentMethodCardReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<DeletePaymentMethodCardReply>,
  ): DeletePaymentMethodCardReply {
    const message = createBaseDeletePaymentMethodCardReply();
    return message;
  },
};

function createBaseCancelPaymentAttemptRequest(): CancelPaymentAttemptRequest {
  return { attemptId: '' };
}

export const CancelPaymentAttemptRequest = {
  encode(
    message: CancelPaymentAttemptRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.attemptId !== '') {
      writer.uint32(10).string(message.attemptId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CancelPaymentAttemptRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelPaymentAttemptRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.attemptId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CancelPaymentAttemptRequest {
    return {
      attemptId: isSet(object.attemptId) ? String(object.attemptId) : '',
    };
  },

  toJSON(message: CancelPaymentAttemptRequest): unknown {
    const obj: any = {};
    message.attemptId !== undefined && (obj.attemptId = message.attemptId);
    return obj;
  },

  create(
    base?: DeepPartial<CancelPaymentAttemptRequest>,
  ): CancelPaymentAttemptRequest {
    return CancelPaymentAttemptRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CancelPaymentAttemptRequest>,
  ): CancelPaymentAttemptRequest {
    const message = createBaseCancelPaymentAttemptRequest();
    message.attemptId = object.attemptId ?? '';
    return message;
  },
};

function createBaseCancelPaymentAttemptReply(): CancelPaymentAttemptReply {
  return {};
}

export const CancelPaymentAttemptReply = {
  encode(
    _: CancelPaymentAttemptReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CancelPaymentAttemptReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelPaymentAttemptReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CancelPaymentAttemptReply {
    return {};
  },

  toJSON(_: CancelPaymentAttemptReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<CancelPaymentAttemptReply>,
  ): CancelPaymentAttemptReply {
    return CancelPaymentAttemptReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<CancelPaymentAttemptReply>,
  ): CancelPaymentAttemptReply {
    const message = createBaseCancelPaymentAttemptReply();
    return message;
  },
};

function createBaseStoreCardPaymentDetailsRequest(): StoreCardPaymentDetailsRequest {
  return { cardData: undefined };
}

export const StoreCardPaymentDetailsRequest = {
  encode(
    message: StoreCardPaymentDetailsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.cardData !== undefined) {
      CardData.encode(message.cardData, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StoreCardPaymentDetailsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStoreCardPaymentDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cardData = CardData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StoreCardPaymentDetailsRequest {
    return {
      cardData: isSet(object.cardData)
        ? CardData.fromJSON(object.cardData)
        : undefined,
    };
  },

  toJSON(message: StoreCardPaymentDetailsRequest): unknown {
    const obj: any = {};
    message.cardData !== undefined &&
      (obj.cardData = message.cardData
        ? CardData.toJSON(message.cardData)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<StoreCardPaymentDetailsRequest>,
  ): StoreCardPaymentDetailsRequest {
    return StoreCardPaymentDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<StoreCardPaymentDetailsRequest>,
  ): StoreCardPaymentDetailsRequest {
    const message = createBaseStoreCardPaymentDetailsRequest();
    message.cardData =
      object.cardData !== undefined && object.cardData !== null
        ? CardData.fromPartial(object.cardData)
        : undefined;
    return message;
  },
};

function createBaseStoreCardPaymentDetailsReply(): StoreCardPaymentDetailsReply {
  return { url: '' };
}

export const StoreCardPaymentDetailsReply = {
  encode(
    message: StoreCardPaymentDetailsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.url !== '') {
      writer.uint32(10).string(message.url);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StoreCardPaymentDetailsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStoreCardPaymentDetailsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StoreCardPaymentDetailsReply {
    return { url: isSet(object.url) ? String(object.url) : '' };
  },

  toJSON(message: StoreCardPaymentDetailsReply): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create(
    base?: DeepPartial<StoreCardPaymentDetailsReply>,
  ): StoreCardPaymentDetailsReply {
    return StoreCardPaymentDetailsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<StoreCardPaymentDetailsReply>,
  ): StoreCardPaymentDetailsReply {
    const message = createBaseStoreCardPaymentDetailsReply();
    message.url = object.url ?? '';
    return message;
  },
};

function createBaseGetApplePayPaymentDetailsRequest(): GetApplePayPaymentDetailsRequest {
  return { transactionId: '' };
}

export const GetApplePayPaymentDetailsRequest = {
  encode(
    message: GetApplePayPaymentDetailsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetApplePayPaymentDetailsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetApplePayPaymentDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetApplePayPaymentDetailsRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: GetApplePayPaymentDetailsRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(
    base?: DeepPartial<GetApplePayPaymentDetailsRequest>,
  ): GetApplePayPaymentDetailsRequest {
    return GetApplePayPaymentDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetApplePayPaymentDetailsRequest>,
  ): GetApplePayPaymentDetailsRequest {
    const message = createBaseGetApplePayPaymentDetailsRequest();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseGetApplePayPaymentDetailsReply(): GetApplePayPaymentDetailsReply {
  return {
    providerName: '',
    transactionId: '',
    providerData: {},
    attemptId: '',
  };
}

export const GetApplePayPaymentDetailsReply = {
  encode(
    message: GetApplePayPaymentDetailsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.providerName !== '') {
      writer.uint32(10).string(message.providerName);
    }
    if (message.transactionId !== '') {
      writer.uint32(18).string(message.transactionId);
    }
    Object.entries(message.providerData).forEach(([key, value]) => {
      GetApplePayPaymentDetailsReply_ProviderDataEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    if (message.attemptId !== '') {
      writer.uint32(34).string(message.attemptId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetApplePayPaymentDetailsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetApplePayPaymentDetailsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 =
            GetApplePayPaymentDetailsReply_ProviderDataEntry.decode(
              reader,
              reader.uint32(),
            );
          if (entry3.value !== undefined) {
            message.providerData[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.attemptId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetApplePayPaymentDetailsReply {
    return {
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      providerData: isObject(object.providerData)
        ? Object.entries(object.providerData).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      attemptId: isSet(object.attemptId) ? String(object.attemptId) : '',
    };
  },

  toJSON(message: GetApplePayPaymentDetailsReply): unknown {
    const obj: any = {};
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    obj.providerData = {};
    if (message.providerData) {
      Object.entries(message.providerData).forEach(([k, v]) => {
        obj.providerData[k] = v;
      });
    }
    message.attemptId !== undefined && (obj.attemptId = message.attemptId);
    return obj;
  },

  create(
    base?: DeepPartial<GetApplePayPaymentDetailsReply>,
  ): GetApplePayPaymentDetailsReply {
    return GetApplePayPaymentDetailsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetApplePayPaymentDetailsReply>,
  ): GetApplePayPaymentDetailsReply {
    const message = createBaseGetApplePayPaymentDetailsReply();
    message.providerName = object.providerName ?? '';
    message.transactionId = object.transactionId ?? '';
    message.providerData = Object.entries(object.providerData ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.attemptId = object.attemptId ?? '';
    return message;
  },
};

function createBaseGetApplePayPaymentDetailsReply_ProviderDataEntry(): GetApplePayPaymentDetailsReply_ProviderDataEntry {
  return { key: '', value: '' };
}

export const GetApplePayPaymentDetailsReply_ProviderDataEntry = {
  encode(
    message: GetApplePayPaymentDetailsReply_ProviderDataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetApplePayPaymentDetailsReply_ProviderDataEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseGetApplePayPaymentDetailsReply_ProviderDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetApplePayPaymentDetailsReply_ProviderDataEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: GetApplePayPaymentDetailsReply_ProviderDataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<GetApplePayPaymentDetailsReply_ProviderDataEntry>,
  ): GetApplePayPaymentDetailsReply_ProviderDataEntry {
    return GetApplePayPaymentDetailsReply_ProviderDataEntry.fromPartial(
      base ?? {},
    );
  },

  fromPartial(
    object: DeepPartial<GetApplePayPaymentDetailsReply_ProviderDataEntry>,
  ): GetApplePayPaymentDetailsReply_ProviderDataEntry {
    const message =
      createBaseGetApplePayPaymentDetailsReply_ProviderDataEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseGetAccountLimitsRequest(): GetAccountLimitsRequest {
  return { fromCurrencyCode: '' };
}

export const GetAccountLimitsRequest = {
  encode(
    message: GetAccountLimitsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.fromCurrencyCode !== '') {
      writer.uint32(10).string(message.fromCurrencyCode);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAccountLimitsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAccountLimitsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fromCurrencyCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAccountLimitsRequest {
    return {
      fromCurrencyCode: isSet(object.fromCurrencyCode)
        ? String(object.fromCurrencyCode)
        : '',
    };
  },

  toJSON(message: GetAccountLimitsRequest): unknown {
    const obj: any = {};
    message.fromCurrencyCode !== undefined &&
      (obj.fromCurrencyCode = message.fromCurrencyCode);
    return obj;
  },

  create(base?: DeepPartial<GetAccountLimitsRequest>): GetAccountLimitsRequest {
    return GetAccountLimitsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetAccountLimitsRequest>,
  ): GetAccountLimitsRequest {
    const message = createBaseGetAccountLimitsRequest();
    message.fromCurrencyCode = object.fromCurrencyCode ?? '';
    return message;
  },
};

function createBaseGetAccountLimitsReply(): GetAccountLimitsReply {
  return { accountLimits: {} };
}

export const GetAccountLimitsReply = {
  encode(
    message: GetAccountLimitsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.accountLimits).forEach(([key, value]) => {
      GetAccountLimitsReply_AccountLimitsEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAccountLimitsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAccountLimitsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = GetAccountLimitsReply_AccountLimitsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry1.value !== undefined) {
            message.accountLimits[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAccountLimitsReply {
    return {
      accountLimits: isObject(object.accountLimits)
        ? Object.entries(object.accountLimits).reduce<{
            [key: string]: UserLimits;
          }>((acc, [key, value]) => {
            acc[key] = UserLimits.fromJSON(value);
            return acc;
          }, {})
        : {},
    };
  },

  toJSON(message: GetAccountLimitsReply): unknown {
    const obj: any = {};
    obj.accountLimits = {};
    if (message.accountLimits) {
      Object.entries(message.accountLimits).forEach(([k, v]) => {
        obj.accountLimits[k] = UserLimits.toJSON(v);
      });
    }
    return obj;
  },

  create(base?: DeepPartial<GetAccountLimitsReply>): GetAccountLimitsReply {
    return GetAccountLimitsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetAccountLimitsReply>,
  ): GetAccountLimitsReply {
    const message = createBaseGetAccountLimitsReply();
    message.accountLimits = Object.entries(object.accountLimits ?? {}).reduce<{
      [key: string]: UserLimits;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = UserLimits.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGetAccountLimitsReply_AccountLimitsEntry(): GetAccountLimitsReply_AccountLimitsEntry {
  return { key: '', value: undefined };
}

export const GetAccountLimitsReply_AccountLimitsEntry = {
  encode(
    message: GetAccountLimitsReply_AccountLimitsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      UserLimits.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAccountLimitsReply_AccountLimitsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAccountLimitsReply_AccountLimitsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = UserLimits.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAccountLimitsReply_AccountLimitsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? UserLimits.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(message: GetAccountLimitsReply_AccountLimitsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? UserLimits.toJSON(message.value)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetAccountLimitsReply_AccountLimitsEntry>,
  ): GetAccountLimitsReply_AccountLimitsEntry {
    return GetAccountLimitsReply_AccountLimitsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetAccountLimitsReply_AccountLimitsEntry>,
  ): GetAccountLimitsReply_AccountLimitsEntry {
    const message = createBaseGetAccountLimitsReply_AccountLimitsEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? UserLimits.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseGetTransactionLimitsRequest(): GetTransactionLimitsRequest {
  return {
    fromCurrencyCode: '',
    recipientId: '',
    recipientAccountId: '',
    transactionType: 0,
    walletId: '',
  };
}

export const GetTransactionLimitsRequest = {
  encode(
    message: GetTransactionLimitsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.fromCurrencyCode !== '') {
      writer.uint32(10).string(message.fromCurrencyCode);
    }
    if (message.recipientId !== '') {
      writer.uint32(18).string(message.recipientId);
    }
    if (message.recipientAccountId !== '') {
      writer.uint32(26).string(message.recipientAccountId);
    }
    if (message.transactionType !== 0) {
      writer.uint32(32).int32(message.transactionType);
    }
    if (message.walletId !== '') {
      writer.uint32(42).string(message.walletId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetTransactionLimitsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTransactionLimitsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fromCurrencyCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipientId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.recipientAccountId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.transactionType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.walletId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTransactionLimitsRequest {
    return {
      fromCurrencyCode: isSet(object.fromCurrencyCode)
        ? String(object.fromCurrencyCode)
        : '',
      recipientId: isSet(object.recipientId) ? String(object.recipientId) : '',
      recipientAccountId: isSet(object.recipientAccountId)
        ? String(object.recipientAccountId)
        : '',
      transactionType: isSet(object.transactionType)
        ? transactionTypeFromJSON(object.transactionType)
        : 0,
      walletId: isSet(object.walletId) ? String(object.walletId) : '',
    };
  },

  toJSON(message: GetTransactionLimitsRequest): unknown {
    const obj: any = {};
    message.fromCurrencyCode !== undefined &&
      (obj.fromCurrencyCode = message.fromCurrencyCode);
    message.recipientId !== undefined &&
      (obj.recipientId = message.recipientId);
    message.recipientAccountId !== undefined &&
      (obj.recipientAccountId = message.recipientAccountId);
    message.transactionType !== undefined &&
      (obj.transactionType = transactionTypeToJSON(message.transactionType));
    message.walletId !== undefined && (obj.walletId = message.walletId);
    return obj;
  },

  create(
    base?: DeepPartial<GetTransactionLimitsRequest>,
  ): GetTransactionLimitsRequest {
    return GetTransactionLimitsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetTransactionLimitsRequest>,
  ): GetTransactionLimitsRequest {
    const message = createBaseGetTransactionLimitsRequest();
    message.fromCurrencyCode = object.fromCurrencyCode ?? '';
    message.recipientId = object.recipientId ?? '';
    message.recipientAccountId = object.recipientAccountId ?? '';
    message.transactionType = object.transactionType ?? 0;
    message.walletId = object.walletId ?? '';
    return message;
  },
};

function createBaseGetTransactionLimitsReply(): GetTransactionLimitsReply {
  return { senderLimits: undefined, recipientLimits: undefined };
}

export const GetTransactionLimitsReply = {
  encode(
    message: GetTransactionLimitsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.senderLimits !== undefined) {
      UserLimits.encode(
        message.senderLimits,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.recipientLimits !== undefined) {
      UserLimits.encode(
        message.recipientLimits,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetTransactionLimitsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTransactionLimitsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.senderLimits = UserLimits.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipientLimits = UserLimits.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTransactionLimitsReply {
    return {
      senderLimits: isSet(object.senderLimits)
        ? UserLimits.fromJSON(object.senderLimits)
        : undefined,
      recipientLimits: isSet(object.recipientLimits)
        ? UserLimits.fromJSON(object.recipientLimits)
        : undefined,
    };
  },

  toJSON(message: GetTransactionLimitsReply): unknown {
    const obj: any = {};
    message.senderLimits !== undefined &&
      (obj.senderLimits = message.senderLimits
        ? UserLimits.toJSON(message.senderLimits)
        : undefined);
    message.recipientLimits !== undefined &&
      (obj.recipientLimits = message.recipientLimits
        ? UserLimits.toJSON(message.recipientLimits)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetTransactionLimitsReply>,
  ): GetTransactionLimitsReply {
    return GetTransactionLimitsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetTransactionLimitsReply>,
  ): GetTransactionLimitsReply {
    const message = createBaseGetTransactionLimitsReply();
    message.senderLimits =
      object.senderLimits !== undefined && object.senderLimits !== null
        ? UserLimits.fromPartial(object.senderLimits)
        : undefined;
    message.recipientLimits =
      object.recipientLimits !== undefined && object.recipientLimits !== null
        ? UserLimits.fromPartial(object.recipientLimits)
        : undefined;
    return message;
  },
};

function createBaseCreateRecipientAccountRequest(): CreateRecipientAccountRequest {
  return { recipientAccount: undefined };
}

export const CreateRecipientAccountRequest = {
  encode(
    message: CreateRecipientAccountRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipientAccount !== undefined) {
      RecipientAccount.encode(
        message.recipientAccount,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateRecipientAccountRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRecipientAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientAccount = RecipientAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRecipientAccountRequest {
    return {
      recipientAccount: isSet(object.recipientAccount)
        ? RecipientAccount.fromJSON(object.recipientAccount)
        : undefined,
    };
  },

  toJSON(message: CreateRecipientAccountRequest): unknown {
    const obj: any = {};
    message.recipientAccount !== undefined &&
      (obj.recipientAccount = message.recipientAccount
        ? RecipientAccount.toJSON(message.recipientAccount)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<CreateRecipientAccountRequest>,
  ): CreateRecipientAccountRequest {
    return CreateRecipientAccountRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateRecipientAccountRequest>,
  ): CreateRecipientAccountRequest {
    const message = createBaseCreateRecipientAccountRequest();
    message.recipientAccount =
      object.recipientAccount !== undefined && object.recipientAccount !== null
        ? RecipientAccount.fromPartial(object.recipientAccount)
        : undefined;
    return message;
  },
};

function createBaseCreateRecipientAccountReply(): CreateRecipientAccountReply {
  return { recipient: undefined, recipientAccount: undefined };
}

export const CreateRecipientAccountReply = {
  encode(
    message: CreateRecipientAccountReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipient !== undefined) {
      Recipient.encode(message.recipient, writer.uint32(10).fork()).ldelim();
    }
    if (message.recipientAccount !== undefined) {
      RecipientAccount.encode(
        message.recipientAccount,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateRecipientAccountReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRecipientAccountReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipient = Recipient.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipientAccount = RecipientAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateRecipientAccountReply {
    return {
      recipient: isSet(object.recipient)
        ? Recipient.fromJSON(object.recipient)
        : undefined,
      recipientAccount: isSet(object.recipientAccount)
        ? RecipientAccount.fromJSON(object.recipientAccount)
        : undefined,
    };
  },

  toJSON(message: CreateRecipientAccountReply): unknown {
    const obj: any = {};
    message.recipient !== undefined &&
      (obj.recipient = message.recipient
        ? Recipient.toJSON(message.recipient)
        : undefined);
    message.recipientAccount !== undefined &&
      (obj.recipientAccount = message.recipientAccount
        ? RecipientAccount.toJSON(message.recipientAccount)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<CreateRecipientAccountReply>,
  ): CreateRecipientAccountReply {
    return CreateRecipientAccountReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateRecipientAccountReply>,
  ): CreateRecipientAccountReply {
    const message = createBaseCreateRecipientAccountReply();
    message.recipient =
      object.recipient !== undefined && object.recipient !== null
        ? Recipient.fromPartial(object.recipient)
        : undefined;
    message.recipientAccount =
      object.recipientAccount !== undefined && object.recipientAccount !== null
        ? RecipientAccount.fromPartial(object.recipientAccount)
        : undefined;
    return message;
  },
};

function createBaseGetBanksRequest(): GetBanksRequest {
  return { country: '' };
}

export const GetBanksRequest = {
  encode(
    message: GetBanksRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.country !== '') {
      writer.uint32(10).string(message.country);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBanksRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBanksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.country = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBanksRequest {
    return { country: isSet(object.country) ? String(object.country) : '' };
  },

  toJSON(message: GetBanksRequest): unknown {
    const obj: any = {};
    message.country !== undefined && (obj.country = message.country);
    return obj;
  },

  create(base?: DeepPartial<GetBanksRequest>): GetBanksRequest {
    return GetBanksRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetBanksRequest>): GetBanksRequest {
    const message = createBaseGetBanksRequest();
    message.country = object.country ?? '';
    return message;
  },
};

function createBaseGetBanksReply(): GetBanksReply {
  return { banks: [] };
}

export const GetBanksReply = {
  encode(
    message: GetBanksReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.banks) {
      Bank.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBanksReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBanksReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.banks.push(Bank.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBanksReply {
    return {
      banks: Array.isArray(object?.banks)
        ? object.banks.map((e: any) => Bank.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetBanksReply): unknown {
    const obj: any = {};
    if (message.banks) {
      obj.banks = message.banks.map((e) => (e ? Bank.toJSON(e) : undefined));
    } else {
      obj.banks = [];
    }
    return obj;
  },

  create(base?: DeepPartial<GetBanksReply>): GetBanksReply {
    return GetBanksReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetBanksReply>): GetBanksReply {
    const message = createBaseGetBanksReply();
    message.banks = object.banks?.map((e) => Bank.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetETARequest(): GetETARequest {
  return { recipientAccountId: '', amount: undefined };
}

export const GetETARequest = {
  encode(
    message: GetETARequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipientAccountId !== '') {
      writer.uint32(10).string(message.recipientAccountId);
    }
    if (message.amount !== undefined) {
      Money.encode(message.amount, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetETARequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetETARequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientAccountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.amount = Money.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetETARequest {
    return {
      recipientAccountId: isSet(object.recipientAccountId)
        ? String(object.recipientAccountId)
        : '',
      amount: isSet(object.amount) ? Money.fromJSON(object.amount) : undefined,
    };
  },

  toJSON(message: GetETARequest): unknown {
    const obj: any = {};
    message.recipientAccountId !== undefined &&
      (obj.recipientAccountId = message.recipientAccountId);
    message.amount !== undefined &&
      (obj.amount = message.amount ? Money.toJSON(message.amount) : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetETARequest>): GetETARequest {
    return GetETARequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetETARequest>): GetETARequest {
    const message = createBaseGetETARequest();
    message.recipientAccountId = object.recipientAccountId ?? '';
    message.amount =
      object.amount !== undefined && object.amount !== null
        ? Money.fromPartial(object.amount)
        : undefined;
    return message;
  },
};

function createBaseGetETAReply(): GetETAReply {
  return { eta: undefined };
}

export const GetETAReply = {
  encode(
    message: GetETAReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.eta !== undefined) {
      ETA.encode(message.eta, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetETAReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetETAReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.eta = ETA.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetETAReply {
    return { eta: isSet(object.eta) ? ETA.fromJSON(object.eta) : undefined };
  },

  toJSON(message: GetETAReply): unknown {
    const obj: any = {};
    message.eta !== undefined &&
      (obj.eta = message.eta ? ETA.toJSON(message.eta) : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetETAReply>): GetETAReply {
    return GetETAReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetETAReply>): GetETAReply {
    const message = createBaseGetETAReply();
    message.eta =
      object.eta !== undefined && object.eta !== null
        ? ETA.fromPartial(object.eta)
        : undefined;
    return message;
  },
};

function createBaseGetCollectionETARequest(): GetCollectionETARequest {
  return { paymentMethodType: 0, providerName: '' };
}

export const GetCollectionETARequest = {
  encode(
    message: GetCollectionETARequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.paymentMethodType !== 0) {
      writer.uint32(8).int32(message.paymentMethodType);
    }
    if (message.providerName !== '') {
      writer.uint32(18).string(message.providerName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetCollectionETARequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectionETARequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.paymentMethodType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCollectionETARequest {
    return {
      paymentMethodType: isSet(object.paymentMethodType)
        ? paymentMethodTypeFromJSON(object.paymentMethodType)
        : 0,
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
    };
  },

  toJSON(message: GetCollectionETARequest): unknown {
    const obj: any = {};
    message.paymentMethodType !== undefined &&
      (obj.paymentMethodType = paymentMethodTypeToJSON(
        message.paymentMethodType,
      ));
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    return obj;
  },

  create(base?: DeepPartial<GetCollectionETARequest>): GetCollectionETARequest {
    return GetCollectionETARequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetCollectionETARequest>,
  ): GetCollectionETARequest {
    const message = createBaseGetCollectionETARequest();
    message.paymentMethodType = object.paymentMethodType ?? 0;
    message.providerName = object.providerName ?? '';
    return message;
  },
};

function createBaseGetCollectionETAReply(): GetCollectionETAReply {
  return { eta: undefined };
}

export const GetCollectionETAReply = {
  encode(
    message: GetCollectionETAReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.eta !== undefined) {
      ETA.encode(message.eta, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetCollectionETAReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectionETAReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.eta = ETA.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCollectionETAReply {
    return { eta: isSet(object.eta) ? ETA.fromJSON(object.eta) : undefined };
  },

  toJSON(message: GetCollectionETAReply): unknown {
    const obj: any = {};
    message.eta !== undefined &&
      (obj.eta = message.eta ? ETA.toJSON(message.eta) : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetCollectionETAReply>): GetCollectionETAReply {
    return GetCollectionETAReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetCollectionETAReply>,
  ): GetCollectionETAReply {
    const message = createBaseGetCollectionETAReply();
    message.eta =
      object.eta !== undefined && object.eta !== null
        ? ETA.fromPartial(object.eta)
        : undefined;
    return message;
  },
};

function createBasePayWithWalletRequest(): PayWithWalletRequest {
  return { transactionId: '', walletId: '' };
}

export const PayWithWalletRequest = {
  encode(
    message: PayWithWalletRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.walletId !== '') {
      writer.uint32(18).string(message.walletId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PayWithWalletRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayWithWalletRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.walletId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayWithWalletRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      walletId: isSet(object.walletId) ? String(object.walletId) : '',
    };
  },

  toJSON(message: PayWithWalletRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.walletId !== undefined && (obj.walletId = message.walletId);
    return obj;
  },

  create(base?: DeepPartial<PayWithWalletRequest>): PayWithWalletRequest {
    return PayWithWalletRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PayWithWalletRequest>): PayWithWalletRequest {
    const message = createBasePayWithWalletRequest();
    message.transactionId = object.transactionId ?? '';
    message.walletId = object.walletId ?? '';
    return message;
  },
};

function createBasePayWithWalletReply(): PayWithWalletReply {
  return {};
}

export const PayWithWalletReply = {
  encode(
    _: PayWithWalletReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayWithWalletReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayWithWalletReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): PayWithWalletReply {
    return {};
  },

  toJSON(_: PayWithWalletReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<PayWithWalletReply>): PayWithWalletReply {
    return PayWithWalletReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<PayWithWalletReply>): PayWithWalletReply {
    const message = createBasePayWithWalletReply();
    return message;
  },
};

function createBasePayWithExternalWalletRequest(): PayWithExternalWalletRequest {
  return { transactionId: '', type: 0, tokenData: '', clientResult: '' };
}

export const PayWithExternalWalletRequest = {
  encode(
    message: PayWithExternalWalletRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.tokenData !== '') {
      writer.uint32(26).string(message.tokenData);
    }
    if (message.clientResult !== '') {
      writer.uint32(34).string(message.clientResult);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PayWithExternalWalletRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayWithExternalWalletRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tokenData = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clientResult = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayWithExternalWalletRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      type: isSet(object.type) ? paymentMethodTypeFromJSON(object.type) : 0,
      tokenData: isSet(object.tokenData) ? String(object.tokenData) : '',
      clientResult: isSet(object.clientResult)
        ? String(object.clientResult)
        : '',
    };
  },

  toJSON(message: PayWithExternalWalletRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.type !== undefined &&
      (obj.type = paymentMethodTypeToJSON(message.type));
    message.tokenData !== undefined && (obj.tokenData = message.tokenData);
    message.clientResult !== undefined &&
      (obj.clientResult = message.clientResult);
    return obj;
  },

  create(
    base?: DeepPartial<PayWithExternalWalletRequest>,
  ): PayWithExternalWalletRequest {
    return PayWithExternalWalletRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<PayWithExternalWalletRequest>,
  ): PayWithExternalWalletRequest {
    const message = createBasePayWithExternalWalletRequest();
    message.transactionId = object.transactionId ?? '';
    message.type = object.type ?? 0;
    message.tokenData = object.tokenData ?? '';
    message.clientResult = object.clientResult ?? '';
    return message;
  },
};

function createBasePayWithExternalWalletReply(): PayWithExternalWalletReply {
  return { attemptId: '', nextStepUrl: '', providerName: '' };
}

export const PayWithExternalWalletReply = {
  encode(
    message: PayWithExternalWalletReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.attemptId !== '') {
      writer.uint32(10).string(message.attemptId);
    }
    if (message.nextStepUrl !== '') {
      writer.uint32(18).string(message.nextStepUrl);
    }
    if (message.providerName !== '') {
      writer.uint32(26).string(message.providerName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PayWithExternalWalletReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayWithExternalWalletReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.attemptId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextStepUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.providerName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PayWithExternalWalletReply {
    return {
      attemptId: isSet(object.attemptId) ? String(object.attemptId) : '',
      nextStepUrl: isSet(object.nextStepUrl) ? String(object.nextStepUrl) : '',
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
    };
  },

  toJSON(message: PayWithExternalWalletReply): unknown {
    const obj: any = {};
    message.attemptId !== undefined && (obj.attemptId = message.attemptId);
    message.nextStepUrl !== undefined &&
      (obj.nextStepUrl = message.nextStepUrl);
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    return obj;
  },

  create(
    base?: DeepPartial<PayWithExternalWalletReply>,
  ): PayWithExternalWalletReply {
    return PayWithExternalWalletReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<PayWithExternalWalletReply>,
  ): PayWithExternalWalletReply {
    const message = createBasePayWithExternalWalletReply();
    message.attemptId = object.attemptId ?? '';
    message.nextStepUrl = object.nextStepUrl ?? '';
    message.providerName = object.providerName ?? '';
    return message;
  },
};

function createBaseGetRecipientAccountByIDRequest(): GetRecipientAccountByIDRequest {
  return { recipientAccountId: '' };
}

export const GetRecipientAccountByIDRequest = {
  encode(
    message: GetRecipientAccountByIDRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipientAccountId !== '') {
      writer.uint32(10).string(message.recipientAccountId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetRecipientAccountByIDRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRecipientAccountByIDRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientAccountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRecipientAccountByIDRequest {
    return {
      recipientAccountId: isSet(object.recipientAccountId)
        ? String(object.recipientAccountId)
        : '',
    };
  },

  toJSON(message: GetRecipientAccountByIDRequest): unknown {
    const obj: any = {};
    message.recipientAccountId !== undefined &&
      (obj.recipientAccountId = message.recipientAccountId);
    return obj;
  },

  create(
    base?: DeepPartial<GetRecipientAccountByIDRequest>,
  ): GetRecipientAccountByIDRequest {
    return GetRecipientAccountByIDRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetRecipientAccountByIDRequest>,
  ): GetRecipientAccountByIDRequest {
    const message = createBaseGetRecipientAccountByIDRequest();
    message.recipientAccountId = object.recipientAccountId ?? '';
    return message;
  },
};

function createBaseGetRecipientAccountByIDReply(): GetRecipientAccountByIDReply {
  return { recipientAccount: undefined };
}

export const GetRecipientAccountByIDReply = {
  encode(
    message: GetRecipientAccountByIDReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipientAccount !== undefined) {
      RecipientAccount.encode(
        message.recipientAccount,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetRecipientAccountByIDReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRecipientAccountByIDReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientAccount = RecipientAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRecipientAccountByIDReply {
    return {
      recipientAccount: isSet(object.recipientAccount)
        ? RecipientAccount.fromJSON(object.recipientAccount)
        : undefined,
    };
  },

  toJSON(message: GetRecipientAccountByIDReply): unknown {
    const obj: any = {};
    message.recipientAccount !== undefined &&
      (obj.recipientAccount = message.recipientAccount
        ? RecipientAccount.toJSON(message.recipientAccount)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<GetRecipientAccountByIDReply>,
  ): GetRecipientAccountByIDReply {
    return GetRecipientAccountByIDReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetRecipientAccountByIDReply>,
  ): GetRecipientAccountByIDReply {
    const message = createBaseGetRecipientAccountByIDReply();
    message.recipientAccount =
      object.recipientAccount !== undefined && object.recipientAccount !== null
        ? RecipientAccount.fromPartial(object.recipientAccount)
        : undefined;
    return message;
  },
};

function createBaseGetRecipientByIDRequest(): GetRecipientByIDRequest {
  return { recipientId: '' };
}

export const GetRecipientByIDRequest = {
  encode(
    message: GetRecipientByIDRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipientId !== '') {
      writer.uint32(10).string(message.recipientId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetRecipientByIDRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRecipientByIDRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipientId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRecipientByIDRequest {
    return {
      recipientId: isSet(object.recipientId) ? String(object.recipientId) : '',
    };
  },

  toJSON(message: GetRecipientByIDRequest): unknown {
    const obj: any = {};
    message.recipientId !== undefined &&
      (obj.recipientId = message.recipientId);
    return obj;
  },

  create(base?: DeepPartial<GetRecipientByIDRequest>): GetRecipientByIDRequest {
    return GetRecipientByIDRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetRecipientByIDRequest>,
  ): GetRecipientByIDRequest {
    const message = createBaseGetRecipientByIDRequest();
    message.recipientId = object.recipientId ?? '';
    return message;
  },
};

function createBaseGetRecipientByIDReply(): GetRecipientByIDReply {
  return { recipient: undefined };
}

export const GetRecipientByIDReply = {
  encode(
    message: GetRecipientByIDReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.recipient !== undefined) {
      Recipient.encode(message.recipient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetRecipientByIDReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRecipientByIDReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.recipient = Recipient.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRecipientByIDReply {
    return {
      recipient: isSet(object.recipient)
        ? Recipient.fromJSON(object.recipient)
        : undefined,
    };
  },

  toJSON(message: GetRecipientByIDReply): unknown {
    const obj: any = {};
    message.recipient !== undefined &&
      (obj.recipient = message.recipient
        ? Recipient.toJSON(message.recipient)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetRecipientByIDReply>): GetRecipientByIDReply {
    return GetRecipientByIDReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetRecipientByIDReply>,
  ): GetRecipientByIDReply {
    const message = createBaseGetRecipientByIDReply();
    message.recipient =
      object.recipient !== undefined && object.recipient !== null
        ? Recipient.fromPartial(object.recipient)
        : undefined;
    return message;
  },
};

function createBaseExportStatementToFileRequest(): ExportStatementToFileRequest {
  return {
    fromDate: undefined,
    toDate: undefined,
    outputFormat: 0,
    walletId: '',
    month: 0,
    year: 0,
  };
}

export const ExportStatementToFileRequest = {
  encode(
    message: ExportStatementToFileRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.fromDate !== undefined) {
      Timestamp.encode(message.fromDate, writer.uint32(18).fork()).ldelim();
    }
    if (message.toDate !== undefined) {
      Timestamp.encode(message.toDate, writer.uint32(26).fork()).ldelim();
    }
    if (message.outputFormat !== 0) {
      writer.uint32(32).int32(message.outputFormat);
    }
    if (message.walletId !== '') {
      writer.uint32(42).string(message.walletId);
    }
    if (message.month !== 0) {
      writer.uint32(48).int32(message.month);
    }
    if (message.year !== 0) {
      writer.uint32(56).int32(message.year);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ExportStatementToFileRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExportStatementToFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fromDate = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.toDate = Timestamp.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.outputFormat = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.walletId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.month = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.year = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExportStatementToFileRequest {
    return {
      fromDate: isSet(object.fromDate)
        ? fromJsonTimestamp(object.fromDate)
        : undefined,
      toDate: isSet(object.toDate)
        ? fromJsonTimestamp(object.toDate)
        : undefined,
      outputFormat: isSet(object.outputFormat)
        ? statementOutputFormatFromJSON(object.outputFormat)
        : 0,
      walletId: isSet(object.walletId) ? String(object.walletId) : '',
      month: isSet(object.month) ? Number(object.month) : 0,
      year: isSet(object.year) ? Number(object.year) : 0,
    };
  },

  toJSON(message: ExportStatementToFileRequest): unknown {
    const obj: any = {};
    message.fromDate !== undefined &&
      (obj.fromDate = fromTimestamp(message.fromDate).toISOString());
    message.toDate !== undefined &&
      (obj.toDate = fromTimestamp(message.toDate).toISOString());
    message.outputFormat !== undefined &&
      (obj.outputFormat = statementOutputFormatToJSON(message.outputFormat));
    message.walletId !== undefined && (obj.walletId = message.walletId);
    message.month !== undefined && (obj.month = Math.round(message.month));
    message.year !== undefined && (obj.year = Math.round(message.year));
    return obj;
  },

  create(
    base?: DeepPartial<ExportStatementToFileRequest>,
  ): ExportStatementToFileRequest {
    return ExportStatementToFileRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ExportStatementToFileRequest>,
  ): ExportStatementToFileRequest {
    const message = createBaseExportStatementToFileRequest();
    message.fromDate =
      object.fromDate !== undefined && object.fromDate !== null
        ? Timestamp.fromPartial(object.fromDate)
        : undefined;
    message.toDate =
      object.toDate !== undefined && object.toDate !== null
        ? Timestamp.fromPartial(object.toDate)
        : undefined;
    message.outputFormat = object.outputFormat ?? 0;
    message.walletId = object.walletId ?? '';
    message.month = object.month ?? 0;
    message.year = object.year ?? 0;
    return message;
  },
};

function createBaseExportStatementToFileReply(): ExportStatementToFileReply {
  return { statementFile: new Uint8Array(0) };
}

export const ExportStatementToFileReply = {
  encode(
    message: ExportStatementToFileReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.statementFile.length !== 0) {
      writer.uint32(10).bytes(message.statementFile);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ExportStatementToFileReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExportStatementToFileReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.statementFile = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExportStatementToFileReply {
    return {
      statementFile: isSet(object.statementFile)
        ? bytesFromBase64(object.statementFile)
        : new Uint8Array(0),
    };
  },

  toJSON(message: ExportStatementToFileReply): unknown {
    const obj: any = {};
    message.statementFile !== undefined &&
      (obj.statementFile = base64FromBytes(
        message.statementFile !== undefined
          ? message.statementFile
          : new Uint8Array(0),
      ));
    return obj;
  },

  create(
    base?: DeepPartial<ExportStatementToFileReply>,
  ): ExportStatementToFileReply {
    return ExportStatementToFileReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ExportStatementToFileReply>,
  ): ExportStatementToFileReply {
    const message = createBaseExportStatementToFileReply();
    message.statementFile = object.statementFile ?? new Uint8Array(0);
    return message;
  },
};

function createBaseGetStatementDownloadURLRequest(): GetStatementDownloadURLRequest {
  return { outputFormat: 0, walletId: '', month: 0, year: 0 };
}

export const GetStatementDownloadURLRequest = {
  encode(
    message: GetStatementDownloadURLRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.outputFormat !== 0) {
      writer.uint32(8).int32(message.outputFormat);
    }
    if (message.walletId !== '') {
      writer.uint32(18).string(message.walletId);
    }
    if (message.month !== 0) {
      writer.uint32(24).int32(message.month);
    }
    if (message.year !== 0) {
      writer.uint32(32).int32(message.year);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetStatementDownloadURLRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStatementDownloadURLRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.outputFormat = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.walletId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.month = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.year = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStatementDownloadURLRequest {
    return {
      outputFormat: isSet(object.outputFormat)
        ? statementOutputFormatFromJSON(object.outputFormat)
        : 0,
      walletId: isSet(object.walletId) ? String(object.walletId) : '',
      month: isSet(object.month) ? Number(object.month) : 0,
      year: isSet(object.year) ? Number(object.year) : 0,
    };
  },

  toJSON(message: GetStatementDownloadURLRequest): unknown {
    const obj: any = {};
    message.outputFormat !== undefined &&
      (obj.outputFormat = statementOutputFormatToJSON(message.outputFormat));
    message.walletId !== undefined && (obj.walletId = message.walletId);
    message.month !== undefined && (obj.month = Math.round(message.month));
    message.year !== undefined && (obj.year = Math.round(message.year));
    return obj;
  },

  create(
    base?: DeepPartial<GetStatementDownloadURLRequest>,
  ): GetStatementDownloadURLRequest {
    return GetStatementDownloadURLRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetStatementDownloadURLRequest>,
  ): GetStatementDownloadURLRequest {
    const message = createBaseGetStatementDownloadURLRequest();
    message.outputFormat = object.outputFormat ?? 0;
    message.walletId = object.walletId ?? '';
    message.month = object.month ?? 0;
    message.year = object.year ?? 0;
    return message;
  },
};

function createBaseGetStatementDownloadURLReply(): GetStatementDownloadURLReply {
  return { url: '' };
}

export const GetStatementDownloadURLReply = {
  encode(
    message: GetStatementDownloadURLReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.url !== '') {
      writer.uint32(10).string(message.url);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetStatementDownloadURLReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStatementDownloadURLReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStatementDownloadURLReply {
    return { url: isSet(object.url) ? String(object.url) : '' };
  },

  toJSON(message: GetStatementDownloadURLReply): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create(
    base?: DeepPartial<GetStatementDownloadURLReply>,
  ): GetStatementDownloadURLReply {
    return GetStatementDownloadURLReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetStatementDownloadURLReply>,
  ): GetStatementDownloadURLReply {
    const message = createBaseGetStatementDownloadURLReply();
    message.url = object.url ?? '';
    return message;
  },
};

function createBaseGetReceiptDownloadURLRequest(): GetReceiptDownloadURLRequest {
  return { transactionId: '' };
}

export const GetReceiptDownloadURLRequest = {
  encode(
    message: GetReceiptDownloadURLRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetReceiptDownloadURLRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReceiptDownloadURLRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReceiptDownloadURLRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: GetReceiptDownloadURLRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(
    base?: DeepPartial<GetReceiptDownloadURLRequest>,
  ): GetReceiptDownloadURLRequest {
    return GetReceiptDownloadURLRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetReceiptDownloadURLRequest>,
  ): GetReceiptDownloadURLRequest {
    const message = createBaseGetReceiptDownloadURLRequest();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseGetReceiptDownloadURLReply(): GetReceiptDownloadURLReply {
  return { url: '' };
}

export const GetReceiptDownloadURLReply = {
  encode(
    message: GetReceiptDownloadURLReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.url !== '') {
      writer.uint32(10).string(message.url);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetReceiptDownloadURLReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReceiptDownloadURLReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetReceiptDownloadURLReply {
    return { url: isSet(object.url) ? String(object.url) : '' };
  },

  toJSON(message: GetReceiptDownloadURLReply): unknown {
    const obj: any = {};
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create(
    base?: DeepPartial<GetReceiptDownloadURLReply>,
  ): GetReceiptDownloadURLReply {
    return GetReceiptDownloadURLReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetReceiptDownloadURLReply>,
  ): GetReceiptDownloadURLReply {
    const message = createBaseGetReceiptDownloadURLReply();
    message.url = object.url ?? '';
    return message;
  },
};

function createBaseGetSummaryRequest(): GetSummaryRequest {
  return {
    transactionType: 0,
    recipientAccountId: '',
    clientQuote: undefined,
    quoteId: '',
    fromWalletId: '',
    toWalletId: '',
  };
}

export const GetSummaryRequest = {
  encode(
    message: GetSummaryRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionType !== 0) {
      writer.uint32(8).int32(message.transactionType);
    }
    if (message.recipientAccountId !== '') {
      writer.uint32(18).string(message.recipientAccountId);
    }
    if (message.clientQuote !== undefined) {
      ClientQuote.encode(
        message.clientQuote,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.quoteId !== '') {
      writer.uint32(34).string(message.quoteId);
    }
    if (message.fromWalletId !== '') {
      writer.uint32(42).string(message.fromWalletId);
    }
    if (message.toWalletId !== '') {
      writer.uint32(50).string(message.toWalletId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSummaryRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSummaryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.transactionType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipientAccountId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientQuote = ClientQuote.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.quoteId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fromWalletId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.toWalletId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSummaryRequest {
    return {
      transactionType: isSet(object.transactionType)
        ? transactionTypeFromJSON(object.transactionType)
        : 0,
      recipientAccountId: isSet(object.recipientAccountId)
        ? String(object.recipientAccountId)
        : '',
      clientQuote: isSet(object.clientQuote)
        ? ClientQuote.fromJSON(object.clientQuote)
        : undefined,
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : '',
      fromWalletId: isSet(object.fromWalletId)
        ? String(object.fromWalletId)
        : '',
      toWalletId: isSet(object.toWalletId) ? String(object.toWalletId) : '',
    };
  },

  toJSON(message: GetSummaryRequest): unknown {
    const obj: any = {};
    message.transactionType !== undefined &&
      (obj.transactionType = transactionTypeToJSON(message.transactionType));
    message.recipientAccountId !== undefined &&
      (obj.recipientAccountId = message.recipientAccountId);
    message.clientQuote !== undefined &&
      (obj.clientQuote = message.clientQuote
        ? ClientQuote.toJSON(message.clientQuote)
        : undefined);
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    message.fromWalletId !== undefined &&
      (obj.fromWalletId = message.fromWalletId);
    message.toWalletId !== undefined && (obj.toWalletId = message.toWalletId);
    return obj;
  },

  create(base?: DeepPartial<GetSummaryRequest>): GetSummaryRequest {
    return GetSummaryRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetSummaryRequest>): GetSummaryRequest {
    const message = createBaseGetSummaryRequest();
    message.transactionType = object.transactionType ?? 0;
    message.recipientAccountId = object.recipientAccountId ?? '';
    message.clientQuote =
      object.clientQuote !== undefined && object.clientQuote !== null
        ? ClientQuote.fromPartial(object.clientQuote)
        : undefined;
    message.quoteId = object.quoteId ?? '';
    message.fromWalletId = object.fromWalletId ?? '';
    message.toWalletId = object.toWalletId ?? '';
    return message;
  },
};

function createBaseGetSummaryReply(): GetSummaryReply {
  return { summary: undefined, paymentSummary: undefined };
}

export const GetSummaryReply = {
  encode(
    message: GetSummaryReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.summary !== undefined) {
      Summary.encode(message.summary, writer.uint32(10).fork()).ldelim();
    }
    if (message.paymentSummary !== undefined) {
      PaymentSummary.encode(
        message.paymentSummary,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSummaryReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSummaryReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.summary = Summary.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.paymentSummary = PaymentSummary.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSummaryReply {
    return {
      summary: isSet(object.summary)
        ? Summary.fromJSON(object.summary)
        : undefined,
      paymentSummary: isSet(object.paymentSummary)
        ? PaymentSummary.fromJSON(object.paymentSummary)
        : undefined,
    };
  },

  toJSON(message: GetSummaryReply): unknown {
    const obj: any = {};
    message.summary !== undefined &&
      (obj.summary = message.summary
        ? Summary.toJSON(message.summary)
        : undefined);
    message.paymentSummary !== undefined &&
      (obj.paymentSummary = message.paymentSummary
        ? PaymentSummary.toJSON(message.paymentSummary)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetSummaryReply>): GetSummaryReply {
    return GetSummaryReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetSummaryReply>): GetSummaryReply {
    const message = createBaseGetSummaryReply();
    message.summary =
      object.summary !== undefined && object.summary !== null
        ? Summary.fromPartial(object.summary)
        : undefined;
    message.paymentSummary =
      object.paymentSummary !== undefined && object.paymentSummary !== null
        ? PaymentSummary.fromPartial(object.paymentSummary)
        : undefined;
    return message;
  },
};

function createBaseProcessBulkPaymentFileRequest(): ProcessBulkPaymentFileRequest {
  return { bulkPaymentFile: new Uint8Array(0), quoteId: '' };
}

export const ProcessBulkPaymentFileRequest = {
  encode(
    message: ProcessBulkPaymentFileRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.bulkPaymentFile.length !== 0) {
      writer.uint32(10).bytes(message.bulkPaymentFile);
    }
    if (message.quoteId !== '') {
      writer.uint32(18).string(message.quoteId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ProcessBulkPaymentFileRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessBulkPaymentFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bulkPaymentFile = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.quoteId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProcessBulkPaymentFileRequest {
    return {
      bulkPaymentFile: isSet(object.bulkPaymentFile)
        ? bytesFromBase64(object.bulkPaymentFile)
        : new Uint8Array(0),
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : '',
    };
  },

  toJSON(message: ProcessBulkPaymentFileRequest): unknown {
    const obj: any = {};
    message.bulkPaymentFile !== undefined &&
      (obj.bulkPaymentFile = base64FromBytes(
        message.bulkPaymentFile !== undefined
          ? message.bulkPaymentFile
          : new Uint8Array(0),
      ));
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    return obj;
  },

  create(
    base?: DeepPartial<ProcessBulkPaymentFileRequest>,
  ): ProcessBulkPaymentFileRequest {
    return ProcessBulkPaymentFileRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ProcessBulkPaymentFileRequest>,
  ): ProcessBulkPaymentFileRequest {
    const message = createBaseProcessBulkPaymentFileRequest();
    message.bulkPaymentFile = object.bulkPaymentFile ?? new Uint8Array(0);
    message.quoteId = object.quoteId ?? '';
    return message;
  },
};

function createBaseProcessBulkPaymentFileReply(): ProcessBulkPaymentFileReply {
  return { transactionReviews: [] };
}

export const ProcessBulkPaymentFileReply = {
  encode(
    message: ProcessBulkPaymentFileReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.transactionReviews) {
      TransactionReview.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ProcessBulkPaymentFileReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessBulkPaymentFileReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionReviews.push(
            TransactionReview.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProcessBulkPaymentFileReply {
    return {
      transactionReviews: Array.isArray(object?.transactionReviews)
        ? object.transactionReviews.map((e: any) =>
            TransactionReview.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ProcessBulkPaymentFileReply): unknown {
    const obj: any = {};
    if (message.transactionReviews) {
      obj.transactionReviews = message.transactionReviews.map((e) =>
        e ? TransactionReview.toJSON(e) : undefined,
      );
    } else {
      obj.transactionReviews = [];
    }
    return obj;
  },

  create(
    base?: DeepPartial<ProcessBulkPaymentFileReply>,
  ): ProcessBulkPaymentFileReply {
    return ProcessBulkPaymentFileReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ProcessBulkPaymentFileReply>,
  ): ProcessBulkPaymentFileReply {
    const message = createBaseProcessBulkPaymentFileReply();
    message.transactionReviews =
      object.transactionReviews?.map((e) => TransactionReview.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseCreateBulkPaymentFromReviewsRequest(): CreateBulkPaymentFromReviewsRequest {
  return {
    fromWalletId: '',
    name: '',
    transactionReviews: [],
    quoteId: '',
    purpose: '',
  };
}

export const CreateBulkPaymentFromReviewsRequest = {
  encode(
    message: CreateBulkPaymentFromReviewsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.fromWalletId !== '') {
      writer.uint32(10).string(message.fromWalletId);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.transactionReviews) {
      TransactionReview.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.quoteId !== '') {
      writer.uint32(34).string(message.quoteId);
    }
    if (message.purpose !== '') {
      writer.uint32(42).string(message.purpose);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateBulkPaymentFromReviewsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBulkPaymentFromReviewsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fromWalletId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.transactionReviews.push(
            TransactionReview.decode(reader, reader.uint32()),
          );
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.quoteId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.purpose = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBulkPaymentFromReviewsRequest {
    return {
      fromWalletId: isSet(object.fromWalletId)
        ? String(object.fromWalletId)
        : '',
      name: isSet(object.name) ? String(object.name) : '',
      transactionReviews: Array.isArray(object?.transactionReviews)
        ? object.transactionReviews.map((e: any) =>
            TransactionReview.fromJSON(e),
          )
        : [],
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : '',
      purpose: isSet(object.purpose) ? String(object.purpose) : '',
    };
  },

  toJSON(message: CreateBulkPaymentFromReviewsRequest): unknown {
    const obj: any = {};
    message.fromWalletId !== undefined &&
      (obj.fromWalletId = message.fromWalletId);
    message.name !== undefined && (obj.name = message.name);
    if (message.transactionReviews) {
      obj.transactionReviews = message.transactionReviews.map((e) =>
        e ? TransactionReview.toJSON(e) : undefined,
      );
    } else {
      obj.transactionReviews = [];
    }
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    return obj;
  },

  create(
    base?: DeepPartial<CreateBulkPaymentFromReviewsRequest>,
  ): CreateBulkPaymentFromReviewsRequest {
    return CreateBulkPaymentFromReviewsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateBulkPaymentFromReviewsRequest>,
  ): CreateBulkPaymentFromReviewsRequest {
    const message = createBaseCreateBulkPaymentFromReviewsRequest();
    message.fromWalletId = object.fromWalletId ?? '';
    message.name = object.name ?? '';
    message.transactionReviews =
      object.transactionReviews?.map((e) => TransactionReview.fromPartial(e)) ||
      [];
    message.quoteId = object.quoteId ?? '';
    message.purpose = object.purpose ?? '';
    return message;
  },
};

function createBaseCreateBulkPaymentFromReviewsReply(): CreateBulkPaymentFromReviewsReply {
  return { bulkPaymentId: '' };
}

export const CreateBulkPaymentFromReviewsReply = {
  encode(
    message: CreateBulkPaymentFromReviewsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.bulkPaymentId !== '') {
      writer.uint32(10).string(message.bulkPaymentId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateBulkPaymentFromReviewsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBulkPaymentFromReviewsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bulkPaymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBulkPaymentFromReviewsReply {
    return {
      bulkPaymentId: isSet(object.bulkPaymentId)
        ? String(object.bulkPaymentId)
        : '',
    };
  },

  toJSON(message: CreateBulkPaymentFromReviewsReply): unknown {
    const obj: any = {};
    message.bulkPaymentId !== undefined &&
      (obj.bulkPaymentId = message.bulkPaymentId);
    return obj;
  },

  create(
    base?: DeepPartial<CreateBulkPaymentFromReviewsReply>,
  ): CreateBulkPaymentFromReviewsReply {
    return CreateBulkPaymentFromReviewsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateBulkPaymentFromReviewsReply>,
  ): CreateBulkPaymentFromReviewsReply {
    const message = createBaseCreateBulkPaymentFromReviewsReply();
    message.bulkPaymentId = object.bulkPaymentId ?? '';
    return message;
  },
};

function createBaseListTransactionsRequest(): ListTransactionsRequest {
  return {
    walletId: '',
    orderBy: 0,
    orderDirection: 0,
    pagination: undefined,
    searchTerm: '',
    transactionsFilter: undefined,
  };
}

export const ListTransactionsRequest = {
  encode(
    message: ListTransactionsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.walletId !== '') {
      writer.uint32(10).string(message.walletId);
    }
    if (message.orderBy !== 0) {
      writer.uint32(16).int32(message.orderBy);
    }
    if (message.orderDirection !== 0) {
      writer.uint32(24).int32(message.orderDirection);
    }
    if (message.pagination !== undefined) {
      PagePaginationParams.encode(
        message.pagination,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.searchTerm !== '') {
      writer.uint32(42).string(message.searchTerm);
    }
    if (message.transactionsFilter !== undefined) {
      TransactionsFilter.encode(
        message.transactionsFilter,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListTransactionsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTransactionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.walletId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.orderBy = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.orderDirection = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = PagePaginationParams.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.searchTerm = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.transactionsFilter = TransactionsFilter.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTransactionsRequest {
    return {
      walletId: isSet(object.walletId) ? String(object.walletId) : '',
      orderBy: isSet(object.orderBy)
        ? listTransactionsRequest_OrderByFromJSON(object.orderBy)
        : 0,
      orderDirection: isSet(object.orderDirection)
        ? orderingDirectionFromJSON(object.orderDirection)
        : 0,
      pagination: isSet(object.pagination)
        ? PagePaginationParams.fromJSON(object.pagination)
        : undefined,
      searchTerm: isSet(object.searchTerm) ? String(object.searchTerm) : '',
      transactionsFilter: isSet(object.transactionsFilter)
        ? TransactionsFilter.fromJSON(object.transactionsFilter)
        : undefined,
    };
  },

  toJSON(message: ListTransactionsRequest): unknown {
    const obj: any = {};
    message.walletId !== undefined && (obj.walletId = message.walletId);
    message.orderBy !== undefined &&
      (obj.orderBy = listTransactionsRequest_OrderByToJSON(message.orderBy));
    message.orderDirection !== undefined &&
      (obj.orderDirection = orderingDirectionToJSON(message.orderDirection));
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PagePaginationParams.toJSON(message.pagination)
        : undefined);
    message.searchTerm !== undefined && (obj.searchTerm = message.searchTerm);
    message.transactionsFilter !== undefined &&
      (obj.transactionsFilter = message.transactionsFilter
        ? TransactionsFilter.toJSON(message.transactionsFilter)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<ListTransactionsRequest>): ListTransactionsRequest {
    return ListTransactionsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListTransactionsRequest>,
  ): ListTransactionsRequest {
    const message = createBaseListTransactionsRequest();
    message.walletId = object.walletId ?? '';
    message.orderBy = object.orderBy ?? 0;
    message.orderDirection = object.orderDirection ?? 0;
    message.pagination =
      object.pagination !== undefined && object.pagination !== null
        ? PagePaginationParams.fromPartial(object.pagination)
        : undefined;
    message.searchTerm = object.searchTerm ?? '';
    message.transactionsFilter =
      object.transactionsFilter !== undefined &&
      object.transactionsFilter !== null
        ? TransactionsFilter.fromPartial(object.transactionsFilter)
        : undefined;
    return message;
  },
};

function createBaseListTransactionsReply(): ListTransactionsReply {
  return { transactions: [], pagination: undefined };
}

export const ListTransactionsReply = {
  encode(
    message: ListTransactionsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.transactions) {
      Transaction1.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PagePagination.encode(
        message.pagination,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListTransactionsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTransactionsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactions.push(
            Transaction1.decode(reader, reader.uint32()),
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = PagePagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTransactionsReply {
    return {
      transactions: Array.isArray(object?.transactions)
        ? object.transactions.map((e: any) => Transaction.fromJSON(e))
        : [],
      pagination: isSet(object.pagination)
        ? PagePagination.fromJSON(object.pagination)
        : undefined,
    };
  },

  toJSON(message: ListTransactionsReply): unknown {
    const obj: any = {};
    if (message.transactions) {
      obj.transactions = message.transactions.map((e) =>
        e ? Transaction1.toJSON(e) : undefined,
      );
    } else {
      obj.transactions = [];
    }
    message.pagination !== undefined &&
      (obj.pagination = message.pagination
        ? PagePagination.toJSON(message.pagination)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<ListTransactionsReply>): ListTransactionsReply {
    return ListTransactionsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListTransactionsReply>,
  ): ListTransactionsReply {
    const message = createBaseListTransactionsReply();
    message.transactions =
      object.transactions?.map((e) => Transaction1.fromPartial(e)) || [];
    message.pagination =
      object.pagination !== undefined && object.pagination !== null
        ? PagePagination.fromPartial(object.pagination)
        : undefined;
    return message;
  },
};

function createBaseExportTransactionsToFileRequest(): ExportTransactionsToFileRequest {
  return { request: undefined, outputFormat: 0 };
}

export const ExportTransactionsToFileRequest = {
  encode(
    message: ExportTransactionsToFileRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.request !== undefined) {
      ListTransactionsRequest.encode(
        message.request,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.outputFormat !== 0) {
      writer.uint32(16).int32(message.outputFormat);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ExportTransactionsToFileRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExportTransactionsToFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = ListTransactionsRequest.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.outputFormat = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExportTransactionsToFileRequest {
    return {
      request: isSet(object.request)
        ? ListTransactionsRequest.fromJSON(object.request)
        : undefined,
      outputFormat: isSet(object.outputFormat)
        ? statementOutputFormatFromJSON(object.outputFormat)
        : 0,
    };
  },

  toJSON(message: ExportTransactionsToFileRequest): unknown {
    const obj: any = {};
    message.request !== undefined &&
      (obj.request = message.request
        ? ListTransactionsRequest.toJSON(message.request)
        : undefined);
    message.outputFormat !== undefined &&
      (obj.outputFormat = statementOutputFormatToJSON(message.outputFormat));
    return obj;
  },

  create(
    base?: DeepPartial<ExportTransactionsToFileRequest>,
  ): ExportTransactionsToFileRequest {
    return ExportTransactionsToFileRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ExportTransactionsToFileRequest>,
  ): ExportTransactionsToFileRequest {
    const message = createBaseExportTransactionsToFileRequest();
    message.request =
      object.request !== undefined && object.request !== null
        ? ListTransactionsRequest.fromPartial(object.request)
        : undefined;
    message.outputFormat = object.outputFormat ?? 0;
    return message;
  },
};

function createBaseExportTransactionsToFileReply(): ExportTransactionsToFileReply {
  return { downloadUrl: '' };
}

export const ExportTransactionsToFileReply = {
  encode(
    message: ExportTransactionsToFileReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.downloadUrl !== '') {
      writer.uint32(10).string(message.downloadUrl);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ExportTransactionsToFileReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExportTransactionsToFileReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.downloadUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExportTransactionsToFileReply {
    return {
      downloadUrl: isSet(object.downloadUrl) ? String(object.downloadUrl) : '',
    };
  },

  toJSON(message: ExportTransactionsToFileReply): unknown {
    const obj: any = {};
    message.downloadUrl !== undefined &&
      (obj.downloadUrl = message.downloadUrl);
    return obj;
  },

  create(
    base?: DeepPartial<ExportTransactionsToFileReply>,
  ): ExportTransactionsToFileReply {
    return ExportTransactionsToFileReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ExportTransactionsToFileReply>,
  ): ExportTransactionsToFileReply {
    const message = createBaseExportTransactionsToFileReply();
    message.downloadUrl = object.downloadUrl ?? '';
    return message;
  },
};

function createBaseGetTransactionRequest(): GetTransactionRequest {
  return { transactionId: '' };
}

export const GetTransactionRequest = {
  encode(
    message: GetTransactionRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetTransactionRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTransactionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTransactionRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: GetTransactionRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(base?: DeepPartial<GetTransactionRequest>): GetTransactionRequest {
    return GetTransactionRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetTransactionRequest>,
  ): GetTransactionRequest {
    const message = createBaseGetTransactionRequest();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseGetTransactionReply(): GetTransactionReply {
  return { transaction: undefined };
}

export const GetTransactionReply = {
  encode(
    message: GetTransactionReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transaction !== undefined) {
      Transaction1.encode(
        message.transaction,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTransactionReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTransactionReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transaction = Transaction1.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTransactionReply {
    return {
      transaction: isSet(object.transaction)
        ? Transaction1.fromJSON(object.transaction)
        : undefined,
    };
  },

  toJSON(message: GetTransactionReply): unknown {
    const obj: any = {};
    message.transaction !== undefined &&
      (obj.transaction = message.transaction
        ? Transaction1.toJSON(message.transaction)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetTransactionReply>): GetTransactionReply {
    return GetTransactionReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetTransactionReply>): GetTransactionReply {
    const message = createBaseGetTransactionReply();
    message.transaction =
      object.transaction !== undefined && object.transaction !== null
        ? Transaction1.fromPartial(object.transaction)
        : undefined;
    return message;
  },
};

function createBaseListTransactionUpdatesRequest(): ListTransactionUpdatesRequest {
  return { transactionId: '' };
}

export const ListTransactionUpdatesRequest = {
  encode(
    message: ListTransactionUpdatesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListTransactionUpdatesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTransactionUpdatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTransactionUpdatesRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: ListTransactionUpdatesRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(
    base?: DeepPartial<ListTransactionUpdatesRequest>,
  ): ListTransactionUpdatesRequest {
    return ListTransactionUpdatesRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListTransactionUpdatesRequest>,
  ): ListTransactionUpdatesRequest {
    const message = createBaseListTransactionUpdatesRequest();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseListTransactionUpdatesReply(): ListTransactionUpdatesReply {
  return { transactionUpdates: [] };
}

export const ListTransactionUpdatesReply = {
  encode(
    message: ListTransactionUpdatesReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.transactionUpdates) {
      TransactionUpdate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListTransactionUpdatesReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTransactionUpdatesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionUpdates.push(
            TransactionUpdate.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTransactionUpdatesReply {
    return {
      transactionUpdates: Array.isArray(object?.transactionUpdates)
        ? object.transactionUpdates.map((e: any) =>
            TransactionUpdate.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ListTransactionUpdatesReply): unknown {
    const obj: any = {};
    if (message.transactionUpdates) {
      obj.transactionUpdates = message.transactionUpdates.map((e) =>
        e ? TransactionUpdate.toJSON(e) : undefined,
      );
    } else {
      obj.transactionUpdates = [];
    }
    return obj;
  },

  create(
    base?: DeepPartial<ListTransactionUpdatesReply>,
  ): ListTransactionUpdatesReply {
    return ListTransactionUpdatesReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListTransactionUpdatesReply>,
  ): ListTransactionUpdatesReply {
    const message = createBaseListTransactionUpdatesReply();
    message.transactionUpdates =
      object.transactionUpdates?.map((e) => TransactionUpdate.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseCancelTransactionRequest(): CancelTransactionRequest {
  return { transactionId: '' };
}

export const CancelTransactionRequest = {
  encode(
    message: CancelTransactionRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CancelTransactionRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelTransactionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CancelTransactionRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: CancelTransactionRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(
    base?: DeepPartial<CancelTransactionRequest>,
  ): CancelTransactionRequest {
    return CancelTransactionRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CancelTransactionRequest>,
  ): CancelTransactionRequest {
    const message = createBaseCancelTransactionRequest();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseCancelTransactionReply(): CancelTransactionReply {
  return {};
}

export const CancelTransactionReply = {
  encode(
    _: CancelTransactionReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CancelTransactionReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelTransactionReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): CancelTransactionReply {
    return {};
  },

  toJSON(_: CancelTransactionReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<CancelTransactionReply>): CancelTransactionReply {
    return CancelTransactionReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<CancelTransactionReply>): CancelTransactionReply {
    const message = createBaseCancelTransactionReply();
    return message;
  },
};

function createBaseGetFundingDetailsRequest(): GetFundingDetailsRequest {
  return { transactionId: '' };
}

export const GetFundingDetailsRequest = {
  encode(
    message: GetFundingDetailsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetFundingDetailsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFundingDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFundingDetailsRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: GetFundingDetailsRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(
    base?: DeepPartial<GetFundingDetailsRequest>,
  ): GetFundingDetailsRequest {
    return GetFundingDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetFundingDetailsRequest>,
  ): GetFundingDetailsRequest {
    const message = createBaseGetFundingDetailsRequest();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseGetFundingDetailsReply(): GetFundingDetailsReply {
  return {
    accountName: '',
    accountNumber: '',
    accountType: '',
    bankName: '',
    swift: '',
    bankAddress: '',
    uniqueReference: '',
  };
}

export const GetFundingDetailsReply = {
  encode(
    message: GetFundingDetailsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.accountName !== '') {
      writer.uint32(10).string(message.accountName);
    }
    if (message.accountNumber !== '') {
      writer.uint32(18).string(message.accountNumber);
    }
    if (message.accountType !== '') {
      writer.uint32(26).string(message.accountType);
    }
    if (message.bankName !== '') {
      writer.uint32(34).string(message.bankName);
    }
    if (message.swift !== '') {
      writer.uint32(42).string(message.swift);
    }
    if (message.bankAddress !== '') {
      writer.uint32(50).string(message.bankAddress);
    }
    if (message.uniqueReference !== '') {
      writer.uint32(58).string(message.uniqueReference);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetFundingDetailsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFundingDetailsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountType = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bankName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.swift = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.bankAddress = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.uniqueReference = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFundingDetailsReply {
    return {
      accountName: isSet(object.accountName) ? String(object.accountName) : '',
      accountNumber: isSet(object.accountNumber)
        ? String(object.accountNumber)
        : '',
      accountType: isSet(object.accountType) ? String(object.accountType) : '',
      bankName: isSet(object.bankName) ? String(object.bankName) : '',
      swift: isSet(object.swift) ? String(object.swift) : '',
      bankAddress: isSet(object.bankAddress) ? String(object.bankAddress) : '',
      uniqueReference: isSet(object.uniqueReference)
        ? String(object.uniqueReference)
        : '',
    };
  },

  toJSON(message: GetFundingDetailsReply): unknown {
    const obj: any = {};
    message.accountName !== undefined &&
      (obj.accountName = message.accountName);
    message.accountNumber !== undefined &&
      (obj.accountNumber = message.accountNumber);
    message.accountType !== undefined &&
      (obj.accountType = message.accountType);
    message.bankName !== undefined && (obj.bankName = message.bankName);
    message.swift !== undefined && (obj.swift = message.swift);
    message.bankAddress !== undefined &&
      (obj.bankAddress = message.bankAddress);
    message.uniqueReference !== undefined &&
      (obj.uniqueReference = message.uniqueReference);
    return obj;
  },

  create(base?: DeepPartial<GetFundingDetailsReply>): GetFundingDetailsReply {
    return GetFundingDetailsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetFundingDetailsReply>,
  ): GetFundingDetailsReply {
    const message = createBaseGetFundingDetailsReply();
    message.accountName = object.accountName ?? '';
    message.accountNumber = object.accountNumber ?? '';
    message.accountType = object.accountType ?? '';
    message.bankName = object.bankName ?? '';
    message.swift = object.swift ?? '';
    message.bankAddress = object.bankAddress ?? '';
    message.uniqueReference = object.uniqueReference ?? '';
    return message;
  },
};

function createBaseListOffPlatformFundingOptionsRequest(): ListOffPlatformFundingOptionsRequest {
  return { fromCurrency: '' };
}

export const ListOffPlatformFundingOptionsRequest = {
  encode(
    message: ListOffPlatformFundingOptionsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.fromCurrency !== '') {
      writer.uint32(10).string(message.fromCurrency);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListOffPlatformFundingOptionsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOffPlatformFundingOptionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fromCurrency = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOffPlatformFundingOptionsRequest {
    return {
      fromCurrency: isSet(object.fromCurrency)
        ? String(object.fromCurrency)
        : '',
    };
  },

  toJSON(message: ListOffPlatformFundingOptionsRequest): unknown {
    const obj: any = {};
    message.fromCurrency !== undefined &&
      (obj.fromCurrency = message.fromCurrency);
    return obj;
  },

  create(
    base?: DeepPartial<ListOffPlatformFundingOptionsRequest>,
  ): ListOffPlatformFundingOptionsRequest {
    return ListOffPlatformFundingOptionsRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListOffPlatformFundingOptionsRequest>,
  ): ListOffPlatformFundingOptionsRequest {
    const message = createBaseListOffPlatformFundingOptionsRequest();
    message.fromCurrency = object.fromCurrency ?? '';
    return message;
  },
};

function createBaseListOffPlatformFundingOptionsReply(): ListOffPlatformFundingOptionsReply {
  return { fundingOptions: [] };
}

export const ListOffPlatformFundingOptionsReply = {
  encode(
    message: ListOffPlatformFundingOptionsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.fundingOptions) {
      FundingOption.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListOffPlatformFundingOptionsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOffPlatformFundingOptionsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fundingOptions.push(
            FundingOption.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListOffPlatformFundingOptionsReply {
    return {
      fundingOptions: Array.isArray(object?.fundingOptions)
        ? object.fundingOptions.map((e: any) => FundingOption.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListOffPlatformFundingOptionsReply): unknown {
    const obj: any = {};
    if (message.fundingOptions) {
      obj.fundingOptions = message.fundingOptions.map((e) =>
        e ? FundingOption.toJSON(e) : undefined,
      );
    } else {
      obj.fundingOptions = [];
    }
    return obj;
  },

  create(
    base?: DeepPartial<ListOffPlatformFundingOptionsReply>,
  ): ListOffPlatformFundingOptionsReply {
    return ListOffPlatformFundingOptionsReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListOffPlatformFundingOptionsReply>,
  ): ListOffPlatformFundingOptionsReply {
    const message = createBaseListOffPlatformFundingOptionsReply();
    message.fundingOptions =
      object.fundingOptions?.map((e) => FundingOption.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListPaymentSourcesRequest(): ListPaymentSourcesRequest {
  return {};
}

export const ListPaymentSourcesRequest = {
  encode(
    _: ListPaymentSourcesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListPaymentSourcesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListPaymentSourcesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListPaymentSourcesRequest {
    return {};
  },

  toJSON(_: ListPaymentSourcesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<ListPaymentSourcesRequest>,
  ): ListPaymentSourcesRequest {
    return ListPaymentSourcesRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<ListPaymentSourcesRequest>,
  ): ListPaymentSourcesRequest {
    const message = createBaseListPaymentSourcesRequest();
    return message;
  },
};

function createBaseListPaymentSourcesReply(): ListPaymentSourcesReply {
  return { sources: [] };
}

export const ListPaymentSourcesReply = {
  encode(
    message: ListPaymentSourcesReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.sources) {
      PaymentSource.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListPaymentSourcesReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListPaymentSourcesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sources.push(PaymentSource.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListPaymentSourcesReply {
    return {
      sources: Array.isArray(object?.sources)
        ? object.sources.map((e: any) => PaymentSource.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListPaymentSourcesReply): unknown {
    const obj: any = {};
    if (message.sources) {
      obj.sources = message.sources.map((e) =>
        e ? PaymentSource.toJSON(e) : undefined,
      );
    } else {
      obj.sources = [];
    }
    return obj;
  },

  create(base?: DeepPartial<ListPaymentSourcesReply>): ListPaymentSourcesReply {
    return ListPaymentSourcesReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListPaymentSourcesReply>,
  ): ListPaymentSourcesReply {
    const message = createBaseListPaymentSourcesReply();
    message.sources =
      object.sources?.map((e) => PaymentSource.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListTransactionPurposesRequest(): ListTransactionPurposesRequest {
  return {};
}

export const ListTransactionPurposesRequest = {
  encode(
    _: ListTransactionPurposesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListTransactionPurposesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTransactionPurposesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListTransactionPurposesRequest {
    return {};
  },

  toJSON(_: ListTransactionPurposesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<ListTransactionPurposesRequest>,
  ): ListTransactionPurposesRequest {
    return ListTransactionPurposesRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<ListTransactionPurposesRequest>,
  ): ListTransactionPurposesRequest {
    const message = createBaseListTransactionPurposesRequest();
    return message;
  },
};

function createBaseListTransactionPurposesReply(): ListTransactionPurposesReply {
  return { purposes: [] };
}

export const ListTransactionPurposesReply = {
  encode(
    message: ListTransactionPurposesReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.purposes) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListTransactionPurposesReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTransactionPurposesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.purposes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListTransactionPurposesReply {
    return {
      purposes: Array.isArray(object?.purposes)
        ? object.purposes.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: ListTransactionPurposesReply): unknown {
    const obj: any = {};
    if (message.purposes) {
      obj.purposes = message.purposes.map((e) => e);
    } else {
      obj.purposes = [];
    }
    return obj;
  },

  create(
    base?: DeepPartial<ListTransactionPurposesReply>,
  ): ListTransactionPurposesReply {
    return ListTransactionPurposesReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ListTransactionPurposesReply>,
  ): ListTransactionPurposesReply {
    const message = createBaseListTransactionPurposesReply();
    message.purposes = object.purposes?.map((e) => e) || [];
    return message;
  },
};

function createBaseSubmitPurposeProofRequest(): SubmitPurposeProofRequest {
  return { transactionId: '', document: undefined };
}

export const SubmitPurposeProofRequest = {
  encode(
    message: SubmitPurposeProofRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.document !== undefined) {
      Document.encode(message.document, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubmitPurposeProofRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitPurposeProofRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.document = Document.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubmitPurposeProofRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      document: isSet(object.document)
        ? Document.fromJSON(object.document)
        : undefined,
    };
  },

  toJSON(message: SubmitPurposeProofRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.document !== undefined &&
      (obj.document = message.document
        ? Document.toJSON(message.document)
        : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<SubmitPurposeProofRequest>,
  ): SubmitPurposeProofRequest {
    return SubmitPurposeProofRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<SubmitPurposeProofRequest>,
  ): SubmitPurposeProofRequest {
    const message = createBaseSubmitPurposeProofRequest();
    message.transactionId = object.transactionId ?? '';
    message.document =
      object.document !== undefined && object.document !== null
        ? Document.fromPartial(object.document)
        : undefined;
    return message;
  },
};

function createBaseSubmitPurposeProofReply(): SubmitPurposeProofReply {
  return {};
}

export const SubmitPurposeProofReply = {
  encode(
    _: SubmitPurposeProofReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubmitPurposeProofReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubmitPurposeProofReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SubmitPurposeProofReply {
    return {};
  },

  toJSON(_: SubmitPurposeProofReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<SubmitPurposeProofReply>): SubmitPurposeProofReply {
    return SubmitPurposeProofReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<SubmitPurposeProofReply>,
  ): SubmitPurposeProofReply {
    const message = createBaseSubmitPurposeProofReply();
    return message;
  },
};

function createBaseApproveTransactionRequest(): ApproveTransactionRequest {
  return { transactionId: '' };
}

export const ApproveTransactionRequest = {
  encode(
    message: ApproveTransactionRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ApproveTransactionRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveTransactionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApproveTransactionRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: ApproveTransactionRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(
    base?: DeepPartial<ApproveTransactionRequest>,
  ): ApproveTransactionRequest {
    return ApproveTransactionRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ApproveTransactionRequest>,
  ): ApproveTransactionRequest {
    const message = createBaseApproveTransactionRequest();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseApproveTransactionReply(): ApproveTransactionReply {
  return {};
}

export const ApproveTransactionReply = {
  encode(
    _: ApproveTransactionReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ApproveTransactionReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveTransactionReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ApproveTransactionReply {
    return {};
  },

  toJSON(_: ApproveTransactionReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<ApproveTransactionReply>): ApproveTransactionReply {
    return ApproveTransactionReply.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<ApproveTransactionReply>,
  ): ApproveTransactionReply {
    const message = createBaseApproveTransactionReply();
    return message;
  },
};

function createBaseRejectTransactionRequest(): RejectTransactionRequest {
  return { transactionId: '' };
}

export const RejectTransactionRequest = {
  encode(
    message: RejectTransactionRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RejectTransactionRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRejectTransactionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RejectTransactionRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: RejectTransactionRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(
    base?: DeepPartial<RejectTransactionRequest>,
  ): RejectTransactionRequest {
    return RejectTransactionRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<RejectTransactionRequest>,
  ): RejectTransactionRequest {
    const message = createBaseRejectTransactionRequest();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseRejectTransactionReply(): RejectTransactionReply {
  return {};
}

export const RejectTransactionReply = {
  encode(
    _: RejectTransactionReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RejectTransactionReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRejectTransactionReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): RejectTransactionReply {
    return {};
  },

  toJSON(_: RejectTransactionReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<RejectTransactionReply>): RejectTransactionReply {
    return RejectTransactionReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<RejectTransactionReply>): RejectTransactionReply {
    const message = createBaseRejectTransactionReply();
    return message;
  },
};

export interface Transactions {
  /**
   * Use CreateRecipientAccount instead.
   *
   * @deprecated
   */
  CreateRecipient(
    request: DeepPartial<CreateRecipientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateRecipientReply>;
  /** buf:lint:ignore COMMENT_RPC */
  CreateRecipientAccount(
    request: DeepPartial<CreateRecipientAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateRecipientAccountReply>;
  /** buf:lint:ignore COMMENT_RPC */
  DeleteRecipient(
    request: DeepPartial<DeleteRecipientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteRecipientReply>;
  /** buf:lint:ignore COMMENT_RPC */
  DeleteRecipientAccount(
    request: DeepPartial<DeleteRecipientAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteRecipientAccountReply>;
  /** buf:lint:ignore COMMENT_RPC */
  UpdateRecipient(
    request: DeepPartial<UpdateRecipientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateRecipientReply>;
  /** buf:lint:ignore COMMENT_RPC */
  UpdateRecipientAccount(
    request: DeepPartial<UpdateRecipientAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateRecipientAccountReply>;
  /** buf:lint:ignore COMMENT_RPC */
  ListRecipients(
    request: DeepPartial<ListRecipientsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListRecipientsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  CreateTransaction(
    request: DeepPartial<CreateTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateTransactionReply>;
  /** GetTransactionDetails returns the details of a transaction. */
  GetTransactionDetails(
    request: DeepPartial<GetTransactionDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetTransactionDetailsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SetTransactionDetails(
    request: DeepPartial<SetTransactionDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetTransactionDetailsReply>;
  /**
   * ListPaymentMethods returns the list of payment methods available for a given transaction.
   * ETA configs are included for each payment method and account for the collection step as well as
   * the disbursement step if any.
   */
  ListPaymentMethods(
    request: DeepPartial<ListPaymentMethodsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListPaymentMethodsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  ListPaymentMethodCards(
    request: DeepPartial<ListPaymentMethodCardsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListPaymentMethodCardsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  DeletePaymentMethodCard(
    request: DeepPartial<DeletePaymentMethodCardRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeletePaymentMethodCardReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetCardPaymentDetails(
    request: DeepPartial<GetCardPaymentDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCardPaymentDetailsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  StoreCardPaymentDetails(
    request: DeepPartial<StoreCardPaymentDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StoreCardPaymentDetailsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetExternalPaymentDetails(
    request: DeepPartial<GetExternalPaymentDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetExternalPaymentDetailsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  MarkTransactionAsPaid(
    request: DeepPartial<MarkTransactionAsPaidRequest>,
    metadata?: grpc.Metadata,
  ): Promise<MarkTransactionAsPaidReply>;
  /** buf:lint:ignore COMMENT_RPC */
  CancelPaymentAttempt(
    request: DeepPartial<CancelPaymentAttemptRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CancelPaymentAttemptReply>;
  /**
   * Use PayWithExternalWallet instead.
   *
   * @deprecated
   */
  GetApplePayPaymentDetails(
    request: DeepPartial<GetApplePayPaymentDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetApplePayPaymentDetailsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetAccountLimits(
    request: DeepPartial<GetAccountLimitsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetAccountLimitsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetTransactionLimits(
    request: DeepPartial<GetTransactionLimitsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetTransactionLimitsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetBanks(
    request: DeepPartial<GetBanksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetBanksReply>;
  /** GetETA returns the estimated completion time for a disbursement. */
  GetETA(
    request: DeepPartial<GetETARequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetETAReply>;
  /** GetETA returns the estimated completion time for a collection. */
  GetCollectionETA(
    request: DeepPartial<GetCollectionETARequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectionETAReply>;
  /** buf:lint:ignore COMMENT_RPC */
  PayWithWallet(
    request: DeepPartial<PayWithWalletRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PayWithWalletReply>;
  /** buf:lint:ignore COMMENT_RPC */
  PayWithExternalWallet(
    request: DeepPartial<PayWithExternalWalletRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PayWithExternalWalletReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetRecipientAccountByID(
    request: DeepPartial<GetRecipientAccountByIDRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetRecipientAccountByIDReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetRecipientByID(
    request: DeepPartial<GetRecipientByIDRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetRecipientByIDReply>;
  /** buf:lint:ignore COMMENT_RPC */
  ExportStatementToFile(
    request: DeepPartial<ExportStatementToFileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ExportStatementToFileReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetStatementDownloadURL(
    request: DeepPartial<GetStatementDownloadURLRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetStatementDownloadURLReply>;
  /** GetReceiptDownloadURL gets the download URL for a transaction receipt. */
  GetReceiptDownloadURL(
    request: DeepPartial<GetReceiptDownloadURLRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetReceiptDownloadURLReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetSummary(
    request: DeepPartial<GetSummaryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSummaryReply>;
  /** buf:lint:ignore COMMENT_RPC */
  ProcessBulkPaymentFile(
    request: DeepPartial<ProcessBulkPaymentFileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProcessBulkPaymentFileReply>;
  /** buf:lint:ignore COMMENT_RPC */
  CreateBulkPaymentFromReviews(
    request: DeepPartial<CreateBulkPaymentFromReviewsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateBulkPaymentFromReviewsReply>;
  /**
   * ListTransactions returns a list of transactions for the current account.
   * NOTE: returns the experimental v2 Transaction, otherwise see Feed.ListItems.
   */
  ListTransactions(
    request: DeepPartial<ListTransactionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListTransactionsReply>;
  /** ExportTransactionsToFile exports a list of transactions in a given format. */
  ExportTransactionsToFile(
    request: DeepPartial<ExportTransactionsToFileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ExportTransactionsToFileReply>;
  /**
   * GetTransaction returns a transaction for the current account
   * NOTE: returns the experimental v2 Transaction, otherwise see GetTransactionDetails.
   */
  GetTransaction(
    request: DeepPartial<GetTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetTransactionReply>;
  /** ListTransactionUpdates returns a list of transaction updates for the current account */
  ListTransactionUpdates(
    request: DeepPartial<ListTransactionUpdatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListTransactionUpdatesReply>;
  /** CancelTransaction cancels a transaction. */
  CancelTransaction(
    request: DeepPartial<CancelTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CancelTransactionReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetFundingDetails(
    request: DeepPartial<GetFundingDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFundingDetailsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  ListOffPlatformFundingOptions(
    request: DeepPartial<ListOffPlatformFundingOptionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListOffPlatformFundingOptionsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  ListPaymentSources(
    request: DeepPartial<ListPaymentSourcesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListPaymentSourcesReply>;
  /** Returns the list of transaction purposes available for a given transaction. */
  ListTransactionPurposes(
    request: DeepPartial<ListTransactionPurposesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListTransactionPurposesReply>;
  /** Submit proof of payment for a transaction. */
  SubmitPurposeProof(
    request: DeepPartial<SubmitPurposeProofRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubmitPurposeProofReply>;
  /** ApproveTransaction attempts to approve a transaction that is in a workflow review state. */
  ApproveTransaction(
    request: DeepPartial<ApproveTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ApproveTransactionReply>;
  /** RejectTransactions attempts to reject a transaction that is in a workflow review state. */
  RejectTransaction(
    request: DeepPartial<RejectTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RejectTransactionReply>;
}

export class TransactionsClientImpl implements Transactions {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateRecipient = this.CreateRecipient.bind(this);
    this.CreateRecipientAccount = this.CreateRecipientAccount.bind(this);
    this.DeleteRecipient = this.DeleteRecipient.bind(this);
    this.DeleteRecipientAccount = this.DeleteRecipientAccount.bind(this);
    this.UpdateRecipient = this.UpdateRecipient.bind(this);
    this.UpdateRecipientAccount = this.UpdateRecipientAccount.bind(this);
    this.ListRecipients = this.ListRecipients.bind(this);
    this.CreateTransaction = this.CreateTransaction.bind(this);
    this.GetTransactionDetails = this.GetTransactionDetails.bind(this);
    this.SetTransactionDetails = this.SetTransactionDetails.bind(this);
    this.ListPaymentMethods = this.ListPaymentMethods.bind(this);
    this.ListPaymentMethodCards = this.ListPaymentMethodCards.bind(this);
    this.DeletePaymentMethodCard = this.DeletePaymentMethodCard.bind(this);
    this.GetCardPaymentDetails = this.GetCardPaymentDetails.bind(this);
    this.StoreCardPaymentDetails = this.StoreCardPaymentDetails.bind(this);
    this.GetExternalPaymentDetails = this.GetExternalPaymentDetails.bind(this);
    this.MarkTransactionAsPaid = this.MarkTransactionAsPaid.bind(this);
    this.CancelPaymentAttempt = this.CancelPaymentAttempt.bind(this);
    this.GetApplePayPaymentDetails = this.GetApplePayPaymentDetails.bind(this);
    this.GetAccountLimits = this.GetAccountLimits.bind(this);
    this.GetTransactionLimits = this.GetTransactionLimits.bind(this);
    this.GetBanks = this.GetBanks.bind(this);
    this.GetETA = this.GetETA.bind(this);
    this.GetCollectionETA = this.GetCollectionETA.bind(this);
    this.PayWithWallet = this.PayWithWallet.bind(this);
    this.PayWithExternalWallet = this.PayWithExternalWallet.bind(this);
    this.GetRecipientAccountByID = this.GetRecipientAccountByID.bind(this);
    this.GetRecipientByID = this.GetRecipientByID.bind(this);
    this.ExportStatementToFile = this.ExportStatementToFile.bind(this);
    this.GetStatementDownloadURL = this.GetStatementDownloadURL.bind(this);
    this.GetReceiptDownloadURL = this.GetReceiptDownloadURL.bind(this);
    this.GetSummary = this.GetSummary.bind(this);
    this.ProcessBulkPaymentFile = this.ProcessBulkPaymentFile.bind(this);
    this.CreateBulkPaymentFromReviews =
      this.CreateBulkPaymentFromReviews.bind(this);
    this.ListTransactions = this.ListTransactions.bind(this);
    this.ExportTransactionsToFile = this.ExportTransactionsToFile.bind(this);
    this.GetTransaction = this.GetTransaction.bind(this);
    this.ListTransactionUpdates = this.ListTransactionUpdates.bind(this);
    this.CancelTransaction = this.CancelTransaction.bind(this);
    this.GetFundingDetails = this.GetFundingDetails.bind(this);
    this.ListOffPlatformFundingOptions =
      this.ListOffPlatformFundingOptions.bind(this);
    this.ListPaymentSources = this.ListPaymentSources.bind(this);
    this.ListTransactionPurposes = this.ListTransactionPurposes.bind(this);
    this.SubmitPurposeProof = this.SubmitPurposeProof.bind(this);
    this.ApproveTransaction = this.ApproveTransaction.bind(this);
    this.RejectTransaction = this.RejectTransaction.bind(this);
  }

  CreateRecipient(
    request: DeepPartial<CreateRecipientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateRecipientReply> {
    return this.rpc.unary(
      TransactionsCreateRecipientDesc,
      CreateRecipientRequest.fromPartial(request),
      metadata,
    );
  }

  CreateRecipientAccount(
    request: DeepPartial<CreateRecipientAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateRecipientAccountReply> {
    return this.rpc.unary(
      TransactionsCreateRecipientAccountDesc,
      CreateRecipientAccountRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteRecipient(
    request: DeepPartial<DeleteRecipientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteRecipientReply> {
    return this.rpc.unary(
      TransactionsDeleteRecipientDesc,
      DeleteRecipientRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteRecipientAccount(
    request: DeepPartial<DeleteRecipientAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteRecipientAccountReply> {
    return this.rpc.unary(
      TransactionsDeleteRecipientAccountDesc,
      DeleteRecipientAccountRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateRecipient(
    request: DeepPartial<UpdateRecipientRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateRecipientReply> {
    return this.rpc.unary(
      TransactionsUpdateRecipientDesc,
      UpdateRecipientRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateRecipientAccount(
    request: DeepPartial<UpdateRecipientAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateRecipientAccountReply> {
    return this.rpc.unary(
      TransactionsUpdateRecipientAccountDesc,
      UpdateRecipientAccountRequest.fromPartial(request),
      metadata,
    );
  }

  ListRecipients(
    request: DeepPartial<ListRecipientsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListRecipientsReply> {
    return this.rpc.unary(
      TransactionsListRecipientsDesc,
      ListRecipientsRequest.fromPartial(request),
      metadata,
    );
  }

  CreateTransaction(
    request: DeepPartial<CreateTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateTransactionReply> {
    return this.rpc.unary(
      TransactionsCreateTransactionDesc,
      CreateTransactionRequest.fromPartial(request),
      metadata,
    );
  }

  GetTransactionDetails(
    request: DeepPartial<GetTransactionDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetTransactionDetailsReply> {
    return this.rpc.unary(
      TransactionsGetTransactionDetailsDesc,
      GetTransactionDetailsRequest.fromPartial(request),
      metadata,
    );
  }

  SetTransactionDetails(
    request: DeepPartial<SetTransactionDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetTransactionDetailsReply> {
    return this.rpc.unary(
      TransactionsSetTransactionDetailsDesc,
      SetTransactionDetailsRequest.fromPartial(request),
      metadata,
    );
  }

  ListPaymentMethods(
    request: DeepPartial<ListPaymentMethodsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListPaymentMethodsReply> {
    return this.rpc.unary(
      TransactionsListPaymentMethodsDesc,
      ListPaymentMethodsRequest.fromPartial(request),
      metadata,
    );
  }

  ListPaymentMethodCards(
    request: DeepPartial<ListPaymentMethodCardsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListPaymentMethodCardsReply> {
    return this.rpc.unary(
      TransactionsListPaymentMethodCardsDesc,
      ListPaymentMethodCardsRequest.fromPartial(request),
      metadata,
    );
  }

  DeletePaymentMethodCard(
    request: DeepPartial<DeletePaymentMethodCardRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeletePaymentMethodCardReply> {
    return this.rpc.unary(
      TransactionsDeletePaymentMethodCardDesc,
      DeletePaymentMethodCardRequest.fromPartial(request),
      metadata,
    );
  }

  GetCardPaymentDetails(
    request: DeepPartial<GetCardPaymentDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCardPaymentDetailsReply> {
    return this.rpc.unary(
      TransactionsGetCardPaymentDetailsDesc,
      GetCardPaymentDetailsRequest.fromPartial(request),
      metadata,
    );
  }

  StoreCardPaymentDetails(
    request: DeepPartial<StoreCardPaymentDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<StoreCardPaymentDetailsReply> {
    return this.rpc.unary(
      TransactionsStoreCardPaymentDetailsDesc,
      StoreCardPaymentDetailsRequest.fromPartial(request),
      metadata,
    );
  }

  GetExternalPaymentDetails(
    request: DeepPartial<GetExternalPaymentDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetExternalPaymentDetailsReply> {
    return this.rpc.unary(
      TransactionsGetExternalPaymentDetailsDesc,
      GetExternalPaymentDetailsRequest.fromPartial(request),
      metadata,
    );
  }

  MarkTransactionAsPaid(
    request: DeepPartial<MarkTransactionAsPaidRequest>,
    metadata?: grpc.Metadata,
  ): Promise<MarkTransactionAsPaidReply> {
    return this.rpc.unary(
      TransactionsMarkTransactionAsPaidDesc,
      MarkTransactionAsPaidRequest.fromPartial(request),
      metadata,
    );
  }

  CancelPaymentAttempt(
    request: DeepPartial<CancelPaymentAttemptRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CancelPaymentAttemptReply> {
    return this.rpc.unary(
      TransactionsCancelPaymentAttemptDesc,
      CancelPaymentAttemptRequest.fromPartial(request),
      metadata,
    );
  }

  GetApplePayPaymentDetails(
    request: DeepPartial<GetApplePayPaymentDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetApplePayPaymentDetailsReply> {
    return this.rpc.unary(
      TransactionsGetApplePayPaymentDetailsDesc,
      GetApplePayPaymentDetailsRequest.fromPartial(request),
      metadata,
    );
  }

  GetAccountLimits(
    request: DeepPartial<GetAccountLimitsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetAccountLimitsReply> {
    return this.rpc.unary(
      TransactionsGetAccountLimitsDesc,
      GetAccountLimitsRequest.fromPartial(request),
      metadata,
    );
  }

  GetTransactionLimits(
    request: DeepPartial<GetTransactionLimitsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetTransactionLimitsReply> {
    return this.rpc.unary(
      TransactionsGetTransactionLimitsDesc,
      GetTransactionLimitsRequest.fromPartial(request),
      metadata,
    );
  }

  GetBanks(
    request: DeepPartial<GetBanksRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetBanksReply> {
    return this.rpc.unary(
      TransactionsGetBanksDesc,
      GetBanksRequest.fromPartial(request),
      metadata,
    );
  }

  GetETA(
    request: DeepPartial<GetETARequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetETAReply> {
    return this.rpc.unary(
      TransactionsGetETADesc,
      GetETARequest.fromPartial(request),
      metadata,
    );
  }

  GetCollectionETA(
    request: DeepPartial<GetCollectionETARequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectionETAReply> {
    return this.rpc.unary(
      TransactionsGetCollectionETADesc,
      GetCollectionETARequest.fromPartial(request),
      metadata,
    );
  }

  PayWithWallet(
    request: DeepPartial<PayWithWalletRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PayWithWalletReply> {
    return this.rpc.unary(
      TransactionsPayWithWalletDesc,
      PayWithWalletRequest.fromPartial(request),
      metadata,
    );
  }

  PayWithExternalWallet(
    request: DeepPartial<PayWithExternalWalletRequest>,
    metadata?: grpc.Metadata,
  ): Promise<PayWithExternalWalletReply> {
    return this.rpc.unary(
      TransactionsPayWithExternalWalletDesc,
      PayWithExternalWalletRequest.fromPartial(request),
      metadata,
    );
  }

  GetRecipientAccountByID(
    request: DeepPartial<GetRecipientAccountByIDRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetRecipientAccountByIDReply> {
    return this.rpc.unary(
      TransactionsGetRecipientAccountByIDDesc,
      GetRecipientAccountByIDRequest.fromPartial(request),
      metadata,
    );
  }

  GetRecipientByID(
    request: DeepPartial<GetRecipientByIDRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetRecipientByIDReply> {
    return this.rpc.unary(
      TransactionsGetRecipientByIDDesc,
      GetRecipientByIDRequest.fromPartial(request),
      metadata,
    );
  }

  ExportStatementToFile(
    request: DeepPartial<ExportStatementToFileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ExportStatementToFileReply> {
    return this.rpc.unary(
      TransactionsExportStatementToFileDesc,
      ExportStatementToFileRequest.fromPartial(request),
      metadata,
    );
  }

  GetStatementDownloadURL(
    request: DeepPartial<GetStatementDownloadURLRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetStatementDownloadURLReply> {
    return this.rpc.unary(
      TransactionsGetStatementDownloadURLDesc,
      GetStatementDownloadURLRequest.fromPartial(request),
      metadata,
    );
  }

  GetReceiptDownloadURL(
    request: DeepPartial<GetReceiptDownloadURLRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetReceiptDownloadURLReply> {
    return this.rpc.unary(
      TransactionsGetReceiptDownloadURLDesc,
      GetReceiptDownloadURLRequest.fromPartial(request),
      metadata,
    );
  }

  GetSummary(
    request: DeepPartial<GetSummaryRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetSummaryReply> {
    return this.rpc.unary(
      TransactionsGetSummaryDesc,
      GetSummaryRequest.fromPartial(request),
      metadata,
    );
  }

  ProcessBulkPaymentFile(
    request: DeepPartial<ProcessBulkPaymentFileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProcessBulkPaymentFileReply> {
    return this.rpc.unary(
      TransactionsProcessBulkPaymentFileDesc,
      ProcessBulkPaymentFileRequest.fromPartial(request),
      metadata,
    );
  }

  CreateBulkPaymentFromReviews(
    request: DeepPartial<CreateBulkPaymentFromReviewsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateBulkPaymentFromReviewsReply> {
    return this.rpc.unary(
      TransactionsCreateBulkPaymentFromReviewsDesc,
      CreateBulkPaymentFromReviewsRequest.fromPartial(request),
      metadata,
    );
  }

  ListTransactions(
    request: DeepPartial<ListTransactionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListTransactionsReply> {
    return this.rpc.unary(
      TransactionsListTransactionsDesc,
      ListTransactionsRequest.fromPartial(request),
      metadata,
    );
  }

  ExportTransactionsToFile(
    request: DeepPartial<ExportTransactionsToFileRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ExportTransactionsToFileReply> {
    return this.rpc.unary(
      TransactionsExportTransactionsToFileDesc,
      ExportTransactionsToFileRequest.fromPartial(request),
      metadata,
    );
  }

  GetTransaction(
    request: DeepPartial<GetTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetTransactionReply> {
    return this.rpc.unary(
      TransactionsGetTransactionDesc,
      GetTransactionRequest.fromPartial(request),
      metadata,
    );
  }

  ListTransactionUpdates(
    request: DeepPartial<ListTransactionUpdatesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListTransactionUpdatesReply> {
    return this.rpc.unary(
      TransactionsListTransactionUpdatesDesc,
      ListTransactionUpdatesRequest.fromPartial(request),
      metadata,
    );
  }

  CancelTransaction(
    request: DeepPartial<CancelTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CancelTransactionReply> {
    return this.rpc.unary(
      TransactionsCancelTransactionDesc,
      CancelTransactionRequest.fromPartial(request),
      metadata,
    );
  }

  GetFundingDetails(
    request: DeepPartial<GetFundingDetailsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFundingDetailsReply> {
    return this.rpc.unary(
      TransactionsGetFundingDetailsDesc,
      GetFundingDetailsRequest.fromPartial(request),
      metadata,
    );
  }

  ListOffPlatformFundingOptions(
    request: DeepPartial<ListOffPlatformFundingOptionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListOffPlatformFundingOptionsReply> {
    return this.rpc.unary(
      TransactionsListOffPlatformFundingOptionsDesc,
      ListOffPlatformFundingOptionsRequest.fromPartial(request),
      metadata,
    );
  }

  ListPaymentSources(
    request: DeepPartial<ListPaymentSourcesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListPaymentSourcesReply> {
    return this.rpc.unary(
      TransactionsListPaymentSourcesDesc,
      ListPaymentSourcesRequest.fromPartial(request),
      metadata,
    );
  }

  ListTransactionPurposes(
    request: DeepPartial<ListTransactionPurposesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListTransactionPurposesReply> {
    return this.rpc.unary(
      TransactionsListTransactionPurposesDesc,
      ListTransactionPurposesRequest.fromPartial(request),
      metadata,
    );
  }

  SubmitPurposeProof(
    request: DeepPartial<SubmitPurposeProofRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SubmitPurposeProofReply> {
    return this.rpc.unary(
      TransactionsSubmitPurposeProofDesc,
      SubmitPurposeProofRequest.fromPartial(request),
      metadata,
    );
  }

  ApproveTransaction(
    request: DeepPartial<ApproveTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ApproveTransactionReply> {
    return this.rpc.unary(
      TransactionsApproveTransactionDesc,
      ApproveTransactionRequest.fromPartial(request),
      metadata,
    );
  }

  RejectTransaction(
    request: DeepPartial<RejectTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RejectTransactionReply> {
    return this.rpc.unary(
      TransactionsRejectTransactionDesc,
      RejectTransactionRequest.fromPartial(request),
      metadata,
    );
  }
}

export const TransactionsDesc = { serviceName: 'public.Transactions' };

export const TransactionsCreateRecipientDesc: UnaryMethodDefinitionish = {
  methodName: 'CreateRecipient',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateRecipientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateRecipientReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsCreateRecipientAccountDesc: UnaryMethodDefinitionish =
  {
    methodName: 'CreateRecipientAccount',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return CreateRecipientAccountRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = CreateRecipientAccountReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsDeleteRecipientDesc: UnaryMethodDefinitionish = {
  methodName: 'DeleteRecipient',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteRecipientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DeleteRecipientReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsDeleteRecipientAccountDesc: UnaryMethodDefinitionish =
  {
    methodName: 'DeleteRecipientAccount',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return DeleteRecipientAccountRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = DeleteRecipientAccountReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsUpdateRecipientDesc: UnaryMethodDefinitionish = {
  methodName: 'UpdateRecipient',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateRecipientRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateRecipientReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsUpdateRecipientAccountDesc: UnaryMethodDefinitionish =
  {
    methodName: 'UpdateRecipientAccount',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return UpdateRecipientAccountRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = UpdateRecipientAccountReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsListRecipientsDesc: UnaryMethodDefinitionish = {
  methodName: 'ListRecipients',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListRecipientsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListRecipientsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsCreateTransactionDesc: UnaryMethodDefinitionish = {
  methodName: 'CreateTransaction',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateTransactionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateTransactionReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsGetTransactionDetailsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetTransactionDetails',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTransactionDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetTransactionDetailsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsSetTransactionDetailsDesc: UnaryMethodDefinitionish = {
  methodName: 'SetTransactionDetails',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetTransactionDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetTransactionDetailsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsListPaymentMethodsDesc: UnaryMethodDefinitionish = {
  methodName: 'ListPaymentMethods',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListPaymentMethodsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListPaymentMethodsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsListPaymentMethodCardsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ListPaymentMethodCards',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ListPaymentMethodCardsRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = ListPaymentMethodCardsReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsDeletePaymentMethodCardDesc: UnaryMethodDefinitionish =
  {
    methodName: 'DeletePaymentMethodCard',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return DeletePaymentMethodCardRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = DeletePaymentMethodCardReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsGetCardPaymentDetailsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetCardPaymentDetails',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCardPaymentDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCardPaymentDetailsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsStoreCardPaymentDetailsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'StoreCardPaymentDetails',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return StoreCardPaymentDetailsRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = StoreCardPaymentDetailsReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsGetExternalPaymentDetailsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'GetExternalPaymentDetails',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return GetExternalPaymentDetailsRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = GetExternalPaymentDetailsReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsMarkTransactionAsPaidDesc: UnaryMethodDefinitionish = {
  methodName: 'MarkTransactionAsPaid',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return MarkTransactionAsPaidRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = MarkTransactionAsPaidReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsCancelPaymentAttemptDesc: UnaryMethodDefinitionish = {
  methodName: 'CancelPaymentAttempt',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CancelPaymentAttemptRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CancelPaymentAttemptReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsGetApplePayPaymentDetailsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'GetApplePayPaymentDetails',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return GetApplePayPaymentDetailsRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = GetApplePayPaymentDetailsReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsGetAccountLimitsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetAccountLimits',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAccountLimitsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetAccountLimitsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsGetTransactionLimitsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetTransactionLimits',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTransactionLimitsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetTransactionLimitsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsGetBanksDesc: UnaryMethodDefinitionish = {
  methodName: 'GetBanks',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBanksRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetBanksReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsGetETADesc: UnaryMethodDefinitionish = {
  methodName: 'GetETA',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetETARequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetETAReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsGetCollectionETADesc: UnaryMethodDefinitionish = {
  methodName: 'GetCollectionETA',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCollectionETARequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCollectionETAReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsPayWithWalletDesc: UnaryMethodDefinitionish = {
  methodName: 'PayWithWallet',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PayWithWalletRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PayWithWalletReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsPayWithExternalWalletDesc: UnaryMethodDefinitionish = {
  methodName: 'PayWithExternalWallet',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PayWithExternalWalletRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PayWithExternalWalletReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsGetRecipientAccountByIDDesc: UnaryMethodDefinitionish =
  {
    methodName: 'GetRecipientAccountByID',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return GetRecipientAccountByIDRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = GetRecipientAccountByIDReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsGetRecipientByIDDesc: UnaryMethodDefinitionish = {
  methodName: 'GetRecipientByID',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetRecipientByIDRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetRecipientByIDReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsExportStatementToFileDesc: UnaryMethodDefinitionish = {
  methodName: 'ExportStatementToFile',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ExportStatementToFileRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ExportStatementToFileReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsGetStatementDownloadURLDesc: UnaryMethodDefinitionish =
  {
    methodName: 'GetStatementDownloadURL',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return GetStatementDownloadURLRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = GetStatementDownloadURLReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsGetReceiptDownloadURLDesc: UnaryMethodDefinitionish = {
  methodName: 'GetReceiptDownloadURL',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetReceiptDownloadURLRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetReceiptDownloadURLReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsGetSummaryDesc: UnaryMethodDefinitionish = {
  methodName: 'GetSummary',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSummaryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSummaryReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsProcessBulkPaymentFileDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ProcessBulkPaymentFile',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ProcessBulkPaymentFileRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = ProcessBulkPaymentFileReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsCreateBulkPaymentFromReviewsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'CreateBulkPaymentFromReviews',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return CreateBulkPaymentFromReviewsRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = CreateBulkPaymentFromReviewsReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsListTransactionsDesc: UnaryMethodDefinitionish = {
  methodName: 'ListTransactions',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListTransactionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListTransactionsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsExportTransactionsToFileDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ExportTransactionsToFile',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ExportTransactionsToFileRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = ExportTransactionsToFileReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsGetTransactionDesc: UnaryMethodDefinitionish = {
  methodName: 'GetTransaction',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTransactionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetTransactionReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsListTransactionUpdatesDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ListTransactionUpdates',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ListTransactionUpdatesRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = ListTransactionUpdatesReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsCancelTransactionDesc: UnaryMethodDefinitionish = {
  methodName: 'CancelTransaction',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CancelTransactionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CancelTransactionReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsGetFundingDetailsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetFundingDetails',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFundingDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFundingDetailsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsListOffPlatformFundingOptionsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ListOffPlatformFundingOptions',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ListOffPlatformFundingOptionsRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = ListOffPlatformFundingOptionsReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsListPaymentSourcesDesc: UnaryMethodDefinitionish = {
  methodName: 'ListPaymentSources',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListPaymentSourcesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListPaymentSourcesReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsListTransactionPurposesDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ListTransactionPurposes',
    service: TransactionsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ListTransactionPurposesRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = ListTransactionPurposesReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const TransactionsSubmitPurposeProofDesc: UnaryMethodDefinitionish = {
  methodName: 'SubmitPurposeProof',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SubmitPurposeProofRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SubmitPurposeProofReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsApproveTransactionDesc: UnaryMethodDefinitionish = {
  methodName: 'ApproveTransaction',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ApproveTransactionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ApproveTransactionReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionsRejectTransactionDesc: UnaryMethodDefinitionish = {
  methodName: 'RejectTransaction',
  service: TransactionsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RejectTransactionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RejectTransactionReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined
          ? { transport: this.options.transport }
          : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, 'base64'));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString('base64');
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(''));
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(
    message: string,
    public code: grpc.Code,
    public metadata: grpc.Metadata,
  ) {
    super(message);
  }
}
