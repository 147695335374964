/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../../../google/protobuf/timestamp';
import {
  PaymentMethodBankAccount,
  PaymentMethodCard,
} from '../../collections/types';
import { RecipientAccount } from '../../disbursements/types';
import { Money } from '../../fx/types';
import { ETA } from '../../utils/types';
import { Wallet } from '../../wallets/types';
import { Summary } from '../summary';
import {
  PublicState,
  publicStateFromJSON,
  publicStateToJSON,
  TransactionType,
  transactionTypeFromJSON,
  transactionTypeToJSON,
} from '../types';

export const protobufPackage = 'transactions.v2';

/** TransactionFromType is the type of the transaction source. */
export enum TransactionFromType {
  TRANSACTION_FROM_TYPE_INVALID = 0,
  TRANSACTION_FROM_TYPE_WALLET = 1,
  TRANSACTION_FROM_TYPE_CARD = 2,
  TRANSACTION_FROM_TYPE_BANK_ACCOUNT = 3,
  TRANSACTION_FROM_TYPE_OFFPLATFORM = 4,
  UNRECOGNIZED = -1,
}

export function transactionFromTypeFromJSON(object: any): TransactionFromType {
  switch (object) {
    case 0:
    case 'TRANSACTION_FROM_TYPE_INVALID':
      return TransactionFromType.TRANSACTION_FROM_TYPE_INVALID;
    case 1:
    case 'TRANSACTION_FROM_TYPE_WALLET':
      return TransactionFromType.TRANSACTION_FROM_TYPE_WALLET;
    case 2:
    case 'TRANSACTION_FROM_TYPE_CARD':
      return TransactionFromType.TRANSACTION_FROM_TYPE_CARD;
    case 3:
    case 'TRANSACTION_FROM_TYPE_BANK_ACCOUNT':
      return TransactionFromType.TRANSACTION_FROM_TYPE_BANK_ACCOUNT;
    case 4:
    case 'TRANSACTION_FROM_TYPE_OFFPLATFORM':
      return TransactionFromType.TRANSACTION_FROM_TYPE_OFFPLATFORM;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TransactionFromType.UNRECOGNIZED;
  }
}

export function transactionFromTypeToJSON(object: TransactionFromType): string {
  switch (object) {
    case TransactionFromType.TRANSACTION_FROM_TYPE_INVALID:
      return 'TRANSACTION_FROM_TYPE_INVALID';
    case TransactionFromType.TRANSACTION_FROM_TYPE_WALLET:
      return 'TRANSACTION_FROM_TYPE_WALLET';
    case TransactionFromType.TRANSACTION_FROM_TYPE_CARD:
      return 'TRANSACTION_FROM_TYPE_CARD';
    case TransactionFromType.TRANSACTION_FROM_TYPE_BANK_ACCOUNT:
      return 'TRANSACTION_FROM_TYPE_BANK_ACCOUNT';
    case TransactionFromType.TRANSACTION_FROM_TYPE_OFFPLATFORM:
      return 'TRANSACTION_FROM_TYPE_OFFPLATFORM';
    case TransactionFromType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** TransactionDirection is the direction of the transaction. */
export enum TransactionDirection {
  TRANSACTION_DIRECTION_INVALID = 0,
  TRANSACTION_DIRECTION_CREDIT = 1,
  TRANSACTION_DIRECTION_DEBIT = 2,
  TRANSACTION_DIRECTION_EXCHANGE = 3,
  UNRECOGNIZED = -1,
}

export function transactionDirectionFromJSON(
  object: any,
): TransactionDirection {
  switch (object) {
    case 0:
    case 'TRANSACTION_DIRECTION_INVALID':
      return TransactionDirection.TRANSACTION_DIRECTION_INVALID;
    case 1:
    case 'TRANSACTION_DIRECTION_CREDIT':
      return TransactionDirection.TRANSACTION_DIRECTION_CREDIT;
    case 2:
    case 'TRANSACTION_DIRECTION_DEBIT':
      return TransactionDirection.TRANSACTION_DIRECTION_DEBIT;
    case 3:
    case 'TRANSACTION_DIRECTION_EXCHANGE':
      return TransactionDirection.TRANSACTION_DIRECTION_EXCHANGE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TransactionDirection.UNRECOGNIZED;
  }
}

export function transactionDirectionToJSON(
  object: TransactionDirection,
): string {
  switch (object) {
    case TransactionDirection.TRANSACTION_DIRECTION_INVALID:
      return 'TRANSACTION_DIRECTION_INVALID';
    case TransactionDirection.TRANSACTION_DIRECTION_CREDIT:
      return 'TRANSACTION_DIRECTION_CREDIT';
    case TransactionDirection.TRANSACTION_DIRECTION_DEBIT:
      return 'TRANSACTION_DIRECTION_DEBIT';
    case TransactionDirection.TRANSACTION_DIRECTION_EXCHANGE:
      return 'TRANSACTION_DIRECTION_EXCHANGE';
    case TransactionDirection.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** TransactionAdditionalInfoType is the type of additional info. */
export enum TransactionAdditionalInfoType {
  TRANSACTION_ADDITIONAL_INFO_TYPE_INVALID = 0,
  TRANSACTION_ADDITIONAL_INFO_TYPE_RECIPIENT_MESSAGE = 1,
  TRANSACTION_ADDITIONAL_INFO_TYPE_RECEIPT = 2,
  TRANSACTION_ADDITIONAL_INFO_TYPE_REFERENCE = 3,
  TRANSACTION_ADDITIONAL_INFO_TYPE_PURPOSE = 4,
  TRANSACTION_ADDITIONAL_INFO_TYPE_PURPOSE_PROOF = 5,
  TRANSACTION_ADDITIONAL_INFO_TYPE_CUSTOM_ID = 6,
  TRANSACTION_ADDITIONAL_INFO_TYPE_PUBLIC_ID = 7,
  TRANSACTION_ADDITIONAL_INFO_TYPE_STATE_CONTEXT = 8,
  UNRECOGNIZED = -1,
}

export function transactionAdditionalInfoTypeFromJSON(
  object: any,
): TransactionAdditionalInfoType {
  switch (object) {
    case 0:
    case 'TRANSACTION_ADDITIONAL_INFO_TYPE_INVALID':
      return TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_INVALID;
    case 1:
    case 'TRANSACTION_ADDITIONAL_INFO_TYPE_RECIPIENT_MESSAGE':
      return TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_RECIPIENT_MESSAGE;
    case 2:
    case 'TRANSACTION_ADDITIONAL_INFO_TYPE_RECEIPT':
      return TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_RECEIPT;
    case 3:
    case 'TRANSACTION_ADDITIONAL_INFO_TYPE_REFERENCE':
      return TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_REFERENCE;
    case 4:
    case 'TRANSACTION_ADDITIONAL_INFO_TYPE_PURPOSE':
      return TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_PURPOSE;
    case 5:
    case 'TRANSACTION_ADDITIONAL_INFO_TYPE_PURPOSE_PROOF':
      return TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_PURPOSE_PROOF;
    case 6:
    case 'TRANSACTION_ADDITIONAL_INFO_TYPE_CUSTOM_ID':
      return TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_CUSTOM_ID;
    case 7:
    case 'TRANSACTION_ADDITIONAL_INFO_TYPE_PUBLIC_ID':
      return TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_PUBLIC_ID;
    case 8:
    case 'TRANSACTION_ADDITIONAL_INFO_TYPE_STATE_CONTEXT':
      return TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_STATE_CONTEXT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TransactionAdditionalInfoType.UNRECOGNIZED;
  }
}

export function transactionAdditionalInfoTypeToJSON(
  object: TransactionAdditionalInfoType,
): string {
  switch (object) {
    case TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_INVALID:
      return 'TRANSACTION_ADDITIONAL_INFO_TYPE_INVALID';
    case TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_RECIPIENT_MESSAGE:
      return 'TRANSACTION_ADDITIONAL_INFO_TYPE_RECIPIENT_MESSAGE';
    case TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_RECEIPT:
      return 'TRANSACTION_ADDITIONAL_INFO_TYPE_RECEIPT';
    case TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_REFERENCE:
      return 'TRANSACTION_ADDITIONAL_INFO_TYPE_REFERENCE';
    case TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_PURPOSE:
      return 'TRANSACTION_ADDITIONAL_INFO_TYPE_PURPOSE';
    case TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_PURPOSE_PROOF:
      return 'TRANSACTION_ADDITIONAL_INFO_TYPE_PURPOSE_PROOF';
    case TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_CUSTOM_ID:
      return 'TRANSACTION_ADDITIONAL_INFO_TYPE_CUSTOM_ID';
    case TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_PUBLIC_ID:
      return 'TRANSACTION_ADDITIONAL_INFO_TYPE_PUBLIC_ID';
    case TransactionAdditionalInfoType.TRANSACTION_ADDITIONAL_INFO_TYPE_STATE_CONTEXT:
      return 'TRANSACTION_ADDITIONAL_INFO_TYPE_STATE_CONTEXT';
    case TransactionAdditionalInfoType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** TransactionOrigin is the origin of the transaction. */
export enum TransactionOrigin {
  TRANSACTION_ORIGIN_INVALID = 0,
  TRANSACTION_ORIGIN_WEB = 1,
  TRANSACTION_ORIGIN_API = 2,
  UNRECOGNIZED = -1,
}

export function transactionOriginFromJSON(object: any): TransactionOrigin {
  switch (object) {
    case 0:
    case 'TRANSACTION_ORIGIN_INVALID':
      return TransactionOrigin.TRANSACTION_ORIGIN_INVALID;
    case 1:
    case 'TRANSACTION_ORIGIN_WEB':
      return TransactionOrigin.TRANSACTION_ORIGIN_WEB;
    case 2:
    case 'TRANSACTION_ORIGIN_API':
      return TransactionOrigin.TRANSACTION_ORIGIN_API;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TransactionOrigin.UNRECOGNIZED;
  }
}

export function transactionOriginToJSON(object: TransactionOrigin): string {
  switch (object) {
    case TransactionOrigin.TRANSACTION_ORIGIN_INVALID:
      return 'TRANSACTION_ORIGIN_INVALID';
    case TransactionOrigin.TRANSACTION_ORIGIN_WEB:
      return 'TRANSACTION_ORIGIN_WEB';
    case TransactionOrigin.TRANSACTION_ORIGIN_API:
      return 'TRANSACTION_ORIGIN_API';
    case TransactionOrigin.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** TransactionFrom contains the source of the transaction. */
export interface TransactionFrom {
  type: TransactionFromType;
  wallet?: Wallet | undefined;
  card?: PaymentMethodCard | undefined;
  bankAccount?: PaymentMethodBankAccount | undefined;
}

/** TransactionTo contains the destination of the transaction. */
export interface TransactionTo {
  wallet?: Wallet | undefined;
  recipientAccount?: RecipientAccount | undefined;
}

/** TransactionAdditionalInfo contains additional info about the transaction. */
export interface TransactionAdditionalInfo {
  type: TransactionAdditionalInfoType;
  value: string;
}

/** TransactionConversion represents the conversion of a transaction. */
export interface TransactionConversion {
  sourceCurrency: string;
  destinationCurrency: string;
  exchangeRate: string;
  expiresAt: Timestamp | undefined;
}

/**
 * Transaction contains the details of a transaction.
 * NOTE: v2 is experimental.
 */
export interface Transaction {
  id: string;
  type: TransactionType;
  state: PublicState;
  direction: TransactionDirection;
  createdAt: Timestamp | undefined;
  sentAmount: Money | undefined;
  receivedAmount: Money | undefined;
  from: TransactionFrom | undefined;
  to: TransactionTo | undefined;
  summary: Summary | undefined;
  memo: string;
  eta: ETA | undefined;
  additionalInfos: TransactionAdditionalInfo[];
  userId: string;
  expiresAt: Timestamp | undefined;
  /** optional conversion in case of exchange transactions */
  conversion: TransactionConversion | undefined;
  /** optional origin of the transaction */
  origin: TransactionOrigin;
}

function createBaseTransactionFrom(): TransactionFrom {
  return {
    type: 0,
    wallet: undefined,
    card: undefined,
    bankAccount: undefined,
  };
}

export const TransactionFrom = {
  encode(
    message: TransactionFrom,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.wallet !== undefined) {
      Wallet.encode(message.wallet, writer.uint32(18).fork()).ldelim();
    }
    if (message.card !== undefined) {
      PaymentMethodCard.encode(message.card, writer.uint32(26).fork()).ldelim();
    }
    if (message.bankAccount !== undefined) {
      PaymentMethodBankAccount.encode(
        message.bankAccount,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionFrom {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionFrom();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.wallet = Wallet.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.card = PaymentMethodCard.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bankAccount = PaymentMethodBankAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionFrom {
    return {
      type: isSet(object.type) ? transactionFromTypeFromJSON(object.type) : 0,
      wallet: isSet(object.wallet) ? Wallet.fromJSON(object.wallet) : undefined,
      card: isSet(object.card)
        ? PaymentMethodCard.fromJSON(object.card)
        : undefined,
      bankAccount: isSet(object.bankAccount)
        ? PaymentMethodBankAccount.fromJSON(object.bankAccount)
        : undefined,
    };
  },

  toJSON(message: TransactionFrom): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = transactionFromTypeToJSON(message.type));
    message.wallet !== undefined &&
      (obj.wallet = message.wallet ? Wallet.toJSON(message.wallet) : undefined);
    message.card !== undefined &&
      (obj.card = message.card
        ? PaymentMethodCard.toJSON(message.card)
        : undefined);
    message.bankAccount !== undefined &&
      (obj.bankAccount = message.bankAccount
        ? PaymentMethodBankAccount.toJSON(message.bankAccount)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<TransactionFrom>): TransactionFrom {
    return TransactionFrom.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TransactionFrom>): TransactionFrom {
    const message = createBaseTransactionFrom();
    message.type = object.type ?? 0;
    message.wallet =
      object.wallet !== undefined && object.wallet !== null
        ? Wallet.fromPartial(object.wallet)
        : undefined;
    message.card =
      object.card !== undefined && object.card !== null
        ? PaymentMethodCard.fromPartial(object.card)
        : undefined;
    message.bankAccount =
      object.bankAccount !== undefined && object.bankAccount !== null
        ? PaymentMethodBankAccount.fromPartial(object.bankAccount)
        : undefined;
    return message;
  },
};

function createBaseTransactionTo(): TransactionTo {
  return { wallet: undefined, recipientAccount: undefined };
}

export const TransactionTo = {
  encode(
    message: TransactionTo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.wallet !== undefined) {
      Wallet.encode(message.wallet, writer.uint32(10).fork()).ldelim();
    }
    if (message.recipientAccount !== undefined) {
      RecipientAccount.encode(
        message.recipientAccount,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionTo {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionTo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wallet = Wallet.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipientAccount = RecipientAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionTo {
    return {
      wallet: isSet(object.wallet) ? Wallet.fromJSON(object.wallet) : undefined,
      recipientAccount: isSet(object.recipientAccount)
        ? RecipientAccount.fromJSON(object.recipientAccount)
        : undefined,
    };
  },

  toJSON(message: TransactionTo): unknown {
    const obj: any = {};
    message.wallet !== undefined &&
      (obj.wallet = message.wallet ? Wallet.toJSON(message.wallet) : undefined);
    message.recipientAccount !== undefined &&
      (obj.recipientAccount = message.recipientAccount
        ? RecipientAccount.toJSON(message.recipientAccount)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<TransactionTo>): TransactionTo {
    return TransactionTo.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TransactionTo>): TransactionTo {
    const message = createBaseTransactionTo();
    message.wallet =
      object.wallet !== undefined && object.wallet !== null
        ? Wallet.fromPartial(object.wallet)
        : undefined;
    message.recipientAccount =
      object.recipientAccount !== undefined && object.recipientAccount !== null
        ? RecipientAccount.fromPartial(object.recipientAccount)
        : undefined;
    return message;
  },
};

function createBaseTransactionAdditionalInfo(): TransactionAdditionalInfo {
  return { type: 0, value: '' };
}

export const TransactionAdditionalInfo = {
  encode(
    message: TransactionAdditionalInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TransactionAdditionalInfo {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionAdditionalInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionAdditionalInfo {
    return {
      type: isSet(object.type)
        ? transactionAdditionalInfoTypeFromJSON(object.type)
        : 0,
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: TransactionAdditionalInfo): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = transactionAdditionalInfoTypeToJSON(message.type));
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<TransactionAdditionalInfo>,
  ): TransactionAdditionalInfo {
    return TransactionAdditionalInfo.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<TransactionAdditionalInfo>,
  ): TransactionAdditionalInfo {
    const message = createBaseTransactionAdditionalInfo();
    message.type = object.type ?? 0;
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseTransactionConversion(): TransactionConversion {
  return {
    sourceCurrency: '',
    destinationCurrency: '',
    exchangeRate: '',
    expiresAt: undefined,
  };
}

export const TransactionConversion = {
  encode(
    message: TransactionConversion,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.sourceCurrency !== '') {
      writer.uint32(10).string(message.sourceCurrency);
    }
    if (message.destinationCurrency !== '') {
      writer.uint32(18).string(message.destinationCurrency);
    }
    if (message.exchangeRate !== '') {
      writer.uint32(26).string(message.exchangeRate);
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TransactionConversion {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionConversion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sourceCurrency = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.destinationCurrency = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.exchangeRate = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TransactionConversion {
    return {
      sourceCurrency: isSet(object.sourceCurrency)
        ? String(object.sourceCurrency)
        : '',
      destinationCurrency: isSet(object.destinationCurrency)
        ? String(object.destinationCurrency)
        : '',
      exchangeRate: isSet(object.exchangeRate)
        ? String(object.exchangeRate)
        : '',
      expiresAt: isSet(object.expiresAt)
        ? fromJsonTimestamp(object.expiresAt)
        : undefined,
    };
  },

  toJSON(message: TransactionConversion): unknown {
    const obj: any = {};
    message.sourceCurrency !== undefined &&
      (obj.sourceCurrency = message.sourceCurrency);
    message.destinationCurrency !== undefined &&
      (obj.destinationCurrency = message.destinationCurrency);
    message.exchangeRate !== undefined &&
      (obj.exchangeRate = message.exchangeRate);
    message.expiresAt !== undefined &&
      (obj.expiresAt = fromTimestamp(message.expiresAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<TransactionConversion>): TransactionConversion {
    return TransactionConversion.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<TransactionConversion>,
  ): TransactionConversion {
    const message = createBaseTransactionConversion();
    message.sourceCurrency = object.sourceCurrency ?? '';
    message.destinationCurrency = object.destinationCurrency ?? '';
    message.exchangeRate = object.exchangeRate ?? '';
    message.expiresAt =
      object.expiresAt !== undefined && object.expiresAt !== null
        ? Timestamp.fromPartial(object.expiresAt)
        : undefined;
    return message;
  },
};

function createBaseTransaction(): Transaction {
  return {
    id: '',
    type: 0,
    state: 0,
    direction: 0,
    createdAt: undefined,
    sentAmount: undefined,
    receivedAmount: undefined,
    from: undefined,
    to: undefined,
    summary: undefined,
    memo: '',
    eta: undefined,
    additionalInfos: [],
    userId: '',
    expiresAt: undefined,
    conversion: undefined,
    origin: 0,
  };
}

export const Transaction = {
  encode(
    message: Transaction,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.state !== 0) {
      writer.uint32(24).int32(message.state);
    }
    if (message.direction !== 0) {
      writer.uint32(32).int32(message.direction);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.sentAmount !== undefined) {
      Money.encode(message.sentAmount, writer.uint32(50).fork()).ldelim();
    }
    if (message.receivedAmount !== undefined) {
      Money.encode(message.receivedAmount, writer.uint32(58).fork()).ldelim();
    }
    if (message.from !== undefined) {
      TransactionFrom.encode(message.from, writer.uint32(66).fork()).ldelim();
    }
    if (message.to !== undefined) {
      TransactionTo.encode(message.to, writer.uint32(74).fork()).ldelim();
    }
    if (message.summary !== undefined) {
      Summary.encode(message.summary, writer.uint32(82).fork()).ldelim();
    }
    if (message.memo !== '') {
      writer.uint32(90).string(message.memo);
    }
    if (message.eta !== undefined) {
      ETA.encode(message.eta, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.additionalInfos) {
      TransactionAdditionalInfo.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.userId !== '') {
      writer.uint32(114).string(message.userId);
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(122).fork()).ldelim();
    }
    if (message.conversion !== undefined) {
      TransactionConversion.encode(
        message.conversion,
        writer.uint32(130).fork(),
      ).ldelim();
    }
    if (message.origin !== 0) {
      writer.uint32(136).int32(message.origin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Transaction {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.direction = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sentAmount = Money.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.receivedAmount = Money.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.from = TransactionFrom.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.to = TransactionTo.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.summary = Summary.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.memo = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.eta = ETA.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.additionalInfos.push(
            TransactionAdditionalInfo.decode(reader, reader.uint32()),
          );
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.conversion = TransactionConversion.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.origin = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Transaction {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      type: isSet(object.type) ? transactionTypeFromJSON(object.type) : 0,
      state: isSet(object.state) ? publicStateFromJSON(object.state) : 0,
      direction: isSet(object.direction)
        ? transactionDirectionFromJSON(object.direction)
        : 0,
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      sentAmount: isSet(object.sentAmount)
        ? Money.fromJSON(object.sentAmount)
        : undefined,
      receivedAmount: isSet(object.receivedAmount)
        ? Money.fromJSON(object.receivedAmount)
        : undefined,
      from: isSet(object.from)
        ? TransactionFrom.fromJSON(object.from)
        : undefined,
      to: isSet(object.to) ? TransactionTo.fromJSON(object.to) : undefined,
      summary: isSet(object.summary)
        ? Summary.fromJSON(object.summary)
        : undefined,
      memo: isSet(object.memo) ? String(object.memo) : '',
      eta: isSet(object.eta) ? ETA.fromJSON(object.eta) : undefined,
      additionalInfos: Array.isArray(object?.additionalInfos)
        ? object.additionalInfos.map((e: any) =>
            TransactionAdditionalInfo.fromJSON(e),
          )
        : [],
      userId: isSet(object.userId) ? String(object.userId) : '',
      expiresAt: isSet(object.expiresAt)
        ? fromJsonTimestamp(object.expiresAt)
        : undefined,
      conversion: isSet(object.conversion)
        ? TransactionConversion.fromJSON(object.conversion)
        : undefined,
      origin: isSet(object.origin)
        ? transactionOriginFromJSON(object.origin)
        : 0,
    };
  },

  toJSON(message: Transaction): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.type !== undefined &&
      (obj.type = transactionTypeToJSON(message.type));
    message.state !== undefined &&
      (obj.state = publicStateToJSON(message.state));
    message.direction !== undefined &&
      (obj.direction = transactionDirectionToJSON(message.direction));
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.sentAmount !== undefined &&
      (obj.sentAmount = message.sentAmount
        ? Money.toJSON(message.sentAmount)
        : undefined);
    message.receivedAmount !== undefined &&
      (obj.receivedAmount = message.receivedAmount
        ? Money.toJSON(message.receivedAmount)
        : undefined);
    message.from !== undefined &&
      (obj.from = message.from
        ? TransactionFrom.toJSON(message.from)
        : undefined);
    message.to !== undefined &&
      (obj.to = message.to ? TransactionTo.toJSON(message.to) : undefined);
    message.summary !== undefined &&
      (obj.summary = message.summary
        ? Summary.toJSON(message.summary)
        : undefined);
    message.memo !== undefined && (obj.memo = message.memo);
    message.eta !== undefined &&
      (obj.eta = message.eta ? ETA.toJSON(message.eta) : undefined);
    if (message.additionalInfos) {
      obj.additionalInfos = message.additionalInfos.map((e) =>
        e ? TransactionAdditionalInfo.toJSON(e) : undefined,
      );
    } else {
      obj.additionalInfos = [];
    }
    message.userId !== undefined && (obj.userId = message.userId);
    message.expiresAt !== undefined &&
      (obj.expiresAt = fromTimestamp(message.expiresAt).toISOString());
    message.conversion !== undefined &&
      (obj.conversion = message.conversion
        ? TransactionConversion.toJSON(message.conversion)
        : undefined);
    message.origin !== undefined &&
      (obj.origin = transactionOriginToJSON(message.origin));
    return obj;
  },

  create(base?: DeepPartial<Transaction>): Transaction {
    return Transaction.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Transaction>): Transaction {
    const message = createBaseTransaction();
    message.id = object.id ?? '';
    message.type = object.type ?? 0;
    message.state = object.state ?? 0;
    message.direction = object.direction ?? 0;
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.sentAmount =
      object.sentAmount !== undefined && object.sentAmount !== null
        ? Money.fromPartial(object.sentAmount)
        : undefined;
    message.receivedAmount =
      object.receivedAmount !== undefined && object.receivedAmount !== null
        ? Money.fromPartial(object.receivedAmount)
        : undefined;
    message.from =
      object.from !== undefined && object.from !== null
        ? TransactionFrom.fromPartial(object.from)
        : undefined;
    message.to =
      object.to !== undefined && object.to !== null
        ? TransactionTo.fromPartial(object.to)
        : undefined;
    message.summary =
      object.summary !== undefined && object.summary !== null
        ? Summary.fromPartial(object.summary)
        : undefined;
    message.memo = object.memo ?? '';
    message.eta =
      object.eta !== undefined && object.eta !== null
        ? ETA.fromPartial(object.eta)
        : undefined;
    message.additionalInfos =
      object.additionalInfos?.map((e) =>
        TransactionAdditionalInfo.fromPartial(e),
      ) || [];
    message.userId = object.userId ?? '';
    message.expiresAt =
      object.expiresAt !== undefined && object.expiresAt !== null
        ? Timestamp.fromPartial(object.expiresAt)
        : undefined;
    message.conversion =
      object.conversion !== undefined && object.conversion !== null
        ? TransactionConversion.fromPartial(object.conversion)
        : undefined;
    message.origin = object.origin ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
