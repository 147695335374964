/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import _m0 from 'protobufjs/minimal';
import { ClientQuote } from '../../../common/proto/fx/types';
import { Wallet } from '../../../common/proto/wallets/types';

export const protobufPackage = 'public';

export interface GetWalletByIDRequest {
  walletId: string;
}

export interface GetWalletByIDReply {
  wallet: Wallet | undefined;
}

export interface ListWalletsRequest {}

export interface ListWalletsReply {
  wallets: Wallet[];
}

export interface CreateDisbursementRequest {
  transactionId: string;
  fromWalletId: string;
  quoteId: string;
  recipientAccountId: string;
  recipientMessage: string;
  clientQuote: ClientQuote | undefined;
  reference: string;
  /** Purpose for the disbursement itself. */
  purpose: string;
}

export interface CreateDisbursementReply {
  transactionId: string;
}

export interface CreateCollectionRequest {
  transactionId: string;
  toWalletId: string;
  quoteId: string;
  clientQuote: ClientQuote | undefined;
}

export interface CreateCollectionReply {
  transactionId: string;
}

export interface CreateWalletRequest {
  displayName: string;
  currencyCode: string;
}

export interface CreateWalletReply {
  walletId: string;
}

export interface UpdateWalletRequest {
  walletId: string;
  displayName: string;
}

export interface UpdateWalletReply {}

function createBaseGetWalletByIDRequest(): GetWalletByIDRequest {
  return { walletId: '' };
}

export const GetWalletByIDRequest = {
  encode(
    message: GetWalletByIDRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.walletId !== '') {
      writer.uint32(10).string(message.walletId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetWalletByIDRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWalletByIDRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.walletId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWalletByIDRequest {
    return { walletId: isSet(object.walletId) ? String(object.walletId) : '' };
  },

  toJSON(message: GetWalletByIDRequest): unknown {
    const obj: any = {};
    message.walletId !== undefined && (obj.walletId = message.walletId);
    return obj;
  },

  create(base?: DeepPartial<GetWalletByIDRequest>): GetWalletByIDRequest {
    return GetWalletByIDRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetWalletByIDRequest>): GetWalletByIDRequest {
    const message = createBaseGetWalletByIDRequest();
    message.walletId = object.walletId ?? '';
    return message;
  },
};

function createBaseGetWalletByIDReply(): GetWalletByIDReply {
  return { wallet: undefined };
}

export const GetWalletByIDReply = {
  encode(
    message: GetWalletByIDReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.wallet !== undefined) {
      Wallet.encode(message.wallet, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWalletByIDReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWalletByIDReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wallet = Wallet.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWalletByIDReply {
    return {
      wallet: isSet(object.wallet) ? Wallet.fromJSON(object.wallet) : undefined,
    };
  },

  toJSON(message: GetWalletByIDReply): unknown {
    const obj: any = {};
    message.wallet !== undefined &&
      (obj.wallet = message.wallet ? Wallet.toJSON(message.wallet) : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetWalletByIDReply>): GetWalletByIDReply {
    return GetWalletByIDReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetWalletByIDReply>): GetWalletByIDReply {
    const message = createBaseGetWalletByIDReply();
    message.wallet =
      object.wallet !== undefined && object.wallet !== null
        ? Wallet.fromPartial(object.wallet)
        : undefined;
    return message;
  },
};

function createBaseListWalletsRequest(): ListWalletsRequest {
  return {};
}

export const ListWalletsRequest = {
  encode(
    _: ListWalletsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListWalletsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListWalletsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListWalletsRequest {
    return {};
  },

  toJSON(_: ListWalletsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<ListWalletsRequest>): ListWalletsRequest {
    return ListWalletsRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<ListWalletsRequest>): ListWalletsRequest {
    const message = createBaseListWalletsRequest();
    return message;
  },
};

function createBaseListWalletsReply(): ListWalletsReply {
  return { wallets: [] };
}

export const ListWalletsReply = {
  encode(
    message: ListWalletsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.wallets) {
      Wallet.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListWalletsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListWalletsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wallets.push(Wallet.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListWalletsReply {
    return {
      wallets: Array.isArray(object?.wallets)
        ? object.wallets.map((e: any) => Wallet.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListWalletsReply): unknown {
    const obj: any = {};
    if (message.wallets) {
      obj.wallets = message.wallets.map((e) =>
        e ? Wallet.toJSON(e) : undefined,
      );
    } else {
      obj.wallets = [];
    }
    return obj;
  },

  create(base?: DeepPartial<ListWalletsReply>): ListWalletsReply {
    return ListWalletsReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ListWalletsReply>): ListWalletsReply {
    const message = createBaseListWalletsReply();
    message.wallets = object.wallets?.map((e) => Wallet.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateDisbursementRequest(): CreateDisbursementRequest {
  return {
    transactionId: '',
    fromWalletId: '',
    quoteId: '',
    recipientAccountId: '',
    recipientMessage: '',
    clientQuote: undefined,
    reference: '',
    purpose: '',
  };
}

export const CreateDisbursementRequest = {
  encode(
    message: CreateDisbursementRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.fromWalletId !== '') {
      writer.uint32(18).string(message.fromWalletId);
    }
    if (message.quoteId !== '') {
      writer.uint32(26).string(message.quoteId);
    }
    if (message.recipientAccountId !== '') {
      writer.uint32(34).string(message.recipientAccountId);
    }
    if (message.recipientMessage !== '') {
      writer.uint32(42).string(message.recipientMessage);
    }
    if (message.clientQuote !== undefined) {
      ClientQuote.encode(
        message.clientQuote,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.reference !== '') {
      writer.uint32(58).string(message.reference);
    }
    if (message.purpose !== '') {
      writer.uint32(66).string(message.purpose);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateDisbursementRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDisbursementRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fromWalletId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.quoteId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.recipientAccountId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.recipientMessage = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.clientQuote = ClientQuote.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.reference = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.purpose = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateDisbursementRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      fromWalletId: isSet(object.fromWalletId)
        ? String(object.fromWalletId)
        : '',
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : '',
      recipientAccountId: isSet(object.recipientAccountId)
        ? String(object.recipientAccountId)
        : '',
      recipientMessage: isSet(object.recipientMessage)
        ? String(object.recipientMessage)
        : '',
      clientQuote: isSet(object.clientQuote)
        ? ClientQuote.fromJSON(object.clientQuote)
        : undefined,
      reference: isSet(object.reference) ? String(object.reference) : '',
      purpose: isSet(object.purpose) ? String(object.purpose) : '',
    };
  },

  toJSON(message: CreateDisbursementRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.fromWalletId !== undefined &&
      (obj.fromWalletId = message.fromWalletId);
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    message.recipientAccountId !== undefined &&
      (obj.recipientAccountId = message.recipientAccountId);
    message.recipientMessage !== undefined &&
      (obj.recipientMessage = message.recipientMessage);
    message.clientQuote !== undefined &&
      (obj.clientQuote = message.clientQuote
        ? ClientQuote.toJSON(message.clientQuote)
        : undefined);
    message.reference !== undefined && (obj.reference = message.reference);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    return obj;
  },

  create(
    base?: DeepPartial<CreateDisbursementRequest>,
  ): CreateDisbursementRequest {
    return CreateDisbursementRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateDisbursementRequest>,
  ): CreateDisbursementRequest {
    const message = createBaseCreateDisbursementRequest();
    message.transactionId = object.transactionId ?? '';
    message.fromWalletId = object.fromWalletId ?? '';
    message.quoteId = object.quoteId ?? '';
    message.recipientAccountId = object.recipientAccountId ?? '';
    message.recipientMessage = object.recipientMessage ?? '';
    message.clientQuote =
      object.clientQuote !== undefined && object.clientQuote !== null
        ? ClientQuote.fromPartial(object.clientQuote)
        : undefined;
    message.reference = object.reference ?? '';
    message.purpose = object.purpose ?? '';
    return message;
  },
};

function createBaseCreateDisbursementReply(): CreateDisbursementReply {
  return { transactionId: '' };
}

export const CreateDisbursementReply = {
  encode(
    message: CreateDisbursementReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateDisbursementReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDisbursementReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateDisbursementReply {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: CreateDisbursementReply): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(base?: DeepPartial<CreateDisbursementReply>): CreateDisbursementReply {
    return CreateDisbursementReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateDisbursementReply>,
  ): CreateDisbursementReply {
    const message = createBaseCreateDisbursementReply();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseCreateCollectionRequest(): CreateCollectionRequest {
  return {
    transactionId: '',
    toWalletId: '',
    quoteId: '',
    clientQuote: undefined,
  };
}

export const CreateCollectionRequest = {
  encode(
    message: CreateCollectionRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.toWalletId !== '') {
      writer.uint32(18).string(message.toWalletId);
    }
    if (message.quoteId !== '') {
      writer.uint32(26).string(message.quoteId);
    }
    if (message.clientQuote !== undefined) {
      ClientQuote.encode(
        message.clientQuote,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateCollectionRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCollectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.toWalletId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.quoteId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clientQuote = ClientQuote.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateCollectionRequest {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
      toWalletId: isSet(object.toWalletId) ? String(object.toWalletId) : '',
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : '',
      clientQuote: isSet(object.clientQuote)
        ? ClientQuote.fromJSON(object.clientQuote)
        : undefined,
    };
  },

  toJSON(message: CreateCollectionRequest): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    message.toWalletId !== undefined && (obj.toWalletId = message.toWalletId);
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    message.clientQuote !== undefined &&
      (obj.clientQuote = message.clientQuote
        ? ClientQuote.toJSON(message.clientQuote)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<CreateCollectionRequest>): CreateCollectionRequest {
    return CreateCollectionRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateCollectionRequest>,
  ): CreateCollectionRequest {
    const message = createBaseCreateCollectionRequest();
    message.transactionId = object.transactionId ?? '';
    message.toWalletId = object.toWalletId ?? '';
    message.quoteId = object.quoteId ?? '';
    message.clientQuote =
      object.clientQuote !== undefined && object.clientQuote !== null
        ? ClientQuote.fromPartial(object.clientQuote)
        : undefined;
    return message;
  },
};

function createBaseCreateCollectionReply(): CreateCollectionReply {
  return { transactionId: '' };
}

export const CreateCollectionReply = {
  encode(
    message: CreateCollectionReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.transactionId !== '') {
      writer.uint32(10).string(message.transactionId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateCollectionReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCollectionReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateCollectionReply {
    return {
      transactionId: isSet(object.transactionId)
        ? String(object.transactionId)
        : '',
    };
  },

  toJSON(message: CreateCollectionReply): unknown {
    const obj: any = {};
    message.transactionId !== undefined &&
      (obj.transactionId = message.transactionId);
    return obj;
  },

  create(base?: DeepPartial<CreateCollectionReply>): CreateCollectionReply {
    return CreateCollectionReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<CreateCollectionReply>,
  ): CreateCollectionReply {
    const message = createBaseCreateCollectionReply();
    message.transactionId = object.transactionId ?? '';
    return message;
  },
};

function createBaseCreateWalletRequest(): CreateWalletRequest {
  return { displayName: '', currencyCode: '' };
}

export const CreateWalletRequest = {
  encode(
    message: CreateWalletRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.displayName !== '') {
      writer.uint32(10).string(message.displayName);
    }
    if (message.currencyCode !== '') {
      writer.uint32(18).string(message.currencyCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateWalletRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWalletRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currencyCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateWalletRequest {
    return {
      displayName: isSet(object.displayName) ? String(object.displayName) : '',
      currencyCode: isSet(object.currencyCode)
        ? String(object.currencyCode)
        : '',
    };
  },

  toJSON(message: CreateWalletRequest): unknown {
    const obj: any = {};
    message.displayName !== undefined &&
      (obj.displayName = message.displayName);
    message.currencyCode !== undefined &&
      (obj.currencyCode = message.currencyCode);
    return obj;
  },

  create(base?: DeepPartial<CreateWalletRequest>): CreateWalletRequest {
    return CreateWalletRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CreateWalletRequest>): CreateWalletRequest {
    const message = createBaseCreateWalletRequest();
    message.displayName = object.displayName ?? '';
    message.currencyCode = object.currencyCode ?? '';
    return message;
  },
};

function createBaseCreateWalletReply(): CreateWalletReply {
  return { walletId: '' };
}

export const CreateWalletReply = {
  encode(
    message: CreateWalletReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.walletId !== '') {
      writer.uint32(10).string(message.walletId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateWalletReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWalletReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.walletId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateWalletReply {
    return { walletId: isSet(object.walletId) ? String(object.walletId) : '' };
  },

  toJSON(message: CreateWalletReply): unknown {
    const obj: any = {};
    message.walletId !== undefined && (obj.walletId = message.walletId);
    return obj;
  },

  create(base?: DeepPartial<CreateWalletReply>): CreateWalletReply {
    return CreateWalletReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CreateWalletReply>): CreateWalletReply {
    const message = createBaseCreateWalletReply();
    message.walletId = object.walletId ?? '';
    return message;
  },
};

function createBaseUpdateWalletRequest(): UpdateWalletRequest {
  return { walletId: '', displayName: '' };
}

export const UpdateWalletRequest = {
  encode(
    message: UpdateWalletRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.walletId !== '') {
      writer.uint32(10).string(message.walletId);
    }
    if (message.displayName !== '') {
      writer.uint32(18).string(message.displayName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWalletRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWalletRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.walletId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateWalletRequest {
    return {
      walletId: isSet(object.walletId) ? String(object.walletId) : '',
      displayName: isSet(object.displayName) ? String(object.displayName) : '',
    };
  },

  toJSON(message: UpdateWalletRequest): unknown {
    const obj: any = {};
    message.walletId !== undefined && (obj.walletId = message.walletId);
    message.displayName !== undefined &&
      (obj.displayName = message.displayName);
    return obj;
  },

  create(base?: DeepPartial<UpdateWalletRequest>): UpdateWalletRequest {
    return UpdateWalletRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<UpdateWalletRequest>): UpdateWalletRequest {
    const message = createBaseUpdateWalletRequest();
    message.walletId = object.walletId ?? '';
    message.displayName = object.displayName ?? '';
    return message;
  },
};

function createBaseUpdateWalletReply(): UpdateWalletReply {
  return {};
}

export const UpdateWalletReply = {
  encode(
    _: UpdateWalletReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateWalletReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateWalletReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): UpdateWalletReply {
    return {};
  },

  toJSON(_: UpdateWalletReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<UpdateWalletReply>): UpdateWalletReply {
    return UpdateWalletReply.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<UpdateWalletReply>): UpdateWalletReply {
    const message = createBaseUpdateWalletReply();
    return message;
  },
};

export interface Wallets {
  /** buf:lint:ignore COMMENT_RPC */
  ListWallets(
    request: DeepPartial<ListWalletsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListWalletsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetWalletByID(
    request: DeepPartial<GetWalletByIDRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetWalletByIDReply>;
  /** buf:lint:ignore COMMENT_RPC */
  CreateDisbursement(
    request: DeepPartial<CreateDisbursementRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateDisbursementReply>;
  /** buf:lint:ignore COMMENT_RPC */
  CreateCollection(
    request: DeepPartial<CreateCollectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateCollectionReply>;
  /** CreateWallet creates a wallet with the specified details */
  CreateWallet(
    request: DeepPartial<CreateWalletRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateWalletReply>;
  /** UpdateWallet updates a wallet with the specified details */
  UpdateWallet(
    request: DeepPartial<UpdateWalletRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateWalletReply>;
}

export class WalletsClientImpl implements Wallets {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ListWallets = this.ListWallets.bind(this);
    this.GetWalletByID = this.GetWalletByID.bind(this);
    this.CreateDisbursement = this.CreateDisbursement.bind(this);
    this.CreateCollection = this.CreateCollection.bind(this);
    this.CreateWallet = this.CreateWallet.bind(this);
    this.UpdateWallet = this.UpdateWallet.bind(this);
  }

  ListWallets(
    request: DeepPartial<ListWalletsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListWalletsReply> {
    return this.rpc.unary(
      WalletsListWalletsDesc,
      ListWalletsRequest.fromPartial(request),
      metadata,
    );
  }

  GetWalletByID(
    request: DeepPartial<GetWalletByIDRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetWalletByIDReply> {
    return this.rpc.unary(
      WalletsGetWalletByIDDesc,
      GetWalletByIDRequest.fromPartial(request),
      metadata,
    );
  }

  CreateDisbursement(
    request: DeepPartial<CreateDisbursementRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateDisbursementReply> {
    return this.rpc.unary(
      WalletsCreateDisbursementDesc,
      CreateDisbursementRequest.fromPartial(request),
      metadata,
    );
  }

  CreateCollection(
    request: DeepPartial<CreateCollectionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateCollectionReply> {
    return this.rpc.unary(
      WalletsCreateCollectionDesc,
      CreateCollectionRequest.fromPartial(request),
      metadata,
    );
  }

  CreateWallet(
    request: DeepPartial<CreateWalletRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateWalletReply> {
    return this.rpc.unary(
      WalletsCreateWalletDesc,
      CreateWalletRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateWallet(
    request: DeepPartial<UpdateWalletRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateWalletReply> {
    return this.rpc.unary(
      WalletsUpdateWalletDesc,
      UpdateWalletRequest.fromPartial(request),
      metadata,
    );
  }
}

export const WalletsDesc = { serviceName: 'public.Wallets' };

export const WalletsListWalletsDesc: UnaryMethodDefinitionish = {
  methodName: 'ListWallets',
  service: WalletsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListWalletsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListWalletsReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const WalletsGetWalletByIDDesc: UnaryMethodDefinitionish = {
  methodName: 'GetWalletByID',
  service: WalletsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetWalletByIDRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetWalletByIDReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const WalletsCreateDisbursementDesc: UnaryMethodDefinitionish = {
  methodName: 'CreateDisbursement',
  service: WalletsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateDisbursementRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateDisbursementReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const WalletsCreateCollectionDesc: UnaryMethodDefinitionish = {
  methodName: 'CreateCollection',
  service: WalletsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateCollectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateCollectionReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const WalletsCreateWalletDesc: UnaryMethodDefinitionish = {
  methodName: 'CreateWallet',
  service: WalletsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateWalletRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateWalletReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const WalletsUpdateWalletDesc: UnaryMethodDefinitionish = {
  methodName: 'UpdateWallet',
  service: WalletsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateWalletRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateWalletReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined
          ? { transport: this.options.transport }
          : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(
    message: string,
    public code: grpc.Code,
    public metadata: grpc.Metadata,
  ) {
    super(message);
  }
}
