/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import _m0 from 'protobufjs/minimal';
import { Money, Quote } from '../../../common/proto/fx/types';

export const protobufPackage = 'public';

export interface GetFXQuoteRequest {
  quoteId: string;
  source: Money | undefined;
  toCurrency: string;
}

export interface GetFXQuoteReply {
  quote: Quote | undefined;
}

export interface GetFXAllQuotesRequest {
  quoteId: string;
  source: Money | undefined;
}

export interface GetFXAllDefaultQuotesRequest {
  quoteId: string;
  fromCurrency: string;
}

export interface GetFXAllQuotesReply {
  quote: Quote | undefined;
}

export interface GetFXQuoteByIDRequest {
  quoteId: string;
}

export interface GetFXQuoteByIDReply {
  quote: Quote | undefined;
}

function createBaseGetFXQuoteRequest(): GetFXQuoteRequest {
  return { quoteId: '', source: undefined, toCurrency: '' };
}

export const GetFXQuoteRequest = {
  encode(
    message: GetFXQuoteRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.quoteId !== '') {
      writer.uint32(10).string(message.quoteId);
    }
    if (message.source !== undefined) {
      Money.encode(message.source, writer.uint32(18).fork()).ldelim();
    }
    if (message.toCurrency !== '') {
      writer.uint32(26).string(message.toCurrency);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFXQuoteRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFXQuoteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quoteId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = Money.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.toCurrency = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFXQuoteRequest {
    return {
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : '',
      source: isSet(object.source) ? Money.fromJSON(object.source) : undefined,
      toCurrency: isSet(object.toCurrency) ? String(object.toCurrency) : '',
    };
  },

  toJSON(message: GetFXQuoteRequest): unknown {
    const obj: any = {};
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    message.source !== undefined &&
      (obj.source = message.source ? Money.toJSON(message.source) : undefined);
    message.toCurrency !== undefined && (obj.toCurrency = message.toCurrency);
    return obj;
  },

  create(base?: DeepPartial<GetFXQuoteRequest>): GetFXQuoteRequest {
    return GetFXQuoteRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetFXQuoteRequest>): GetFXQuoteRequest {
    const message = createBaseGetFXQuoteRequest();
    message.quoteId = object.quoteId ?? '';
    message.source =
      object.source !== undefined && object.source !== null
        ? Money.fromPartial(object.source)
        : undefined;
    message.toCurrency = object.toCurrency ?? '';
    return message;
  },
};

function createBaseGetFXQuoteReply(): GetFXQuoteReply {
  return { quote: undefined };
}

export const GetFXQuoteReply = {
  encode(
    message: GetFXQuoteReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.quote !== undefined) {
      Quote.encode(message.quote, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFXQuoteReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFXQuoteReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quote = Quote.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFXQuoteReply {
    return {
      quote: isSet(object.quote) ? Quote.fromJSON(object.quote) : undefined,
    };
  },

  toJSON(message: GetFXQuoteReply): unknown {
    const obj: any = {};
    message.quote !== undefined &&
      (obj.quote = message.quote ? Quote.toJSON(message.quote) : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetFXQuoteReply>): GetFXQuoteReply {
    return GetFXQuoteReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetFXQuoteReply>): GetFXQuoteReply {
    const message = createBaseGetFXQuoteReply();
    message.quote =
      object.quote !== undefined && object.quote !== null
        ? Quote.fromPartial(object.quote)
        : undefined;
    return message;
  },
};

function createBaseGetFXAllQuotesRequest(): GetFXAllQuotesRequest {
  return { quoteId: '', source: undefined };
}

export const GetFXAllQuotesRequest = {
  encode(
    message: GetFXAllQuotesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.quoteId !== '') {
      writer.uint32(10).string(message.quoteId);
    }
    if (message.source !== undefined) {
      Money.encode(message.source, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetFXAllQuotesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFXAllQuotesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quoteId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = Money.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFXAllQuotesRequest {
    return {
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : '',
      source: isSet(object.source) ? Money.fromJSON(object.source) : undefined,
    };
  },

  toJSON(message: GetFXAllQuotesRequest): unknown {
    const obj: any = {};
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    message.source !== undefined &&
      (obj.source = message.source ? Money.toJSON(message.source) : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetFXAllQuotesRequest>): GetFXAllQuotesRequest {
    return GetFXAllQuotesRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetFXAllQuotesRequest>,
  ): GetFXAllQuotesRequest {
    const message = createBaseGetFXAllQuotesRequest();
    message.quoteId = object.quoteId ?? '';
    message.source =
      object.source !== undefined && object.source !== null
        ? Money.fromPartial(object.source)
        : undefined;
    return message;
  },
};

function createBaseGetFXAllDefaultQuotesRequest(): GetFXAllDefaultQuotesRequest {
  return { quoteId: '', fromCurrency: '' };
}

export const GetFXAllDefaultQuotesRequest = {
  encode(
    message: GetFXAllDefaultQuotesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.quoteId !== '') {
      writer.uint32(10).string(message.quoteId);
    }
    if (message.fromCurrency !== '') {
      writer.uint32(18).string(message.fromCurrency);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetFXAllDefaultQuotesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFXAllDefaultQuotesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quoteId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fromCurrency = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFXAllDefaultQuotesRequest {
    return {
      quoteId: isSet(object.quoteId) ? String(object.quoteId) : '',
      fromCurrency: isSet(object.fromCurrency)
        ? String(object.fromCurrency)
        : '',
    };
  },

  toJSON(message: GetFXAllDefaultQuotesRequest): unknown {
    const obj: any = {};
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    message.fromCurrency !== undefined &&
      (obj.fromCurrency = message.fromCurrency);
    return obj;
  },

  create(
    base?: DeepPartial<GetFXAllDefaultQuotesRequest>,
  ): GetFXAllDefaultQuotesRequest {
    return GetFXAllDefaultQuotesRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetFXAllDefaultQuotesRequest>,
  ): GetFXAllDefaultQuotesRequest {
    const message = createBaseGetFXAllDefaultQuotesRequest();
    message.quoteId = object.quoteId ?? '';
    message.fromCurrency = object.fromCurrency ?? '';
    return message;
  },
};

function createBaseGetFXAllQuotesReply(): GetFXAllQuotesReply {
  return { quote: undefined };
}

export const GetFXAllQuotesReply = {
  encode(
    message: GetFXAllQuotesReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.quote !== undefined) {
      Quote.encode(message.quote, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFXAllQuotesReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFXAllQuotesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quote = Quote.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFXAllQuotesReply {
    return {
      quote: isSet(object.quote) ? Quote.fromJSON(object.quote) : undefined,
    };
  },

  toJSON(message: GetFXAllQuotesReply): unknown {
    const obj: any = {};
    message.quote !== undefined &&
      (obj.quote = message.quote ? Quote.toJSON(message.quote) : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetFXAllQuotesReply>): GetFXAllQuotesReply {
    return GetFXAllQuotesReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetFXAllQuotesReply>): GetFXAllQuotesReply {
    const message = createBaseGetFXAllQuotesReply();
    message.quote =
      object.quote !== undefined && object.quote !== null
        ? Quote.fromPartial(object.quote)
        : undefined;
    return message;
  },
};

function createBaseGetFXQuoteByIDRequest(): GetFXQuoteByIDRequest {
  return { quoteId: '' };
}

export const GetFXQuoteByIDRequest = {
  encode(
    message: GetFXQuoteByIDRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.quoteId !== '') {
      writer.uint32(10).string(message.quoteId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetFXQuoteByIDRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFXQuoteByIDRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quoteId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFXQuoteByIDRequest {
    return { quoteId: isSet(object.quoteId) ? String(object.quoteId) : '' };
  },

  toJSON(message: GetFXQuoteByIDRequest): unknown {
    const obj: any = {};
    message.quoteId !== undefined && (obj.quoteId = message.quoteId);
    return obj;
  },

  create(base?: DeepPartial<GetFXQuoteByIDRequest>): GetFXQuoteByIDRequest {
    return GetFXQuoteByIDRequest.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetFXQuoteByIDRequest>,
  ): GetFXQuoteByIDRequest {
    const message = createBaseGetFXQuoteByIDRequest();
    message.quoteId = object.quoteId ?? '';
    return message;
  },
};

function createBaseGetFXQuoteByIDReply(): GetFXQuoteByIDReply {
  return { quote: undefined };
}

export const GetFXQuoteByIDReply = {
  encode(
    message: GetFXQuoteByIDReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.quote !== undefined) {
      Quote.encode(message.quote, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFXQuoteByIDReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFXQuoteByIDReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quote = Quote.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFXQuoteByIDReply {
    return {
      quote: isSet(object.quote) ? Quote.fromJSON(object.quote) : undefined,
    };
  },

  toJSON(message: GetFXQuoteByIDReply): unknown {
    const obj: any = {};
    message.quote !== undefined &&
      (obj.quote = message.quote ? Quote.toJSON(message.quote) : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetFXQuoteByIDReply>): GetFXQuoteByIDReply {
    return GetFXQuoteByIDReply.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GetFXQuoteByIDReply>): GetFXQuoteByIDReply {
    const message = createBaseGetFXQuoteByIDReply();
    message.quote =
      object.quote !== undefined && object.quote !== null
        ? Quote.fromPartial(object.quote)
        : undefined;
    return message;
  },
};

export interface FX {
  /** buf:lint:ignore COMMENT_RPC */
  GetFXQuote(
    request: DeepPartial<GetFXQuoteRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFXQuoteReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetFXAllQuotes(
    request: DeepPartial<GetFXAllQuotesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFXAllQuotesReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetFXAllDefaultQuotes(
    request: DeepPartial<GetFXAllDefaultQuotesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFXAllQuotesReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetFXQuoteByID(
    request: DeepPartial<GetFXQuoteByIDRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFXQuoteByIDReply>;
}

export class FXClientImpl implements FX {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetFXQuote = this.GetFXQuote.bind(this);
    this.GetFXAllQuotes = this.GetFXAllQuotes.bind(this);
    this.GetFXAllDefaultQuotes = this.GetFXAllDefaultQuotes.bind(this);
    this.GetFXQuoteByID = this.GetFXQuoteByID.bind(this);
  }

  GetFXQuote(
    request: DeepPartial<GetFXQuoteRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFXQuoteReply> {
    return this.rpc.unary(
      FXGetFXQuoteDesc,
      GetFXQuoteRequest.fromPartial(request),
      metadata,
    );
  }

  GetFXAllQuotes(
    request: DeepPartial<GetFXAllQuotesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFXAllQuotesReply> {
    return this.rpc.unary(
      FXGetFXAllQuotesDesc,
      GetFXAllQuotesRequest.fromPartial(request),
      metadata,
    );
  }

  GetFXAllDefaultQuotes(
    request: DeepPartial<GetFXAllDefaultQuotesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFXAllQuotesReply> {
    return this.rpc.unary(
      FXGetFXAllDefaultQuotesDesc,
      GetFXAllDefaultQuotesRequest.fromPartial(request),
      metadata,
    );
  }

  GetFXQuoteByID(
    request: DeepPartial<GetFXQuoteByIDRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetFXQuoteByIDReply> {
    return this.rpc.unary(
      FXGetFXQuoteByIDDesc,
      GetFXQuoteByIDRequest.fromPartial(request),
      metadata,
    );
  }
}

export const FXDesc = { serviceName: 'public.FX' };

export const FXGetFXQuoteDesc: UnaryMethodDefinitionish = {
  methodName: 'GetFXQuote',
  service: FXDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFXQuoteRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFXQuoteReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FXGetFXAllQuotesDesc: UnaryMethodDefinitionish = {
  methodName: 'GetFXAllQuotes',
  service: FXDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFXAllQuotesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFXAllQuotesReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FXGetFXAllDefaultQuotesDesc: UnaryMethodDefinitionish = {
  methodName: 'GetFXAllDefaultQuotes',
  service: FXDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFXAllDefaultQuotesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFXAllQuotesReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FXGetFXQuoteByIDDesc: UnaryMethodDefinitionish = {
  methodName: 'GetFXQuoteByID',
  service: FXDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFXQuoteByIDRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFXQuoteByIDReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined
          ? { transport: this.options.transport }
          : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(
    message: string,
    public code: grpc.Code,
    public metadata: grpc.Metadata,
  ) {
    super(message);
  }
}
