/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import _m0 from 'protobufjs/minimal';
import { CountriesConfig } from '../../../common/proto/config/types';

export const protobufPackage = 'anonymous';

/** get countries config */
export interface GetCountriesConfigRequest {}

export interface GetCountriesConfigReply {
  config: CountriesConfig | undefined;
}

function createBaseGetCountriesConfigRequest(): GetCountriesConfigRequest {
  return {};
}

export const GetCountriesConfigRequest = {
  encode(
    _: GetCountriesConfigRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetCountriesConfigRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCountriesConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetCountriesConfigRequest {
    return {};
  },

  toJSON(_: GetCountriesConfigRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<GetCountriesConfigRequest>,
  ): GetCountriesConfigRequest {
    return GetCountriesConfigRequest.fromPartial(base ?? {});
  },

  fromPartial(
    _: DeepPartial<GetCountriesConfigRequest>,
  ): GetCountriesConfigRequest {
    const message = createBaseGetCountriesConfigRequest();
    return message;
  },
};

function createBaseGetCountriesConfigReply(): GetCountriesConfigReply {
  return { config: undefined };
}

export const GetCountriesConfigReply = {
  encode(
    message: GetCountriesConfigReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.config !== undefined) {
      CountriesConfig.encode(message.config, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetCountriesConfigReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCountriesConfigReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.config = CountriesConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCountriesConfigReply {
    return {
      config: isSet(object.config)
        ? CountriesConfig.fromJSON(object.config)
        : undefined,
    };
  },

  toJSON(message: GetCountriesConfigReply): unknown {
    const obj: any = {};
    message.config !== undefined &&
      (obj.config = message.config
        ? CountriesConfig.toJSON(message.config)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<GetCountriesConfigReply>): GetCountriesConfigReply {
    return GetCountriesConfigReply.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GetCountriesConfigReply>,
  ): GetCountriesConfigReply {
    const message = createBaseGetCountriesConfigReply();
    message.config =
      object.config !== undefined && object.config !== null
        ? CountriesConfig.fromPartial(object.config)
        : undefined;
    return message;
  },
};

export interface AnonymousConfig {
  /** buf:lint:ignore COMMENT_RPC */
  GetCountriesConfig(
    request: DeepPartial<GetCountriesConfigRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCountriesConfigReply>;
}

export class AnonymousConfigClientImpl implements AnonymousConfig {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetCountriesConfig = this.GetCountriesConfig.bind(this);
  }

  GetCountriesConfig(
    request: DeepPartial<GetCountriesConfigRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetCountriesConfigReply> {
    return this.rpc.unary(
      AnonymousConfigGetCountriesConfigDesc,
      GetCountriesConfigRequest.fromPartial(request),
      metadata,
    );
  }
}

export const AnonymousConfigDesc = { serviceName: 'anonymous.AnonymousConfig' };

export const AnonymousConfigGetCountriesConfigDesc: UnaryMethodDefinitionish = {
  methodName: 'GetCountriesConfig',
  service: AnonymousConfigDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCountriesConfigRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCountriesConfigReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined
          ? { transport: this.options.transport }
          : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(
    message: string,
    public code: grpc.Code,
    public metadata: grpc.Metadata,
  ) {
    super(message);
  }
}
