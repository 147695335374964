/* eslint-disable */

export const protobufPackage = 'permissions';

/**
 * Permission represents a permission that can be assigned to a role.
 * The first part of the prefix is the top level domain of the permissions group e.g. account, users, transactions, etc.
 * buf:lint:ignore ENUM_VALUE_PREFIX
 */
export enum Permission {
  /** INVALID - buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX */
  INVALID = 0,
  /** ACCOUNT_USERS_LIST - users */
  ACCOUNT_USERS_LIST = 10001,
  ACCOUNT_USERS_CREATE = 10002,
  ACCOUNT_USERS_EDIT = 10003,
  ACCOUNT_USERS_DELETE = 10004,
  /** ACCOUNT_TRANSACTIONS_LIST - transactions */
  ACCOUNT_TRANSACTIONS_LIST = 20005,
  ACCOUNT_TRANSACTIONS_DISBURSEMENT_CREATE = 20006,
  ACCOUNT_TRANSACTIONS_COLLECTION_CREATE = 20007,
  ACCOUNT_TRANSACTIONS_CONVERSION_CREATE = 20008,
  ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE = 20009,
  ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE_OWN = 20010,
  ACCOUNT_TRANSACTIONS_APPROVAL_TOGGLE = 20011,
  /** ACCOUNT_WALLETS_LIST - wallets */
  ACCOUNT_WALLETS_LIST = 30009,
  ACCOUNT_WALLETS_CREATE = 30010,
  ACCOUNT_WALLETS_STATEMENT_EXPORT = 30011,
  ACCOUNT_WALLETS_UPDATE = 30012,
  /** ACCOUNT_RECIPIENTS_LIST - recipients */
  ACCOUNT_RECIPIENTS_LIST = 40012,
  ACCOUNT_RECIPIENTS_CREATE = 40013,
  ACCOUNT_RECIPIENTS_DELETE = 40014,
  ACCOUNT_RECIPIENTS_EDIT = 40015,
  /** USERS_STAFF_TEAM_LIST - staff team */
  USERS_STAFF_TEAM_LIST = 50016,
  USERS_STAFF_TEAM_CREATE = 50017,
  USERS_STAFF_TEAM_EDIT = 50018,
  USERS_STAFF_TEAM_DELETE = 50019,
  USERS_STAFF_TEAM_ROLES_LIST = 50020,
  /** USERS_STAFF_TEAM_MEMBERS_LIST - staff team members */
  USERS_STAFF_TEAM_MEMBERS_LIST = 60021,
  USERS_STAFF_TEAM_MEMBERS_CREATE = 60022,
  USERS_STAFF_TEAM_MEMBERS_EDIT = 60023,
  USERS_STAFF_TEAM_MEMBERS_DELETE = 60024,
  FX_ADMIN_READONLY = 70025,
  FX_ADMIN_WRITE = 70026,
  TRANSACTIONS_ADMIN_TREASURY_READONLY = 80027,
  TRANSACTIONS_ADMIN_READONLY = 80028,
  TRANSACTIONS_ADMIN_WRITE = 80029,
  TRANSACTIONS_ADMIN_SUPPORT = 80030,
  TRANSACTIONS_ADMIN_TREASURY = 80031,
  TRANSACTIONS_ADMIN_FINANCE = 80032,
  TRANSACTIONS_ADMIN_FINANCE_READONLY = 80033,
  WALLET_WITHDRAWAL_WRITE = 90034,
  USERS_ADMIN_READONLY = 100035,
  USERS_ADMIN_READWRITE = 100036,
  USERS_ADMIN_WRITE = 1000310,
  USERS_ADMIN_WRITE_LIMIT = 100038,
  USERS_ADMIN_STATUS = 100039,
  USERS_ADMIN_STATUS_SUSPEND = 100040,
  USERS_ADMIN_STATUS_UNBAN = 100041,
  USERS_ADMIN_DELETE = 100042,
  USERS_LIST_ROLES = 100043,
  USERS_ACCOUNT_PROFILES = 100044,
  USERS_ADMIN_TAG = 100045,
  USERS_ADMIN_ACCOUNTS_WRITE = 100046,
  USERS_ADMIN_WAITLIST = 100047,
  USERS_ADMIN_INVITATION_CODES = 100048,
  USERS_ADMIN_STAFF = 100049,
  USERS_ADMIN_STAFF_ADD = 100050,
  USERS_ADMIN_STAFF_UPDATE = 100051,
  USERS_ADMIN_WRITE_FEATURES = 100052,
  KYC_ADMIN_READONLY = 110053,
  KYC_ADMIN_WRITE = 110054,
  KYC_ADMIN_EXPORT = 110055,
  DOCUMENTS_LIST = 120056,
  DOCUMENTS_REVIEW = 120057,
  DOCUMENTS_REQUEST = 120058,
  DOCUMENTS_DOWNLOAD = 120059,
  DOCUMENTS_UPLOAD = 120060,
  RISK_READONLY = 130061,
  RISK_WRITE = 130062,
  BUSINESS_WRITE = 140063,
  HOLDS_READ = 150064,
  HOLDS_WRITE = 150065,
  TASKS_ADMIN_READONLY = 160066,
  TASKS_ADMIN_WRITE = 170067,
  TASKS_SOP_ADMIN_WRITE = 170068,
  NOTES_ADMIN_WRITE = 180069,
  NOTES_ADMIN_READONLY = 180070,
  PROVIDERS_ESCALATION_CONFIG_WRITE = 190071,
  PROVIDERS_ESCALATION_CONFIG_READ = 190072,
  PROVIDERS_HTTP_INTERACTIONS_READ = 190073,
  CONVERSATIONS_ADMIN_READONLY = 200074,
  AUDIT_READ = 210075,
  WORKFLOWS_READ = 220076,
  BASE = 230077,
  DEVELOPER = 240078,
  DEVELOPER_ADVANCED = 250079,
  CONFIG_READONLY = 260080,
  PROVIDERS_CONFIG = 270081,
  SUPERADMIN = 280082,
  ADMIN_READONLY = 280083,
  APP_VERSION = 290083,
  BANNER_ITEMS = 300084,
  GROWTH_RATES_WRITE = 310085,
  INVALID_PAYMENT_RESOLUTION = 320086,
  KYB_ADMIN_WRITE = 330087,
  PSP_PAYOPS = 340088,
  UNRECOGNIZED = -1,
}

export function permissionFromJSON(object: any): Permission {
  switch (object) {
    case 0:
    case 'INVALID':
      return Permission.INVALID;
    case 10001:
    case 'ACCOUNT_USERS_LIST':
      return Permission.ACCOUNT_USERS_LIST;
    case 10002:
    case 'ACCOUNT_USERS_CREATE':
      return Permission.ACCOUNT_USERS_CREATE;
    case 10003:
    case 'ACCOUNT_USERS_EDIT':
      return Permission.ACCOUNT_USERS_EDIT;
    case 10004:
    case 'ACCOUNT_USERS_DELETE':
      return Permission.ACCOUNT_USERS_DELETE;
    case 20005:
    case 'ACCOUNT_TRANSACTIONS_LIST':
      return Permission.ACCOUNT_TRANSACTIONS_LIST;
    case 20006:
    case 'ACCOUNT_TRANSACTIONS_DISBURSEMENT_CREATE':
      return Permission.ACCOUNT_TRANSACTIONS_DISBURSEMENT_CREATE;
    case 20007:
    case 'ACCOUNT_TRANSACTIONS_COLLECTION_CREATE':
      return Permission.ACCOUNT_TRANSACTIONS_COLLECTION_CREATE;
    case 20008:
    case 'ACCOUNT_TRANSACTIONS_CONVERSION_CREATE':
      return Permission.ACCOUNT_TRANSACTIONS_CONVERSION_CREATE;
    case 20009:
    case 'ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE':
      return Permission.ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE;
    case 20010:
    case 'ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE_OWN':
      return Permission.ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE_OWN;
    case 20011:
    case 'ACCOUNT_TRANSACTIONS_APPROVAL_TOGGLE':
      return Permission.ACCOUNT_TRANSACTIONS_APPROVAL_TOGGLE;
    case 30009:
    case 'ACCOUNT_WALLETS_LIST':
      return Permission.ACCOUNT_WALLETS_LIST;
    case 30010:
    case 'ACCOUNT_WALLETS_CREATE':
      return Permission.ACCOUNT_WALLETS_CREATE;
    case 30011:
    case 'ACCOUNT_WALLETS_STATEMENT_EXPORT':
      return Permission.ACCOUNT_WALLETS_STATEMENT_EXPORT;
    case 30012:
    case 'ACCOUNT_WALLETS_UPDATE':
      return Permission.ACCOUNT_WALLETS_UPDATE;
    case 40012:
    case 'ACCOUNT_RECIPIENTS_LIST':
      return Permission.ACCOUNT_RECIPIENTS_LIST;
    case 40013:
    case 'ACCOUNT_RECIPIENTS_CREATE':
      return Permission.ACCOUNT_RECIPIENTS_CREATE;
    case 40014:
    case 'ACCOUNT_RECIPIENTS_DELETE':
      return Permission.ACCOUNT_RECIPIENTS_DELETE;
    case 40015:
    case 'ACCOUNT_RECIPIENTS_EDIT':
      return Permission.ACCOUNT_RECIPIENTS_EDIT;
    case 50016:
    case 'USERS_STAFF_TEAM_LIST':
      return Permission.USERS_STAFF_TEAM_LIST;
    case 50017:
    case 'USERS_STAFF_TEAM_CREATE':
      return Permission.USERS_STAFF_TEAM_CREATE;
    case 50018:
    case 'USERS_STAFF_TEAM_EDIT':
      return Permission.USERS_STAFF_TEAM_EDIT;
    case 50019:
    case 'USERS_STAFF_TEAM_DELETE':
      return Permission.USERS_STAFF_TEAM_DELETE;
    case 50020:
    case 'USERS_STAFF_TEAM_ROLES_LIST':
      return Permission.USERS_STAFF_TEAM_ROLES_LIST;
    case 60021:
    case 'USERS_STAFF_TEAM_MEMBERS_LIST':
      return Permission.USERS_STAFF_TEAM_MEMBERS_LIST;
    case 60022:
    case 'USERS_STAFF_TEAM_MEMBERS_CREATE':
      return Permission.USERS_STAFF_TEAM_MEMBERS_CREATE;
    case 60023:
    case 'USERS_STAFF_TEAM_MEMBERS_EDIT':
      return Permission.USERS_STAFF_TEAM_MEMBERS_EDIT;
    case 60024:
    case 'USERS_STAFF_TEAM_MEMBERS_DELETE':
      return Permission.USERS_STAFF_TEAM_MEMBERS_DELETE;
    case 70025:
    case 'FX_ADMIN_READONLY':
      return Permission.FX_ADMIN_READONLY;
    case 70026:
    case 'FX_ADMIN_WRITE':
      return Permission.FX_ADMIN_WRITE;
    case 80027:
    case 'TRANSACTIONS_ADMIN_TREASURY_READONLY':
      return Permission.TRANSACTIONS_ADMIN_TREASURY_READONLY;
    case 80028:
    case 'TRANSACTIONS_ADMIN_READONLY':
      return Permission.TRANSACTIONS_ADMIN_READONLY;
    case 80029:
    case 'TRANSACTIONS_ADMIN_WRITE':
      return Permission.TRANSACTIONS_ADMIN_WRITE;
    case 80030:
    case 'TRANSACTIONS_ADMIN_SUPPORT':
      return Permission.TRANSACTIONS_ADMIN_SUPPORT;
    case 80031:
    case 'TRANSACTIONS_ADMIN_TREASURY':
      return Permission.TRANSACTIONS_ADMIN_TREASURY;
    case 80032:
    case 'TRANSACTIONS_ADMIN_FINANCE':
      return Permission.TRANSACTIONS_ADMIN_FINANCE;
    case 80033:
    case 'TRANSACTIONS_ADMIN_FINANCE_READONLY':
      return Permission.TRANSACTIONS_ADMIN_FINANCE_READONLY;
    case 90034:
    case 'WALLET_WITHDRAWAL_WRITE':
      return Permission.WALLET_WITHDRAWAL_WRITE;
    case 100035:
    case 'USERS_ADMIN_READONLY':
      return Permission.USERS_ADMIN_READONLY;
    case 100036:
    case 'USERS_ADMIN_READWRITE':
      return Permission.USERS_ADMIN_READWRITE;
    case 1000310:
    case 'USERS_ADMIN_WRITE':
      return Permission.USERS_ADMIN_WRITE;
    case 100038:
    case 'USERS_ADMIN_WRITE_LIMIT':
      return Permission.USERS_ADMIN_WRITE_LIMIT;
    case 100039:
    case 'USERS_ADMIN_STATUS':
      return Permission.USERS_ADMIN_STATUS;
    case 100040:
    case 'USERS_ADMIN_STATUS_SUSPEND':
      return Permission.USERS_ADMIN_STATUS_SUSPEND;
    case 100041:
    case 'USERS_ADMIN_STATUS_UNBAN':
      return Permission.USERS_ADMIN_STATUS_UNBAN;
    case 100042:
    case 'USERS_ADMIN_DELETE':
      return Permission.USERS_ADMIN_DELETE;
    case 100043:
    case 'USERS_LIST_ROLES':
      return Permission.USERS_LIST_ROLES;
    case 100044:
    case 'USERS_ACCOUNT_PROFILES':
      return Permission.USERS_ACCOUNT_PROFILES;
    case 100045:
    case 'USERS_ADMIN_TAG':
      return Permission.USERS_ADMIN_TAG;
    case 100046:
    case 'USERS_ADMIN_ACCOUNTS_WRITE':
      return Permission.USERS_ADMIN_ACCOUNTS_WRITE;
    case 100047:
    case 'USERS_ADMIN_WAITLIST':
      return Permission.USERS_ADMIN_WAITLIST;
    case 100048:
    case 'USERS_ADMIN_INVITATION_CODES':
      return Permission.USERS_ADMIN_INVITATION_CODES;
    case 100049:
    case 'USERS_ADMIN_STAFF':
      return Permission.USERS_ADMIN_STAFF;
    case 100050:
    case 'USERS_ADMIN_STAFF_ADD':
      return Permission.USERS_ADMIN_STAFF_ADD;
    case 100051:
    case 'USERS_ADMIN_STAFF_UPDATE':
      return Permission.USERS_ADMIN_STAFF_UPDATE;
    case 100052:
    case 'USERS_ADMIN_WRITE_FEATURES':
      return Permission.USERS_ADMIN_WRITE_FEATURES;
    case 110053:
    case 'KYC_ADMIN_READONLY':
      return Permission.KYC_ADMIN_READONLY;
    case 110054:
    case 'KYC_ADMIN_WRITE':
      return Permission.KYC_ADMIN_WRITE;
    case 110055:
    case 'KYC_ADMIN_EXPORT':
      return Permission.KYC_ADMIN_EXPORT;
    case 120056:
    case 'DOCUMENTS_LIST':
      return Permission.DOCUMENTS_LIST;
    case 120057:
    case 'DOCUMENTS_REVIEW':
      return Permission.DOCUMENTS_REVIEW;
    case 120058:
    case 'DOCUMENTS_REQUEST':
      return Permission.DOCUMENTS_REQUEST;
    case 120059:
    case 'DOCUMENTS_DOWNLOAD':
      return Permission.DOCUMENTS_DOWNLOAD;
    case 120060:
    case 'DOCUMENTS_UPLOAD':
      return Permission.DOCUMENTS_UPLOAD;
    case 130061:
    case 'RISK_READONLY':
      return Permission.RISK_READONLY;
    case 130062:
    case 'RISK_WRITE':
      return Permission.RISK_WRITE;
    case 140063:
    case 'BUSINESS_WRITE':
      return Permission.BUSINESS_WRITE;
    case 150064:
    case 'HOLDS_READ':
      return Permission.HOLDS_READ;
    case 150065:
    case 'HOLDS_WRITE':
      return Permission.HOLDS_WRITE;
    case 160066:
    case 'TASKS_ADMIN_READONLY':
      return Permission.TASKS_ADMIN_READONLY;
    case 170067:
    case 'TASKS_ADMIN_WRITE':
      return Permission.TASKS_ADMIN_WRITE;
    case 170068:
    case 'TASKS_SOP_ADMIN_WRITE':
      return Permission.TASKS_SOP_ADMIN_WRITE;
    case 180069:
    case 'NOTES_ADMIN_WRITE':
      return Permission.NOTES_ADMIN_WRITE;
    case 180070:
    case 'NOTES_ADMIN_READONLY':
      return Permission.NOTES_ADMIN_READONLY;
    case 190071:
    case 'PROVIDERS_ESCALATION_CONFIG_WRITE':
      return Permission.PROVIDERS_ESCALATION_CONFIG_WRITE;
    case 190072:
    case 'PROVIDERS_ESCALATION_CONFIG_READ':
      return Permission.PROVIDERS_ESCALATION_CONFIG_READ;
    case 190073:
    case 'PROVIDERS_HTTP_INTERACTIONS_READ':
      return Permission.PROVIDERS_HTTP_INTERACTIONS_READ;
    case 200074:
    case 'CONVERSATIONS_ADMIN_READONLY':
      return Permission.CONVERSATIONS_ADMIN_READONLY;
    case 210075:
    case 'AUDIT_READ':
      return Permission.AUDIT_READ;
    case 220076:
    case 'WORKFLOWS_READ':
      return Permission.WORKFLOWS_READ;
    case 230077:
    case 'BASE':
      return Permission.BASE;
    case 240078:
    case 'DEVELOPER':
      return Permission.DEVELOPER;
    case 250079:
    case 'DEVELOPER_ADVANCED':
      return Permission.DEVELOPER_ADVANCED;
    case 260080:
    case 'CONFIG_READONLY':
      return Permission.CONFIG_READONLY;
    case 270081:
    case 'PROVIDERS_CONFIG':
      return Permission.PROVIDERS_CONFIG;
    case 280082:
    case 'SUPERADMIN':
      return Permission.SUPERADMIN;
    case 280083:
    case 'ADMIN_READONLY':
      return Permission.ADMIN_READONLY;
    case 290083:
    case 'APP_VERSION':
      return Permission.APP_VERSION;
    case 300084:
    case 'BANNER_ITEMS':
      return Permission.BANNER_ITEMS;
    case 310085:
    case 'GROWTH_RATES_WRITE':
      return Permission.GROWTH_RATES_WRITE;
    case 320086:
    case 'INVALID_PAYMENT_RESOLUTION':
      return Permission.INVALID_PAYMENT_RESOLUTION;
    case 330087:
    case 'KYB_ADMIN_WRITE':
      return Permission.KYB_ADMIN_WRITE;
    case 340088:
    case 'PSP_PAYOPS':
      return Permission.PSP_PAYOPS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Permission.UNRECOGNIZED;
  }
}

export function permissionToJSON(object: Permission): string {
  switch (object) {
    case Permission.INVALID:
      return 'INVALID';
    case Permission.ACCOUNT_USERS_LIST:
      return 'ACCOUNT_USERS_LIST';
    case Permission.ACCOUNT_USERS_CREATE:
      return 'ACCOUNT_USERS_CREATE';
    case Permission.ACCOUNT_USERS_EDIT:
      return 'ACCOUNT_USERS_EDIT';
    case Permission.ACCOUNT_USERS_DELETE:
      return 'ACCOUNT_USERS_DELETE';
    case Permission.ACCOUNT_TRANSACTIONS_LIST:
      return 'ACCOUNT_TRANSACTIONS_LIST';
    case Permission.ACCOUNT_TRANSACTIONS_DISBURSEMENT_CREATE:
      return 'ACCOUNT_TRANSACTIONS_DISBURSEMENT_CREATE';
    case Permission.ACCOUNT_TRANSACTIONS_COLLECTION_CREATE:
      return 'ACCOUNT_TRANSACTIONS_COLLECTION_CREATE';
    case Permission.ACCOUNT_TRANSACTIONS_CONVERSION_CREATE:
      return 'ACCOUNT_TRANSACTIONS_CONVERSION_CREATE';
    case Permission.ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE:
      return 'ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE';
    case Permission.ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE_OWN:
      return 'ACCOUNT_TRANSACTIONS_APPROVAL_APPROVE_OWN';
    case Permission.ACCOUNT_TRANSACTIONS_APPROVAL_TOGGLE:
      return 'ACCOUNT_TRANSACTIONS_APPROVAL_TOGGLE';
    case Permission.ACCOUNT_WALLETS_LIST:
      return 'ACCOUNT_WALLETS_LIST';
    case Permission.ACCOUNT_WALLETS_CREATE:
      return 'ACCOUNT_WALLETS_CREATE';
    case Permission.ACCOUNT_WALLETS_STATEMENT_EXPORT:
      return 'ACCOUNT_WALLETS_STATEMENT_EXPORT';
    case Permission.ACCOUNT_WALLETS_UPDATE:
      return 'ACCOUNT_WALLETS_UPDATE';
    case Permission.ACCOUNT_RECIPIENTS_LIST:
      return 'ACCOUNT_RECIPIENTS_LIST';
    case Permission.ACCOUNT_RECIPIENTS_CREATE:
      return 'ACCOUNT_RECIPIENTS_CREATE';
    case Permission.ACCOUNT_RECIPIENTS_DELETE:
      return 'ACCOUNT_RECIPIENTS_DELETE';
    case Permission.ACCOUNT_RECIPIENTS_EDIT:
      return 'ACCOUNT_RECIPIENTS_EDIT';
    case Permission.USERS_STAFF_TEAM_LIST:
      return 'USERS_STAFF_TEAM_LIST';
    case Permission.USERS_STAFF_TEAM_CREATE:
      return 'USERS_STAFF_TEAM_CREATE';
    case Permission.USERS_STAFF_TEAM_EDIT:
      return 'USERS_STAFF_TEAM_EDIT';
    case Permission.USERS_STAFF_TEAM_DELETE:
      return 'USERS_STAFF_TEAM_DELETE';
    case Permission.USERS_STAFF_TEAM_ROLES_LIST:
      return 'USERS_STAFF_TEAM_ROLES_LIST';
    case Permission.USERS_STAFF_TEAM_MEMBERS_LIST:
      return 'USERS_STAFF_TEAM_MEMBERS_LIST';
    case Permission.USERS_STAFF_TEAM_MEMBERS_CREATE:
      return 'USERS_STAFF_TEAM_MEMBERS_CREATE';
    case Permission.USERS_STAFF_TEAM_MEMBERS_EDIT:
      return 'USERS_STAFF_TEAM_MEMBERS_EDIT';
    case Permission.USERS_STAFF_TEAM_MEMBERS_DELETE:
      return 'USERS_STAFF_TEAM_MEMBERS_DELETE';
    case Permission.FX_ADMIN_READONLY:
      return 'FX_ADMIN_READONLY';
    case Permission.FX_ADMIN_WRITE:
      return 'FX_ADMIN_WRITE';
    case Permission.TRANSACTIONS_ADMIN_TREASURY_READONLY:
      return 'TRANSACTIONS_ADMIN_TREASURY_READONLY';
    case Permission.TRANSACTIONS_ADMIN_READONLY:
      return 'TRANSACTIONS_ADMIN_READONLY';
    case Permission.TRANSACTIONS_ADMIN_WRITE:
      return 'TRANSACTIONS_ADMIN_WRITE';
    case Permission.TRANSACTIONS_ADMIN_SUPPORT:
      return 'TRANSACTIONS_ADMIN_SUPPORT';
    case Permission.TRANSACTIONS_ADMIN_TREASURY:
      return 'TRANSACTIONS_ADMIN_TREASURY';
    case Permission.TRANSACTIONS_ADMIN_FINANCE:
      return 'TRANSACTIONS_ADMIN_FINANCE';
    case Permission.TRANSACTIONS_ADMIN_FINANCE_READONLY:
      return 'TRANSACTIONS_ADMIN_FINANCE_READONLY';
    case Permission.WALLET_WITHDRAWAL_WRITE:
      return 'WALLET_WITHDRAWAL_WRITE';
    case Permission.USERS_ADMIN_READONLY:
      return 'USERS_ADMIN_READONLY';
    case Permission.USERS_ADMIN_READWRITE:
      return 'USERS_ADMIN_READWRITE';
    case Permission.USERS_ADMIN_WRITE:
      return 'USERS_ADMIN_WRITE';
    case Permission.USERS_ADMIN_WRITE_LIMIT:
      return 'USERS_ADMIN_WRITE_LIMIT';
    case Permission.USERS_ADMIN_STATUS:
      return 'USERS_ADMIN_STATUS';
    case Permission.USERS_ADMIN_STATUS_SUSPEND:
      return 'USERS_ADMIN_STATUS_SUSPEND';
    case Permission.USERS_ADMIN_STATUS_UNBAN:
      return 'USERS_ADMIN_STATUS_UNBAN';
    case Permission.USERS_ADMIN_DELETE:
      return 'USERS_ADMIN_DELETE';
    case Permission.USERS_LIST_ROLES:
      return 'USERS_LIST_ROLES';
    case Permission.USERS_ACCOUNT_PROFILES:
      return 'USERS_ACCOUNT_PROFILES';
    case Permission.USERS_ADMIN_TAG:
      return 'USERS_ADMIN_TAG';
    case Permission.USERS_ADMIN_ACCOUNTS_WRITE:
      return 'USERS_ADMIN_ACCOUNTS_WRITE';
    case Permission.USERS_ADMIN_WAITLIST:
      return 'USERS_ADMIN_WAITLIST';
    case Permission.USERS_ADMIN_INVITATION_CODES:
      return 'USERS_ADMIN_INVITATION_CODES';
    case Permission.USERS_ADMIN_STAFF:
      return 'USERS_ADMIN_STAFF';
    case Permission.USERS_ADMIN_STAFF_ADD:
      return 'USERS_ADMIN_STAFF_ADD';
    case Permission.USERS_ADMIN_STAFF_UPDATE:
      return 'USERS_ADMIN_STAFF_UPDATE';
    case Permission.USERS_ADMIN_WRITE_FEATURES:
      return 'USERS_ADMIN_WRITE_FEATURES';
    case Permission.KYC_ADMIN_READONLY:
      return 'KYC_ADMIN_READONLY';
    case Permission.KYC_ADMIN_WRITE:
      return 'KYC_ADMIN_WRITE';
    case Permission.KYC_ADMIN_EXPORT:
      return 'KYC_ADMIN_EXPORT';
    case Permission.DOCUMENTS_LIST:
      return 'DOCUMENTS_LIST';
    case Permission.DOCUMENTS_REVIEW:
      return 'DOCUMENTS_REVIEW';
    case Permission.DOCUMENTS_REQUEST:
      return 'DOCUMENTS_REQUEST';
    case Permission.DOCUMENTS_DOWNLOAD:
      return 'DOCUMENTS_DOWNLOAD';
    case Permission.DOCUMENTS_UPLOAD:
      return 'DOCUMENTS_UPLOAD';
    case Permission.RISK_READONLY:
      return 'RISK_READONLY';
    case Permission.RISK_WRITE:
      return 'RISK_WRITE';
    case Permission.BUSINESS_WRITE:
      return 'BUSINESS_WRITE';
    case Permission.HOLDS_READ:
      return 'HOLDS_READ';
    case Permission.HOLDS_WRITE:
      return 'HOLDS_WRITE';
    case Permission.TASKS_ADMIN_READONLY:
      return 'TASKS_ADMIN_READONLY';
    case Permission.TASKS_ADMIN_WRITE:
      return 'TASKS_ADMIN_WRITE';
    case Permission.TASKS_SOP_ADMIN_WRITE:
      return 'TASKS_SOP_ADMIN_WRITE';
    case Permission.NOTES_ADMIN_WRITE:
      return 'NOTES_ADMIN_WRITE';
    case Permission.NOTES_ADMIN_READONLY:
      return 'NOTES_ADMIN_READONLY';
    case Permission.PROVIDERS_ESCALATION_CONFIG_WRITE:
      return 'PROVIDERS_ESCALATION_CONFIG_WRITE';
    case Permission.PROVIDERS_ESCALATION_CONFIG_READ:
      return 'PROVIDERS_ESCALATION_CONFIG_READ';
    case Permission.PROVIDERS_HTTP_INTERACTIONS_READ:
      return 'PROVIDERS_HTTP_INTERACTIONS_READ';
    case Permission.CONVERSATIONS_ADMIN_READONLY:
      return 'CONVERSATIONS_ADMIN_READONLY';
    case Permission.AUDIT_READ:
      return 'AUDIT_READ';
    case Permission.WORKFLOWS_READ:
      return 'WORKFLOWS_READ';
    case Permission.BASE:
      return 'BASE';
    case Permission.DEVELOPER:
      return 'DEVELOPER';
    case Permission.DEVELOPER_ADVANCED:
      return 'DEVELOPER_ADVANCED';
    case Permission.CONFIG_READONLY:
      return 'CONFIG_READONLY';
    case Permission.PROVIDERS_CONFIG:
      return 'PROVIDERS_CONFIG';
    case Permission.SUPERADMIN:
      return 'SUPERADMIN';
    case Permission.ADMIN_READONLY:
      return 'ADMIN_READONLY';
    case Permission.APP_VERSION:
      return 'APP_VERSION';
    case Permission.BANNER_ITEMS:
      return 'BANNER_ITEMS';
    case Permission.GROWTH_RATES_WRITE:
      return 'GROWTH_RATES_WRITE';
    case Permission.INVALID_PAYMENT_RESOLUTION:
      return 'INVALID_PAYMENT_RESOLUTION';
    case Permission.KYB_ADMIN_WRITE:
      return 'KYB_ADMIN_WRITE';
    case Permission.PSP_PAYOPS:
      return 'PSP_PAYOPS';
    case Permission.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}
