// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               unknown
// source: api/notifications/proto/notifications.proto

/* eslint-disable */
import { grpc } from '@improbable-eng/grpc-web';
import { BrowserHeaders } from 'browser-headers';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'public';

/**
 * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
 * buf:lint:ignore COMMENT_ENUM
 */
export enum NotificationType {
  NOTIFICATION_TYPE_PUSH_FCM = 0,
  NOTIFICATION_TYPE_PUSH_APNS = 1,
  UNRECOGNIZED = -1,
}

export function notificationTypeFromJSON(object: any): NotificationType {
  switch (object) {
    case 0:
    case 'NOTIFICATION_TYPE_PUSH_FCM':
      return NotificationType.NOTIFICATION_TYPE_PUSH_FCM;
    case 1:
    case 'NOTIFICATION_TYPE_PUSH_APNS':
      return NotificationType.NOTIFICATION_TYPE_PUSH_APNS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return NotificationType.UNRECOGNIZED;
  }
}

export function notificationTypeToJSON(object: NotificationType): string {
  switch (object) {
    case NotificationType.NOTIFICATION_TYPE_PUSH_FCM:
      return 'NOTIFICATION_TYPE_PUSH_FCM';
    case NotificationType.NOTIFICATION_TYPE_PUSH_APNS:
      return 'NOTIFICATION_TYPE_PUSH_APNS';
    case NotificationType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface RegisterTargetRequest {
  type: NotificationType;
  data: string;
}

export interface RegisterTargetReply {
  targetId: string;
}

export interface DeregisterTargetRequest {
  targetId: string;
}

export interface DeregisterTargetReply {}

/** preferences */
export interface NotificationPreference {
  push: boolean;
  email: boolean;
}

export interface NotificationPreferences {
  preferences: { [key: string]: NotificationPreference };
}

export interface NotificationPreferences_PreferencesEntry {
  key: string;
  value: NotificationPreference | undefined;
}

export interface SetNotificationPreferencesRequest {
  preferences: NotificationPreferences | undefined;
}

export interface SetNotificationPreferencesReply {}

export interface GetNotificationPreferencesRequest {}

export interface GetNotificationPreferencesReply {
  preferences: NotificationPreferences | undefined;
}

export interface WebhookConfiguration {
  id: string;
  url: string;
  signingKey: string;
  onPayoutStateUpdated: boolean;
}

/**
 * if the ID is provided will attempt to update
 * otherwise will attempt to create a new one.
 */
export interface CreateOrUpdateWebhookConfigurationRequest {
  webhookConfiguration: WebhookConfiguration | undefined;
}

export interface CreateOrUpdateWebhookConfigurationReply {
  webhookConfiguration: WebhookConfiguration | undefined;
}

export interface ListWebhookConfigurationsRequest {}

export interface ListWebhookConfigurationsReply {
  webhookConfigurations: WebhookConfiguration[];
}

function createBaseRegisterTargetRequest(): RegisterTargetRequest {
  return { type: 0, data: '' };
}

export const RegisterTargetRequest = {
  encode(
    message: RegisterTargetRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.data !== '') {
      writer.uint32(18).string(message.data);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RegisterTargetRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterTargetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterTargetRequest {
    return {
      type: isSet(object.type) ? notificationTypeFromJSON(object.type) : 0,
      data: isSet(object.data) ? globalThis.String(object.data) : '',
    };
  },

  toJSON(message: RegisterTargetRequest): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = notificationTypeToJSON(message.type);
    }
    if (message.data !== '') {
      obj.data = message.data;
    }
    return obj;
  },

  create(base?: DeepPartial<RegisterTargetRequest>): RegisterTargetRequest {
    return RegisterTargetRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<RegisterTargetRequest>,
  ): RegisterTargetRequest {
    const message = createBaseRegisterTargetRequest();
    message.type = object.type ?? 0;
    message.data = object.data ?? '';
    return message;
  },
};

function createBaseRegisterTargetReply(): RegisterTargetReply {
  return { targetId: '' };
}

export const RegisterTargetReply = {
  encode(
    message: RegisterTargetReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.targetId !== '') {
      writer.uint32(10).string(message.targetId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterTargetReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterTargetReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.targetId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterTargetReply {
    return {
      targetId: isSet(object.targetId)
        ? globalThis.String(object.targetId)
        : '',
    };
  },

  toJSON(message: RegisterTargetReply): unknown {
    const obj: any = {};
    if (message.targetId !== '') {
      obj.targetId = message.targetId;
    }
    return obj;
  },

  create(base?: DeepPartial<RegisterTargetReply>): RegisterTargetReply {
    return RegisterTargetReply.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RegisterTargetReply>): RegisterTargetReply {
    const message = createBaseRegisterTargetReply();
    message.targetId = object.targetId ?? '';
    return message;
  },
};

function createBaseDeregisterTargetRequest(): DeregisterTargetRequest {
  return { targetId: '' };
}

export const DeregisterTargetRequest = {
  encode(
    message: DeregisterTargetRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.targetId !== '') {
      writer.uint32(10).string(message.targetId);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeregisterTargetRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeregisterTargetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.targetId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeregisterTargetRequest {
    return {
      targetId: isSet(object.targetId)
        ? globalThis.String(object.targetId)
        : '',
    };
  },

  toJSON(message: DeregisterTargetRequest): unknown {
    const obj: any = {};
    if (message.targetId !== '') {
      obj.targetId = message.targetId;
    }
    return obj;
  },

  create(base?: DeepPartial<DeregisterTargetRequest>): DeregisterTargetRequest {
    return DeregisterTargetRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<DeregisterTargetRequest>,
  ): DeregisterTargetRequest {
    const message = createBaseDeregisterTargetRequest();
    message.targetId = object.targetId ?? '';
    return message;
  },
};

function createBaseDeregisterTargetReply(): DeregisterTargetReply {
  return {};
}

export const DeregisterTargetReply = {
  encode(
    _: DeregisterTargetReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeregisterTargetReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeregisterTargetReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeregisterTargetReply {
    return {};
  },

  toJSON(_: DeregisterTargetReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(base?: DeepPartial<DeregisterTargetReply>): DeregisterTargetReply {
    return DeregisterTargetReply.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeregisterTargetReply>): DeregisterTargetReply {
    const message = createBaseDeregisterTargetReply();
    return message;
  },
};

function createBaseNotificationPreference(): NotificationPreference {
  return { push: false, email: false };
}

export const NotificationPreference = {
  encode(
    message: NotificationPreference,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.push !== false) {
      writer.uint32(8).bool(message.push);
    }
    if (message.email !== false) {
      writer.uint32(16).bool(message.email);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationPreference {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationPreference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.push = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.email = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationPreference {
    return {
      push: isSet(object.push) ? globalThis.Boolean(object.push) : false,
      email: isSet(object.email) ? globalThis.Boolean(object.email) : false,
    };
  },

  toJSON(message: NotificationPreference): unknown {
    const obj: any = {};
    if (message.push !== false) {
      obj.push = message.push;
    }
    if (message.email !== false) {
      obj.email = message.email;
    }
    return obj;
  },

  create(base?: DeepPartial<NotificationPreference>): NotificationPreference {
    return NotificationPreference.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<NotificationPreference>,
  ): NotificationPreference {
    const message = createBaseNotificationPreference();
    message.push = object.push ?? false;
    message.email = object.email ?? false;
    return message;
  },
};

function createBaseNotificationPreferences(): NotificationPreferences {
  return { preferences: {} };
}

export const NotificationPreferences = {
  encode(
    message: NotificationPreferences,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.preferences).forEach(([key, value]) => {
      NotificationPreferences_PreferencesEntry.encode(
        { key: key as any, value },
        writer.uint32(10).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationPreferences {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationPreferences();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = NotificationPreferences_PreferencesEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry1.value !== undefined) {
            message.preferences[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationPreferences {
    return {
      preferences: isObject(object.preferences)
        ? Object.entries(object.preferences).reduce<{
            [key: string]: NotificationPreference;
          }>((acc, [key, value]) => {
            acc[key] = NotificationPreference.fromJSON(value);
            return acc;
          }, {})
        : {},
    };
  },

  toJSON(message: NotificationPreferences): unknown {
    const obj: any = {};
    if (message.preferences) {
      const entries = Object.entries(message.preferences);
      if (entries.length > 0) {
        obj.preferences = {};
        entries.forEach(([k, v]) => {
          obj.preferences[k] = NotificationPreference.toJSON(v);
        });
      }
    }
    return obj;
  },

  create(base?: DeepPartial<NotificationPreferences>): NotificationPreferences {
    return NotificationPreferences.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<NotificationPreferences>,
  ): NotificationPreferences {
    const message = createBaseNotificationPreferences();
    message.preferences = Object.entries(object.preferences ?? {}).reduce<{
      [key: string]: NotificationPreference;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = NotificationPreference.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseNotificationPreferences_PreferencesEntry(): NotificationPreferences_PreferencesEntry {
  return { key: '', value: undefined };
}

export const NotificationPreferences_PreferencesEntry = {
  encode(
    message: NotificationPreferences_PreferencesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      NotificationPreference.encode(
        message.value,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotificationPreferences_PreferencesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotificationPreferences_PreferencesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = NotificationPreference.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NotificationPreferences_PreferencesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : '',
      value: isSet(object.value)
        ? NotificationPreference.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(message: NotificationPreferences_PreferencesEntry): unknown {
    const obj: any = {};
    if (message.key !== '') {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = NotificationPreference.toJSON(message.value);
    }
    return obj;
  },

  create(
    base?: DeepPartial<NotificationPreferences_PreferencesEntry>,
  ): NotificationPreferences_PreferencesEntry {
    return NotificationPreferences_PreferencesEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<NotificationPreferences_PreferencesEntry>,
  ): NotificationPreferences_PreferencesEntry {
    const message = createBaseNotificationPreferences_PreferencesEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? NotificationPreference.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseSetNotificationPreferencesRequest(): SetNotificationPreferencesRequest {
  return { preferences: undefined };
}

export const SetNotificationPreferencesRequest = {
  encode(
    message: SetNotificationPreferencesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.preferences !== undefined) {
      NotificationPreferences.encode(
        message.preferences,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetNotificationPreferencesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetNotificationPreferencesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.preferences = NotificationPreferences.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetNotificationPreferencesRequest {
    return {
      preferences: isSet(object.preferences)
        ? NotificationPreferences.fromJSON(object.preferences)
        : undefined,
    };
  },

  toJSON(message: SetNotificationPreferencesRequest): unknown {
    const obj: any = {};
    if (message.preferences !== undefined) {
      obj.preferences = NotificationPreferences.toJSON(message.preferences);
    }
    return obj;
  },

  create(
    base?: DeepPartial<SetNotificationPreferencesRequest>,
  ): SetNotificationPreferencesRequest {
    return SetNotificationPreferencesRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<SetNotificationPreferencesRequest>,
  ): SetNotificationPreferencesRequest {
    const message = createBaseSetNotificationPreferencesRequest();
    message.preferences =
      object.preferences !== undefined && object.preferences !== null
        ? NotificationPreferences.fromPartial(object.preferences)
        : undefined;
    return message;
  },
};

function createBaseSetNotificationPreferencesReply(): SetNotificationPreferencesReply {
  return {};
}

export const SetNotificationPreferencesReply = {
  encode(
    _: SetNotificationPreferencesReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetNotificationPreferencesReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetNotificationPreferencesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetNotificationPreferencesReply {
    return {};
  },

  toJSON(_: SetNotificationPreferencesReply): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<SetNotificationPreferencesReply>,
  ): SetNotificationPreferencesReply {
    return SetNotificationPreferencesReply.fromPartial(base ?? {});
  },
  fromPartial(
    _: DeepPartial<SetNotificationPreferencesReply>,
  ): SetNotificationPreferencesReply {
    const message = createBaseSetNotificationPreferencesReply();
    return message;
  },
};

function createBaseGetNotificationPreferencesRequest(): GetNotificationPreferencesRequest {
  return {};
}

export const GetNotificationPreferencesRequest = {
  encode(
    _: GetNotificationPreferencesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetNotificationPreferencesRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNotificationPreferencesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetNotificationPreferencesRequest {
    return {};
  },

  toJSON(_: GetNotificationPreferencesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<GetNotificationPreferencesRequest>,
  ): GetNotificationPreferencesRequest {
    return GetNotificationPreferencesRequest.fromPartial(base ?? {});
  },
  fromPartial(
    _: DeepPartial<GetNotificationPreferencesRequest>,
  ): GetNotificationPreferencesRequest {
    const message = createBaseGetNotificationPreferencesRequest();
    return message;
  },
};

function createBaseGetNotificationPreferencesReply(): GetNotificationPreferencesReply {
  return { preferences: undefined };
}

export const GetNotificationPreferencesReply = {
  encode(
    message: GetNotificationPreferencesReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.preferences !== undefined) {
      NotificationPreferences.encode(
        message.preferences,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetNotificationPreferencesReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNotificationPreferencesReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.preferences = NotificationPreferences.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNotificationPreferencesReply {
    return {
      preferences: isSet(object.preferences)
        ? NotificationPreferences.fromJSON(object.preferences)
        : undefined,
    };
  },

  toJSON(message: GetNotificationPreferencesReply): unknown {
    const obj: any = {};
    if (message.preferences !== undefined) {
      obj.preferences = NotificationPreferences.toJSON(message.preferences);
    }
    return obj;
  },

  create(
    base?: DeepPartial<GetNotificationPreferencesReply>,
  ): GetNotificationPreferencesReply {
    return GetNotificationPreferencesReply.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<GetNotificationPreferencesReply>,
  ): GetNotificationPreferencesReply {
    const message = createBaseGetNotificationPreferencesReply();
    message.preferences =
      object.preferences !== undefined && object.preferences !== null
        ? NotificationPreferences.fromPartial(object.preferences)
        : undefined;
    return message;
  },
};

function createBaseWebhookConfiguration(): WebhookConfiguration {
  return { id: '', url: '', signingKey: '', onPayoutStateUpdated: false };
}

export const WebhookConfiguration = {
  encode(
    message: WebhookConfiguration,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.url !== '') {
      writer.uint32(18).string(message.url);
    }
    if (message.signingKey !== '') {
      writer.uint32(26).string(message.signingKey);
    }
    if (message.onPayoutStateUpdated !== false) {
      writer.uint32(32).bool(message.onPayoutStateUpdated);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): WebhookConfiguration {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWebhookConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signingKey = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.onPayoutStateUpdated = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WebhookConfiguration {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : '',
      url: isSet(object.url) ? globalThis.String(object.url) : '',
      signingKey: isSet(object.signingKey)
        ? globalThis.String(object.signingKey)
        : '',
      onPayoutStateUpdated: isSet(object.onPayoutStateUpdated)
        ? globalThis.Boolean(object.onPayoutStateUpdated)
        : false,
    };
  },

  toJSON(message: WebhookConfiguration): unknown {
    const obj: any = {};
    if (message.id !== '') {
      obj.id = message.id;
    }
    if (message.url !== '') {
      obj.url = message.url;
    }
    if (message.signingKey !== '') {
      obj.signingKey = message.signingKey;
    }
    if (message.onPayoutStateUpdated !== false) {
      obj.onPayoutStateUpdated = message.onPayoutStateUpdated;
    }
    return obj;
  },

  create(base?: DeepPartial<WebhookConfiguration>): WebhookConfiguration {
    return WebhookConfiguration.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<WebhookConfiguration>): WebhookConfiguration {
    const message = createBaseWebhookConfiguration();
    message.id = object.id ?? '';
    message.url = object.url ?? '';
    message.signingKey = object.signingKey ?? '';
    message.onPayoutStateUpdated = object.onPayoutStateUpdated ?? false;
    return message;
  },
};

function createBaseCreateOrUpdateWebhookConfigurationRequest(): CreateOrUpdateWebhookConfigurationRequest {
  return { webhookConfiguration: undefined };
}

export const CreateOrUpdateWebhookConfigurationRequest = {
  encode(
    message: CreateOrUpdateWebhookConfigurationRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.webhookConfiguration !== undefined) {
      WebhookConfiguration.encode(
        message.webhookConfiguration,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateOrUpdateWebhookConfigurationRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateWebhookConfigurationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhookConfiguration = WebhookConfiguration.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateWebhookConfigurationRequest {
    return {
      webhookConfiguration: isSet(object.webhookConfiguration)
        ? WebhookConfiguration.fromJSON(object.webhookConfiguration)
        : undefined,
    };
  },

  toJSON(message: CreateOrUpdateWebhookConfigurationRequest): unknown {
    const obj: any = {};
    if (message.webhookConfiguration !== undefined) {
      obj.webhookConfiguration = WebhookConfiguration.toJSON(
        message.webhookConfiguration,
      );
    }
    return obj;
  },

  create(
    base?: DeepPartial<CreateOrUpdateWebhookConfigurationRequest>,
  ): CreateOrUpdateWebhookConfigurationRequest {
    return CreateOrUpdateWebhookConfigurationRequest.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<CreateOrUpdateWebhookConfigurationRequest>,
  ): CreateOrUpdateWebhookConfigurationRequest {
    const message = createBaseCreateOrUpdateWebhookConfigurationRequest();
    message.webhookConfiguration =
      object.webhookConfiguration !== undefined &&
      object.webhookConfiguration !== null
        ? WebhookConfiguration.fromPartial(object.webhookConfiguration)
        : undefined;
    return message;
  },
};

function createBaseCreateOrUpdateWebhookConfigurationReply(): CreateOrUpdateWebhookConfigurationReply {
  return { webhookConfiguration: undefined };
}

export const CreateOrUpdateWebhookConfigurationReply = {
  encode(
    message: CreateOrUpdateWebhookConfigurationReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.webhookConfiguration !== undefined) {
      WebhookConfiguration.encode(
        message.webhookConfiguration,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateOrUpdateWebhookConfigurationReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateWebhookConfigurationReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhookConfiguration = WebhookConfiguration.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateWebhookConfigurationReply {
    return {
      webhookConfiguration: isSet(object.webhookConfiguration)
        ? WebhookConfiguration.fromJSON(object.webhookConfiguration)
        : undefined,
    };
  },

  toJSON(message: CreateOrUpdateWebhookConfigurationReply): unknown {
    const obj: any = {};
    if (message.webhookConfiguration !== undefined) {
      obj.webhookConfiguration = WebhookConfiguration.toJSON(
        message.webhookConfiguration,
      );
    }
    return obj;
  },

  create(
    base?: DeepPartial<CreateOrUpdateWebhookConfigurationReply>,
  ): CreateOrUpdateWebhookConfigurationReply {
    return CreateOrUpdateWebhookConfigurationReply.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<CreateOrUpdateWebhookConfigurationReply>,
  ): CreateOrUpdateWebhookConfigurationReply {
    const message = createBaseCreateOrUpdateWebhookConfigurationReply();
    message.webhookConfiguration =
      object.webhookConfiguration !== undefined &&
      object.webhookConfiguration !== null
        ? WebhookConfiguration.fromPartial(object.webhookConfiguration)
        : undefined;
    return message;
  },
};

function createBaseListWebhookConfigurationsRequest(): ListWebhookConfigurationsRequest {
  return {};
}

export const ListWebhookConfigurationsRequest = {
  encode(
    _: ListWebhookConfigurationsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListWebhookConfigurationsRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListWebhookConfigurationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListWebhookConfigurationsRequest {
    return {};
  },

  toJSON(_: ListWebhookConfigurationsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create(
    base?: DeepPartial<ListWebhookConfigurationsRequest>,
  ): ListWebhookConfigurationsRequest {
    return ListWebhookConfigurationsRequest.fromPartial(base ?? {});
  },
  fromPartial(
    _: DeepPartial<ListWebhookConfigurationsRequest>,
  ): ListWebhookConfigurationsRequest {
    const message = createBaseListWebhookConfigurationsRequest();
    return message;
  },
};

function createBaseListWebhookConfigurationsReply(): ListWebhookConfigurationsReply {
  return { webhookConfigurations: [] };
}

export const ListWebhookConfigurationsReply = {
  encode(
    message: ListWebhookConfigurationsReply,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.webhookConfigurations) {
      WebhookConfiguration.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ListWebhookConfigurationsReply {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListWebhookConfigurationsReply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhookConfigurations.push(
            WebhookConfiguration.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListWebhookConfigurationsReply {
    return {
      webhookConfigurations: globalThis.Array.isArray(
        object?.webhookConfigurations,
      )
        ? object.webhookConfigurations.map((e: any) =>
            WebhookConfiguration.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ListWebhookConfigurationsReply): unknown {
    const obj: any = {};
    if (message.webhookConfigurations?.length) {
      obj.webhookConfigurations = message.webhookConfigurations.map((e) =>
        WebhookConfiguration.toJSON(e),
      );
    }
    return obj;
  },

  create(
    base?: DeepPartial<ListWebhookConfigurationsReply>,
  ): ListWebhookConfigurationsReply {
    return ListWebhookConfigurationsReply.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListWebhookConfigurationsReply>,
  ): ListWebhookConfigurationsReply {
    const message = createBaseListWebhookConfigurationsReply();
    message.webhookConfigurations =
      object.webhookConfigurations?.map((e) =>
        WebhookConfiguration.fromPartial(e),
      ) || [];
    return message;
  },
};

export interface Notifications {
  /** buf:lint:ignore COMMENT_RPC */
  RegisterTarget(
    request: DeepPartial<RegisterTargetRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RegisterTargetReply>;
  /** buf:lint:ignore COMMENT_RPC */
  DeregisterTarget(
    request: DeepPartial<DeregisterTargetRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeregisterTargetReply>;
  /** buf:lint:ignore COMMENT_RPC */
  SetNotificationPreferences(
    request: DeepPartial<SetNotificationPreferencesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetNotificationPreferencesReply>;
  /** buf:lint:ignore COMMENT_RPC */
  GetNotificationPreferences(
    request: DeepPartial<GetNotificationPreferencesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetNotificationPreferencesReply>;
  /** buf:lint:ignore COMMENT_RPC */
  ListWebhookConfigurations(
    request: DeepPartial<ListWebhookConfigurationsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListWebhookConfigurationsReply>;
  /** buf:lint:ignore COMMENT_RPC */
  CreateOrUpdateWebhookConfiguration(
    request: DeepPartial<CreateOrUpdateWebhookConfigurationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateOrUpdateWebhookConfigurationReply>;
}

export class NotificationsClientImpl implements Notifications {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.RegisterTarget = this.RegisterTarget.bind(this);
    this.DeregisterTarget = this.DeregisterTarget.bind(this);
    this.SetNotificationPreferences =
      this.SetNotificationPreferences.bind(this);
    this.GetNotificationPreferences =
      this.GetNotificationPreferences.bind(this);
    this.ListWebhookConfigurations = this.ListWebhookConfigurations.bind(this);
    this.CreateOrUpdateWebhookConfiguration =
      this.CreateOrUpdateWebhookConfiguration.bind(this);
  }

  RegisterTarget(
    request: DeepPartial<RegisterTargetRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RegisterTargetReply> {
    return this.rpc.unary(
      NotificationsRegisterTargetDesc,
      RegisterTargetRequest.fromPartial(request),
      metadata,
    );
  }

  DeregisterTarget(
    request: DeepPartial<DeregisterTargetRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeregisterTargetReply> {
    return this.rpc.unary(
      NotificationsDeregisterTargetDesc,
      DeregisterTargetRequest.fromPartial(request),
      metadata,
    );
  }

  SetNotificationPreferences(
    request: DeepPartial<SetNotificationPreferencesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SetNotificationPreferencesReply> {
    return this.rpc.unary(
      NotificationsSetNotificationPreferencesDesc,
      SetNotificationPreferencesRequest.fromPartial(request),
      metadata,
    );
  }

  GetNotificationPreferences(
    request: DeepPartial<GetNotificationPreferencesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetNotificationPreferencesReply> {
    return this.rpc.unary(
      NotificationsGetNotificationPreferencesDesc,
      GetNotificationPreferencesRequest.fromPartial(request),
      metadata,
    );
  }

  ListWebhookConfigurations(
    request: DeepPartial<ListWebhookConfigurationsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListWebhookConfigurationsReply> {
    return this.rpc.unary(
      NotificationsListWebhookConfigurationsDesc,
      ListWebhookConfigurationsRequest.fromPartial(request),
      metadata,
    );
  }

  CreateOrUpdateWebhookConfiguration(
    request: DeepPartial<CreateOrUpdateWebhookConfigurationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateOrUpdateWebhookConfigurationReply> {
    return this.rpc.unary(
      NotificationsCreateOrUpdateWebhookConfigurationDesc,
      CreateOrUpdateWebhookConfigurationRequest.fromPartial(request),
      metadata,
    );
  }
}

export const NotificationsDesc = { serviceName: 'public.Notifications' };

export const NotificationsRegisterTargetDesc: UnaryMethodDefinitionish = {
  methodName: 'RegisterTarget',
  service: NotificationsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RegisterTargetRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RegisterTargetReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const NotificationsDeregisterTargetDesc: UnaryMethodDefinitionish = {
  methodName: 'DeregisterTarget',
  service: NotificationsDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeregisterTargetRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DeregisterTargetReply.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const NotificationsSetNotificationPreferencesDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetNotificationPreferences',
    service: NotificationsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetNotificationPreferencesRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = SetNotificationPreferencesReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const NotificationsGetNotificationPreferencesDesc: UnaryMethodDefinitionish =
  {
    methodName: 'GetNotificationPreferences',
    service: NotificationsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return GetNotificationPreferencesRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = GetNotificationPreferencesReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const NotificationsListWebhookConfigurationsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ListWebhookConfigurations',
    service: NotificationsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ListWebhookConfigurationsRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = ListWebhookConfigurationsReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

export const NotificationsCreateOrUpdateWebhookConfigurationDesc: UnaryMethodDefinitionish =
  {
    methodName: 'CreateOrUpdateWebhookConfiguration',
    service: NotificationsDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return CreateOrUpdateWebhookConfigurationRequest.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        const value = CreateOrUpdateWebhookConfigurationReply.decode(data);
        return {
          ...value,
          toObject() {
            return value;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined
          ? { transport: this.options.transport }
          : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers,
            );
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends globalThis.Array<infer U>
  ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(
    message: string,
    public code: grpc.Code,
    public metadata: grpc.Metadata,
  ) {
    super(message);
  }
}
