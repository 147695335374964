/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../../google/protobuf/timestamp';
import { LastUsedPaymentMethod } from '../collections/types';
import { Money } from '../fx/types';
import {
  Name,
  PublicStatus,
  publicStatusFromJSON,
  publicStatusToJSON,
} from '../kyc/types';
import {
  Permission,
  permissionFromJSON,
  permissionToJSON,
} from '../permissions/permissions';
import { TimeRange } from '../utils/types';
import { ColourStyling, Icon } from '../utils/visuals';

export const protobufPackage = 'users';

/** buf:lint:ignore COMMENT_ENUM */
export enum AuthProvider {
  AUTH_PROVIDER_INVALID = 0,
  AUTH_PROVIDER_FIREBASE = 1,
  AUTH_PROVIDER_AUTH0 = 2,
  UNRECOGNIZED = -1,
}

export function authProviderFromJSON(object: any): AuthProvider {
  switch (object) {
    case 0:
    case 'AUTH_PROVIDER_INVALID':
      return AuthProvider.AUTH_PROVIDER_INVALID;
    case 1:
    case 'AUTH_PROVIDER_FIREBASE':
      return AuthProvider.AUTH_PROVIDER_FIREBASE;
    case 2:
    case 'AUTH_PROVIDER_AUTH0':
      return AuthProvider.AUTH_PROVIDER_AUTH0;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AuthProvider.UNRECOGNIZED;
  }
}

export function authProviderToJSON(object: AuthProvider): string {
  switch (object) {
    case AuthProvider.AUTH_PROVIDER_INVALID:
      return 'AUTH_PROVIDER_INVALID';
    case AuthProvider.AUTH_PROVIDER_FIREBASE:
      return 'AUTH_PROVIDER_FIREBASE';
    case AuthProvider.AUTH_PROVIDER_AUTH0:
      return 'AUTH_PROVIDER_AUTH0';
    case AuthProvider.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** FeatureType describes how the feature has been set */
export enum FeatureType {
  FEATURE_TYPE_INVALID = 0,
  FEATURE_TYPE_DEFAULT = 1,
  FEATURE_TYPE_MANUAL = 2,
  FEATURE_TYPE_PROFILE = 3,
  FEATURE_TYPE_ACCOUNT_MEMBER = 4,
  UNRECOGNIZED = -1,
}

export function featureTypeFromJSON(object: any): FeatureType {
  switch (object) {
    case 0:
    case 'FEATURE_TYPE_INVALID':
      return FeatureType.FEATURE_TYPE_INVALID;
    case 1:
    case 'FEATURE_TYPE_DEFAULT':
      return FeatureType.FEATURE_TYPE_DEFAULT;
    case 2:
    case 'FEATURE_TYPE_MANUAL':
      return FeatureType.FEATURE_TYPE_MANUAL;
    case 3:
    case 'FEATURE_TYPE_PROFILE':
      return FeatureType.FEATURE_TYPE_PROFILE;
    case 4:
    case 'FEATURE_TYPE_ACCOUNT_MEMBER':
      return FeatureType.FEATURE_TYPE_ACCOUNT_MEMBER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FeatureType.UNRECOGNIZED;
  }
}

export function featureTypeToJSON(object: FeatureType): string {
  switch (object) {
    case FeatureType.FEATURE_TYPE_INVALID:
      return 'FEATURE_TYPE_INVALID';
    case FeatureType.FEATURE_TYPE_DEFAULT:
      return 'FEATURE_TYPE_DEFAULT';
    case FeatureType.FEATURE_TYPE_MANUAL:
      return 'FEATURE_TYPE_MANUAL';
    case FeatureType.FEATURE_TYPE_PROFILE:
      return 'FEATURE_TYPE_PROFILE';
    case FeatureType.FEATURE_TYPE_ACCOUNT_MEMBER:
      return 'FEATURE_TYPE_ACCOUNT_MEMBER';
    case FeatureType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** FeatureState is the state of a feature */
export enum FeatureState {
  FEATURE_STATE_INVALID = 0,
  FEATURE_STATE_HIDDEN = 1,
  FEATURE_STATE_EDITABLE = 2,
  FEATURE_STATE_READ_ONLY = 3,
  UNRECOGNIZED = -1,
}

export function featureStateFromJSON(object: any): FeatureState {
  switch (object) {
    case 0:
    case 'FEATURE_STATE_INVALID':
      return FeatureState.FEATURE_STATE_INVALID;
    case 1:
    case 'FEATURE_STATE_HIDDEN':
      return FeatureState.FEATURE_STATE_HIDDEN;
    case 2:
    case 'FEATURE_STATE_EDITABLE':
      return FeatureState.FEATURE_STATE_EDITABLE;
    case 3:
    case 'FEATURE_STATE_READ_ONLY':
      return FeatureState.FEATURE_STATE_READ_ONLY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FeatureState.UNRECOGNIZED;
  }
}

export function featureStateToJSON(object: FeatureState): string {
  switch (object) {
    case FeatureState.FEATURE_STATE_INVALID:
      return 'FEATURE_STATE_INVALID';
    case FeatureState.FEATURE_STATE_HIDDEN:
      return 'FEATURE_STATE_HIDDEN';
    case FeatureState.FEATURE_STATE_EDITABLE:
      return 'FEATURE_STATE_EDITABLE';
    case FeatureState.FEATURE_STATE_READ_ONLY:
      return 'FEATURE_STATE_READ_ONLY';
    case FeatureState.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum AccountType {
  ACCOUNT_TYPE_INVALID = 0,
  ACCOUNT_TYPE_PERSONAL = 1,
  ACCOUNT_TYPE_BUSINESS = 2,
  ACCOUNT_TYPE_PSP = 3,
  UNRECOGNIZED = -1,
}

export function accountTypeFromJSON(object: any): AccountType {
  switch (object) {
    case 0:
    case 'ACCOUNT_TYPE_INVALID':
      return AccountType.ACCOUNT_TYPE_INVALID;
    case 1:
    case 'ACCOUNT_TYPE_PERSONAL':
      return AccountType.ACCOUNT_TYPE_PERSONAL;
    case 2:
    case 'ACCOUNT_TYPE_BUSINESS':
      return AccountType.ACCOUNT_TYPE_BUSINESS;
    case 3:
    case 'ACCOUNT_TYPE_PSP':
      return AccountType.ACCOUNT_TYPE_PSP;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AccountType.UNRECOGNIZED;
  }
}

export function accountTypeToJSON(object: AccountType): string {
  switch (object) {
    case AccountType.ACCOUNT_TYPE_INVALID:
      return 'ACCOUNT_TYPE_INVALID';
    case AccountType.ACCOUNT_TYPE_PERSONAL:
      return 'ACCOUNT_TYPE_PERSONAL';
    case AccountType.ACCOUNT_TYPE_BUSINESS:
      return 'ACCOUNT_TYPE_BUSINESS';
    case AccountType.ACCOUNT_TYPE_PSP:
      return 'ACCOUNT_TYPE_PSP';
    case AccountType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
 * buf:lint:ignore COMMENT_ENUM
 */
export enum ContactDetailType {
  CONTACT_DETAIL_TYPE_EMAIL = 0,
  CONTACT_DETAIL_TYPE_PHONE_NUMBER = 1,
  UNRECOGNIZED = -1,
}

export function contactDetailTypeFromJSON(object: any): ContactDetailType {
  switch (object) {
    case 0:
    case 'CONTACT_DETAIL_TYPE_EMAIL':
      return ContactDetailType.CONTACT_DETAIL_TYPE_EMAIL;
    case 1:
    case 'CONTACT_DETAIL_TYPE_PHONE_NUMBER':
      return ContactDetailType.CONTACT_DETAIL_TYPE_PHONE_NUMBER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ContactDetailType.UNRECOGNIZED;
  }
}

export function contactDetailTypeToJSON(object: ContactDetailType): string {
  switch (object) {
    case ContactDetailType.CONTACT_DETAIL_TYPE_EMAIL:
      return 'CONTACT_DETAIL_TYPE_EMAIL';
    case ContactDetailType.CONTACT_DETAIL_TYPE_PHONE_NUMBER:
      return 'CONTACT_DETAIL_TYPE_PHONE_NUMBER';
    case ContactDetailType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum BannerAction {
  BANNER_ACTION_INVALID = 0,
  BANNER_ACTION_KYC = 1,
  BANNER_ACTION_VERIFY_EMAIL_ADDRESS = 2,
  BANNER_ACTION_CONTACT_SUPPORT = 3,
  BANNER_ACTION_OPEN_URL = 4,
  BANNER_ACTION_ASK_PERMISSION = 5,
  BANNER_ACTION_NO_ACTION = 6,
  BANNER_ACTION_INVITATION_CODE = 7,
  BANNER_ACTION_SERVICE_STATUS = 8,
  BANNER_ACTION_START_P2P_ONBOARDING = 9,
  UNRECOGNIZED = -1,
}

export function bannerActionFromJSON(object: any): BannerAction {
  switch (object) {
    case 0:
    case 'BANNER_ACTION_INVALID':
      return BannerAction.BANNER_ACTION_INVALID;
    case 1:
    case 'BANNER_ACTION_KYC':
      return BannerAction.BANNER_ACTION_KYC;
    case 2:
    case 'BANNER_ACTION_VERIFY_EMAIL_ADDRESS':
      return BannerAction.BANNER_ACTION_VERIFY_EMAIL_ADDRESS;
    case 3:
    case 'BANNER_ACTION_CONTACT_SUPPORT':
      return BannerAction.BANNER_ACTION_CONTACT_SUPPORT;
    case 4:
    case 'BANNER_ACTION_OPEN_URL':
      return BannerAction.BANNER_ACTION_OPEN_URL;
    case 5:
    case 'BANNER_ACTION_ASK_PERMISSION':
      return BannerAction.BANNER_ACTION_ASK_PERMISSION;
    case 6:
    case 'BANNER_ACTION_NO_ACTION':
      return BannerAction.BANNER_ACTION_NO_ACTION;
    case 7:
    case 'BANNER_ACTION_INVITATION_CODE':
      return BannerAction.BANNER_ACTION_INVITATION_CODE;
    case 8:
    case 'BANNER_ACTION_SERVICE_STATUS':
      return BannerAction.BANNER_ACTION_SERVICE_STATUS;
    case 9:
    case 'BANNER_ACTION_START_P2P_ONBOARDING':
      return BannerAction.BANNER_ACTION_START_P2P_ONBOARDING;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return BannerAction.UNRECOGNIZED;
  }
}

export function bannerActionToJSON(object: BannerAction): string {
  switch (object) {
    case BannerAction.BANNER_ACTION_INVALID:
      return 'BANNER_ACTION_INVALID';
    case BannerAction.BANNER_ACTION_KYC:
      return 'BANNER_ACTION_KYC';
    case BannerAction.BANNER_ACTION_VERIFY_EMAIL_ADDRESS:
      return 'BANNER_ACTION_VERIFY_EMAIL_ADDRESS';
    case BannerAction.BANNER_ACTION_CONTACT_SUPPORT:
      return 'BANNER_ACTION_CONTACT_SUPPORT';
    case BannerAction.BANNER_ACTION_OPEN_URL:
      return 'BANNER_ACTION_OPEN_URL';
    case BannerAction.BANNER_ACTION_ASK_PERMISSION:
      return 'BANNER_ACTION_ASK_PERMISSION';
    case BannerAction.BANNER_ACTION_NO_ACTION:
      return 'BANNER_ACTION_NO_ACTION';
    case BannerAction.BANNER_ACTION_INVITATION_CODE:
      return 'BANNER_ACTION_INVITATION_CODE';
    case BannerAction.BANNER_ACTION_SERVICE_STATUS:
      return 'BANNER_ACTION_SERVICE_STATUS';
    case BannerAction.BANNER_ACTION_START_P2P_ONBOARDING:
      return 'BANNER_ACTION_START_P2P_ONBOARDING';
    case BannerAction.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum Status {
  STATUS_INVALID = 0,
  STATUS_ACTIVE = 1,
  STATUS_DELETED = 2,
  STATUS_SUSPENDED = 3,
  STATUS_BANNED = 4,
  STATUS_REFUSED_SERVICE = 5,
  UNRECOGNIZED = -1,
}

export function statusFromJSON(object: any): Status {
  switch (object) {
    case 0:
    case 'STATUS_INVALID':
      return Status.STATUS_INVALID;
    case 1:
    case 'STATUS_ACTIVE':
      return Status.STATUS_ACTIVE;
    case 2:
    case 'STATUS_DELETED':
      return Status.STATUS_DELETED;
    case 3:
    case 'STATUS_SUSPENDED':
      return Status.STATUS_SUSPENDED;
    case 4:
    case 'STATUS_BANNED':
      return Status.STATUS_BANNED;
    case 5:
    case 'STATUS_REFUSED_SERVICE':
      return Status.STATUS_REFUSED_SERVICE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Status.UNRECOGNIZED;
  }
}

export function statusToJSON(object: Status): string {
  switch (object) {
    case Status.STATUS_INVALID:
      return 'STATUS_INVALID';
    case Status.STATUS_ACTIVE:
      return 'STATUS_ACTIVE';
    case Status.STATUS_DELETED:
      return 'STATUS_DELETED';
    case Status.STATUS_SUSPENDED:
      return 'STATUS_SUSPENDED';
    case Status.STATUS_BANNED:
      return 'STATUS_BANNED';
    case Status.STATUS_REFUSED_SERVICE:
      return 'STATUS_REFUSED_SERVICE';
    case Status.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum PostLoginActionType {
  POST_LOGIN_ACTION_TYPE_INVALID = 0,
  POST_LOGIN_ACTION_TYPE_ASK_SENDER_COUNTRY = 1,
  POST_LOGIN_ACTION_TYPE_ASK_RECIPIENT_COUNTRIES = 2,
  POST_LOGIN_ACTION_TYPE_VERIFY_PHONE_NUMBER = 3,
  POST_LOGIN_ACTION_TYPE_VERIFY_EMAIL = 4,
  POST_LOGIN_ACTION_TYPE_ASK_INVITATION_CODE_WAITLIST = 5,
  POST_LOGIN_ACTION_TYPE_ASK_INVITATION_CODE_ATTRIBUTION = 6,
  POST_LOGIN_ACTION_TYPE_OPEN_URL = 7,
  POST_LOGIN_ACTION_TYPE_KYC = 8,
  POST_LOGIN_ACTION_TYPE_ACCEPT_TERMS = 9,
  UNRECOGNIZED = -1,
}

export function postLoginActionTypeFromJSON(object: any): PostLoginActionType {
  switch (object) {
    case 0:
    case 'POST_LOGIN_ACTION_TYPE_INVALID':
      return PostLoginActionType.POST_LOGIN_ACTION_TYPE_INVALID;
    case 1:
    case 'POST_LOGIN_ACTION_TYPE_ASK_SENDER_COUNTRY':
      return PostLoginActionType.POST_LOGIN_ACTION_TYPE_ASK_SENDER_COUNTRY;
    case 2:
    case 'POST_LOGIN_ACTION_TYPE_ASK_RECIPIENT_COUNTRIES':
      return PostLoginActionType.POST_LOGIN_ACTION_TYPE_ASK_RECIPIENT_COUNTRIES;
    case 3:
    case 'POST_LOGIN_ACTION_TYPE_VERIFY_PHONE_NUMBER':
      return PostLoginActionType.POST_LOGIN_ACTION_TYPE_VERIFY_PHONE_NUMBER;
    case 4:
    case 'POST_LOGIN_ACTION_TYPE_VERIFY_EMAIL':
      return PostLoginActionType.POST_LOGIN_ACTION_TYPE_VERIFY_EMAIL;
    case 5:
    case 'POST_LOGIN_ACTION_TYPE_ASK_INVITATION_CODE_WAITLIST':
      return PostLoginActionType.POST_LOGIN_ACTION_TYPE_ASK_INVITATION_CODE_WAITLIST;
    case 6:
    case 'POST_LOGIN_ACTION_TYPE_ASK_INVITATION_CODE_ATTRIBUTION':
      return PostLoginActionType.POST_LOGIN_ACTION_TYPE_ASK_INVITATION_CODE_ATTRIBUTION;
    case 7:
    case 'POST_LOGIN_ACTION_TYPE_OPEN_URL':
      return PostLoginActionType.POST_LOGIN_ACTION_TYPE_OPEN_URL;
    case 8:
    case 'POST_LOGIN_ACTION_TYPE_KYC':
      return PostLoginActionType.POST_LOGIN_ACTION_TYPE_KYC;
    case 9:
    case 'POST_LOGIN_ACTION_TYPE_ACCEPT_TERMS':
      return PostLoginActionType.POST_LOGIN_ACTION_TYPE_ACCEPT_TERMS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PostLoginActionType.UNRECOGNIZED;
  }
}

export function postLoginActionTypeToJSON(object: PostLoginActionType): string {
  switch (object) {
    case PostLoginActionType.POST_LOGIN_ACTION_TYPE_INVALID:
      return 'POST_LOGIN_ACTION_TYPE_INVALID';
    case PostLoginActionType.POST_LOGIN_ACTION_TYPE_ASK_SENDER_COUNTRY:
      return 'POST_LOGIN_ACTION_TYPE_ASK_SENDER_COUNTRY';
    case PostLoginActionType.POST_LOGIN_ACTION_TYPE_ASK_RECIPIENT_COUNTRIES:
      return 'POST_LOGIN_ACTION_TYPE_ASK_RECIPIENT_COUNTRIES';
    case PostLoginActionType.POST_LOGIN_ACTION_TYPE_VERIFY_PHONE_NUMBER:
      return 'POST_LOGIN_ACTION_TYPE_VERIFY_PHONE_NUMBER';
    case PostLoginActionType.POST_LOGIN_ACTION_TYPE_VERIFY_EMAIL:
      return 'POST_LOGIN_ACTION_TYPE_VERIFY_EMAIL';
    case PostLoginActionType.POST_LOGIN_ACTION_TYPE_ASK_INVITATION_CODE_WAITLIST:
      return 'POST_LOGIN_ACTION_TYPE_ASK_INVITATION_CODE_WAITLIST';
    case PostLoginActionType.POST_LOGIN_ACTION_TYPE_ASK_INVITATION_CODE_ATTRIBUTION:
      return 'POST_LOGIN_ACTION_TYPE_ASK_INVITATION_CODE_ATTRIBUTION';
    case PostLoginActionType.POST_LOGIN_ACTION_TYPE_OPEN_URL:
      return 'POST_LOGIN_ACTION_TYPE_OPEN_URL';
    case PostLoginActionType.POST_LOGIN_ACTION_TYPE_KYC:
      return 'POST_LOGIN_ACTION_TYPE_KYC';
    case PostLoginActionType.POST_LOGIN_ACTION_TYPE_ACCEPT_TERMS:
      return 'POST_LOGIN_ACTION_TYPE_ACCEPT_TERMS';
    case PostLoginActionType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** TermsType is the type of terms. */
export enum TermsType {
  TERMS_TYPE_INVALID = 0,
  TERMS_TYPE_N4B = 1,
  TERMS_TYPE_PEER_TO_PEER = 2,
  UNRECOGNIZED = -1,
}

export function termsTypeFromJSON(object: any): TermsType {
  switch (object) {
    case 0:
    case 'TERMS_TYPE_INVALID':
      return TermsType.TERMS_TYPE_INVALID;
    case 1:
    case 'TERMS_TYPE_N4B':
      return TermsType.TERMS_TYPE_N4B;
    case 2:
    case 'TERMS_TYPE_PEER_TO_PEER':
      return TermsType.TERMS_TYPE_PEER_TO_PEER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return TermsType.UNRECOGNIZED;
  }
}

export function termsTypeToJSON(object: TermsType): string {
  switch (object) {
    case TermsType.TERMS_TYPE_INVALID:
      return 'TERMS_TYPE_INVALID';
    case TermsType.TERMS_TYPE_N4B:
      return 'TERMS_TYPE_N4B';
    case TermsType.TERMS_TYPE_PEER_TO_PEER:
      return 'TERMS_TYPE_PEER_TO_PEER';
    case TermsType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * MembershipStatus is the status of a user's membership.
 * DISABLED: The user is still an active member, but the membership is disabled.
 * ACTIVE: The user is an active member.
 * DELETE: The user has been removed from the account.
 */
export enum MembershipStatus {
  MEMBERSHIP_STATUS_INVALID = 0,
  MEMBERSHIP_STATUS_DISABLED = 1,
  MEMBERSHIP_STATUS_DELETED = 2,
  MEMBERSHIP_STATUS_ACTIVE = 3,
  MEMBERSHIP_STATUS_WAITING_FOR_APPROVAL = 4,
  UNRECOGNIZED = -1,
}

export function membershipStatusFromJSON(object: any): MembershipStatus {
  switch (object) {
    case 0:
    case 'MEMBERSHIP_STATUS_INVALID':
      return MembershipStatus.MEMBERSHIP_STATUS_INVALID;
    case 1:
    case 'MEMBERSHIP_STATUS_DISABLED':
      return MembershipStatus.MEMBERSHIP_STATUS_DISABLED;
    case 2:
    case 'MEMBERSHIP_STATUS_DELETED':
      return MembershipStatus.MEMBERSHIP_STATUS_DELETED;
    case 3:
    case 'MEMBERSHIP_STATUS_ACTIVE':
      return MembershipStatus.MEMBERSHIP_STATUS_ACTIVE;
    case 4:
    case 'MEMBERSHIP_STATUS_WAITING_FOR_APPROVAL':
      return MembershipStatus.MEMBERSHIP_STATUS_WAITING_FOR_APPROVAL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MembershipStatus.UNRECOGNIZED;
  }
}

export function membershipStatusToJSON(object: MembershipStatus): string {
  switch (object) {
    case MembershipStatus.MEMBERSHIP_STATUS_INVALID:
      return 'MEMBERSHIP_STATUS_INVALID';
    case MembershipStatus.MEMBERSHIP_STATUS_DISABLED:
      return 'MEMBERSHIP_STATUS_DISABLED';
    case MembershipStatus.MEMBERSHIP_STATUS_DELETED:
      return 'MEMBERSHIP_STATUS_DELETED';
    case MembershipStatus.MEMBERSHIP_STATUS_ACTIVE:
      return 'MEMBERSHIP_STATUS_ACTIVE';
    case MembershipStatus.MEMBERSHIP_STATUS_WAITING_FOR_APPROVAL:
      return 'MEMBERSHIP_STATUS_WAITING_FOR_APPROVAL';
    case MembershipStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Role represent a user's role in an account. */
export enum MembershipRole {
  MEMBERSHIP_ROLE_INVALID = 0,
  MEMBERSHIP_ROLE_OWNER = 1,
  MEMBERSHIP_ROLE_ADMIN = 2,
  MEMBERSHIP_ROLE_USER = 3,
  MEMBERSHIP_ROLE_MANAGER = 4,
  UNRECOGNIZED = -1,
}

export function membershipRoleFromJSON(object: any): MembershipRole {
  switch (object) {
    case 0:
    case 'MEMBERSHIP_ROLE_INVALID':
      return MembershipRole.MEMBERSHIP_ROLE_INVALID;
    case 1:
    case 'MEMBERSHIP_ROLE_OWNER':
      return MembershipRole.MEMBERSHIP_ROLE_OWNER;
    case 2:
    case 'MEMBERSHIP_ROLE_ADMIN':
      return MembershipRole.MEMBERSHIP_ROLE_ADMIN;
    case 3:
    case 'MEMBERSHIP_ROLE_USER':
      return MembershipRole.MEMBERSHIP_ROLE_USER;
    case 4:
    case 'MEMBERSHIP_ROLE_MANAGER':
      return MembershipRole.MEMBERSHIP_ROLE_MANAGER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return MembershipRole.UNRECOGNIZED;
  }
}

export function membershipRoleToJSON(object: MembershipRole): string {
  switch (object) {
    case MembershipRole.MEMBERSHIP_ROLE_INVALID:
      return 'MEMBERSHIP_ROLE_INVALID';
    case MembershipRole.MEMBERSHIP_ROLE_OWNER:
      return 'MEMBERSHIP_ROLE_OWNER';
    case MembershipRole.MEMBERSHIP_ROLE_ADMIN:
      return 'MEMBERSHIP_ROLE_ADMIN';
    case MembershipRole.MEMBERSHIP_ROLE_USER:
      return 'MEMBERSHIP_ROLE_USER';
    case MembershipRole.MEMBERSHIP_ROLE_MANAGER:
      return 'MEMBERSHIP_ROLE_MANAGER';
    case MembershipRole.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** ActionType is the type of action. */
export enum ActionType {
  ACTION_TYPE_INVALID = 0,
  ACTION_TYPE_ACCEPT_TERMS = 1,
  ACTION_TYPE_SET_TAG = 2,
  UNRECOGNIZED = -1,
}

export function actionTypeFromJSON(object: any): ActionType {
  switch (object) {
    case 0:
    case 'ACTION_TYPE_INVALID':
      return ActionType.ACTION_TYPE_INVALID;
    case 1:
    case 'ACTION_TYPE_ACCEPT_TERMS':
      return ActionType.ACTION_TYPE_ACCEPT_TERMS;
    case 2:
    case 'ACTION_TYPE_SET_TAG':
      return ActionType.ACTION_TYPE_SET_TAG;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ActionType.UNRECOGNIZED;
  }
}

export function actionTypeToJSON(object: ActionType): string {
  switch (object) {
    case ActionType.ACTION_TYPE_INVALID:
      return 'ACTION_TYPE_INVALID';
    case ActionType.ACTION_TYPE_ACCEPT_TERMS:
      return 'ACTION_TYPE_ACCEPT_TERMS';
    case ActionType.ACTION_TYPE_SET_TAG:
      return 'ACTION_TYPE_SET_TAG';
    case ActionType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Feature {
  enabled: boolean;
  configuration: string;
  type: FeatureType;
  state: FeatureState;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Usage {
  senderCountry: string;
  recipientCountries: string[];
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface User {
  id: string;
  created: Timestamp | undefined;
  profilePicture: string;
  /** @deprecated */
  deprecatedClientProperties: { [key: string]: string };
  name: Name | undefined;
  features: { [key: string]: Feature };
  waitlist: boolean;
  clientProperties: ClientProperties | undefined;
  allowed: Timestamp | undefined;
  status: PublicStatus;
}

export interface User_DeprecatedClientPropertiesEntry {
  key: string;
  value: string;
}

export interface User_FeaturesEntry {
  key: string;
  value: Feature | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Account {
  id: string;
  ownerId: string;
  accountType: AccountType;
  name: string;
  profilePicture: string;
  created: Timestamp | undefined;
  clientProperties: ClientProperties | undefined;
  features: { [key: string]: Feature };
  defaultCurrency: string;
  profileId: string;
}

export interface Account_FeaturesEntry {
  key: string;
  value: Feature | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface ContactDetail {
  id: string;
  userId: string;
  type: ContactDetailType;
  value: string;
  createdAt: Timestamp | undefined;
  verifiedAt: Timestamp | undefined;
  isDefault: boolean;
}

/** BannerStyling allows specifying a BannerItem's colours and icons. */
export interface BannerStyling {
  /** Optional. If not specified, frontend should use default styling. */
  text: ColourStyling | undefined;
  /** If empty, no icon should be displayed. */
  primaryIconStack: Icon[];
  /** If empty, no icon should be displayed. */
  actionIconStack: Icon[];
}

/**
 * BannerItem is a banner to be displayed to the user
 * with information and/or to prompt them towards an action.
 * They are typically listed in the frontend by descending priority.
 */
export interface BannerItem {
  title: string;
  subTitle: string;
  progress: number;
  priority: number;
  action: BannerAction;
  options: { [key: string]: string };
  notify: boolean;
  timeRange: TimeRange | undefined;
  countryRestrictions: string[];
  senderCountryRestrictions: string[];
  styling: BannerStyling | undefined;
}

export interface BannerItem_OptionsEntry {
  key: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface StatusReview {
  id: string;
  userId: string;
  reviewerId: string;
  status: Status;
  content: string;
  createdAt: Timestamp | undefined;
  metadata: Uint8Array;
  reason: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface InvitationCode {
  code: string;
  ownerId: string;
  title: string;
  usageLimit: number;
  usageCount: number;
  active: boolean;
  validAfter: Timestamp | undefined;
  validBefore: Timestamp | undefined;
  createdAt: Timestamp | undefined;
  link: string;
  id: string;
  isUser: boolean;
  countries: string[];
  rewardConfiguration: InvitationCodeRewardConfiguration | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface InvitationCodeRewardConfiguration {
  /** Country ISO 3166 Alpha-2 => Money Amount */
  rewardsReferrerUserTransaction: { [key: string]: Money };
  rewardsNewUserWithInvitationCode: { [key: string]: Money };
  minSendAmount: { [key: string]: Money };
}

export interface InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry {
  key: string;
  value: Money | undefined;
}

export interface InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry {
  key: string;
  value: Money | undefined;
}

export interface InvitationCodeRewardConfiguration_MinSendAmountEntry {
  key: string;
  value: Money | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface ClientProperties {
  intercomSignedUserIdIos: string;
  intercomSignedUserIdAndroid: string;
  hasTransactions: boolean;
  lastUsedPaymentMethod: LastUsedPaymentMethod | undefined;
  wasOnWaitlist: boolean;
  shouldPromptInvitationCode: boolean;
  invitationCodePage: string;
  businessAccountFormUrl: string;
  intercomSignedUserIdWeb: string;
  statementExportAvailable: boolean;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Source {
  /** branch */
  feature: string;
  campaign: string;
  channel: string;
  tags: string[];
  link: string;
  /** google */
  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
}

/** AcceptTermsDetails is the details of the accept terms post login action. */
export interface AcceptTermsDetails {
  type: TermsType;
  url: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface PostLoginAction {
  type: PostLoginActionType;
  data: Uint8Array;
  completed: boolean;
  acceptTermsDetails?: AcceptTermsDetails | undefined;
}

/**
 * Membership is the user relationship to an account. It contains the user's role and status.
 * A user can have multiple memberships to different accounts.
 * User will have a subset of permissions based on the role and status of the membership.
 */
export interface Membership {
  accountId: string;
  userId: string;
  status: MembershipStatus;
  roles: MembershipRole[];
  /** Timestamp of when the membership was created. It can be used to know when the user joined the account. */
  createdAt: Timestamp | undefined;
  permissions: Permission[];
}

/** Member is the FE friendly version of Membership. It contains the internal membership along with some additional information. */
export interface Member {
  membership: Membership | undefined;
  name: Name | undefined;
  email: string;
  profilePicture: string;
  displayName: string;
}

/** Role represents a set of permissions. */
export interface MembershipRoleWithPermissions {
  role: MembershipRole;
  permissions: Permission[];
}

/** MembershipInvitation is an invitation to join an account. */
export interface MembershipInvitation {
  id: string;
  accountId: string;
  /** Will be empty if the user has not accepted the invitation yet. */
  userId: string;
  email: string;
  phoneNumber: string;
  roles: MembershipRole[];
  createdAt: Timestamp | undefined;
  expiresAt: Timestamp | undefined;
  deletedAt: Timestamp | undefined;
  name: string;
}

/** ExternalAuth is the relation ship between a user and an external auth provider. */
export interface ExternalAuth {
  userId: string;
  providerName: string;
  providerId: string;
}

/** StaffTeam is a team of staff users. */
export interface StaffTeam {
  id: string;
  name: string;
  contactDetails: { [key: string]: string };
}

export interface StaffTeam_ContactDetailsEntry {
  key: string;
  value: string;
}

/** StaffTeamMember is the relation ship between a staff user and a staff team. */
export interface StaffTeamMember {
  staffId: string;
  staffTeamId: string;
  roles: string[];
}

/** AcceptTermsActionData is the data of the accept terms action. */
export interface AcceptTermsActionData {
  type: TermsType;
  url: string;
  title: string;
}

/** SetTagActionData is the data of the set tag action. */
export interface SetTagActionData {
  suggestedTag: string;
  minLength: number;
  maxLength: number;
}

/** Action represents an action a user needs to take to fulfil a requirement. */
export interface Action {
  type: ActionType;
  acceptTerms?: AcceptTermsActionData | undefined;
  setTag?: SetTagActionData | undefined;
}

function createBaseFeature(): Feature {
  return { enabled: false, configuration: '', type: 0, state: 0 };
}

export const Feature = {
  encode(
    message: Feature,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.enabled === true) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.configuration !== '') {
      writer.uint32(18).string(message.configuration);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.state !== 0) {
      writer.uint32(32).int32(message.state);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Feature {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeature();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.configuration = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Feature {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : false,
      configuration: isSet(object.configuration)
        ? String(object.configuration)
        : '',
      type: isSet(object.type) ? featureTypeFromJSON(object.type) : 0,
      state: isSet(object.state) ? featureStateFromJSON(object.state) : 0,
    };
  },

  toJSON(message: Feature): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    message.configuration !== undefined &&
      (obj.configuration = message.configuration);
    message.type !== undefined && (obj.type = featureTypeToJSON(message.type));
    message.state !== undefined &&
      (obj.state = featureStateToJSON(message.state));
    return obj;
  },

  create(base?: DeepPartial<Feature>): Feature {
    return Feature.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Feature>): Feature {
    const message = createBaseFeature();
    message.enabled = object.enabled ?? false;
    message.configuration = object.configuration ?? '';
    message.type = object.type ?? 0;
    message.state = object.state ?? 0;
    return message;
  },
};

function createBaseUsage(): Usage {
  return { senderCountry: '', recipientCountries: [] };
}

export const Usage = {
  encode(message: Usage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.senderCountry !== '') {
      writer.uint32(10).string(message.senderCountry);
    }
    for (const v of message.recipientCountries) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Usage {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUsage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.senderCountry = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipientCountries.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Usage {
    return {
      senderCountry: isSet(object.senderCountry)
        ? String(object.senderCountry)
        : '',
      recipientCountries: Array.isArray(object?.recipientCountries)
        ? object.recipientCountries.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: Usage): unknown {
    const obj: any = {};
    message.senderCountry !== undefined &&
      (obj.senderCountry = message.senderCountry);
    if (message.recipientCountries) {
      obj.recipientCountries = message.recipientCountries.map((e) => e);
    } else {
      obj.recipientCountries = [];
    }
    return obj;
  },

  create(base?: DeepPartial<Usage>): Usage {
    return Usage.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Usage>): Usage {
    const message = createBaseUsage();
    message.senderCountry = object.senderCountry ?? '';
    message.recipientCountries = object.recipientCountries?.map((e) => e) || [];
    return message;
  },
};

function createBaseUser(): User {
  return {
    id: '',
    created: undefined,
    profilePicture: '',
    deprecatedClientProperties: {},
    name: undefined,
    features: {},
    waitlist: false,
    clientProperties: undefined,
    allowed: undefined,
    status: 0,
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.created !== undefined) {
      Timestamp.encode(message.created, writer.uint32(18).fork()).ldelim();
    }
    if (message.profilePicture !== '') {
      writer.uint32(26).string(message.profilePicture);
    }
    Object.entries(message.deprecatedClientProperties).forEach(
      ([key, value]) => {
        User_DeprecatedClientPropertiesEntry.encode(
          { key: key as any, value },
          writer.uint32(34).fork(),
        ).ldelim();
      },
    );
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(42).fork()).ldelim();
    }
    Object.entries(message.features).forEach(([key, value]) => {
      User_FeaturesEntry.encode(
        { key: key as any, value },
        writer.uint32(58).fork(),
      ).ldelim();
    });
    if (message.waitlist === true) {
      writer.uint32(64).bool(message.waitlist);
    }
    if (message.clientProperties !== undefined) {
      ClientProperties.encode(
        message.clientProperties,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    if (message.allowed !== undefined) {
      Timestamp.encode(message.allowed, writer.uint32(82).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(88).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.profilePicture = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = User_DeprecatedClientPropertiesEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry4.value !== undefined) {
            message.deprecatedClientProperties[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = User_FeaturesEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.features[entry7.key] = entry7.value;
          }
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.waitlist = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.clientProperties = ClientProperties.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.allowed = Timestamp.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      created: isSet(object.created)
        ? fromJsonTimestamp(object.created)
        : undefined,
      profilePicture: isSet(object.profilePicture)
        ? String(object.profilePicture)
        : '',
      deprecatedClientProperties: isObject(object.deprecatedClientProperties)
        ? Object.entries(object.deprecatedClientProperties).reduce<{
            [key: string]: string;
          }>((acc, [key, value]) => {
            acc[key] = String(value);
            return acc;
          }, {})
        : {},
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
      features: isObject(object.features)
        ? Object.entries(object.features).reduce<{ [key: string]: Feature }>(
            (acc, [key, value]) => {
              acc[key] = Feature.fromJSON(value);
              return acc;
            },
            {},
          )
        : {},
      waitlist: isSet(object.waitlist) ? Boolean(object.waitlist) : false,
      clientProperties: isSet(object.clientProperties)
        ? ClientProperties.fromJSON(object.clientProperties)
        : undefined,
      allowed: isSet(object.allowed)
        ? fromJsonTimestamp(object.allowed)
        : undefined,
      status: isSet(object.status) ? publicStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.created !== undefined &&
      (obj.created = fromTimestamp(message.created).toISOString());
    message.profilePicture !== undefined &&
      (obj.profilePicture = message.profilePicture);
    obj.deprecatedClientProperties = {};
    if (message.deprecatedClientProperties) {
      Object.entries(message.deprecatedClientProperties).forEach(([k, v]) => {
        obj.deprecatedClientProperties[k] = v;
      });
    }
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    obj.features = {};
    if (message.features) {
      Object.entries(message.features).forEach(([k, v]) => {
        obj.features[k] = Feature.toJSON(v);
      });
    }
    message.waitlist !== undefined && (obj.waitlist = message.waitlist);
    message.clientProperties !== undefined &&
      (obj.clientProperties = message.clientProperties
        ? ClientProperties.toJSON(message.clientProperties)
        : undefined);
    message.allowed !== undefined &&
      (obj.allowed = fromTimestamp(message.allowed).toISOString());
    message.status !== undefined &&
      (obj.status = publicStatusToJSON(message.status));
    return obj;
  },

  create(base?: DeepPartial<User>): User {
    return User.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<User>): User {
    const message = createBaseUser();
    message.id = object.id ?? '';
    message.created =
      object.created !== undefined && object.created !== null
        ? Timestamp.fromPartial(object.created)
        : undefined;
    message.profilePicture = object.profilePicture ?? '';
    message.deprecatedClientProperties = Object.entries(
      object.deprecatedClientProperties ?? {},
    ).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    message.features = Object.entries(object.features ?? {}).reduce<{
      [key: string]: Feature;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Feature.fromPartial(value);
      }
      return acc;
    }, {});
    message.waitlist = object.waitlist ?? false;
    message.clientProperties =
      object.clientProperties !== undefined && object.clientProperties !== null
        ? ClientProperties.fromPartial(object.clientProperties)
        : undefined;
    message.allowed =
      object.allowed !== undefined && object.allowed !== null
        ? Timestamp.fromPartial(object.allowed)
        : undefined;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseUser_DeprecatedClientPropertiesEntry(): User_DeprecatedClientPropertiesEntry {
  return { key: '', value: '' };
}

export const User_DeprecatedClientPropertiesEntry = {
  encode(
    message: User_DeprecatedClientPropertiesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): User_DeprecatedClientPropertiesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_DeprecatedClientPropertiesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_DeprecatedClientPropertiesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: User_DeprecatedClientPropertiesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<User_DeprecatedClientPropertiesEntry>,
  ): User_DeprecatedClientPropertiesEntry {
    return User_DeprecatedClientPropertiesEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<User_DeprecatedClientPropertiesEntry>,
  ): User_DeprecatedClientPropertiesEntry {
    const message = createBaseUser_DeprecatedClientPropertiesEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseUser_FeaturesEntry(): User_FeaturesEntry {
  return { key: '', value: undefined };
}

export const User_FeaturesEntry = {
  encode(
    message: User_FeaturesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Feature.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User_FeaturesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser_FeaturesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Feature.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User_FeaturesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? Feature.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: User_FeaturesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Feature.toJSON(message.value) : undefined);
    return obj;
  },

  create(base?: DeepPartial<User_FeaturesEntry>): User_FeaturesEntry {
    return User_FeaturesEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<User_FeaturesEntry>): User_FeaturesEntry {
    const message = createBaseUser_FeaturesEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? Feature.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseAccount(): Account {
  return {
    id: '',
    ownerId: '',
    accountType: 0,
    name: '',
    profilePicture: '',
    created: undefined,
    clientProperties: undefined,
    features: {},
    defaultCurrency: '',
    profileId: '',
  };
}

export const Account = {
  encode(
    message: Account,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.ownerId !== '') {
      writer.uint32(18).string(message.ownerId);
    }
    if (message.accountType !== 0) {
      writer.uint32(24).int32(message.accountType);
    }
    if (message.name !== '') {
      writer.uint32(34).string(message.name);
    }
    if (message.profilePicture !== '') {
      writer.uint32(42).string(message.profilePicture);
    }
    if (message.created !== undefined) {
      Timestamp.encode(message.created, writer.uint32(50).fork()).ldelim();
    }
    if (message.clientProperties !== undefined) {
      ClientProperties.encode(
        message.clientProperties,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    Object.entries(message.features).forEach(([key, value]) => {
      Account_FeaturesEntry.encode(
        { key: key as any, value },
        writer.uint32(66).fork(),
      ).ldelim();
    });
    if (message.defaultCurrency !== '') {
      writer.uint32(74).string(message.defaultCurrency);
    }
    if (message.profileId !== '') {
      writer.uint32(82).string(message.profileId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Account {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ownerId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.accountType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.profilePicture = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.created = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.clientProperties = ClientProperties.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = Account_FeaturesEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.features[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.defaultCurrency = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.profileId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Account {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      ownerId: isSet(object.ownerId) ? String(object.ownerId) : '',
      accountType: isSet(object.accountType)
        ? accountTypeFromJSON(object.accountType)
        : 0,
      name: isSet(object.name) ? String(object.name) : '',
      profilePicture: isSet(object.profilePicture)
        ? String(object.profilePicture)
        : '',
      created: isSet(object.created)
        ? fromJsonTimestamp(object.created)
        : undefined,
      clientProperties: isSet(object.clientProperties)
        ? ClientProperties.fromJSON(object.clientProperties)
        : undefined,
      features: isObject(object.features)
        ? Object.entries(object.features).reduce<{ [key: string]: Feature }>(
            (acc, [key, value]) => {
              acc[key] = Feature.fromJSON(value);
              return acc;
            },
            {},
          )
        : {},
      defaultCurrency: isSet(object.defaultCurrency)
        ? String(object.defaultCurrency)
        : '',
      profileId: isSet(object.profileId) ? String(object.profileId) : '',
    };
  },

  toJSON(message: Account): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.ownerId !== undefined && (obj.ownerId = message.ownerId);
    message.accountType !== undefined &&
      (obj.accountType = accountTypeToJSON(message.accountType));
    message.name !== undefined && (obj.name = message.name);
    message.profilePicture !== undefined &&
      (obj.profilePicture = message.profilePicture);
    message.created !== undefined &&
      (obj.created = fromTimestamp(message.created).toISOString());
    message.clientProperties !== undefined &&
      (obj.clientProperties = message.clientProperties
        ? ClientProperties.toJSON(message.clientProperties)
        : undefined);
    obj.features = {};
    if (message.features) {
      Object.entries(message.features).forEach(([k, v]) => {
        obj.features[k] = Feature.toJSON(v);
      });
    }
    message.defaultCurrency !== undefined &&
      (obj.defaultCurrency = message.defaultCurrency);
    message.profileId !== undefined && (obj.profileId = message.profileId);
    return obj;
  },

  create(base?: DeepPartial<Account>): Account {
    return Account.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Account>): Account {
    const message = createBaseAccount();
    message.id = object.id ?? '';
    message.ownerId = object.ownerId ?? '';
    message.accountType = object.accountType ?? 0;
    message.name = object.name ?? '';
    message.profilePicture = object.profilePicture ?? '';
    message.created =
      object.created !== undefined && object.created !== null
        ? Timestamp.fromPartial(object.created)
        : undefined;
    message.clientProperties =
      object.clientProperties !== undefined && object.clientProperties !== null
        ? ClientProperties.fromPartial(object.clientProperties)
        : undefined;
    message.features = Object.entries(object.features ?? {}).reduce<{
      [key: string]: Feature;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Feature.fromPartial(value);
      }
      return acc;
    }, {});
    message.defaultCurrency = object.defaultCurrency ?? '';
    message.profileId = object.profileId ?? '';
    return message;
  },
};

function createBaseAccount_FeaturesEntry(): Account_FeaturesEntry {
  return { key: '', value: undefined };
}

export const Account_FeaturesEntry = {
  encode(
    message: Account_FeaturesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Feature.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Account_FeaturesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccount_FeaturesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Feature.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Account_FeaturesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? Feature.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: Account_FeaturesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Feature.toJSON(message.value) : undefined);
    return obj;
  },

  create(base?: DeepPartial<Account_FeaturesEntry>): Account_FeaturesEntry {
    return Account_FeaturesEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<Account_FeaturesEntry>,
  ): Account_FeaturesEntry {
    const message = createBaseAccount_FeaturesEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? Feature.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseContactDetail(): ContactDetail {
  return {
    id: '',
    userId: '',
    type: 0,
    value: '',
    createdAt: undefined,
    verifiedAt: undefined,
    isDefault: false,
  };
}

export const ContactDetail = {
  encode(
    message: ContactDetail,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.userId !== '') {
      writer.uint32(18).string(message.userId);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.value !== '') {
      writer.uint32(34).string(message.value);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.verifiedAt !== undefined) {
      Timestamp.encode(message.verifiedAt, writer.uint32(50).fork()).ldelim();
    }
    if (message.isDefault === true) {
      writer.uint32(56).bool(message.isDefault);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ContactDetail {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContactDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.verifiedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ContactDetail {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      userId: isSet(object.userId) ? String(object.userId) : '',
      type: isSet(object.type) ? contactDetailTypeFromJSON(object.type) : 0,
      value: isSet(object.value) ? String(object.value) : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      verifiedAt: isSet(object.verifiedAt)
        ? fromJsonTimestamp(object.verifiedAt)
        : undefined,
      isDefault: isSet(object.isDefault) ? Boolean(object.isDefault) : false,
    };
  },

  toJSON(message: ContactDetail): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userId !== undefined && (obj.userId = message.userId);
    message.type !== undefined &&
      (obj.type = contactDetailTypeToJSON(message.type));
    message.value !== undefined && (obj.value = message.value);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.verifiedAt !== undefined &&
      (obj.verifiedAt = fromTimestamp(message.verifiedAt).toISOString());
    message.isDefault !== undefined && (obj.isDefault = message.isDefault);
    return obj;
  },

  create(base?: DeepPartial<ContactDetail>): ContactDetail {
    return ContactDetail.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ContactDetail>): ContactDetail {
    const message = createBaseContactDetail();
    message.id = object.id ?? '';
    message.userId = object.userId ?? '';
    message.type = object.type ?? 0;
    message.value = object.value ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.verifiedAt =
      object.verifiedAt !== undefined && object.verifiedAt !== null
        ? Timestamp.fromPartial(object.verifiedAt)
        : undefined;
    message.isDefault = object.isDefault ?? false;
    return message;
  },
};

function createBaseBannerStyling(): BannerStyling {
  return { text: undefined, primaryIconStack: [], actionIconStack: [] };
}

export const BannerStyling = {
  encode(
    message: BannerStyling,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.text !== undefined) {
      ColourStyling.encode(message.text, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.primaryIconStack) {
      Icon.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.actionIconStack) {
      Icon.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BannerStyling {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBannerStyling();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.text = ColourStyling.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.primaryIconStack.push(Icon.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.actionIconStack.push(Icon.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BannerStyling {
    return {
      text: isSet(object.text)
        ? ColourStyling.fromJSON(object.text)
        : undefined,
      primaryIconStack: Array.isArray(object?.primaryIconStack)
        ? object.primaryIconStack.map((e: any) => Icon.fromJSON(e))
        : [],
      actionIconStack: Array.isArray(object?.actionIconStack)
        ? object.actionIconStack.map((e: any) => Icon.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BannerStyling): unknown {
    const obj: any = {};
    message.text !== undefined &&
      (obj.text = message.text
        ? ColourStyling.toJSON(message.text)
        : undefined);
    if (message.primaryIconStack) {
      obj.primaryIconStack = message.primaryIconStack.map((e) =>
        e ? Icon.toJSON(e) : undefined,
      );
    } else {
      obj.primaryIconStack = [];
    }
    if (message.actionIconStack) {
      obj.actionIconStack = message.actionIconStack.map((e) =>
        e ? Icon.toJSON(e) : undefined,
      );
    } else {
      obj.actionIconStack = [];
    }
    return obj;
  },

  create(base?: DeepPartial<BannerStyling>): BannerStyling {
    return BannerStyling.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<BannerStyling>): BannerStyling {
    const message = createBaseBannerStyling();
    message.text =
      object.text !== undefined && object.text !== null
        ? ColourStyling.fromPartial(object.text)
        : undefined;
    message.primaryIconStack =
      object.primaryIconStack?.map((e) => Icon.fromPartial(e)) || [];
    message.actionIconStack =
      object.actionIconStack?.map((e) => Icon.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBannerItem(): BannerItem {
  return {
    title: '',
    subTitle: '',
    progress: 0,
    priority: 0,
    action: 0,
    options: {},
    notify: false,
    timeRange: undefined,
    countryRestrictions: [],
    senderCountryRestrictions: [],
    styling: undefined,
  };
}

export const BannerItem = {
  encode(
    message: BannerItem,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.title !== '') {
      writer.uint32(10).string(message.title);
    }
    if (message.subTitle !== '') {
      writer.uint32(18).string(message.subTitle);
    }
    if (message.progress !== 0) {
      writer.uint32(29).float(message.progress);
    }
    if (message.priority !== 0) {
      writer.uint32(32).int32(message.priority);
    }
    if (message.action !== 0) {
      writer.uint32(40).int32(message.action);
    }
    Object.entries(message.options).forEach(([key, value]) => {
      BannerItem_OptionsEntry.encode(
        { key: key as any, value },
        writer.uint32(50).fork(),
      ).ldelim();
    });
    if (message.notify === true) {
      writer.uint32(56).bool(message.notify);
    }
    if (message.timeRange !== undefined) {
      TimeRange.encode(message.timeRange, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.countryRestrictions) {
      writer.uint32(74).string(v!);
    }
    for (const v of message.senderCountryRestrictions) {
      writer.uint32(82).string(v!);
    }
    if (message.styling !== undefined) {
      BannerStyling.encode(message.styling, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BannerItem {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBannerItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subTitle = reader.string();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.progress = reader.float();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.priority = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.action = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = BannerItem_OptionsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry6.value !== undefined) {
            message.options[entry6.key] = entry6.value;
          }
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.notify = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.timeRange = TimeRange.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.countryRestrictions.push(reader.string());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.senderCountryRestrictions.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.styling = BannerStyling.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BannerItem {
    return {
      title: isSet(object.title) ? String(object.title) : '',
      subTitle: isSet(object.subTitle) ? String(object.subTitle) : '',
      progress: isSet(object.progress) ? Number(object.progress) : 0,
      priority: isSet(object.priority) ? Number(object.priority) : 0,
      action: isSet(object.action) ? bannerActionFromJSON(object.action) : 0,
      options: isObject(object.options)
        ? Object.entries(object.options).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      notify: isSet(object.notify) ? Boolean(object.notify) : false,
      timeRange: isSet(object.timeRange)
        ? TimeRange.fromJSON(object.timeRange)
        : undefined,
      countryRestrictions: Array.isArray(object?.countryRestrictions)
        ? object.countryRestrictions.map((e: any) => String(e))
        : [],
      senderCountryRestrictions: Array.isArray(
        object?.senderCountryRestrictions,
      )
        ? object.senderCountryRestrictions.map((e: any) => String(e))
        : [],
      styling: isSet(object.styling)
        ? BannerStyling.fromJSON(object.styling)
        : undefined,
    };
  },

  toJSON(message: BannerItem): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subTitle !== undefined && (obj.subTitle = message.subTitle);
    message.progress !== undefined && (obj.progress = message.progress);
    message.priority !== undefined &&
      (obj.priority = Math.round(message.priority));
    message.action !== undefined &&
      (obj.action = bannerActionToJSON(message.action));
    obj.options = {};
    if (message.options) {
      Object.entries(message.options).forEach(([k, v]) => {
        obj.options[k] = v;
      });
    }
    message.notify !== undefined && (obj.notify = message.notify);
    message.timeRange !== undefined &&
      (obj.timeRange = message.timeRange
        ? TimeRange.toJSON(message.timeRange)
        : undefined);
    if (message.countryRestrictions) {
      obj.countryRestrictions = message.countryRestrictions.map((e) => e);
    } else {
      obj.countryRestrictions = [];
    }
    if (message.senderCountryRestrictions) {
      obj.senderCountryRestrictions = message.senderCountryRestrictions.map(
        (e) => e,
      );
    } else {
      obj.senderCountryRestrictions = [];
    }
    message.styling !== undefined &&
      (obj.styling = message.styling
        ? BannerStyling.toJSON(message.styling)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<BannerItem>): BannerItem {
    return BannerItem.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<BannerItem>): BannerItem {
    const message = createBaseBannerItem();
    message.title = object.title ?? '';
    message.subTitle = object.subTitle ?? '';
    message.progress = object.progress ?? 0;
    message.priority = object.priority ?? 0;
    message.action = object.action ?? 0;
    message.options = Object.entries(object.options ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.notify = object.notify ?? false;
    message.timeRange =
      object.timeRange !== undefined && object.timeRange !== null
        ? TimeRange.fromPartial(object.timeRange)
        : undefined;
    message.countryRestrictions =
      object.countryRestrictions?.map((e) => e) || [];
    message.senderCountryRestrictions =
      object.senderCountryRestrictions?.map((e) => e) || [];
    message.styling =
      object.styling !== undefined && object.styling !== null
        ? BannerStyling.fromPartial(object.styling)
        : undefined;
    return message;
  },
};

function createBaseBannerItem_OptionsEntry(): BannerItem_OptionsEntry {
  return { key: '', value: '' };
}

export const BannerItem_OptionsEntry = {
  encode(
    message: BannerItem_OptionsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): BannerItem_OptionsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBannerItem_OptionsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BannerItem_OptionsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: BannerItem_OptionsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(base?: DeepPartial<BannerItem_OptionsEntry>): BannerItem_OptionsEntry {
    return BannerItem_OptionsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<BannerItem_OptionsEntry>,
  ): BannerItem_OptionsEntry {
    const message = createBaseBannerItem_OptionsEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseStatusReview(): StatusReview {
  return {
    id: '',
    userId: '',
    reviewerId: '',
    status: 0,
    content: '',
    createdAt: undefined,
    metadata: new Uint8Array(0),
    reason: '',
  };
}

export const StatusReview = {
  encode(
    message: StatusReview,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.userId !== '') {
      writer.uint32(18).string(message.userId);
    }
    if (message.reviewerId !== '') {
      writer.uint32(26).string(message.reviewerId);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.content !== '') {
      writer.uint32(42).string(message.content);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(50).fork()).ldelim();
    }
    if (message.metadata.length !== 0) {
      writer.uint32(58).bytes(message.metadata);
    }
    if (message.reason !== '') {
      writer.uint32(66).string(message.reason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StatusReview {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatusReview();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.reviewerId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.content = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = reader.bytes();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.reason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StatusReview {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      userId: isSet(object.userId) ? String(object.userId) : '',
      reviewerId: isSet(object.reviewerId) ? String(object.reviewerId) : '',
      status: isSet(object.status) ? statusFromJSON(object.status) : 0,
      content: isSet(object.content) ? String(object.content) : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      metadata: isSet(object.metadata)
        ? bytesFromBase64(object.metadata)
        : new Uint8Array(0),
      reason: isSet(object.reason) ? String(object.reason) : '',
    };
  },

  toJSON(message: StatusReview): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userId !== undefined && (obj.userId = message.userId);
    message.reviewerId !== undefined && (obj.reviewerId = message.reviewerId);
    message.status !== undefined && (obj.status = statusToJSON(message.status));
    message.content !== undefined && (obj.content = message.content);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.metadata !== undefined &&
      (obj.metadata = base64FromBytes(
        message.metadata !== undefined ? message.metadata : new Uint8Array(0),
      ));
    message.reason !== undefined && (obj.reason = message.reason);
    return obj;
  },

  create(base?: DeepPartial<StatusReview>): StatusReview {
    return StatusReview.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<StatusReview>): StatusReview {
    const message = createBaseStatusReview();
    message.id = object.id ?? '';
    message.userId = object.userId ?? '';
    message.reviewerId = object.reviewerId ?? '';
    message.status = object.status ?? 0;
    message.content = object.content ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.metadata = object.metadata ?? new Uint8Array(0);
    message.reason = object.reason ?? '';
    return message;
  },
};

function createBaseInvitationCode(): InvitationCode {
  return {
    code: '',
    ownerId: '',
    title: '',
    usageLimit: 0,
    usageCount: 0,
    active: false,
    validAfter: undefined,
    validBefore: undefined,
    createdAt: undefined,
    link: '',
    id: '',
    isUser: false,
    countries: [],
    rewardConfiguration: undefined,
  };
}

export const InvitationCode = {
  encode(
    message: InvitationCode,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.code !== '') {
      writer.uint32(10).string(message.code);
    }
    if (message.ownerId !== '') {
      writer.uint32(18).string(message.ownerId);
    }
    if (message.title !== '') {
      writer.uint32(26).string(message.title);
    }
    if (message.usageLimit !== 0) {
      writer.uint32(32).int64(message.usageLimit);
    }
    if (message.usageCount !== 0) {
      writer.uint32(40).int64(message.usageCount);
    }
    if (message.active === true) {
      writer.uint32(48).bool(message.active);
    }
    if (message.validAfter !== undefined) {
      Timestamp.encode(message.validAfter, writer.uint32(58).fork()).ldelim();
    }
    if (message.validBefore !== undefined) {
      Timestamp.encode(message.validBefore, writer.uint32(66).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(74).fork()).ldelim();
    }
    if (message.link !== '') {
      writer.uint32(82).string(message.link);
    }
    if (message.id !== '') {
      writer.uint32(90).string(message.id);
    }
    if (message.isUser === true) {
      writer.uint32(96).bool(message.isUser);
    }
    for (const v of message.countries) {
      writer.uint32(106).string(v!);
    }
    if (message.rewardConfiguration !== undefined) {
      InvitationCodeRewardConfiguration.encode(
        message.rewardConfiguration,
        writer.uint32(114).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InvitationCode {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationCode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ownerId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.usageLimit = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.usageCount = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.validAfter = Timestamp.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.validBefore = Timestamp.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.link = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.id = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isUser = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.countries.push(reader.string());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.rewardConfiguration =
            InvitationCodeRewardConfiguration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InvitationCode {
    return {
      code: isSet(object.code) ? String(object.code) : '',
      ownerId: isSet(object.ownerId) ? String(object.ownerId) : '',
      title: isSet(object.title) ? String(object.title) : '',
      usageLimit: isSet(object.usageLimit) ? Number(object.usageLimit) : 0,
      usageCount: isSet(object.usageCount) ? Number(object.usageCount) : 0,
      active: isSet(object.active) ? Boolean(object.active) : false,
      validAfter: isSet(object.validAfter)
        ? fromJsonTimestamp(object.validAfter)
        : undefined,
      validBefore: isSet(object.validBefore)
        ? fromJsonTimestamp(object.validBefore)
        : undefined,
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      link: isSet(object.link) ? String(object.link) : '',
      id: isSet(object.id) ? String(object.id) : '',
      isUser: isSet(object.isUser) ? Boolean(object.isUser) : false,
      countries: Array.isArray(object?.countries)
        ? object.countries.map((e: any) => String(e))
        : [],
      rewardConfiguration: isSet(object.rewardConfiguration)
        ? InvitationCodeRewardConfiguration.fromJSON(object.rewardConfiguration)
        : undefined,
    };
  },

  toJSON(message: InvitationCode): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.ownerId !== undefined && (obj.ownerId = message.ownerId);
    message.title !== undefined && (obj.title = message.title);
    message.usageLimit !== undefined &&
      (obj.usageLimit = Math.round(message.usageLimit));
    message.usageCount !== undefined &&
      (obj.usageCount = Math.round(message.usageCount));
    message.active !== undefined && (obj.active = message.active);
    message.validAfter !== undefined &&
      (obj.validAfter = fromTimestamp(message.validAfter).toISOString());
    message.validBefore !== undefined &&
      (obj.validBefore = fromTimestamp(message.validBefore).toISOString());
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.link !== undefined && (obj.link = message.link);
    message.id !== undefined && (obj.id = message.id);
    message.isUser !== undefined && (obj.isUser = message.isUser);
    if (message.countries) {
      obj.countries = message.countries.map((e) => e);
    } else {
      obj.countries = [];
    }
    message.rewardConfiguration !== undefined &&
      (obj.rewardConfiguration = message.rewardConfiguration
        ? InvitationCodeRewardConfiguration.toJSON(message.rewardConfiguration)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<InvitationCode>): InvitationCode {
    return InvitationCode.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<InvitationCode>): InvitationCode {
    const message = createBaseInvitationCode();
    message.code = object.code ?? '';
    message.ownerId = object.ownerId ?? '';
    message.title = object.title ?? '';
    message.usageLimit = object.usageLimit ?? 0;
    message.usageCount = object.usageCount ?? 0;
    message.active = object.active ?? false;
    message.validAfter =
      object.validAfter !== undefined && object.validAfter !== null
        ? Timestamp.fromPartial(object.validAfter)
        : undefined;
    message.validBefore =
      object.validBefore !== undefined && object.validBefore !== null
        ? Timestamp.fromPartial(object.validBefore)
        : undefined;
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.link = object.link ?? '';
    message.id = object.id ?? '';
    message.isUser = object.isUser ?? false;
    message.countries = object.countries?.map((e) => e) || [];
    message.rewardConfiguration =
      object.rewardConfiguration !== undefined &&
      object.rewardConfiguration !== null
        ? InvitationCodeRewardConfiguration.fromPartial(
            object.rewardConfiguration,
          )
        : undefined;
    return message;
  },
};

function createBaseInvitationCodeRewardConfiguration(): InvitationCodeRewardConfiguration {
  return {
    rewardsReferrerUserTransaction: {},
    rewardsNewUserWithInvitationCode: {},
    minSendAmount: {},
  };
}

export const InvitationCodeRewardConfiguration = {
  encode(
    message: InvitationCodeRewardConfiguration,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    Object.entries(message.rewardsReferrerUserTransaction).forEach(
      ([key, value]) => {
        InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry.encode(
          { key: key as any, value },
          writer.uint32(10).fork(),
        ).ldelim();
      },
    );
    Object.entries(message.rewardsNewUserWithInvitationCode).forEach(
      ([key, value]) => {
        InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry.encode(
          { key: key as any, value },
          writer.uint32(18).fork(),
        ).ldelim();
      },
    );
    Object.entries(message.minSendAmount).forEach(([key, value]) => {
      InvitationCodeRewardConfiguration_MinSendAmountEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): InvitationCodeRewardConfiguration {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvitationCodeRewardConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 =
            InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry.decode(
              reader,
              reader.uint32(),
            );
          if (entry1.value !== undefined) {
            message.rewardsReferrerUserTransaction[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 =
            InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry.decode(
              reader,
              reader.uint32(),
            );
          if (entry2.value !== undefined) {
            message.rewardsNewUserWithInvitationCode[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 =
            InvitationCodeRewardConfiguration_MinSendAmountEntry.decode(
              reader,
              reader.uint32(),
            );
          if (entry3.value !== undefined) {
            message.minSendAmount[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InvitationCodeRewardConfiguration {
    return {
      rewardsReferrerUserTransaction: isObject(
        object.rewardsReferrerUserTransaction,
      )
        ? Object.entries(object.rewardsReferrerUserTransaction).reduce<{
            [key: string]: Money;
          }>((acc, [key, value]) => {
            acc[key] = Money.fromJSON(value);
            return acc;
          }, {})
        : {},
      rewardsNewUserWithInvitationCode: isObject(
        object.rewardsNewUserWithInvitationCode,
      )
        ? Object.entries(object.rewardsNewUserWithInvitationCode).reduce<{
            [key: string]: Money;
          }>((acc, [key, value]) => {
            acc[key] = Money.fromJSON(value);
            return acc;
          }, {})
        : {},
      minSendAmount: isObject(object.minSendAmount)
        ? Object.entries(object.minSendAmount).reduce<{ [key: string]: Money }>(
            (acc, [key, value]) => {
              acc[key] = Money.fromJSON(value);
              return acc;
            },
            {},
          )
        : {},
    };
  },

  toJSON(message: InvitationCodeRewardConfiguration): unknown {
    const obj: any = {};
    obj.rewardsReferrerUserTransaction = {};
    if (message.rewardsReferrerUserTransaction) {
      Object.entries(message.rewardsReferrerUserTransaction).forEach(
        ([k, v]) => {
          obj.rewardsReferrerUserTransaction[k] = Money.toJSON(v);
        },
      );
    }
    obj.rewardsNewUserWithInvitationCode = {};
    if (message.rewardsNewUserWithInvitationCode) {
      Object.entries(message.rewardsNewUserWithInvitationCode).forEach(
        ([k, v]) => {
          obj.rewardsNewUserWithInvitationCode[k] = Money.toJSON(v);
        },
      );
    }
    obj.minSendAmount = {};
    if (message.minSendAmount) {
      Object.entries(message.minSendAmount).forEach(([k, v]) => {
        obj.minSendAmount[k] = Money.toJSON(v);
      });
    }
    return obj;
  },

  create(
    base?: DeepPartial<InvitationCodeRewardConfiguration>,
  ): InvitationCodeRewardConfiguration {
    return InvitationCodeRewardConfiguration.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<InvitationCodeRewardConfiguration>,
  ): InvitationCodeRewardConfiguration {
    const message = createBaseInvitationCodeRewardConfiguration();
    message.rewardsReferrerUserTransaction = Object.entries(
      object.rewardsReferrerUserTransaction ?? {},
    ).reduce<{ [key: string]: Money }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Money.fromPartial(value);
      }
      return acc;
    }, {});
    message.rewardsNewUserWithInvitationCode = Object.entries(
      object.rewardsNewUserWithInvitationCode ?? {},
    ).reduce<{ [key: string]: Money }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Money.fromPartial(value);
      }
      return acc;
    }, {});
    message.minSendAmount = Object.entries(object.minSendAmount ?? {}).reduce<{
      [key: string]: Money;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Money.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseInvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry(): InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry {
  return { key: '', value: undefined };
}

export const InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry =
  {
    encode(
      message: InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry,
      writer: _m0.Writer = _m0.Writer.create(),
    ): _m0.Writer {
      if (message.key !== '') {
        writer.uint32(10).string(message.key);
      }
      if (message.value !== undefined) {
        Money.encode(message.value, writer.uint32(18).fork()).ldelim();
      }
      return writer;
    },

    decode(
      input: _m0.Reader | Uint8Array,
      length?: number,
    ): InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry {
      const reader =
        input instanceof _m0.Reader ? input : _m0.Reader.create(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message =
        createBaseInvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            if (tag !== 10) {
              break;
            }

            message.key = reader.string();
            continue;
          case 2:
            if (tag !== 18) {
              break;
            }

            message.value = Money.decode(reader, reader.uint32());
            continue;
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skipType(tag & 7);
      }
      return message;
    },

    fromJSON(
      object: any,
    ): InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry {
      return {
        key: isSet(object.key) ? String(object.key) : '',
        value: isSet(object.value) ? Money.fromJSON(object.value) : undefined,
      };
    },

    toJSON(
      message: InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry,
    ): unknown {
      const obj: any = {};
      message.key !== undefined && (obj.key = message.key);
      message.value !== undefined &&
        (obj.value = message.value ? Money.toJSON(message.value) : undefined);
      return obj;
    },

    create(
      base?: DeepPartial<InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry>,
    ): InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry {
      return InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry.fromPartial(
        base ?? {},
      );
    },

    fromPartial(
      object: DeepPartial<InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry>,
    ): InvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry {
      const message =
        createBaseInvitationCodeRewardConfiguration_RewardsReferrerUserTransactionEntry();
      message.key = object.key ?? '';
      message.value =
        object.value !== undefined && object.value !== null
          ? Money.fromPartial(object.value)
          : undefined;
      return message;
    },
  };

function createBaseInvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry(): InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry {
  return { key: '', value: undefined };
}

export const InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry =
  {
    encode(
      message: InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry,
      writer: _m0.Writer = _m0.Writer.create(),
    ): _m0.Writer {
      if (message.key !== '') {
        writer.uint32(10).string(message.key);
      }
      if (message.value !== undefined) {
        Money.encode(message.value, writer.uint32(18).fork()).ldelim();
      }
      return writer;
    },

    decode(
      input: _m0.Reader | Uint8Array,
      length?: number,
    ): InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry {
      const reader =
        input instanceof _m0.Reader ? input : _m0.Reader.create(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message =
        createBaseInvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            if (tag !== 10) {
              break;
            }

            message.key = reader.string();
            continue;
          case 2:
            if (tag !== 18) {
              break;
            }

            message.value = Money.decode(reader, reader.uint32());
            continue;
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skipType(tag & 7);
      }
      return message;
    },

    fromJSON(
      object: any,
    ): InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry {
      return {
        key: isSet(object.key) ? String(object.key) : '',
        value: isSet(object.value) ? Money.fromJSON(object.value) : undefined,
      };
    },

    toJSON(
      message: InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry,
    ): unknown {
      const obj: any = {};
      message.key !== undefined && (obj.key = message.key);
      message.value !== undefined &&
        (obj.value = message.value ? Money.toJSON(message.value) : undefined);
      return obj;
    },

    create(
      base?: DeepPartial<InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry>,
    ): InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry {
      return InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry.fromPartial(
        base ?? {},
      );
    },

    fromPartial(
      object: DeepPartial<InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry>,
    ): InvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry {
      const message =
        createBaseInvitationCodeRewardConfiguration_RewardsNewUserWithInvitationCodeEntry();
      message.key = object.key ?? '';
      message.value =
        object.value !== undefined && object.value !== null
          ? Money.fromPartial(object.value)
          : undefined;
      return message;
    },
  };

function createBaseInvitationCodeRewardConfiguration_MinSendAmountEntry(): InvitationCodeRewardConfiguration_MinSendAmountEntry {
  return { key: '', value: undefined };
}

export const InvitationCodeRewardConfiguration_MinSendAmountEntry = {
  encode(
    message: InvitationCodeRewardConfiguration_MinSendAmountEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Money.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): InvitationCodeRewardConfiguration_MinSendAmountEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseInvitationCodeRewardConfiguration_MinSendAmountEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Money.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InvitationCodeRewardConfiguration_MinSendAmountEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? Money.fromJSON(object.value) : undefined,
    };
  },

  toJSON(
    message: InvitationCodeRewardConfiguration_MinSendAmountEntry,
  ): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Money.toJSON(message.value) : undefined);
    return obj;
  },

  create(
    base?: DeepPartial<InvitationCodeRewardConfiguration_MinSendAmountEntry>,
  ): InvitationCodeRewardConfiguration_MinSendAmountEntry {
    return InvitationCodeRewardConfiguration_MinSendAmountEntry.fromPartial(
      base ?? {},
    );
  },

  fromPartial(
    object: DeepPartial<InvitationCodeRewardConfiguration_MinSendAmountEntry>,
  ): InvitationCodeRewardConfiguration_MinSendAmountEntry {
    const message =
      createBaseInvitationCodeRewardConfiguration_MinSendAmountEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? Money.fromPartial(object.value)
        : undefined;
    return message;
  },
};

function createBaseClientProperties(): ClientProperties {
  return {
    intercomSignedUserIdIos: '',
    intercomSignedUserIdAndroid: '',
    hasTransactions: false,
    lastUsedPaymentMethod: undefined,
    wasOnWaitlist: false,
    shouldPromptInvitationCode: false,
    invitationCodePage: '',
    businessAccountFormUrl: '',
    intercomSignedUserIdWeb: '',
    statementExportAvailable: false,
  };
}

export const ClientProperties = {
  encode(
    message: ClientProperties,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.intercomSignedUserIdIos !== '') {
      writer.uint32(10).string(message.intercomSignedUserIdIos);
    }
    if (message.intercomSignedUserIdAndroid !== '') {
      writer.uint32(18).string(message.intercomSignedUserIdAndroid);
    }
    if (message.hasTransactions === true) {
      writer.uint32(24).bool(message.hasTransactions);
    }
    if (message.lastUsedPaymentMethod !== undefined) {
      LastUsedPaymentMethod.encode(
        message.lastUsedPaymentMethod,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.wasOnWaitlist === true) {
      writer.uint32(40).bool(message.wasOnWaitlist);
    }
    if (message.shouldPromptInvitationCode === true) {
      writer.uint32(48).bool(message.shouldPromptInvitationCode);
    }
    if (message.invitationCodePage !== '') {
      writer.uint32(58).string(message.invitationCodePage);
    }
    if (message.businessAccountFormUrl !== '') {
      writer.uint32(66).string(message.businessAccountFormUrl);
    }
    if (message.intercomSignedUserIdWeb !== '') {
      writer.uint32(74).string(message.intercomSignedUserIdWeb);
    }
    if (message.statementExportAvailable === true) {
      writer.uint32(80).bool(message.statementExportAvailable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientProperties {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientProperties();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.intercomSignedUserIdIos = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.intercomSignedUserIdAndroid = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasTransactions = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastUsedPaymentMethod = LastUsedPaymentMethod.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.wasOnWaitlist = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.shouldPromptInvitationCode = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.invitationCodePage = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.businessAccountFormUrl = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.intercomSignedUserIdWeb = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.statementExportAvailable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientProperties {
    return {
      intercomSignedUserIdIos: isSet(object.intercomSignedUserIdIos)
        ? String(object.intercomSignedUserIdIos)
        : '',
      intercomSignedUserIdAndroid: isSet(object.intercomSignedUserIdAndroid)
        ? String(object.intercomSignedUserIdAndroid)
        : '',
      hasTransactions: isSet(object.hasTransactions)
        ? Boolean(object.hasTransactions)
        : false,
      lastUsedPaymentMethod: isSet(object.lastUsedPaymentMethod)
        ? LastUsedPaymentMethod.fromJSON(object.lastUsedPaymentMethod)
        : undefined,
      wasOnWaitlist: isSet(object.wasOnWaitlist)
        ? Boolean(object.wasOnWaitlist)
        : false,
      shouldPromptInvitationCode: isSet(object.shouldPromptInvitationCode)
        ? Boolean(object.shouldPromptInvitationCode)
        : false,
      invitationCodePage: isSet(object.invitationCodePage)
        ? String(object.invitationCodePage)
        : '',
      businessAccountFormUrl: isSet(object.businessAccountFormUrl)
        ? String(object.businessAccountFormUrl)
        : '',
      intercomSignedUserIdWeb: isSet(object.intercomSignedUserIdWeb)
        ? String(object.intercomSignedUserIdWeb)
        : '',
      statementExportAvailable: isSet(object.statementExportAvailable)
        ? Boolean(object.statementExportAvailable)
        : false,
    };
  },

  toJSON(message: ClientProperties): unknown {
    const obj: any = {};
    message.intercomSignedUserIdIos !== undefined &&
      (obj.intercomSignedUserIdIos = message.intercomSignedUserIdIos);
    message.intercomSignedUserIdAndroid !== undefined &&
      (obj.intercomSignedUserIdAndroid = message.intercomSignedUserIdAndroid);
    message.hasTransactions !== undefined &&
      (obj.hasTransactions = message.hasTransactions);
    message.lastUsedPaymentMethod !== undefined &&
      (obj.lastUsedPaymentMethod = message.lastUsedPaymentMethod
        ? LastUsedPaymentMethod.toJSON(message.lastUsedPaymentMethod)
        : undefined);
    message.wasOnWaitlist !== undefined &&
      (obj.wasOnWaitlist = message.wasOnWaitlist);
    message.shouldPromptInvitationCode !== undefined &&
      (obj.shouldPromptInvitationCode = message.shouldPromptInvitationCode);
    message.invitationCodePage !== undefined &&
      (obj.invitationCodePage = message.invitationCodePage);
    message.businessAccountFormUrl !== undefined &&
      (obj.businessAccountFormUrl = message.businessAccountFormUrl);
    message.intercomSignedUserIdWeb !== undefined &&
      (obj.intercomSignedUserIdWeb = message.intercomSignedUserIdWeb);
    message.statementExportAvailable !== undefined &&
      (obj.statementExportAvailable = message.statementExportAvailable);
    return obj;
  },

  create(base?: DeepPartial<ClientProperties>): ClientProperties {
    return ClientProperties.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ClientProperties>): ClientProperties {
    const message = createBaseClientProperties();
    message.intercomSignedUserIdIos = object.intercomSignedUserIdIos ?? '';
    message.intercomSignedUserIdAndroid =
      object.intercomSignedUserIdAndroid ?? '';
    message.hasTransactions = object.hasTransactions ?? false;
    message.lastUsedPaymentMethod =
      object.lastUsedPaymentMethod !== undefined &&
      object.lastUsedPaymentMethod !== null
        ? LastUsedPaymentMethod.fromPartial(object.lastUsedPaymentMethod)
        : undefined;
    message.wasOnWaitlist = object.wasOnWaitlist ?? false;
    message.shouldPromptInvitationCode =
      object.shouldPromptInvitationCode ?? false;
    message.invitationCodePage = object.invitationCodePage ?? '';
    message.businessAccountFormUrl = object.businessAccountFormUrl ?? '';
    message.intercomSignedUserIdWeb = object.intercomSignedUserIdWeb ?? '';
    message.statementExportAvailable = object.statementExportAvailable ?? false;
    return message;
  },
};

function createBaseSource(): Source {
  return {
    feature: '',
    campaign: '',
    channel: '',
    tags: [],
    link: '',
    utmCampaign: '',
    utmSource: '',
    utmMedium: '',
  };
}

export const Source = {
  encode(
    message: Source,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.feature !== '') {
      writer.uint32(10).string(message.feature);
    }
    if (message.campaign !== '') {
      writer.uint32(18).string(message.campaign);
    }
    if (message.channel !== '') {
      writer.uint32(26).string(message.channel);
    }
    for (const v of message.tags) {
      writer.uint32(34).string(v!);
    }
    if (message.link !== '') {
      writer.uint32(42).string(message.link);
    }
    if (message.utmCampaign !== '') {
      writer.uint32(50).string(message.utmCampaign);
    }
    if (message.utmSource !== '') {
      writer.uint32(58).string(message.utmSource);
    }
    if (message.utmMedium !== '') {
      writer.uint32(66).string(message.utmMedium);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Source {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSource();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.feature = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.campaign = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.channel = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.link = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.utmCampaign = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.utmSource = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.utmMedium = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Source {
    return {
      feature: isSet(object.feature) ? String(object.feature) : '',
      campaign: isSet(object.campaign) ? String(object.campaign) : '',
      channel: isSet(object.channel) ? String(object.channel) : '',
      tags: Array.isArray(object?.tags)
        ? object.tags.map((e: any) => String(e))
        : [],
      link: isSet(object.link) ? String(object.link) : '',
      utmCampaign: isSet(object.utmCampaign) ? String(object.utmCampaign) : '',
      utmSource: isSet(object.utmSource) ? String(object.utmSource) : '',
      utmMedium: isSet(object.utmMedium) ? String(object.utmMedium) : '',
    };
  },

  toJSON(message: Source): unknown {
    const obj: any = {};
    message.feature !== undefined && (obj.feature = message.feature);
    message.campaign !== undefined && (obj.campaign = message.campaign);
    message.channel !== undefined && (obj.channel = message.channel);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.link !== undefined && (obj.link = message.link);
    message.utmCampaign !== undefined &&
      (obj.utmCampaign = message.utmCampaign);
    message.utmSource !== undefined && (obj.utmSource = message.utmSource);
    message.utmMedium !== undefined && (obj.utmMedium = message.utmMedium);
    return obj;
  },

  create(base?: DeepPartial<Source>): Source {
    return Source.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Source>): Source {
    const message = createBaseSource();
    message.feature = object.feature ?? '';
    message.campaign = object.campaign ?? '';
    message.channel = object.channel ?? '';
    message.tags = object.tags?.map((e) => e) || [];
    message.link = object.link ?? '';
    message.utmCampaign = object.utmCampaign ?? '';
    message.utmSource = object.utmSource ?? '';
    message.utmMedium = object.utmMedium ?? '';
    return message;
  },
};

function createBaseAcceptTermsDetails(): AcceptTermsDetails {
  return { type: 0, url: '' };
}

export const AcceptTermsDetails = {
  encode(
    message: AcceptTermsDetails,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.url !== '') {
      writer.uint32(18).string(message.url);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptTermsDetails {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptTermsDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AcceptTermsDetails {
    return {
      type: isSet(object.type) ? termsTypeFromJSON(object.type) : 0,
      url: isSet(object.url) ? String(object.url) : '',
    };
  },

  toJSON(message: AcceptTermsDetails): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = termsTypeToJSON(message.type));
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create(base?: DeepPartial<AcceptTermsDetails>): AcceptTermsDetails {
    return AcceptTermsDetails.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<AcceptTermsDetails>): AcceptTermsDetails {
    const message = createBaseAcceptTermsDetails();
    message.type = object.type ?? 0;
    message.url = object.url ?? '';
    return message;
  },
};

function createBasePostLoginAction(): PostLoginAction {
  return {
    type: 0,
    data: new Uint8Array(0),
    completed: false,
    acceptTermsDetails: undefined,
  };
}

export const PostLoginAction = {
  encode(
    message: PostLoginAction,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.data.length !== 0) {
      writer.uint32(18).bytes(message.data);
    }
    if (message.completed === true) {
      writer.uint32(24).bool(message.completed);
    }
    if (message.acceptTermsDetails !== undefined) {
      AcceptTermsDetails.encode(
        message.acceptTermsDetails,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostLoginAction {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostLoginAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = reader.bytes();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.completed = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.acceptTermsDetails = AcceptTermsDetails.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostLoginAction {
    return {
      type: isSet(object.type) ? postLoginActionTypeFromJSON(object.type) : 0,
      data: isSet(object.data)
        ? bytesFromBase64(object.data)
        : new Uint8Array(0),
      completed: isSet(object.completed) ? Boolean(object.completed) : false,
      acceptTermsDetails: isSet(object.acceptTermsDetails)
        ? AcceptTermsDetails.fromJSON(object.acceptTermsDetails)
        : undefined,
    };
  },

  toJSON(message: PostLoginAction): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = postLoginActionTypeToJSON(message.type));
    message.data !== undefined &&
      (obj.data = base64FromBytes(
        message.data !== undefined ? message.data : new Uint8Array(0),
      ));
    message.completed !== undefined && (obj.completed = message.completed);
    message.acceptTermsDetails !== undefined &&
      (obj.acceptTermsDetails = message.acceptTermsDetails
        ? AcceptTermsDetails.toJSON(message.acceptTermsDetails)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<PostLoginAction>): PostLoginAction {
    return PostLoginAction.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PostLoginAction>): PostLoginAction {
    const message = createBasePostLoginAction();
    message.type = object.type ?? 0;
    message.data = object.data ?? new Uint8Array(0);
    message.completed = object.completed ?? false;
    message.acceptTermsDetails =
      object.acceptTermsDetails !== undefined &&
      object.acceptTermsDetails !== null
        ? AcceptTermsDetails.fromPartial(object.acceptTermsDetails)
        : undefined;
    return message;
  },
};

function createBaseMembership(): Membership {
  return {
    accountId: '',
    userId: '',
    status: 0,
    roles: [],
    createdAt: undefined,
    permissions: [],
  };
}

export const Membership = {
  encode(
    message: Membership,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.accountId !== '') {
      writer.uint32(10).string(message.accountId);
    }
    if (message.userId !== '') {
      writer.uint32(18).string(message.userId);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    writer.uint32(34).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(42).fork()).ldelim();
    }
    writer.uint32(50).fork();
    for (const v of message.permissions) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Membership {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMembership();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag === 32) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag === 48) {
            message.permissions.push(reader.int32() as any);

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.permissions.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Membership {
    return {
      accountId: isSet(object.accountId) ? String(object.accountId) : '',
      userId: isSet(object.userId) ? String(object.userId) : '',
      status: isSet(object.status)
        ? membershipStatusFromJSON(object.status)
        : 0,
      roles: Array.isArray(object?.roles)
        ? object.roles.map((e: any) => membershipRoleFromJSON(e))
        : [],
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      permissions: Array.isArray(object?.permissions)
        ? object.permissions.map((e: any) => permissionFromJSON(e))
        : [],
    };
  },

  toJSON(message: Membership): unknown {
    const obj: any = {};
    message.accountId !== undefined && (obj.accountId = message.accountId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.status !== undefined &&
      (obj.status = membershipStatusToJSON(message.status));
    if (message.roles) {
      obj.roles = message.roles.map((e) => membershipRoleToJSON(e));
    } else {
      obj.roles = [];
    }
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    if (message.permissions) {
      obj.permissions = message.permissions.map((e) => permissionToJSON(e));
    } else {
      obj.permissions = [];
    }
    return obj;
  },

  create(base?: DeepPartial<Membership>): Membership {
    return Membership.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Membership>): Membership {
    const message = createBaseMembership();
    message.accountId = object.accountId ?? '';
    message.userId = object.userId ?? '';
    message.status = object.status ?? 0;
    message.roles = object.roles?.map((e) => e) || [];
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.permissions = object.permissions?.map((e) => e) || [];
    return message;
  },
};

function createBaseMember(): Member {
  return {
    membership: undefined,
    name: undefined,
    email: '',
    profilePicture: '',
    displayName: '',
  };
}

export const Member = {
  encode(
    message: Member,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.membership !== undefined) {
      Membership.encode(message.membership, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(18).fork()).ldelim();
    }
    if (message.email !== '') {
      writer.uint32(26).string(message.email);
    }
    if (message.profilePicture !== '') {
      writer.uint32(34).string(message.profilePicture);
    }
    if (message.displayName !== '') {
      writer.uint32(42).string(message.displayName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Member {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMember();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.membership = Membership.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.profilePicture = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.displayName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Member {
    return {
      membership: isSet(object.membership)
        ? Membership.fromJSON(object.membership)
        : undefined,
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
      email: isSet(object.email) ? String(object.email) : '',
      profilePicture: isSet(object.profilePicture)
        ? String(object.profilePicture)
        : '',
      displayName: isSet(object.displayName) ? String(object.displayName) : '',
    };
  },

  toJSON(message: Member): unknown {
    const obj: any = {};
    message.membership !== undefined &&
      (obj.membership = message.membership
        ? Membership.toJSON(message.membership)
        : undefined);
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    message.email !== undefined && (obj.email = message.email);
    message.profilePicture !== undefined &&
      (obj.profilePicture = message.profilePicture);
    message.displayName !== undefined &&
      (obj.displayName = message.displayName);
    return obj;
  },

  create(base?: DeepPartial<Member>): Member {
    return Member.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Member>): Member {
    const message = createBaseMember();
    message.membership =
      object.membership !== undefined && object.membership !== null
        ? Membership.fromPartial(object.membership)
        : undefined;
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    message.email = object.email ?? '';
    message.profilePicture = object.profilePicture ?? '';
    message.displayName = object.displayName ?? '';
    return message;
  },
};

function createBaseMembershipRoleWithPermissions(): MembershipRoleWithPermissions {
  return { role: 0, permissions: [] };
}

export const MembershipRoleWithPermissions = {
  encode(
    message: MembershipRoleWithPermissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.role !== 0) {
      writer.uint32(8).int32(message.role);
    }
    writer.uint32(18).fork();
    for (const v of message.permissions) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MembershipRoleWithPermissions {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMembershipRoleWithPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 2:
          if (tag === 16) {
            message.permissions.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.permissions.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MembershipRoleWithPermissions {
    return {
      role: isSet(object.role) ? membershipRoleFromJSON(object.role) : 0,
      permissions: Array.isArray(object?.permissions)
        ? object.permissions.map((e: any) => permissionFromJSON(e))
        : [],
    };
  },

  toJSON(message: MembershipRoleWithPermissions): unknown {
    const obj: any = {};
    message.role !== undefined &&
      (obj.role = membershipRoleToJSON(message.role));
    if (message.permissions) {
      obj.permissions = message.permissions.map((e) => permissionToJSON(e));
    } else {
      obj.permissions = [];
    }
    return obj;
  },

  create(
    base?: DeepPartial<MembershipRoleWithPermissions>,
  ): MembershipRoleWithPermissions {
    return MembershipRoleWithPermissions.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<MembershipRoleWithPermissions>,
  ): MembershipRoleWithPermissions {
    const message = createBaseMembershipRoleWithPermissions();
    message.role = object.role ?? 0;
    message.permissions = object.permissions?.map((e) => e) || [];
    return message;
  },
};

function createBaseMembershipInvitation(): MembershipInvitation {
  return {
    id: '',
    accountId: '',
    userId: '',
    email: '',
    phoneNumber: '',
    roles: [],
    createdAt: undefined,
    expiresAt: undefined,
    deletedAt: undefined,
    name: '',
  };
}

export const MembershipInvitation = {
  encode(
    message: MembershipInvitation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.accountId !== '') {
      writer.uint32(18).string(message.accountId);
    }
    if (message.userId !== '') {
      writer.uint32(26).string(message.userId);
    }
    if (message.email !== '') {
      writer.uint32(34).string(message.email);
    }
    if (message.phoneNumber !== '') {
      writer.uint32(42).string(message.phoneNumber);
    }
    writer.uint32(50).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(58).fork()).ldelim();
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(66).fork()).ldelim();
    }
    if (message.deletedAt !== undefined) {
      Timestamp.encode(message.deletedAt, writer.uint32(74).fork()).ldelim();
    }
    if (message.name !== '') {
      writer.uint32(82).string(message.name);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MembershipInvitation {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMembershipInvitation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.email = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 6:
          if (tag === 48) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deletedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MembershipInvitation {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      accountId: isSet(object.accountId) ? String(object.accountId) : '',
      userId: isSet(object.userId) ? String(object.userId) : '',
      email: isSet(object.email) ? String(object.email) : '',
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : '',
      roles: Array.isArray(object?.roles)
        ? object.roles.map((e: any) => membershipRoleFromJSON(e))
        : [],
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      expiresAt: isSet(object.expiresAt)
        ? fromJsonTimestamp(object.expiresAt)
        : undefined,
      deletedAt: isSet(object.deletedAt)
        ? fromJsonTimestamp(object.deletedAt)
        : undefined,
      name: isSet(object.name) ? String(object.name) : '',
    };
  },

  toJSON(message: MembershipInvitation): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.accountId !== undefined && (obj.accountId = message.accountId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.email !== undefined && (obj.email = message.email);
    message.phoneNumber !== undefined &&
      (obj.phoneNumber = message.phoneNumber);
    if (message.roles) {
      obj.roles = message.roles.map((e) => membershipRoleToJSON(e));
    } else {
      obj.roles = [];
    }
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.expiresAt !== undefined &&
      (obj.expiresAt = fromTimestamp(message.expiresAt).toISOString());
    message.deletedAt !== undefined &&
      (obj.deletedAt = fromTimestamp(message.deletedAt).toISOString());
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create(base?: DeepPartial<MembershipInvitation>): MembershipInvitation {
    return MembershipInvitation.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<MembershipInvitation>): MembershipInvitation {
    const message = createBaseMembershipInvitation();
    message.id = object.id ?? '';
    message.accountId = object.accountId ?? '';
    message.userId = object.userId ?? '';
    message.email = object.email ?? '';
    message.phoneNumber = object.phoneNumber ?? '';
    message.roles = object.roles?.map((e) => e) || [];
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.expiresAt =
      object.expiresAt !== undefined && object.expiresAt !== null
        ? Timestamp.fromPartial(object.expiresAt)
        : undefined;
    message.deletedAt =
      object.deletedAt !== undefined && object.deletedAt !== null
        ? Timestamp.fromPartial(object.deletedAt)
        : undefined;
    message.name = object.name ?? '';
    return message;
  },
};

function createBaseExternalAuth(): ExternalAuth {
  return { userId: '', providerName: '', providerId: '' };
}

export const ExternalAuth = {
  encode(
    message: ExternalAuth,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.userId !== '') {
      writer.uint32(10).string(message.userId);
    }
    if (message.providerName !== '') {
      writer.uint32(18).string(message.providerName);
    }
    if (message.providerId !== '') {
      writer.uint32(26).string(message.providerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExternalAuth {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExternalAuth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.providerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExternalAuth {
    return {
      userId: isSet(object.userId) ? String(object.userId) : '',
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      providerId: isSet(object.providerId) ? String(object.providerId) : '',
    };
  },

  toJSON(message: ExternalAuth): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.providerId !== undefined && (obj.providerId = message.providerId);
    return obj;
  },

  create(base?: DeepPartial<ExternalAuth>): ExternalAuth {
    return ExternalAuth.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ExternalAuth>): ExternalAuth {
    const message = createBaseExternalAuth();
    message.userId = object.userId ?? '';
    message.providerName = object.providerName ?? '';
    message.providerId = object.providerId ?? '';
    return message;
  },
};

function createBaseStaffTeam(): StaffTeam {
  return { id: '', name: '', contactDetails: {} };
}

export const StaffTeam = {
  encode(
    message: StaffTeam,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    Object.entries(message.contactDetails).forEach(([key, value]) => {
      StaffTeam_ContactDetailsEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StaffTeam {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaffTeam();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = StaffTeam_ContactDetailsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry3.value !== undefined) {
            message.contactDetails[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaffTeam {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      contactDetails: isObject(object.contactDetails)
        ? Object.entries(object.contactDetails).reduce<{
            [key: string]: string;
          }>((acc, [key, value]) => {
            acc[key] = String(value);
            return acc;
          }, {})
        : {},
    };
  },

  toJSON(message: StaffTeam): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    obj.contactDetails = {};
    if (message.contactDetails) {
      Object.entries(message.contactDetails).forEach(([k, v]) => {
        obj.contactDetails[k] = v;
      });
    }
    return obj;
  },

  create(base?: DeepPartial<StaffTeam>): StaffTeam {
    return StaffTeam.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<StaffTeam>): StaffTeam {
    const message = createBaseStaffTeam();
    message.id = object.id ?? '';
    message.name = object.name ?? '';
    message.contactDetails = Object.entries(
      object.contactDetails ?? {},
    ).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseStaffTeam_ContactDetailsEntry(): StaffTeam_ContactDetailsEntry {
  return { key: '', value: '' };
}

export const StaffTeam_ContactDetailsEntry = {
  encode(
    message: StaffTeam_ContactDetailsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StaffTeam_ContactDetailsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaffTeam_ContactDetailsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaffTeam_ContactDetailsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: StaffTeam_ContactDetailsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<StaffTeam_ContactDetailsEntry>,
  ): StaffTeam_ContactDetailsEntry {
    return StaffTeam_ContactDetailsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<StaffTeam_ContactDetailsEntry>,
  ): StaffTeam_ContactDetailsEntry {
    const message = createBaseStaffTeam_ContactDetailsEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseStaffTeamMember(): StaffTeamMember {
  return { staffId: '', staffTeamId: '', roles: [] };
}

export const StaffTeamMember = {
  encode(
    message: StaffTeamMember,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.staffId !== '') {
      writer.uint32(18).string(message.staffId);
    }
    if (message.staffTeamId !== '') {
      writer.uint32(26).string(message.staffTeamId);
    }
    for (const v of message.roles) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StaffTeamMember {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStaffTeamMember();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.staffTeamId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.roles.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StaffTeamMember {
    return {
      staffId: isSet(object.staffId) ? String(object.staffId) : '',
      staffTeamId: isSet(object.staffTeamId) ? String(object.staffTeamId) : '',
      roles: Array.isArray(object?.roles)
        ? object.roles.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: StaffTeamMember): unknown {
    const obj: any = {};
    message.staffId !== undefined && (obj.staffId = message.staffId);
    message.staffTeamId !== undefined &&
      (obj.staffTeamId = message.staffTeamId);
    if (message.roles) {
      obj.roles = message.roles.map((e) => e);
    } else {
      obj.roles = [];
    }
    return obj;
  },

  create(base?: DeepPartial<StaffTeamMember>): StaffTeamMember {
    return StaffTeamMember.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<StaffTeamMember>): StaffTeamMember {
    const message = createBaseStaffTeamMember();
    message.staffId = object.staffId ?? '';
    message.staffTeamId = object.staffTeamId ?? '';
    message.roles = object.roles?.map((e) => e) || [];
    return message;
  },
};

function createBaseAcceptTermsActionData(): AcceptTermsActionData {
  return { type: 0, url: '', title: '' };
}

export const AcceptTermsActionData = {
  encode(
    message: AcceptTermsActionData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.url !== '') {
      writer.uint32(18).string(message.url);
    }
    if (message.title !== '') {
      writer.uint32(26).string(message.title);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AcceptTermsActionData {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptTermsActionData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AcceptTermsActionData {
    return {
      type: isSet(object.type) ? termsTypeFromJSON(object.type) : 0,
      url: isSet(object.url) ? String(object.url) : '',
      title: isSet(object.title) ? String(object.title) : '',
    };
  },

  toJSON(message: AcceptTermsActionData): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = termsTypeToJSON(message.type));
    message.url !== undefined && (obj.url = message.url);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create(base?: DeepPartial<AcceptTermsActionData>): AcceptTermsActionData {
    return AcceptTermsActionData.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<AcceptTermsActionData>,
  ): AcceptTermsActionData {
    const message = createBaseAcceptTermsActionData();
    message.type = object.type ?? 0;
    message.url = object.url ?? '';
    message.title = object.title ?? '';
    return message;
  },
};

function createBaseSetTagActionData(): SetTagActionData {
  return { suggestedTag: '', minLength: 0, maxLength: 0 };
}

export const SetTagActionData = {
  encode(
    message: SetTagActionData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.suggestedTag !== '') {
      writer.uint32(10).string(message.suggestedTag);
    }
    if (message.minLength !== 0) {
      writer.uint32(16).int32(message.minLength);
    }
    if (message.maxLength !== 0) {
      writer.uint32(24).int32(message.maxLength);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetTagActionData {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTagActionData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.suggestedTag = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.minLength = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.maxLength = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetTagActionData {
    return {
      suggestedTag: isSet(object.suggestedTag)
        ? String(object.suggestedTag)
        : '',
      minLength: isSet(object.minLength) ? Number(object.minLength) : 0,
      maxLength: isSet(object.maxLength) ? Number(object.maxLength) : 0,
    };
  },

  toJSON(message: SetTagActionData): unknown {
    const obj: any = {};
    message.suggestedTag !== undefined &&
      (obj.suggestedTag = message.suggestedTag);
    message.minLength !== undefined &&
      (obj.minLength = Math.round(message.minLength));
    message.maxLength !== undefined &&
      (obj.maxLength = Math.round(message.maxLength));
    return obj;
  },

  create(base?: DeepPartial<SetTagActionData>): SetTagActionData {
    return SetTagActionData.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SetTagActionData>): SetTagActionData {
    const message = createBaseSetTagActionData();
    message.suggestedTag = object.suggestedTag ?? '';
    message.minLength = object.minLength ?? 0;
    message.maxLength = object.maxLength ?? 0;
    return message;
  },
};

function createBaseAction(): Action {
  return { type: 0, acceptTerms: undefined, setTag: undefined };
}

export const Action = {
  encode(
    message: Action,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.acceptTerms !== undefined) {
      AcceptTermsActionData.encode(
        message.acceptTerms,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.setTag !== undefined) {
      SetTagActionData.encode(
        message.setTag,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Action {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.acceptTerms = AcceptTermsActionData.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.setTag = SetTagActionData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Action {
    return {
      type: isSet(object.type) ? actionTypeFromJSON(object.type) : 0,
      acceptTerms: isSet(object.acceptTerms)
        ? AcceptTermsActionData.fromJSON(object.acceptTerms)
        : undefined,
      setTag: isSet(object.setTag)
        ? SetTagActionData.fromJSON(object.setTag)
        : undefined,
    };
  },

  toJSON(message: Action): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = actionTypeToJSON(message.type));
    message.acceptTerms !== undefined &&
      (obj.acceptTerms = message.acceptTerms
        ? AcceptTermsActionData.toJSON(message.acceptTerms)
        : undefined);
    message.setTag !== undefined &&
      (obj.setTag = message.setTag
        ? SetTagActionData.toJSON(message.setTag)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<Action>): Action {
    return Action.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Action>): Action {
    const message = createBaseAction();
    message.type = object.type ?? 0;
    message.acceptTerms =
      object.acceptTerms !== undefined && object.acceptTerms !== null
        ? AcceptTermsActionData.fromPartial(object.acceptTerms)
        : undefined;
    message.setTag =
      object.setTag !== undefined && object.setTag !== null
        ? SetTagActionData.fromPartial(object.setTag)
        : undefined;
    return message;
  },
};

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, 'base64'));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString('base64');
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(''));
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error(
      'Value is larger than Number.MAX_SAFE_INTEGER',
    );
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
