/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../../google/protobuf/timestamp';
import {
  Address,
  EntityType,
  entityTypeFromJSON,
  entityTypeToJSON,
  Name,
  PublicStatus,
  publicStatusFromJSON,
  publicStatusToJSON,
} from '../kyc/types';
import {
  BankAccountDetails,
  BankAccountType,
  bankAccountTypeFromJSON,
  bankAccountTypeToJSON,
} from '../utils/types';

export const protobufPackage = 'disbursements';

/**
 * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
 * buf:lint:ignore COMMENT_ENUM
 */
export enum PhoneOperator {
  PHONE_OPERATOR_MISSING = 0,
  PHONE_OPERATOR_UNKNOWN = 1,
  PHONE_OPERATOR_MPESA = 2,
  PHONE_OPERATOR_AIRTEL = 3,
  PHONE_OPERATOR_TIGO = 4,
  PHONE_OPERATOR_MTN = 5,
  PHONE_OPERATOR_VODAFONE = 6,
  PHONE_OPERATOR_TTCL = 7,
  PHONE_OPERATOR_ZANTEL = 8,
  PHONE_OPERATOR_HALOTEL = 9,
  PHONE_OPERATOR_ORANGE = 10,
  PHONE_OPERATOR_FREE = 11,
  PHONE_OPERATOR_EMONEY = 12,
  PHONE_OPERATOR_MOOV = 13,
  PHONE_OPERATOR_AFRICELL = 14,
  PHONE_OPERATOR_QCELL = 15,
  PHONE_OPERATOR_GAMCEL = 16,
  UNRECOGNIZED = -1,
}

export function phoneOperatorFromJSON(object: any): PhoneOperator {
  switch (object) {
    case 0:
    case 'PHONE_OPERATOR_MISSING':
      return PhoneOperator.PHONE_OPERATOR_MISSING;
    case 1:
    case 'PHONE_OPERATOR_UNKNOWN':
      return PhoneOperator.PHONE_OPERATOR_UNKNOWN;
    case 2:
    case 'PHONE_OPERATOR_MPESA':
      return PhoneOperator.PHONE_OPERATOR_MPESA;
    case 3:
    case 'PHONE_OPERATOR_AIRTEL':
      return PhoneOperator.PHONE_OPERATOR_AIRTEL;
    case 4:
    case 'PHONE_OPERATOR_TIGO':
      return PhoneOperator.PHONE_OPERATOR_TIGO;
    case 5:
    case 'PHONE_OPERATOR_MTN':
      return PhoneOperator.PHONE_OPERATOR_MTN;
    case 6:
    case 'PHONE_OPERATOR_VODAFONE':
      return PhoneOperator.PHONE_OPERATOR_VODAFONE;
    case 7:
    case 'PHONE_OPERATOR_TTCL':
      return PhoneOperator.PHONE_OPERATOR_TTCL;
    case 8:
    case 'PHONE_OPERATOR_ZANTEL':
      return PhoneOperator.PHONE_OPERATOR_ZANTEL;
    case 9:
    case 'PHONE_OPERATOR_HALOTEL':
      return PhoneOperator.PHONE_OPERATOR_HALOTEL;
    case 10:
    case 'PHONE_OPERATOR_ORANGE':
      return PhoneOperator.PHONE_OPERATOR_ORANGE;
    case 11:
    case 'PHONE_OPERATOR_FREE':
      return PhoneOperator.PHONE_OPERATOR_FREE;
    case 12:
    case 'PHONE_OPERATOR_EMONEY':
      return PhoneOperator.PHONE_OPERATOR_EMONEY;
    case 13:
    case 'PHONE_OPERATOR_MOOV':
      return PhoneOperator.PHONE_OPERATOR_MOOV;
    case 14:
    case 'PHONE_OPERATOR_AFRICELL':
      return PhoneOperator.PHONE_OPERATOR_AFRICELL;
    case 15:
    case 'PHONE_OPERATOR_QCELL':
      return PhoneOperator.PHONE_OPERATOR_QCELL;
    case 16:
    case 'PHONE_OPERATOR_GAMCEL':
      return PhoneOperator.PHONE_OPERATOR_GAMCEL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PhoneOperator.UNRECOGNIZED;
  }
}

export function phoneOperatorToJSON(object: PhoneOperator): string {
  switch (object) {
    case PhoneOperator.PHONE_OPERATOR_MISSING:
      return 'PHONE_OPERATOR_MISSING';
    case PhoneOperator.PHONE_OPERATOR_UNKNOWN:
      return 'PHONE_OPERATOR_UNKNOWN';
    case PhoneOperator.PHONE_OPERATOR_MPESA:
      return 'PHONE_OPERATOR_MPESA';
    case PhoneOperator.PHONE_OPERATOR_AIRTEL:
      return 'PHONE_OPERATOR_AIRTEL';
    case PhoneOperator.PHONE_OPERATOR_TIGO:
      return 'PHONE_OPERATOR_TIGO';
    case PhoneOperator.PHONE_OPERATOR_MTN:
      return 'PHONE_OPERATOR_MTN';
    case PhoneOperator.PHONE_OPERATOR_VODAFONE:
      return 'PHONE_OPERATOR_VODAFONE';
    case PhoneOperator.PHONE_OPERATOR_TTCL:
      return 'PHONE_OPERATOR_TTCL';
    case PhoneOperator.PHONE_OPERATOR_ZANTEL:
      return 'PHONE_OPERATOR_ZANTEL';
    case PhoneOperator.PHONE_OPERATOR_HALOTEL:
      return 'PHONE_OPERATOR_HALOTEL';
    case PhoneOperator.PHONE_OPERATOR_ORANGE:
      return 'PHONE_OPERATOR_ORANGE';
    case PhoneOperator.PHONE_OPERATOR_FREE:
      return 'PHONE_OPERATOR_FREE';
    case PhoneOperator.PHONE_OPERATOR_EMONEY:
      return 'PHONE_OPERATOR_EMONEY';
    case PhoneOperator.PHONE_OPERATOR_MOOV:
      return 'PHONE_OPERATOR_MOOV';
    case PhoneOperator.PHONE_OPERATOR_AFRICELL:
      return 'PHONE_OPERATOR_AFRICELL';
    case PhoneOperator.PHONE_OPERATOR_QCELL:
      return 'PHONE_OPERATOR_QCELL';
    case PhoneOperator.PHONE_OPERATOR_GAMCEL:
      return 'PHONE_OPERATOR_GAMCEL';
    case PhoneOperator.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum AccountType {
  ACCOUNT_TYPE_INVALID = 0,
  ACCOUNT_TYPE_MOBILE_MONEY = 1,
  ACCOUNT_TYPE_BANK_ACCOUNT = 2,
  ACCOUNT_TYPE_BUY_GOODS = 3,
  ACCOUNT_TYPE_PAY_BILL = 4,
  /** ACCOUNT_TYPE_OWN_BANK_ACCOUNT - Same data as bank account but the recipient refers to the same entity as the associated account owner */
  ACCOUNT_TYPE_OWN_BANK_ACCOUNT = 5,
  ACCOUNT_TYPE_EXTERNAL_ACCOUNT = 6,
  ACCOUNT_TYPE_PEER_TO_PEER = 7,
  UNRECOGNIZED = -1,
}

export function accountTypeFromJSON(object: any): AccountType {
  switch (object) {
    case 0:
    case 'ACCOUNT_TYPE_INVALID':
      return AccountType.ACCOUNT_TYPE_INVALID;
    case 1:
    case 'ACCOUNT_TYPE_MOBILE_MONEY':
      return AccountType.ACCOUNT_TYPE_MOBILE_MONEY;
    case 2:
    case 'ACCOUNT_TYPE_BANK_ACCOUNT':
      return AccountType.ACCOUNT_TYPE_BANK_ACCOUNT;
    case 3:
    case 'ACCOUNT_TYPE_BUY_GOODS':
      return AccountType.ACCOUNT_TYPE_BUY_GOODS;
    case 4:
    case 'ACCOUNT_TYPE_PAY_BILL':
      return AccountType.ACCOUNT_TYPE_PAY_BILL;
    case 5:
    case 'ACCOUNT_TYPE_OWN_BANK_ACCOUNT':
      return AccountType.ACCOUNT_TYPE_OWN_BANK_ACCOUNT;
    case 6:
    case 'ACCOUNT_TYPE_EXTERNAL_ACCOUNT':
      return AccountType.ACCOUNT_TYPE_EXTERNAL_ACCOUNT;
    case 7:
    case 'ACCOUNT_TYPE_PEER_TO_PEER':
      return AccountType.ACCOUNT_TYPE_PEER_TO_PEER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AccountType.UNRECOGNIZED;
  }
}

export function accountTypeToJSON(object: AccountType): string {
  switch (object) {
    case AccountType.ACCOUNT_TYPE_INVALID:
      return 'ACCOUNT_TYPE_INVALID';
    case AccountType.ACCOUNT_TYPE_MOBILE_MONEY:
      return 'ACCOUNT_TYPE_MOBILE_MONEY';
    case AccountType.ACCOUNT_TYPE_BANK_ACCOUNT:
      return 'ACCOUNT_TYPE_BANK_ACCOUNT';
    case AccountType.ACCOUNT_TYPE_BUY_GOODS:
      return 'ACCOUNT_TYPE_BUY_GOODS';
    case AccountType.ACCOUNT_TYPE_PAY_BILL:
      return 'ACCOUNT_TYPE_PAY_BILL';
    case AccountType.ACCOUNT_TYPE_OWN_BANK_ACCOUNT:
      return 'ACCOUNT_TYPE_OWN_BANK_ACCOUNT';
    case AccountType.ACCOUNT_TYPE_EXTERNAL_ACCOUNT:
      return 'ACCOUNT_TYPE_EXTERNAL_ACCOUNT';
    case AccountType.ACCOUNT_TYPE_PEER_TO_PEER:
      return 'ACCOUNT_TYPE_PEER_TO_PEER';
    case AccountType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * This enum defines the type of external account.
 * The value 0 is reserved for an invalid type.
 * buf:lint:ignore COMMENT_ENUM
 */
export enum ExternalAccountType {
  /** EXTERNAL_ACCOUNT_TYPE_INVALID - This enum defines the type of external account. */
  EXTERNAL_ACCOUNT_TYPE_INVALID = 0,
  /** EXTERNAL_ACCOUNT_TYPE_WAVE - This value represents an external Wave account. */
  EXTERNAL_ACCOUNT_TYPE_WAVE = 1,
  UNRECOGNIZED = -1,
}

export function externalAccountTypeFromJSON(object: any): ExternalAccountType {
  switch (object) {
    case 0:
    case 'EXTERNAL_ACCOUNT_TYPE_INVALID':
      return ExternalAccountType.EXTERNAL_ACCOUNT_TYPE_INVALID;
    case 1:
    case 'EXTERNAL_ACCOUNT_TYPE_WAVE':
      return ExternalAccountType.EXTERNAL_ACCOUNT_TYPE_WAVE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ExternalAccountType.UNRECOGNIZED;
  }
}

export function externalAccountTypeToJSON(object: ExternalAccountType): string {
  switch (object) {
    case ExternalAccountType.EXTERNAL_ACCOUNT_TYPE_INVALID:
      return 'EXTERNAL_ACCOUNT_TYPE_INVALID';
    case ExternalAccountType.EXTERNAL_ACCOUNT_TYPE_WAVE:
      return 'EXTERNAL_ACCOUNT_TYPE_WAVE';
    case ExternalAccountType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** DisbursementError represents the error that occurred during a disbursement. */
export enum DisbursementError {
  DISBURSEMENT_ERROR_INVALID = 0,
  DISBURSEMENT_ERROR_UNKNOWN = 1,
  DISBURSEMENT_ERROR_INSUFFICIENT_FUNDS = 2,
  DISBURSEMENT_ERROR_RECIPIENT_PER_TRANSACTION_LIMIT_REACHED = 3,
  DISBURSEMENT_ERROR_RECIPIENT_LIMIT_REACHED = 4,
  DISBURSEMENT_ERROR_RECIPIENT_DAILY_LIMIT_REACHED = 5,
  DISBURSEMENT_ERROR_RECIPIENT_WEEKLY_LIMIT_REACHED = 6,
  DISBURSEMENT_ERROR_RECIPIENT_MONTHLY_LIMIT_REACHED = 7,
  DISBURSEMENT_ERROR_RECIPIENT_BALANCE_MAXED_OUT = 8,
  DISBURSEMENT_ERROR_RECIPIENT_INVALID_ACCOUNT_NUMBER = 9,
  UNRECOGNIZED = -1,
}

export function disbursementErrorFromJSON(object: any): DisbursementError {
  switch (object) {
    case 0:
    case 'DISBURSEMENT_ERROR_INVALID':
      return DisbursementError.DISBURSEMENT_ERROR_INVALID;
    case 1:
    case 'DISBURSEMENT_ERROR_UNKNOWN':
      return DisbursementError.DISBURSEMENT_ERROR_UNKNOWN;
    case 2:
    case 'DISBURSEMENT_ERROR_INSUFFICIENT_FUNDS':
      return DisbursementError.DISBURSEMENT_ERROR_INSUFFICIENT_FUNDS;
    case 3:
    case 'DISBURSEMENT_ERROR_RECIPIENT_PER_TRANSACTION_LIMIT_REACHED':
      return DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_PER_TRANSACTION_LIMIT_REACHED;
    case 4:
    case 'DISBURSEMENT_ERROR_RECIPIENT_LIMIT_REACHED':
      return DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_LIMIT_REACHED;
    case 5:
    case 'DISBURSEMENT_ERROR_RECIPIENT_DAILY_LIMIT_REACHED':
      return DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_DAILY_LIMIT_REACHED;
    case 6:
    case 'DISBURSEMENT_ERROR_RECIPIENT_WEEKLY_LIMIT_REACHED':
      return DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_WEEKLY_LIMIT_REACHED;
    case 7:
    case 'DISBURSEMENT_ERROR_RECIPIENT_MONTHLY_LIMIT_REACHED':
      return DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_MONTHLY_LIMIT_REACHED;
    case 8:
    case 'DISBURSEMENT_ERROR_RECIPIENT_BALANCE_MAXED_OUT':
      return DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_BALANCE_MAXED_OUT;
    case 9:
    case 'DISBURSEMENT_ERROR_RECIPIENT_INVALID_ACCOUNT_NUMBER':
      return DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_INVALID_ACCOUNT_NUMBER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DisbursementError.UNRECOGNIZED;
  }
}

export function disbursementErrorToJSON(object: DisbursementError): string {
  switch (object) {
    case DisbursementError.DISBURSEMENT_ERROR_INVALID:
      return 'DISBURSEMENT_ERROR_INVALID';
    case DisbursementError.DISBURSEMENT_ERROR_UNKNOWN:
      return 'DISBURSEMENT_ERROR_UNKNOWN';
    case DisbursementError.DISBURSEMENT_ERROR_INSUFFICIENT_FUNDS:
      return 'DISBURSEMENT_ERROR_INSUFFICIENT_FUNDS';
    case DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_PER_TRANSACTION_LIMIT_REACHED:
      return 'DISBURSEMENT_ERROR_RECIPIENT_PER_TRANSACTION_LIMIT_REACHED';
    case DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_LIMIT_REACHED:
      return 'DISBURSEMENT_ERROR_RECIPIENT_LIMIT_REACHED';
    case DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_DAILY_LIMIT_REACHED:
      return 'DISBURSEMENT_ERROR_RECIPIENT_DAILY_LIMIT_REACHED';
    case DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_WEEKLY_LIMIT_REACHED:
      return 'DISBURSEMENT_ERROR_RECIPIENT_WEEKLY_LIMIT_REACHED';
    case DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_MONTHLY_LIMIT_REACHED:
      return 'DISBURSEMENT_ERROR_RECIPIENT_MONTHLY_LIMIT_REACHED';
    case DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_BALANCE_MAXED_OUT:
      return 'DISBURSEMENT_ERROR_RECIPIENT_BALANCE_MAXED_OUT';
    case DisbursementError.DISBURSEMENT_ERROR_RECIPIENT_INVALID_ACCOUNT_NUMBER:
      return 'DISBURSEMENT_ERROR_RECIPIENT_INVALID_ACCOUNT_NUMBER';
    case DisbursementError.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Recipient {
  id: string;
  userId: string;
  phoneNumber: string;
  country: string;
  /** name is only set if recipient_type is RECIPIENT_TYPE_INDIVIDUAL. */
  name: Name | undefined;
  /** display_name must be set if recipient_type is RECIPIENT_TYPE_BUSINESS. */
  displayName: string;
  lastTransactionCreatedAt: Timestamp | undefined;
  phoneOperator: PhoneOperator;
  status: string;
  purpose: string;
  createdAt: Timestamp | undefined;
  lastUpdatedAt: Timestamp | undefined;
  accounts: RecipientAccount[];
  deletedAt: Timestamp | undefined;
  type: EntityType;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface MobileMoneyAccount {
  /** @deprecated */
  deprecatedCountry: string;
  phoneNumber: string;
  phoneOperator: PhoneOperator;
}

/**
 * This message defines the details of an external Wave account.
 * buf:lint:ignore COMMENT_MESSAGE
 */
export interface ExternalAccountDetailsWave {
  /** This field contains the phone number associated with the Wave account. */
  phoneNumber: string;
}

/**
 * This message represents an external account of a certain type.
 * It contains the details of the account service based on its type.
 * buf:lint:ignore COMMENT_MESSAGE
 */
export interface ExternalAccount {
  /** The type of external account (e.g. Wave). */
  type: ExternalAccountType;
  /** Add other account details as necessary. */
  waveDetails?: ExternalAccountDetailsWave | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface BankAccount {
  /** @deprecated */
  deprecatedCountry: string;
  accountNumber: string;
  bankCode: string;
  bankName: string;
  branchCode: string;
  branchName: string;
  /** SWIFT only fields: */
  accountType: BankAccountType;
  bankAddress: string;
  swiftReference: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface TillAccount {
  accountNumber: string;
  defaultReference: string;
}

/** PeerToPeerAccount represents another Nala account. */
export interface PeerToPeerAccount {
  tag: string;
  profilePicUrl: string;
  /** compatible determines if money can be sent to this account from the calling account. */
  canSend: boolean;
  /** disabled determines if an account has been disabled by the system or the account owner. */
  disabled: boolean;
  /** disabled_reason is set if disabled is true. */
  disabledReason: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface RecipientAccount {
  id: string;
  recipientId: string;
  accountType: AccountType;
  /** name is only set if recipient_type is RECIPIENT_TYPE_INDIVIDUAL. */
  name: Name | undefined;
  /** display_name must be set if recipient_type is RECIPIENT_TYPE_BUSINESS. */
  displayName: string;
  createdAt: Timestamp | undefined;
  lastUpdatedAt: Timestamp | undefined;
  lastTransactionCreatedAt: Timestamp | undefined;
  status: string;
  purpose: string;
  mobileMoney: MobileMoneyAccount | undefined;
  /** @deprecated */
  deprecatedBankAccount: BankAccount | undefined;
  deletedAt: Timestamp | undefined;
  address: Address | undefined;
  /** currency_code will be empty for PEER_TO_PEER account type. */
  currencyCode: string;
  tillAccount: TillAccount | undefined;
  recipientType: EntityType;
  publicStatus: PublicStatus;
  externalAccount: ExternalAccount | undefined;
  peerToPeerAccount: PeerToPeerAccount | undefined;
  bankAccount: BankAccountDetails | undefined;
}

/** Bank branch */
export interface Branch {
  code: string;
  name: string;
  id: number;
  /** Optional. Business Identifier Code aka SWIFT code. 8 or 11 characters. */
  bic: string;
}

/**
 * A Bank has unique id and unique (country, code),
 * ie. the same code could exist for a bank in another country.
 */
export interface Bank {
  code: string;
  name: string;
  branches: Branch[];
  id: number;
  country: string;
  /** Optional. Business Identifier Code aka SWIFT code. 8 or 11 characters. */
  bic: string;
}

function createBaseRecipient(): Recipient {
  return {
    id: '',
    userId: '',
    phoneNumber: '',
    country: '',
    name: undefined,
    displayName: '',
    lastTransactionCreatedAt: undefined,
    phoneOperator: 0,
    status: '',
    purpose: '',
    createdAt: undefined,
    lastUpdatedAt: undefined,
    accounts: [],
    deletedAt: undefined,
    type: 0,
  };
}

export const Recipient = {
  encode(
    message: Recipient,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.userId !== '') {
      writer.uint32(18).string(message.userId);
    }
    if (message.phoneNumber !== '') {
      writer.uint32(26).string(message.phoneNumber);
    }
    if (message.country !== '') {
      writer.uint32(34).string(message.country);
    }
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(42).fork()).ldelim();
    }
    if (message.displayName !== '') {
      writer.uint32(50).string(message.displayName);
    }
    if (message.lastTransactionCreatedAt !== undefined) {
      Timestamp.encode(
        message.lastTransactionCreatedAt,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.phoneOperator !== 0) {
      writer.uint32(64).int32(message.phoneOperator);
    }
    if (message.status !== '') {
      writer.uint32(74).string(message.status);
    }
    if (message.purpose !== '') {
      writer.uint32(82).string(message.purpose);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(90).fork()).ldelim();
    }
    if (message.lastUpdatedAt !== undefined) {
      Timestamp.encode(
        message.lastUpdatedAt,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    for (const v of message.accounts) {
      RecipientAccount.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.deletedAt !== undefined) {
      Timestamp.encode(message.deletedAt, writer.uint32(114).fork()).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(120).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Recipient {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecipient();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.country = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastTransactionCreatedAt = Timestamp.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.phoneOperator = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.status = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.purpose = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.lastUpdatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.accounts.push(
            RecipientAccount.decode(reader, reader.uint32()),
          );
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.deletedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Recipient {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      userId: isSet(object.userId) ? String(object.userId) : '',
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : '',
      country: isSet(object.country) ? String(object.country) : '',
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
      displayName: isSet(object.displayName) ? String(object.displayName) : '',
      lastTransactionCreatedAt: isSet(object.lastTransactionCreatedAt)
        ? fromJsonTimestamp(object.lastTransactionCreatedAt)
        : undefined,
      phoneOperator: isSet(object.phoneOperator)
        ? phoneOperatorFromJSON(object.phoneOperator)
        : 0,
      status: isSet(object.status) ? String(object.status) : '',
      purpose: isSet(object.purpose) ? String(object.purpose) : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      lastUpdatedAt: isSet(object.lastUpdatedAt)
        ? fromJsonTimestamp(object.lastUpdatedAt)
        : undefined,
      accounts: Array.isArray(object?.accounts)
        ? object.accounts.map((e: any) => RecipientAccount.fromJSON(e))
        : [],
      deletedAt: isSet(object.deletedAt)
        ? fromJsonTimestamp(object.deletedAt)
        : undefined,
      type: isSet(object.type) ? entityTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: Recipient): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userId !== undefined && (obj.userId = message.userId);
    message.phoneNumber !== undefined &&
      (obj.phoneNumber = message.phoneNumber);
    message.country !== undefined && (obj.country = message.country);
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    message.displayName !== undefined &&
      (obj.displayName = message.displayName);
    message.lastTransactionCreatedAt !== undefined &&
      (obj.lastTransactionCreatedAt = fromTimestamp(
        message.lastTransactionCreatedAt,
      ).toISOString());
    message.phoneOperator !== undefined &&
      (obj.phoneOperator = phoneOperatorToJSON(message.phoneOperator));
    message.status !== undefined && (obj.status = message.status);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.lastUpdatedAt !== undefined &&
      (obj.lastUpdatedAt = fromTimestamp(message.lastUpdatedAt).toISOString());
    if (message.accounts) {
      obj.accounts = message.accounts.map((e) =>
        e ? RecipientAccount.toJSON(e) : undefined,
      );
    } else {
      obj.accounts = [];
    }
    message.deletedAt !== undefined &&
      (obj.deletedAt = fromTimestamp(message.deletedAt).toISOString());
    message.type !== undefined && (obj.type = entityTypeToJSON(message.type));
    return obj;
  },

  create(base?: DeepPartial<Recipient>): Recipient {
    return Recipient.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Recipient>): Recipient {
    const message = createBaseRecipient();
    message.id = object.id ?? '';
    message.userId = object.userId ?? '';
    message.phoneNumber = object.phoneNumber ?? '';
    message.country = object.country ?? '';
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    message.displayName = object.displayName ?? '';
    message.lastTransactionCreatedAt =
      object.lastTransactionCreatedAt !== undefined &&
      object.lastTransactionCreatedAt !== null
        ? Timestamp.fromPartial(object.lastTransactionCreatedAt)
        : undefined;
    message.phoneOperator = object.phoneOperator ?? 0;
    message.status = object.status ?? '';
    message.purpose = object.purpose ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.lastUpdatedAt =
      object.lastUpdatedAt !== undefined && object.lastUpdatedAt !== null
        ? Timestamp.fromPartial(object.lastUpdatedAt)
        : undefined;
    message.accounts =
      object.accounts?.map((e) => RecipientAccount.fromPartial(e)) || [];
    message.deletedAt =
      object.deletedAt !== undefined && object.deletedAt !== null
        ? Timestamp.fromPartial(object.deletedAt)
        : undefined;
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseMobileMoneyAccount(): MobileMoneyAccount {
  return { deprecatedCountry: '', phoneNumber: '', phoneOperator: 0 };
}

export const MobileMoneyAccount = {
  encode(
    message: MobileMoneyAccount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.deprecatedCountry !== '') {
      writer.uint32(10).string(message.deprecatedCountry);
    }
    if (message.phoneNumber !== '') {
      writer.uint32(18).string(message.phoneNumber);
    }
    if (message.phoneOperator !== 0) {
      writer.uint32(24).int32(message.phoneOperator);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MobileMoneyAccount {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMobileMoneyAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deprecatedCountry = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.phoneOperator = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MobileMoneyAccount {
    return {
      deprecatedCountry: isSet(object.deprecatedCountry)
        ? String(object.deprecatedCountry)
        : '',
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : '',
      phoneOperator: isSet(object.phoneOperator)
        ? phoneOperatorFromJSON(object.phoneOperator)
        : 0,
    };
  },

  toJSON(message: MobileMoneyAccount): unknown {
    const obj: any = {};
    message.deprecatedCountry !== undefined &&
      (obj.deprecatedCountry = message.deprecatedCountry);
    message.phoneNumber !== undefined &&
      (obj.phoneNumber = message.phoneNumber);
    message.phoneOperator !== undefined &&
      (obj.phoneOperator = phoneOperatorToJSON(message.phoneOperator));
    return obj;
  },

  create(base?: DeepPartial<MobileMoneyAccount>): MobileMoneyAccount {
    return MobileMoneyAccount.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<MobileMoneyAccount>): MobileMoneyAccount {
    const message = createBaseMobileMoneyAccount();
    message.deprecatedCountry = object.deprecatedCountry ?? '';
    message.phoneNumber = object.phoneNumber ?? '';
    message.phoneOperator = object.phoneOperator ?? 0;
    return message;
  },
};

function createBaseExternalAccountDetailsWave(): ExternalAccountDetailsWave {
  return { phoneNumber: '' };
}

export const ExternalAccountDetailsWave = {
  encode(
    message: ExternalAccountDetailsWave,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.phoneNumber !== '') {
      writer.uint32(10).string(message.phoneNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ExternalAccountDetailsWave {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExternalAccountDetailsWave();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExternalAccountDetailsWave {
    return {
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : '',
    };
  },

  toJSON(message: ExternalAccountDetailsWave): unknown {
    const obj: any = {};
    message.phoneNumber !== undefined &&
      (obj.phoneNumber = message.phoneNumber);
    return obj;
  },

  create(
    base?: DeepPartial<ExternalAccountDetailsWave>,
  ): ExternalAccountDetailsWave {
    return ExternalAccountDetailsWave.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<ExternalAccountDetailsWave>,
  ): ExternalAccountDetailsWave {
    const message = createBaseExternalAccountDetailsWave();
    message.phoneNumber = object.phoneNumber ?? '';
    return message;
  },
};

function createBaseExternalAccount(): ExternalAccount {
  return { type: 0, waveDetails: undefined };
}

export const ExternalAccount = {
  encode(
    message: ExternalAccount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.waveDetails !== undefined) {
      ExternalAccountDetailsWave.encode(
        message.waveDetails,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExternalAccount {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExternalAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.waveDetails = ExternalAccountDetailsWave.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ExternalAccount {
    return {
      type: isSet(object.type) ? externalAccountTypeFromJSON(object.type) : 0,
      waveDetails: isSet(object.waveDetails)
        ? ExternalAccountDetailsWave.fromJSON(object.waveDetails)
        : undefined,
    };
  },

  toJSON(message: ExternalAccount): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = externalAccountTypeToJSON(message.type));
    message.waveDetails !== undefined &&
      (obj.waveDetails = message.waveDetails
        ? ExternalAccountDetailsWave.toJSON(message.waveDetails)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<ExternalAccount>): ExternalAccount {
    return ExternalAccount.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ExternalAccount>): ExternalAccount {
    const message = createBaseExternalAccount();
    message.type = object.type ?? 0;
    message.waveDetails =
      object.waveDetails !== undefined && object.waveDetails !== null
        ? ExternalAccountDetailsWave.fromPartial(object.waveDetails)
        : undefined;
    return message;
  },
};

function createBaseBankAccount(): BankAccount {
  return {
    deprecatedCountry: '',
    accountNumber: '',
    bankCode: '',
    bankName: '',
    branchCode: '',
    branchName: '',
    accountType: 0,
    bankAddress: '',
    swiftReference: '',
  };
}

export const BankAccount = {
  encode(
    message: BankAccount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.deprecatedCountry !== '') {
      writer.uint32(10).string(message.deprecatedCountry);
    }
    if (message.accountNumber !== '') {
      writer.uint32(18).string(message.accountNumber);
    }
    if (message.bankCode !== '') {
      writer.uint32(26).string(message.bankCode);
    }
    if (message.bankName !== '') {
      writer.uint32(34).string(message.bankName);
    }
    if (message.branchCode !== '') {
      writer.uint32(42).string(message.branchCode);
    }
    if (message.branchName !== '') {
      writer.uint32(50).string(message.branchName);
    }
    if (message.accountType !== 0) {
      writer.uint32(56).int32(message.accountType);
    }
    if (message.bankAddress !== '') {
      writer.uint32(66).string(message.bankAddress);
    }
    if (message.swiftReference !== '') {
      writer.uint32(74).string(message.swiftReference);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BankAccount {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deprecatedCountry = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.bankCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bankName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.branchCode = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.branchName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.accountType = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.bankAddress = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.swiftReference = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BankAccount {
    return {
      deprecatedCountry: isSet(object.deprecatedCountry)
        ? String(object.deprecatedCountry)
        : '',
      accountNumber: isSet(object.accountNumber)
        ? String(object.accountNumber)
        : '',
      bankCode: isSet(object.bankCode) ? String(object.bankCode) : '',
      bankName: isSet(object.bankName) ? String(object.bankName) : '',
      branchCode: isSet(object.branchCode) ? String(object.branchCode) : '',
      branchName: isSet(object.branchName) ? String(object.branchName) : '',
      accountType: isSet(object.accountType)
        ? bankAccountTypeFromJSON(object.accountType)
        : 0,
      bankAddress: isSet(object.bankAddress) ? String(object.bankAddress) : '',
      swiftReference: isSet(object.swiftReference)
        ? String(object.swiftReference)
        : '',
    };
  },

  toJSON(message: BankAccount): unknown {
    const obj: any = {};
    message.deprecatedCountry !== undefined &&
      (obj.deprecatedCountry = message.deprecatedCountry);
    message.accountNumber !== undefined &&
      (obj.accountNumber = message.accountNumber);
    message.bankCode !== undefined && (obj.bankCode = message.bankCode);
    message.bankName !== undefined && (obj.bankName = message.bankName);
    message.branchCode !== undefined && (obj.branchCode = message.branchCode);
    message.branchName !== undefined && (obj.branchName = message.branchName);
    message.accountType !== undefined &&
      (obj.accountType = bankAccountTypeToJSON(message.accountType));
    message.bankAddress !== undefined &&
      (obj.bankAddress = message.bankAddress);
    message.swiftReference !== undefined &&
      (obj.swiftReference = message.swiftReference);
    return obj;
  },

  create(base?: DeepPartial<BankAccount>): BankAccount {
    return BankAccount.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<BankAccount>): BankAccount {
    const message = createBaseBankAccount();
    message.deprecatedCountry = object.deprecatedCountry ?? '';
    message.accountNumber = object.accountNumber ?? '';
    message.bankCode = object.bankCode ?? '';
    message.bankName = object.bankName ?? '';
    message.branchCode = object.branchCode ?? '';
    message.branchName = object.branchName ?? '';
    message.accountType = object.accountType ?? 0;
    message.bankAddress = object.bankAddress ?? '';
    message.swiftReference = object.swiftReference ?? '';
    return message;
  },
};

function createBaseTillAccount(): TillAccount {
  return { accountNumber: '', defaultReference: '' };
}

export const TillAccount = {
  encode(
    message: TillAccount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.accountNumber !== '') {
      writer.uint32(10).string(message.accountNumber);
    }
    if (message.defaultReference !== '') {
      writer.uint32(18).string(message.defaultReference);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TillAccount {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTillAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.defaultReference = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TillAccount {
    return {
      accountNumber: isSet(object.accountNumber)
        ? String(object.accountNumber)
        : '',
      defaultReference: isSet(object.defaultReference)
        ? String(object.defaultReference)
        : '',
    };
  },

  toJSON(message: TillAccount): unknown {
    const obj: any = {};
    message.accountNumber !== undefined &&
      (obj.accountNumber = message.accountNumber);
    message.defaultReference !== undefined &&
      (obj.defaultReference = message.defaultReference);
    return obj;
  },

  create(base?: DeepPartial<TillAccount>): TillAccount {
    return TillAccount.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TillAccount>): TillAccount {
    const message = createBaseTillAccount();
    message.accountNumber = object.accountNumber ?? '';
    message.defaultReference = object.defaultReference ?? '';
    return message;
  },
};

function createBasePeerToPeerAccount(): PeerToPeerAccount {
  return {
    tag: '',
    profilePicUrl: '',
    canSend: false,
    disabled: false,
    disabledReason: '',
  };
}

export const PeerToPeerAccount = {
  encode(
    message: PeerToPeerAccount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.tag !== '') {
      writer.uint32(10).string(message.tag);
    }
    if (message.profilePicUrl !== '') {
      writer.uint32(18).string(message.profilePicUrl);
    }
    if (message.canSend === true) {
      writer.uint32(24).bool(message.canSend);
    }
    if (message.disabled === true) {
      writer.uint32(32).bool(message.disabled);
    }
    if (message.disabledReason !== '') {
      writer.uint32(42).string(message.disabledReason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PeerToPeerAccount {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePeerToPeerAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.profilePicUrl = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.canSend = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.disabled = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.disabledReason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PeerToPeerAccount {
    return {
      tag: isSet(object.tag) ? String(object.tag) : '',
      profilePicUrl: isSet(object.profilePicUrl)
        ? String(object.profilePicUrl)
        : '',
      canSend: isSet(object.canSend) ? Boolean(object.canSend) : false,
      disabled: isSet(object.disabled) ? Boolean(object.disabled) : false,
      disabledReason: isSet(object.disabledReason)
        ? String(object.disabledReason)
        : '',
    };
  },

  toJSON(message: PeerToPeerAccount): unknown {
    const obj: any = {};
    message.tag !== undefined && (obj.tag = message.tag);
    message.profilePicUrl !== undefined &&
      (obj.profilePicUrl = message.profilePicUrl);
    message.canSend !== undefined && (obj.canSend = message.canSend);
    message.disabled !== undefined && (obj.disabled = message.disabled);
    message.disabledReason !== undefined &&
      (obj.disabledReason = message.disabledReason);
    return obj;
  },

  create(base?: DeepPartial<PeerToPeerAccount>): PeerToPeerAccount {
    return PeerToPeerAccount.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<PeerToPeerAccount>): PeerToPeerAccount {
    const message = createBasePeerToPeerAccount();
    message.tag = object.tag ?? '';
    message.profilePicUrl = object.profilePicUrl ?? '';
    message.canSend = object.canSend ?? false;
    message.disabled = object.disabled ?? false;
    message.disabledReason = object.disabledReason ?? '';
    return message;
  },
};

function createBaseRecipientAccount(): RecipientAccount {
  return {
    id: '',
    recipientId: '',
    accountType: 0,
    name: undefined,
    displayName: '',
    createdAt: undefined,
    lastUpdatedAt: undefined,
    lastTransactionCreatedAt: undefined,
    status: '',
    purpose: '',
    mobileMoney: undefined,
    deprecatedBankAccount: undefined,
    deletedAt: undefined,
    address: undefined,
    currencyCode: '',
    tillAccount: undefined,
    recipientType: 0,
    publicStatus: 0,
    externalAccount: undefined,
    peerToPeerAccount: undefined,
    bankAccount: undefined,
  };
}

export const RecipientAccount = {
  encode(
    message: RecipientAccount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.recipientId !== '') {
      writer.uint32(18).string(message.recipientId);
    }
    if (message.accountType !== 0) {
      writer.uint32(24).int32(message.accountType);
    }
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(34).fork()).ldelim();
    }
    if (message.displayName !== '') {
      writer.uint32(42).string(message.displayName);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(50).fork()).ldelim();
    }
    if (message.lastUpdatedAt !== undefined) {
      Timestamp.encode(
        message.lastUpdatedAt,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.lastTransactionCreatedAt !== undefined) {
      Timestamp.encode(
        message.lastTransactionCreatedAt,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.status !== '') {
      writer.uint32(74).string(message.status);
    }
    if (message.purpose !== '') {
      writer.uint32(82).string(message.purpose);
    }
    if (message.mobileMoney !== undefined) {
      MobileMoneyAccount.encode(
        message.mobileMoney,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.deprecatedBankAccount !== undefined) {
      BankAccount.encode(
        message.deprecatedBankAccount,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.deletedAt !== undefined) {
      Timestamp.encode(message.deletedAt, writer.uint32(106).fork()).ldelim();
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(114).fork()).ldelim();
    }
    if (message.currencyCode !== '') {
      writer.uint32(122).string(message.currencyCode);
    }
    if (message.tillAccount !== undefined) {
      TillAccount.encode(
        message.tillAccount,
        writer.uint32(130).fork(),
      ).ldelim();
    }
    if (message.recipientType !== 0) {
      writer.uint32(136).int32(message.recipientType);
    }
    if (message.publicStatus !== 0) {
      writer.uint32(144).int32(message.publicStatus);
    }
    if (message.externalAccount !== undefined) {
      ExternalAccount.encode(
        message.externalAccount,
        writer.uint32(154).fork(),
      ).ldelim();
    }
    if (message.peerToPeerAccount !== undefined) {
      PeerToPeerAccount.encode(
        message.peerToPeerAccount,
        writer.uint32(162).fork(),
      ).ldelim();
    }
    if (message.bankAccount !== undefined) {
      BankAccountDetails.encode(
        message.bankAccount,
        writer.uint32(170).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecipientAccount {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecipientAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recipientId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.accountType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastUpdatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.lastTransactionCreatedAt = Timestamp.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.status = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.purpose = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.mobileMoney = MobileMoneyAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.deprecatedBankAccount = BankAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.deletedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.currencyCode = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.tillAccount = TillAccount.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.recipientType = reader.int32() as any;
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.publicStatus = reader.int32() as any;
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.externalAccount = ExternalAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.peerToPeerAccount = PeerToPeerAccount.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.bankAccount = BankAccountDetails.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecipientAccount {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      recipientId: isSet(object.recipientId) ? String(object.recipientId) : '',
      accountType: isSet(object.accountType)
        ? accountTypeFromJSON(object.accountType)
        : 0,
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
      displayName: isSet(object.displayName) ? String(object.displayName) : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      lastUpdatedAt: isSet(object.lastUpdatedAt)
        ? fromJsonTimestamp(object.lastUpdatedAt)
        : undefined,
      lastTransactionCreatedAt: isSet(object.lastTransactionCreatedAt)
        ? fromJsonTimestamp(object.lastTransactionCreatedAt)
        : undefined,
      status: isSet(object.status) ? String(object.status) : '',
      purpose: isSet(object.purpose) ? String(object.purpose) : '',
      mobileMoney: isSet(object.mobileMoney)
        ? MobileMoneyAccount.fromJSON(object.mobileMoney)
        : undefined,
      deprecatedBankAccount: isSet(object.deprecatedBankAccount)
        ? BankAccount.fromJSON(object.deprecatedBankAccount)
        : undefined,
      deletedAt: isSet(object.deletedAt)
        ? fromJsonTimestamp(object.deletedAt)
        : undefined,
      address: isSet(object.address)
        ? Address.fromJSON(object.address)
        : undefined,
      currencyCode: isSet(object.currencyCode)
        ? String(object.currencyCode)
        : '',
      tillAccount: isSet(object.tillAccount)
        ? TillAccount.fromJSON(object.tillAccount)
        : undefined,
      recipientType: isSet(object.recipientType)
        ? entityTypeFromJSON(object.recipientType)
        : 0,
      publicStatus: isSet(object.publicStatus)
        ? publicStatusFromJSON(object.publicStatus)
        : 0,
      externalAccount: isSet(object.externalAccount)
        ? ExternalAccount.fromJSON(object.externalAccount)
        : undefined,
      peerToPeerAccount: isSet(object.peerToPeerAccount)
        ? PeerToPeerAccount.fromJSON(object.peerToPeerAccount)
        : undefined,
      bankAccount: isSet(object.bankAccount)
        ? BankAccountDetails.fromJSON(object.bankAccount)
        : undefined,
    };
  },

  toJSON(message: RecipientAccount): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.recipientId !== undefined &&
      (obj.recipientId = message.recipientId);
    message.accountType !== undefined &&
      (obj.accountType = accountTypeToJSON(message.accountType));
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    message.displayName !== undefined &&
      (obj.displayName = message.displayName);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.lastUpdatedAt !== undefined &&
      (obj.lastUpdatedAt = fromTimestamp(message.lastUpdatedAt).toISOString());
    message.lastTransactionCreatedAt !== undefined &&
      (obj.lastTransactionCreatedAt = fromTimestamp(
        message.lastTransactionCreatedAt,
      ).toISOString());
    message.status !== undefined && (obj.status = message.status);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    message.mobileMoney !== undefined &&
      (obj.mobileMoney = message.mobileMoney
        ? MobileMoneyAccount.toJSON(message.mobileMoney)
        : undefined);
    message.deprecatedBankAccount !== undefined &&
      (obj.deprecatedBankAccount = message.deprecatedBankAccount
        ? BankAccount.toJSON(message.deprecatedBankAccount)
        : undefined);
    message.deletedAt !== undefined &&
      (obj.deletedAt = fromTimestamp(message.deletedAt).toISOString());
    message.address !== undefined &&
      (obj.address = message.address
        ? Address.toJSON(message.address)
        : undefined);
    message.currencyCode !== undefined &&
      (obj.currencyCode = message.currencyCode);
    message.tillAccount !== undefined &&
      (obj.tillAccount = message.tillAccount
        ? TillAccount.toJSON(message.tillAccount)
        : undefined);
    message.recipientType !== undefined &&
      (obj.recipientType = entityTypeToJSON(message.recipientType));
    message.publicStatus !== undefined &&
      (obj.publicStatus = publicStatusToJSON(message.publicStatus));
    message.externalAccount !== undefined &&
      (obj.externalAccount = message.externalAccount
        ? ExternalAccount.toJSON(message.externalAccount)
        : undefined);
    message.peerToPeerAccount !== undefined &&
      (obj.peerToPeerAccount = message.peerToPeerAccount
        ? PeerToPeerAccount.toJSON(message.peerToPeerAccount)
        : undefined);
    message.bankAccount !== undefined &&
      (obj.bankAccount = message.bankAccount
        ? BankAccountDetails.toJSON(message.bankAccount)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<RecipientAccount>): RecipientAccount {
    return RecipientAccount.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RecipientAccount>): RecipientAccount {
    const message = createBaseRecipientAccount();
    message.id = object.id ?? '';
    message.recipientId = object.recipientId ?? '';
    message.accountType = object.accountType ?? 0;
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    message.displayName = object.displayName ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.lastUpdatedAt =
      object.lastUpdatedAt !== undefined && object.lastUpdatedAt !== null
        ? Timestamp.fromPartial(object.lastUpdatedAt)
        : undefined;
    message.lastTransactionCreatedAt =
      object.lastTransactionCreatedAt !== undefined &&
      object.lastTransactionCreatedAt !== null
        ? Timestamp.fromPartial(object.lastTransactionCreatedAt)
        : undefined;
    message.status = object.status ?? '';
    message.purpose = object.purpose ?? '';
    message.mobileMoney =
      object.mobileMoney !== undefined && object.mobileMoney !== null
        ? MobileMoneyAccount.fromPartial(object.mobileMoney)
        : undefined;
    message.deprecatedBankAccount =
      object.deprecatedBankAccount !== undefined &&
      object.deprecatedBankAccount !== null
        ? BankAccount.fromPartial(object.deprecatedBankAccount)
        : undefined;
    message.deletedAt =
      object.deletedAt !== undefined && object.deletedAt !== null
        ? Timestamp.fromPartial(object.deletedAt)
        : undefined;
    message.address =
      object.address !== undefined && object.address !== null
        ? Address.fromPartial(object.address)
        : undefined;
    message.currencyCode = object.currencyCode ?? '';
    message.tillAccount =
      object.tillAccount !== undefined && object.tillAccount !== null
        ? TillAccount.fromPartial(object.tillAccount)
        : undefined;
    message.recipientType = object.recipientType ?? 0;
    message.publicStatus = object.publicStatus ?? 0;
    message.externalAccount =
      object.externalAccount !== undefined && object.externalAccount !== null
        ? ExternalAccount.fromPartial(object.externalAccount)
        : undefined;
    message.peerToPeerAccount =
      object.peerToPeerAccount !== undefined &&
      object.peerToPeerAccount !== null
        ? PeerToPeerAccount.fromPartial(object.peerToPeerAccount)
        : undefined;
    message.bankAccount =
      object.bankAccount !== undefined && object.bankAccount !== null
        ? BankAccountDetails.fromPartial(object.bankAccount)
        : undefined;
    return message;
  },
};

function createBaseBranch(): Branch {
  return { code: '', name: '', id: 0, bic: '' };
}

export const Branch = {
  encode(
    message: Branch,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.code !== '') {
      writer.uint32(10).string(message.code);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.id !== 0) {
      writer.uint32(24).int64(message.id);
    }
    if (message.bic !== '') {
      writer.uint32(34).string(message.bic);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Branch {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBranch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.bic = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Branch {
    return {
      code: isSet(object.code) ? String(object.code) : '',
      name: isSet(object.name) ? String(object.name) : '',
      id: isSet(object.id) ? Number(object.id) : 0,
      bic: isSet(object.bic) ? String(object.bic) : '',
    };
  },

  toJSON(message: Branch): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.name !== undefined && (obj.name = message.name);
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.bic !== undefined && (obj.bic = message.bic);
    return obj;
  },

  create(base?: DeepPartial<Branch>): Branch {
    return Branch.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Branch>): Branch {
    const message = createBaseBranch();
    message.code = object.code ?? '';
    message.name = object.name ?? '';
    message.id = object.id ?? 0;
    message.bic = object.bic ?? '';
    return message;
  },
};

function createBaseBank(): Bank {
  return { code: '', name: '', branches: [], id: 0, country: '', bic: '' };
}

export const Bank = {
  encode(message: Bank, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== '') {
      writer.uint32(10).string(message.code);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.branches) {
      Branch.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.id !== 0) {
      writer.uint32(32).int64(message.id);
    }
    if (message.country !== '') {
      writer.uint32(42).string(message.country);
    }
    if (message.bic !== '') {
      writer.uint32(50).string(message.bic);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Bank {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBank();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.branches.push(Branch.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.country = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.bic = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Bank {
    return {
      code: isSet(object.code) ? String(object.code) : '',
      name: isSet(object.name) ? String(object.name) : '',
      branches: Array.isArray(object?.branches)
        ? object.branches.map((e: any) => Branch.fromJSON(e))
        : [],
      id: isSet(object.id) ? Number(object.id) : 0,
      country: isSet(object.country) ? String(object.country) : '',
      bic: isSet(object.bic) ? String(object.bic) : '',
    };
  },

  toJSON(message: Bank): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.name !== undefined && (obj.name = message.name);
    if (message.branches) {
      obj.branches = message.branches.map((e) =>
        e ? Branch.toJSON(e) : undefined,
      );
    } else {
      obj.branches = [];
    }
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.country !== undefined && (obj.country = message.country);
    message.bic !== undefined && (obj.bic = message.bic);
    return obj;
  },

  create(base?: DeepPartial<Bank>): Bank {
    return Bank.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Bank>): Bank {
    const message = createBaseBank();
    message.code = object.code ?? '';
    message.name = object.name ?? '';
    message.branches = object.branches?.map((e) => Branch.fromPartial(e)) || [];
    message.id = object.id ?? 0;
    message.country = object.country ?? '';
    message.bic = object.bic ?? '';
    return message;
  },
};

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error(
      'Value is larger than Number.MAX_SAFE_INTEGER',
    );
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
