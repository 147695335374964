/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../../google/protobuf/timestamp';
import { Money } from '../fx/types';
import { BankAccountDetails } from '../utils/types';

export const protobufPackage = 'wallets';

/** buf:lint:ignore COMMENT_ENUM */
export enum WalletIcon {
  WALLET_ICON_INVALID = 0,
  WALLET_ICON_GENERIC = 1,
  WALLET_ICON_REWARDS = 2,
  UNRECOGNIZED = -1,
}

export function walletIconFromJSON(object: any): WalletIcon {
  switch (object) {
    case 0:
    case 'WALLET_ICON_INVALID':
      return WalletIcon.WALLET_ICON_INVALID;
    case 1:
    case 'WALLET_ICON_GENERIC':
      return WalletIcon.WALLET_ICON_GENERIC;
    case 2:
    case 'WALLET_ICON_REWARDS':
      return WalletIcon.WALLET_ICON_REWARDS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return WalletIcon.UNRECOGNIZED;
  }
}

export function walletIconToJSON(object: WalletIcon): string {
  switch (object) {
    case WalletIcon.WALLET_ICON_INVALID:
      return 'WALLET_ICON_INVALID';
    case WalletIcon.WALLET_ICON_GENERIC:
      return 'WALLET_ICON_GENERIC';
    case WalletIcon.WALLET_ICON_REWARDS:
      return 'WALLET_ICON_REWARDS';
    case WalletIcon.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum WalletType {
  WALLET_TYPE_INVALID = 0,
  WALLET_TYPE_CASH = 1,
  WALLET_TYPE_CREDIT = 2,
  WALLET_TYPE_VIRTUAL = 3,
  UNRECOGNIZED = -1,
}

export function walletTypeFromJSON(object: any): WalletType {
  switch (object) {
    case 0:
    case 'WALLET_TYPE_INVALID':
      return WalletType.WALLET_TYPE_INVALID;
    case 1:
    case 'WALLET_TYPE_CASH':
      return WalletType.WALLET_TYPE_CASH;
    case 2:
    case 'WALLET_TYPE_CREDIT':
      return WalletType.WALLET_TYPE_CREDIT;
    case 3:
    case 'WALLET_TYPE_VIRTUAL':
      return WalletType.WALLET_TYPE_VIRTUAL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return WalletType.UNRECOGNIZED;
  }
}

export function walletTypeToJSON(object: WalletType): string {
  switch (object) {
    case WalletType.WALLET_TYPE_INVALID:
      return 'WALLET_TYPE_INVALID';
    case WalletType.WALLET_TYPE_CASH:
      return 'WALLET_TYPE_CASH';
    case WalletType.WALLET_TYPE_CREDIT:
      return 'WALLET_TYPE_CREDIT';
    case WalletType.WALLET_TYPE_VIRTUAL:
      return 'WALLET_TYPE_VIRTUAL';
    case WalletType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** Wallet options/flags. */
export interface WalletOptions {
  /**
   * Whether, in general, the wallet can be used to send to a recipient account.
   * See also `can_withdraw` and `can_send_to_peer` for granularities.
   */
  canSend: boolean;
  canTopup: boolean;
  canConvert: boolean;
  isDefault: boolean;
  isDummy: boolean;
  canDownloadStatements: boolean;
  isRewards: boolean;
  canWithdraw: boolean;
  /** Whether the wallet can be used to send to a PeerToPeer account. */
  canSendToPeer: boolean;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Wallet {
  id: string;
  accountId: string;
  type: WalletType;
  name: string;
  currencyCode: string;
  balance: Money | undefined;
  options: WalletOptions | undefined;
  icon: WalletIcon;
  createdAt: Timestamp | undefined;
  bankAccountDetails: BankAccountDetails | undefined;
}

function createBaseWalletOptions(): WalletOptions {
  return {
    canSend: false,
    canTopup: false,
    canConvert: false,
    isDefault: false,
    isDummy: false,
    canDownloadStatements: false,
    isRewards: false,
    canWithdraw: false,
    canSendToPeer: false,
  };
}

export const WalletOptions = {
  encode(
    message: WalletOptions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.canSend === true) {
      writer.uint32(8).bool(message.canSend);
    }
    if (message.canTopup === true) {
      writer.uint32(16).bool(message.canTopup);
    }
    if (message.canConvert === true) {
      writer.uint32(24).bool(message.canConvert);
    }
    if (message.isDefault === true) {
      writer.uint32(32).bool(message.isDefault);
    }
    if (message.isDummy === true) {
      writer.uint32(40).bool(message.isDummy);
    }
    if (message.canDownloadStatements === true) {
      writer.uint32(48).bool(message.canDownloadStatements);
    }
    if (message.isRewards === true) {
      writer.uint32(56).bool(message.isRewards);
    }
    if (message.canWithdraw === true) {
      writer.uint32(64).bool(message.canWithdraw);
    }
    if (message.canSendToPeer === true) {
      writer.uint32(72).bool(message.canSendToPeer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WalletOptions {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWalletOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.canSend = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.canTopup = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.canConvert = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isDummy = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.canDownloadStatements = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isRewards = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.canWithdraw = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.canSendToPeer = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WalletOptions {
    return {
      canSend: isSet(object.canSend) ? Boolean(object.canSend) : false,
      canTopup: isSet(object.canTopup) ? Boolean(object.canTopup) : false,
      canConvert: isSet(object.canConvert) ? Boolean(object.canConvert) : false,
      isDefault: isSet(object.isDefault) ? Boolean(object.isDefault) : false,
      isDummy: isSet(object.isDummy) ? Boolean(object.isDummy) : false,
      canDownloadStatements: isSet(object.canDownloadStatements)
        ? Boolean(object.canDownloadStatements)
        : false,
      isRewards: isSet(object.isRewards) ? Boolean(object.isRewards) : false,
      canWithdraw: isSet(object.canWithdraw)
        ? Boolean(object.canWithdraw)
        : false,
      canSendToPeer: isSet(object.canSendToPeer)
        ? Boolean(object.canSendToPeer)
        : false,
    };
  },

  toJSON(message: WalletOptions): unknown {
    const obj: any = {};
    message.canSend !== undefined && (obj.canSend = message.canSend);
    message.canTopup !== undefined && (obj.canTopup = message.canTopup);
    message.canConvert !== undefined && (obj.canConvert = message.canConvert);
    message.isDefault !== undefined && (obj.isDefault = message.isDefault);
    message.isDummy !== undefined && (obj.isDummy = message.isDummy);
    message.canDownloadStatements !== undefined &&
      (obj.canDownloadStatements = message.canDownloadStatements);
    message.isRewards !== undefined && (obj.isRewards = message.isRewards);
    message.canWithdraw !== undefined &&
      (obj.canWithdraw = message.canWithdraw);
    message.canSendToPeer !== undefined &&
      (obj.canSendToPeer = message.canSendToPeer);
    return obj;
  },

  create(base?: DeepPartial<WalletOptions>): WalletOptions {
    return WalletOptions.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<WalletOptions>): WalletOptions {
    const message = createBaseWalletOptions();
    message.canSend = object.canSend ?? false;
    message.canTopup = object.canTopup ?? false;
    message.canConvert = object.canConvert ?? false;
    message.isDefault = object.isDefault ?? false;
    message.isDummy = object.isDummy ?? false;
    message.canDownloadStatements = object.canDownloadStatements ?? false;
    message.isRewards = object.isRewards ?? false;
    message.canWithdraw = object.canWithdraw ?? false;
    message.canSendToPeer = object.canSendToPeer ?? false;
    return message;
  },
};

function createBaseWallet(): Wallet {
  return {
    id: '',
    accountId: '',
    type: 0,
    name: '',
    currencyCode: '',
    balance: undefined,
    options: undefined,
    icon: 0,
    createdAt: undefined,
    bankAccountDetails: undefined,
  };
}

export const Wallet = {
  encode(
    message: Wallet,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.accountId !== '') {
      writer.uint32(18).string(message.accountId);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.name !== '') {
      writer.uint32(34).string(message.name);
    }
    if (message.currencyCode !== '') {
      writer.uint32(42).string(message.currencyCode);
    }
    if (message.balance !== undefined) {
      Money.encode(message.balance, writer.uint32(50).fork()).ldelim();
    }
    if (message.options !== undefined) {
      WalletOptions.encode(message.options, writer.uint32(58).fork()).ldelim();
    }
    if (message.icon !== 0) {
      writer.uint32(64).int32(message.icon);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(74).fork()).ldelim();
    }
    if (message.bankAccountDetails !== undefined) {
      BankAccountDetails.encode(
        message.bankAccountDetails,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Wallet {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWallet();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.currencyCode = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.balance = Money.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.options = WalletOptions.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.icon = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.bankAccountDetails = BankAccountDetails.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Wallet {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      accountId: isSet(object.accountId) ? String(object.accountId) : '',
      type: isSet(object.type) ? walletTypeFromJSON(object.type) : 0,
      name: isSet(object.name) ? String(object.name) : '',
      currencyCode: isSet(object.currencyCode)
        ? String(object.currencyCode)
        : '',
      balance: isSet(object.balance)
        ? Money.fromJSON(object.balance)
        : undefined,
      options: isSet(object.options)
        ? WalletOptions.fromJSON(object.options)
        : undefined,
      icon: isSet(object.icon) ? walletIconFromJSON(object.icon) : 0,
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      bankAccountDetails: isSet(object.bankAccountDetails)
        ? BankAccountDetails.fromJSON(object.bankAccountDetails)
        : undefined,
    };
  },

  toJSON(message: Wallet): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.accountId !== undefined && (obj.accountId = message.accountId);
    message.type !== undefined && (obj.type = walletTypeToJSON(message.type));
    message.name !== undefined && (obj.name = message.name);
    message.currencyCode !== undefined &&
      (obj.currencyCode = message.currencyCode);
    message.balance !== undefined &&
      (obj.balance = message.balance
        ? Money.toJSON(message.balance)
        : undefined);
    message.options !== undefined &&
      (obj.options = message.options
        ? WalletOptions.toJSON(message.options)
        : undefined);
    message.icon !== undefined && (obj.icon = walletIconToJSON(message.icon));
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.bankAccountDetails !== undefined &&
      (obj.bankAccountDetails = message.bankAccountDetails
        ? BankAccountDetails.toJSON(message.bankAccountDetails)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<Wallet>): Wallet {
    return Wallet.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Wallet>): Wallet {
    const message = createBaseWallet();
    message.id = object.id ?? '';
    message.accountId = object.accountId ?? '';
    message.type = object.type ?? 0;
    message.name = object.name ?? '';
    message.currencyCode = object.currencyCode ?? '';
    message.balance =
      object.balance !== undefined && object.balance !== null
        ? Money.fromPartial(object.balance)
        : undefined;
    message.options =
      object.options !== undefined && object.options !== null
        ? WalletOptions.fromPartial(object.options)
        : undefined;
    message.icon = object.icon ?? 0;
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.bankAccountDetails =
      object.bankAccountDetails !== undefined &&
      object.bankAccountDetails !== null
        ? BankAccountDetails.fromPartial(object.bankAccountDetails)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
