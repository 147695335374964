/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../../google/protobuf/timestamp';
import {
  LimitType,
  limitTypeFromJSON,
  limitTypeToJSON,
  UserLimits,
} from '../utils/types';

export const protobufPackage = 'kyc';

/** buf:lint:ignore COMMENT_ENUM */
export enum ActionType {
  ACTION_TYPE_INVALID = 0,
  ACTION_TYPE_REQUEST_DATA = 1,
  ACTION_TYPE_REQUEST_DOCUMENT = 2,
  ACTION_TYPE_RUN_CHECK = 3,
  ACTION_TYPE_CONTACT_SUPPORT = 4,
  ACTION_TYPE_WAIT = 5,
  /** ACTION_TYPE_REQUEST_ADDRESS_OR_PROOF_OF_ADDRESS - SubActions can specify what options are available to the user. */
  ACTION_TYPE_REQUEST_ADDRESS_OR_PROOF_OF_ADDRESS = 6,
  ACTION_TYPE_EDIT_ADDRESS = 7,
  UNRECOGNIZED = -1,
}

export function actionTypeFromJSON(object: any): ActionType {
  switch (object) {
    case 0:
    case 'ACTION_TYPE_INVALID':
      return ActionType.ACTION_TYPE_INVALID;
    case 1:
    case 'ACTION_TYPE_REQUEST_DATA':
      return ActionType.ACTION_TYPE_REQUEST_DATA;
    case 2:
    case 'ACTION_TYPE_REQUEST_DOCUMENT':
      return ActionType.ACTION_TYPE_REQUEST_DOCUMENT;
    case 3:
    case 'ACTION_TYPE_RUN_CHECK':
      return ActionType.ACTION_TYPE_RUN_CHECK;
    case 4:
    case 'ACTION_TYPE_CONTACT_SUPPORT':
      return ActionType.ACTION_TYPE_CONTACT_SUPPORT;
    case 5:
    case 'ACTION_TYPE_WAIT':
      return ActionType.ACTION_TYPE_WAIT;
    case 6:
    case 'ACTION_TYPE_REQUEST_ADDRESS_OR_PROOF_OF_ADDRESS':
      return ActionType.ACTION_TYPE_REQUEST_ADDRESS_OR_PROOF_OF_ADDRESS;
    case 7:
    case 'ACTION_TYPE_EDIT_ADDRESS':
      return ActionType.ACTION_TYPE_EDIT_ADDRESS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ActionType.UNRECOGNIZED;
  }
}

export function actionTypeToJSON(object: ActionType): string {
  switch (object) {
    case ActionType.ACTION_TYPE_INVALID:
      return 'ACTION_TYPE_INVALID';
    case ActionType.ACTION_TYPE_REQUEST_DATA:
      return 'ACTION_TYPE_REQUEST_DATA';
    case ActionType.ACTION_TYPE_REQUEST_DOCUMENT:
      return 'ACTION_TYPE_REQUEST_DOCUMENT';
    case ActionType.ACTION_TYPE_RUN_CHECK:
      return 'ACTION_TYPE_RUN_CHECK';
    case ActionType.ACTION_TYPE_CONTACT_SUPPORT:
      return 'ACTION_TYPE_CONTACT_SUPPORT';
    case ActionType.ACTION_TYPE_WAIT:
      return 'ACTION_TYPE_WAIT';
    case ActionType.ACTION_TYPE_REQUEST_ADDRESS_OR_PROOF_OF_ADDRESS:
      return 'ACTION_TYPE_REQUEST_ADDRESS_OR_PROOF_OF_ADDRESS';
    case ActionType.ACTION_TYPE_EDIT_ADDRESS:
      return 'ACTION_TYPE_EDIT_ADDRESS';
    case ActionType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
 * buf:lint:ignore COMMENT_ENUM
 */
export enum AddressType {
  ADDRESS_TYPE_PRIMARY_RESIDENCE = 0,
  ADDRESS_TYPE_SECONDARY_RESIDENCE = 1,
  ADDRESS_TYPE_PREVIOUS_RESIDENCE = 2,
  ADDRESS_TYPE_WORKPLACE = 3,
  ADDRESS_TYPE_PREVIOUS_WORKPLACE = 4,
  UNRECOGNIZED = -1,
}

export function addressTypeFromJSON(object: any): AddressType {
  switch (object) {
    case 0:
    case 'ADDRESS_TYPE_PRIMARY_RESIDENCE':
      return AddressType.ADDRESS_TYPE_PRIMARY_RESIDENCE;
    case 1:
    case 'ADDRESS_TYPE_SECONDARY_RESIDENCE':
      return AddressType.ADDRESS_TYPE_SECONDARY_RESIDENCE;
    case 2:
    case 'ADDRESS_TYPE_PREVIOUS_RESIDENCE':
      return AddressType.ADDRESS_TYPE_PREVIOUS_RESIDENCE;
    case 3:
    case 'ADDRESS_TYPE_WORKPLACE':
      return AddressType.ADDRESS_TYPE_WORKPLACE;
    case 4:
    case 'ADDRESS_TYPE_PREVIOUS_WORKPLACE':
      return AddressType.ADDRESS_TYPE_PREVIOUS_WORKPLACE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AddressType.UNRECOGNIZED;
  }
}

export function addressTypeToJSON(object: AddressType): string {
  switch (object) {
    case AddressType.ADDRESS_TYPE_PRIMARY_RESIDENCE:
      return 'ADDRESS_TYPE_PRIMARY_RESIDENCE';
    case AddressType.ADDRESS_TYPE_SECONDARY_RESIDENCE:
      return 'ADDRESS_TYPE_SECONDARY_RESIDENCE';
    case AddressType.ADDRESS_TYPE_PREVIOUS_RESIDENCE:
      return 'ADDRESS_TYPE_PREVIOUS_RESIDENCE';
    case AddressType.ADDRESS_TYPE_WORKPLACE:
      return 'ADDRESS_TYPE_WORKPLACE';
    case AddressType.ADDRESS_TYPE_PREVIOUS_WORKPLACE:
      return 'ADDRESS_TYPE_PREVIOUS_WORKPLACE';
    case AddressType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum DataType {
  DATA_TYPE_INVALID = 0,
  DATA_TYPE_LEGAL_NAME = 1,
  DATA_TYPE_ADDRESS = 2,
  DATA_TYPE_DATE_OF_BIRTH = 3,
  DATA_TYPE_PREFERRED_NAME = 4,
  DATA_TYPE_COUNTRY_OF_BIRTH = 5,
  DATA_TYPE_LEGAL_GENDER = 6,
  DATA_TYPE_RECIPIENT_PURPOSE = 7,
  DATA_TYPE_COUNTRY_OF_RESIDENCE = 8,
  DATA_TYPE_NATIONALITY = 9,
  DATA_TYPE_PHONE_NUMBER = 10,
  DATA_TYPE_EMAIL_ADDRESS = 11,
  DATA_TYPE_INDUSTRY_CODE = 12,
  DATA_TYPE_REGISTRATION_NUMBER = 13,
  DATA_TYPE_COMPANY_TYPE = 14,
  DATA_TYPE_REGISTERED_ADDRESS = 15,
  DATA_TYPE_TRADING_ADDRESS = 16,
  DATA_TYPE_ASSOCIATES = 17,
  DATA_TYPE_DISPLAY_NAME = 18,
  DATA_TYPE_PERSONAL_INFORMATION = 19,
  DATA_TYPE_BIRTH_INFORMATION = 20,
  UNRECOGNIZED = -1,
}

export function dataTypeFromJSON(object: any): DataType {
  switch (object) {
    case 0:
    case 'DATA_TYPE_INVALID':
      return DataType.DATA_TYPE_INVALID;
    case 1:
    case 'DATA_TYPE_LEGAL_NAME':
      return DataType.DATA_TYPE_LEGAL_NAME;
    case 2:
    case 'DATA_TYPE_ADDRESS':
      return DataType.DATA_TYPE_ADDRESS;
    case 3:
    case 'DATA_TYPE_DATE_OF_BIRTH':
      return DataType.DATA_TYPE_DATE_OF_BIRTH;
    case 4:
    case 'DATA_TYPE_PREFERRED_NAME':
      return DataType.DATA_TYPE_PREFERRED_NAME;
    case 5:
    case 'DATA_TYPE_COUNTRY_OF_BIRTH':
      return DataType.DATA_TYPE_COUNTRY_OF_BIRTH;
    case 6:
    case 'DATA_TYPE_LEGAL_GENDER':
      return DataType.DATA_TYPE_LEGAL_GENDER;
    case 7:
    case 'DATA_TYPE_RECIPIENT_PURPOSE':
      return DataType.DATA_TYPE_RECIPIENT_PURPOSE;
    case 8:
    case 'DATA_TYPE_COUNTRY_OF_RESIDENCE':
      return DataType.DATA_TYPE_COUNTRY_OF_RESIDENCE;
    case 9:
    case 'DATA_TYPE_NATIONALITY':
      return DataType.DATA_TYPE_NATIONALITY;
    case 10:
    case 'DATA_TYPE_PHONE_NUMBER':
      return DataType.DATA_TYPE_PHONE_NUMBER;
    case 11:
    case 'DATA_TYPE_EMAIL_ADDRESS':
      return DataType.DATA_TYPE_EMAIL_ADDRESS;
    case 12:
    case 'DATA_TYPE_INDUSTRY_CODE':
      return DataType.DATA_TYPE_INDUSTRY_CODE;
    case 13:
    case 'DATA_TYPE_REGISTRATION_NUMBER':
      return DataType.DATA_TYPE_REGISTRATION_NUMBER;
    case 14:
    case 'DATA_TYPE_COMPANY_TYPE':
      return DataType.DATA_TYPE_COMPANY_TYPE;
    case 15:
    case 'DATA_TYPE_REGISTERED_ADDRESS':
      return DataType.DATA_TYPE_REGISTERED_ADDRESS;
    case 16:
    case 'DATA_TYPE_TRADING_ADDRESS':
      return DataType.DATA_TYPE_TRADING_ADDRESS;
    case 17:
    case 'DATA_TYPE_ASSOCIATES':
      return DataType.DATA_TYPE_ASSOCIATES;
    case 18:
    case 'DATA_TYPE_DISPLAY_NAME':
      return DataType.DATA_TYPE_DISPLAY_NAME;
    case 19:
    case 'DATA_TYPE_PERSONAL_INFORMATION':
      return DataType.DATA_TYPE_PERSONAL_INFORMATION;
    case 20:
    case 'DATA_TYPE_BIRTH_INFORMATION':
      return DataType.DATA_TYPE_BIRTH_INFORMATION;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DataType.UNRECOGNIZED;
  }
}

export function dataTypeToJSON(object: DataType): string {
  switch (object) {
    case DataType.DATA_TYPE_INVALID:
      return 'DATA_TYPE_INVALID';
    case DataType.DATA_TYPE_LEGAL_NAME:
      return 'DATA_TYPE_LEGAL_NAME';
    case DataType.DATA_TYPE_ADDRESS:
      return 'DATA_TYPE_ADDRESS';
    case DataType.DATA_TYPE_DATE_OF_BIRTH:
      return 'DATA_TYPE_DATE_OF_BIRTH';
    case DataType.DATA_TYPE_PREFERRED_NAME:
      return 'DATA_TYPE_PREFERRED_NAME';
    case DataType.DATA_TYPE_COUNTRY_OF_BIRTH:
      return 'DATA_TYPE_COUNTRY_OF_BIRTH';
    case DataType.DATA_TYPE_LEGAL_GENDER:
      return 'DATA_TYPE_LEGAL_GENDER';
    case DataType.DATA_TYPE_RECIPIENT_PURPOSE:
      return 'DATA_TYPE_RECIPIENT_PURPOSE';
    case DataType.DATA_TYPE_COUNTRY_OF_RESIDENCE:
      return 'DATA_TYPE_COUNTRY_OF_RESIDENCE';
    case DataType.DATA_TYPE_NATIONALITY:
      return 'DATA_TYPE_NATIONALITY';
    case DataType.DATA_TYPE_PHONE_NUMBER:
      return 'DATA_TYPE_PHONE_NUMBER';
    case DataType.DATA_TYPE_EMAIL_ADDRESS:
      return 'DATA_TYPE_EMAIL_ADDRESS';
    case DataType.DATA_TYPE_INDUSTRY_CODE:
      return 'DATA_TYPE_INDUSTRY_CODE';
    case DataType.DATA_TYPE_REGISTRATION_NUMBER:
      return 'DATA_TYPE_REGISTRATION_NUMBER';
    case DataType.DATA_TYPE_COMPANY_TYPE:
      return 'DATA_TYPE_COMPANY_TYPE';
    case DataType.DATA_TYPE_REGISTERED_ADDRESS:
      return 'DATA_TYPE_REGISTERED_ADDRESS';
    case DataType.DATA_TYPE_TRADING_ADDRESS:
      return 'DATA_TYPE_TRADING_ADDRESS';
    case DataType.DATA_TYPE_ASSOCIATES:
      return 'DATA_TYPE_ASSOCIATES';
    case DataType.DATA_TYPE_DISPLAY_NAME:
      return 'DATA_TYPE_DISPLAY_NAME';
    case DataType.DATA_TYPE_PERSONAL_INFORMATION:
      return 'DATA_TYPE_PERSONAL_INFORMATION';
    case DataType.DATA_TYPE_BIRTH_INFORMATION:
      return 'DATA_TYPE_BIRTH_INFORMATION';
    case DataType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum PartyType {
  PARTY_TYPE_INVALID = 0,
  PARTY_TYPE_USER = 1,
  PARTY_TYPE_RECIPIENT_ACCOUNT = 2,
  PARTY_TYPE_BUSINESS = 3,
  UNRECOGNIZED = -1,
}

export function partyTypeFromJSON(object: any): PartyType {
  switch (object) {
    case 0:
    case 'PARTY_TYPE_INVALID':
      return PartyType.PARTY_TYPE_INVALID;
    case 1:
    case 'PARTY_TYPE_USER':
      return PartyType.PARTY_TYPE_USER;
    case 2:
    case 'PARTY_TYPE_RECIPIENT_ACCOUNT':
      return PartyType.PARTY_TYPE_RECIPIENT_ACCOUNT;
    case 3:
    case 'PARTY_TYPE_BUSINESS':
      return PartyType.PARTY_TYPE_BUSINESS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PartyType.UNRECOGNIZED;
  }
}

export function partyTypeToJSON(object: PartyType): string {
  switch (object) {
    case PartyType.PARTY_TYPE_INVALID:
      return 'PARTY_TYPE_INVALID';
    case PartyType.PARTY_TYPE_USER:
      return 'PARTY_TYPE_USER';
    case PartyType.PARTY_TYPE_RECIPIENT_ACCOUNT:
      return 'PARTY_TYPE_RECIPIENT_ACCOUNT';
    case PartyType.PARTY_TYPE_BUSINESS:
      return 'PARTY_TYPE_BUSINESS';
    case PartyType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum WaitReason {
  WAIT_REASON_INVALID = 0,
  WAIT_REASON_WAITING_FOR_PROVIDER = 1,
  WAIT_REASON_WAITING_FOR_DOCUMENT_REVIEW = 2,
  UNRECOGNIZED = -1,
}

export function waitReasonFromJSON(object: any): WaitReason {
  switch (object) {
    case 0:
    case 'WAIT_REASON_INVALID':
      return WaitReason.WAIT_REASON_INVALID;
    case 1:
    case 'WAIT_REASON_WAITING_FOR_PROVIDER':
      return WaitReason.WAIT_REASON_WAITING_FOR_PROVIDER;
    case 2:
    case 'WAIT_REASON_WAITING_FOR_DOCUMENT_REVIEW':
      return WaitReason.WAIT_REASON_WAITING_FOR_DOCUMENT_REVIEW;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return WaitReason.UNRECOGNIZED;
  }
}

export function waitReasonToJSON(object: WaitReason): string {
  switch (object) {
    case WaitReason.WAIT_REASON_INVALID:
      return 'WAIT_REASON_INVALID';
    case WaitReason.WAIT_REASON_WAITING_FOR_PROVIDER:
      return 'WAIT_REASON_WAITING_FOR_PROVIDER';
    case WaitReason.WAIT_REASON_WAITING_FOR_DOCUMENT_REVIEW:
      return 'WAIT_REASON_WAITING_FOR_DOCUMENT_REVIEW';
    case WaitReason.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum ActionReason {
  ACTION_REASON_INVALID = 0,
  ACTION_REASON_LIMITS_REACHED = 1,
  ACTION_REASON_CHECK_MISSING = 2,
  ACTION_REASON_CHECK_CREATION_FAILED = 3,
  ACTION_REASON_CHECK_FAILED = 4,
  ACTION_REASON_NO_NEW_DOCUMENTS = 5,
  ACTION_REASON_DATA_MISSING = 6,
  ACTION_REASON_UNEDITABLE_DATA_MISSING = 7,
  ACTION_REASON_TRANSACTION_ON_HOLD = 8,
  ACTION_REASON_CORRIDOR_NOT_SUPPORTED = 9,
  ACTION_REASON_USER_NOT_ACTIVE = 10,
  ACTION_REASON_USER_ACCOUNT_NOT_ACTIVE = 11,
  ACTION_REASON_MAX_ATTEMPTS_REACHED = 12,
  ACTION_REASON_ERRORS = 13,
  UNRECOGNIZED = -1,
}

export function actionReasonFromJSON(object: any): ActionReason {
  switch (object) {
    case 0:
    case 'ACTION_REASON_INVALID':
      return ActionReason.ACTION_REASON_INVALID;
    case 1:
    case 'ACTION_REASON_LIMITS_REACHED':
      return ActionReason.ACTION_REASON_LIMITS_REACHED;
    case 2:
    case 'ACTION_REASON_CHECK_MISSING':
      return ActionReason.ACTION_REASON_CHECK_MISSING;
    case 3:
    case 'ACTION_REASON_CHECK_CREATION_FAILED':
      return ActionReason.ACTION_REASON_CHECK_CREATION_FAILED;
    case 4:
    case 'ACTION_REASON_CHECK_FAILED':
      return ActionReason.ACTION_REASON_CHECK_FAILED;
    case 5:
    case 'ACTION_REASON_NO_NEW_DOCUMENTS':
      return ActionReason.ACTION_REASON_NO_NEW_DOCUMENTS;
    case 6:
    case 'ACTION_REASON_DATA_MISSING':
      return ActionReason.ACTION_REASON_DATA_MISSING;
    case 7:
    case 'ACTION_REASON_UNEDITABLE_DATA_MISSING':
      return ActionReason.ACTION_REASON_UNEDITABLE_DATA_MISSING;
    case 8:
    case 'ACTION_REASON_TRANSACTION_ON_HOLD':
      return ActionReason.ACTION_REASON_TRANSACTION_ON_HOLD;
    case 9:
    case 'ACTION_REASON_CORRIDOR_NOT_SUPPORTED':
      return ActionReason.ACTION_REASON_CORRIDOR_NOT_SUPPORTED;
    case 10:
    case 'ACTION_REASON_USER_NOT_ACTIVE':
      return ActionReason.ACTION_REASON_USER_NOT_ACTIVE;
    case 11:
    case 'ACTION_REASON_USER_ACCOUNT_NOT_ACTIVE':
      return ActionReason.ACTION_REASON_USER_ACCOUNT_NOT_ACTIVE;
    case 12:
    case 'ACTION_REASON_MAX_ATTEMPTS_REACHED':
      return ActionReason.ACTION_REASON_MAX_ATTEMPTS_REACHED;
    case 13:
    case 'ACTION_REASON_ERRORS':
      return ActionReason.ACTION_REASON_ERRORS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ActionReason.UNRECOGNIZED;
  }
}

export function actionReasonToJSON(object: ActionReason): string {
  switch (object) {
    case ActionReason.ACTION_REASON_INVALID:
      return 'ACTION_REASON_INVALID';
    case ActionReason.ACTION_REASON_LIMITS_REACHED:
      return 'ACTION_REASON_LIMITS_REACHED';
    case ActionReason.ACTION_REASON_CHECK_MISSING:
      return 'ACTION_REASON_CHECK_MISSING';
    case ActionReason.ACTION_REASON_CHECK_CREATION_FAILED:
      return 'ACTION_REASON_CHECK_CREATION_FAILED';
    case ActionReason.ACTION_REASON_CHECK_FAILED:
      return 'ACTION_REASON_CHECK_FAILED';
    case ActionReason.ACTION_REASON_NO_NEW_DOCUMENTS:
      return 'ACTION_REASON_NO_NEW_DOCUMENTS';
    case ActionReason.ACTION_REASON_DATA_MISSING:
      return 'ACTION_REASON_DATA_MISSING';
    case ActionReason.ACTION_REASON_UNEDITABLE_DATA_MISSING:
      return 'ACTION_REASON_UNEDITABLE_DATA_MISSING';
    case ActionReason.ACTION_REASON_TRANSACTION_ON_HOLD:
      return 'ACTION_REASON_TRANSACTION_ON_HOLD';
    case ActionReason.ACTION_REASON_CORRIDOR_NOT_SUPPORTED:
      return 'ACTION_REASON_CORRIDOR_NOT_SUPPORTED';
    case ActionReason.ACTION_REASON_USER_NOT_ACTIVE:
      return 'ACTION_REASON_USER_NOT_ACTIVE';
    case ActionReason.ACTION_REASON_USER_ACCOUNT_NOT_ACTIVE:
      return 'ACTION_REASON_USER_ACCOUNT_NOT_ACTIVE';
    case ActionReason.ACTION_REASON_MAX_ATTEMPTS_REACHED:
      return 'ACTION_REASON_MAX_ATTEMPTS_REACHED';
    case ActionReason.ACTION_REASON_ERRORS:
      return 'ACTION_REASON_ERRORS';
    case ActionReason.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum EntityType {
  ENTITY_TYPE_INVALID = 0,
  ENTITY_TYPE_INDIVIDUAL = 1,
  ENTITY_TYPE_BUSINESS = 2,
  UNRECOGNIZED = -1,
}

export function entityTypeFromJSON(object: any): EntityType {
  switch (object) {
    case 0:
    case 'ENTITY_TYPE_INVALID':
      return EntityType.ENTITY_TYPE_INVALID;
    case 1:
    case 'ENTITY_TYPE_INDIVIDUAL':
      return EntityType.ENTITY_TYPE_INDIVIDUAL;
    case 2:
    case 'ENTITY_TYPE_BUSINESS':
      return EntityType.ENTITY_TYPE_BUSINESS;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EntityType.UNRECOGNIZED;
  }
}

export function entityTypeToJSON(object: EntityType): string {
  switch (object) {
    case EntityType.ENTITY_TYPE_INVALID:
      return 'ENTITY_TYPE_INVALID';
    case EntityType.ENTITY_TYPE_INDIVIDUAL:
      return 'ENTITY_TYPE_INDIVIDUAL';
    case EntityType.ENTITY_TYPE_BUSINESS:
      return 'ENTITY_TYPE_BUSINESS';
    case EntityType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum ApprovalStatus {
  APPROVAL_STATUS_INVALID = 0,
  APPROVAL_STATUS_APPROVED = 1,
  APPROVAL_STATUS_PENDING = 2,
  APPROVAL_STATUS_NOT_APPROVED = 3,
  UNRECOGNIZED = -1,
}

export function approvalStatusFromJSON(object: any): ApprovalStatus {
  switch (object) {
    case 0:
    case 'APPROVAL_STATUS_INVALID':
      return ApprovalStatus.APPROVAL_STATUS_INVALID;
    case 1:
    case 'APPROVAL_STATUS_APPROVED':
      return ApprovalStatus.APPROVAL_STATUS_APPROVED;
    case 2:
    case 'APPROVAL_STATUS_PENDING':
      return ApprovalStatus.APPROVAL_STATUS_PENDING;
    case 3:
    case 'APPROVAL_STATUS_NOT_APPROVED':
      return ApprovalStatus.APPROVAL_STATUS_NOT_APPROVED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ApprovalStatus.UNRECOGNIZED;
  }
}

export function approvalStatusToJSON(object: ApprovalStatus): string {
  switch (object) {
    case ApprovalStatus.APPROVAL_STATUS_INVALID:
      return 'APPROVAL_STATUS_INVALID';
    case ApprovalStatus.APPROVAL_STATUS_APPROVED:
      return 'APPROVAL_STATUS_APPROVED';
    case ApprovalStatus.APPROVAL_STATUS_PENDING:
      return 'APPROVAL_STATUS_PENDING';
    case ApprovalStatus.APPROVAL_STATUS_NOT_APPROVED:
      return 'APPROVAL_STATUS_NOT_APPROVED';
    case ApprovalStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum Status {
  STATUS_INVALID = 0,
  STATUS_COLLECT_ONLY = 1,
  STATUS_PASSED_IDV = 2,
  STATUS_PASSED_BASIC = 3,
  STATUS_PASSED_STANDARD = 4,
  STATUS_PASSED_ENHANCED = 5,
  STATUS_PASSED_AML_ONLY = 6,
  STATUS_PASSED_AML_PURPOSE = 7,
  STATUS_PENDING_PEP = 8,
  STATUS_BLOCKED_SANCTIONS = 9,
  STATUS_FAILED = 10,
  STATUS_FAILED_REPUTATION = 11,
  STATUS_FORBIDDEN = 12,
  UNRECOGNIZED = -1,
}

export function statusFromJSON(object: any): Status {
  switch (object) {
    case 0:
    case 'STATUS_INVALID':
      return Status.STATUS_INVALID;
    case 1:
    case 'STATUS_COLLECT_ONLY':
      return Status.STATUS_COLLECT_ONLY;
    case 2:
    case 'STATUS_PASSED_IDV':
      return Status.STATUS_PASSED_IDV;
    case 3:
    case 'STATUS_PASSED_BASIC':
      return Status.STATUS_PASSED_BASIC;
    case 4:
    case 'STATUS_PASSED_STANDARD':
      return Status.STATUS_PASSED_STANDARD;
    case 5:
    case 'STATUS_PASSED_ENHANCED':
      return Status.STATUS_PASSED_ENHANCED;
    case 6:
    case 'STATUS_PASSED_AML_ONLY':
      return Status.STATUS_PASSED_AML_ONLY;
    case 7:
    case 'STATUS_PASSED_AML_PURPOSE':
      return Status.STATUS_PASSED_AML_PURPOSE;
    case 8:
    case 'STATUS_PENDING_PEP':
      return Status.STATUS_PENDING_PEP;
    case 9:
    case 'STATUS_BLOCKED_SANCTIONS':
      return Status.STATUS_BLOCKED_SANCTIONS;
    case 10:
    case 'STATUS_FAILED':
      return Status.STATUS_FAILED;
    case 11:
    case 'STATUS_FAILED_REPUTATION':
      return Status.STATUS_FAILED_REPUTATION;
    case 12:
    case 'STATUS_FORBIDDEN':
      return Status.STATUS_FORBIDDEN;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Status.UNRECOGNIZED;
  }
}

export function statusToJSON(object: Status): string {
  switch (object) {
    case Status.STATUS_INVALID:
      return 'STATUS_INVALID';
    case Status.STATUS_COLLECT_ONLY:
      return 'STATUS_COLLECT_ONLY';
    case Status.STATUS_PASSED_IDV:
      return 'STATUS_PASSED_IDV';
    case Status.STATUS_PASSED_BASIC:
      return 'STATUS_PASSED_BASIC';
    case Status.STATUS_PASSED_STANDARD:
      return 'STATUS_PASSED_STANDARD';
    case Status.STATUS_PASSED_ENHANCED:
      return 'STATUS_PASSED_ENHANCED';
    case Status.STATUS_PASSED_AML_ONLY:
      return 'STATUS_PASSED_AML_ONLY';
    case Status.STATUS_PASSED_AML_PURPOSE:
      return 'STATUS_PASSED_AML_PURPOSE';
    case Status.STATUS_PENDING_PEP:
      return 'STATUS_PENDING_PEP';
    case Status.STATUS_BLOCKED_SANCTIONS:
      return 'STATUS_BLOCKED_SANCTIONS';
    case Status.STATUS_FAILED:
      return 'STATUS_FAILED';
    case Status.STATUS_FAILED_REPUTATION:
      return 'STATUS_FAILED_REPUTATION';
    case Status.STATUS_FORBIDDEN:
      return 'STATUS_FORBIDDEN';
    case Status.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum CompanyType {
  COMPANY_TYPE_INVALID = 0,
  COMPANY_TYPE_LLC = 1,
  COMPANY_TYPE_PLC = 2,
  COMPANY_TYPE_CHARITY = 3,
  COMPANY_TYPE_SOLE_TRADER = 4,
  UNRECOGNIZED = -1,
}

export function companyTypeFromJSON(object: any): CompanyType {
  switch (object) {
    case 0:
    case 'COMPANY_TYPE_INVALID':
      return CompanyType.COMPANY_TYPE_INVALID;
    case 1:
    case 'COMPANY_TYPE_LLC':
      return CompanyType.COMPANY_TYPE_LLC;
    case 2:
    case 'COMPANY_TYPE_PLC':
      return CompanyType.COMPANY_TYPE_PLC;
    case 3:
    case 'COMPANY_TYPE_CHARITY':
      return CompanyType.COMPANY_TYPE_CHARITY;
    case 4:
    case 'COMPANY_TYPE_SOLE_TRADER':
      return CompanyType.COMPANY_TYPE_SOLE_TRADER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CompanyType.UNRECOGNIZED;
  }
}

export function companyTypeToJSON(object: CompanyType): string {
  switch (object) {
    case CompanyType.COMPANY_TYPE_INVALID:
      return 'COMPANY_TYPE_INVALID';
    case CompanyType.COMPANY_TYPE_LLC:
      return 'COMPANY_TYPE_LLC';
    case CompanyType.COMPANY_TYPE_PLC:
      return 'COMPANY_TYPE_PLC';
    case CompanyType.COMPANY_TYPE_CHARITY:
      return 'COMPANY_TYPE_CHARITY';
    case CompanyType.COMPANY_TYPE_SOLE_TRADER:
      return 'COMPANY_TYPE_SOLE_TRADER';
    case CompanyType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum IndustryType {
  INDUSTRY_TYPE_INVALID = 0,
  INDUSTRY_TYPE_AGRICULTURE = 1,
  INDUSTRY_TYPE_CONSTRUCTION_AND_TRANSPORTATION = 2,
  INDUSTRY_TYPE_DEFENCE = 3,
  INDUSTRY_TYPE_EDUCATION = 4,
  INDUSTRY_TYPE_ENERGY = 5,
  INDUSTRY_TYPE_ENTERTAINMENT_AND_MEDIA = 6,
  INDUSTRY_TYPE_FINANCIAL_SERVICES = 7,
  INDUSTRY_TYPE_FINTECH = 8,
  INDUSTRY_TYPE_GOVERNMENT = 9,
  INDUSTRY_TYPE_HEALTHCARE = 10,
  INDUSTRY_TYPE_INFRASTRUCTURE = 11,
  INDUSTRY_TYPE_INSURANCE = 12,
  INDUSTRY_TYPE_MINING = 13,
  INDUSTRY_TYPE_POWER_AND_UTILITIES = 14,
  INDUSTRY_TYPE_RETAIL_AND_CONSUMER = 15,
  INDUSTRY_TYPE_TELECOMMUNICATIONS = 16,
  INDUSTRY_TYPE_TECHNOLOGY = 17,
  UNRECOGNIZED = -1,
}

export function industryTypeFromJSON(object: any): IndustryType {
  switch (object) {
    case 0:
    case 'INDUSTRY_TYPE_INVALID':
      return IndustryType.INDUSTRY_TYPE_INVALID;
    case 1:
    case 'INDUSTRY_TYPE_AGRICULTURE':
      return IndustryType.INDUSTRY_TYPE_AGRICULTURE;
    case 2:
    case 'INDUSTRY_TYPE_CONSTRUCTION_AND_TRANSPORTATION':
      return IndustryType.INDUSTRY_TYPE_CONSTRUCTION_AND_TRANSPORTATION;
    case 3:
    case 'INDUSTRY_TYPE_DEFENCE':
      return IndustryType.INDUSTRY_TYPE_DEFENCE;
    case 4:
    case 'INDUSTRY_TYPE_EDUCATION':
      return IndustryType.INDUSTRY_TYPE_EDUCATION;
    case 5:
    case 'INDUSTRY_TYPE_ENERGY':
      return IndustryType.INDUSTRY_TYPE_ENERGY;
    case 6:
    case 'INDUSTRY_TYPE_ENTERTAINMENT_AND_MEDIA':
      return IndustryType.INDUSTRY_TYPE_ENTERTAINMENT_AND_MEDIA;
    case 7:
    case 'INDUSTRY_TYPE_FINANCIAL_SERVICES':
      return IndustryType.INDUSTRY_TYPE_FINANCIAL_SERVICES;
    case 8:
    case 'INDUSTRY_TYPE_FINTECH':
      return IndustryType.INDUSTRY_TYPE_FINTECH;
    case 9:
    case 'INDUSTRY_TYPE_GOVERNMENT':
      return IndustryType.INDUSTRY_TYPE_GOVERNMENT;
    case 10:
    case 'INDUSTRY_TYPE_HEALTHCARE':
      return IndustryType.INDUSTRY_TYPE_HEALTHCARE;
    case 11:
    case 'INDUSTRY_TYPE_INFRASTRUCTURE':
      return IndustryType.INDUSTRY_TYPE_INFRASTRUCTURE;
    case 12:
    case 'INDUSTRY_TYPE_INSURANCE':
      return IndustryType.INDUSTRY_TYPE_INSURANCE;
    case 13:
    case 'INDUSTRY_TYPE_MINING':
      return IndustryType.INDUSTRY_TYPE_MINING;
    case 14:
    case 'INDUSTRY_TYPE_POWER_AND_UTILITIES':
      return IndustryType.INDUSTRY_TYPE_POWER_AND_UTILITIES;
    case 15:
    case 'INDUSTRY_TYPE_RETAIL_AND_CONSUMER':
      return IndustryType.INDUSTRY_TYPE_RETAIL_AND_CONSUMER;
    case 16:
    case 'INDUSTRY_TYPE_TELECOMMUNICATIONS':
      return IndustryType.INDUSTRY_TYPE_TELECOMMUNICATIONS;
    case 17:
    case 'INDUSTRY_TYPE_TECHNOLOGY':
      return IndustryType.INDUSTRY_TYPE_TECHNOLOGY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return IndustryType.UNRECOGNIZED;
  }
}

export function industryTypeToJSON(object: IndustryType): string {
  switch (object) {
    case IndustryType.INDUSTRY_TYPE_INVALID:
      return 'INDUSTRY_TYPE_INVALID';
    case IndustryType.INDUSTRY_TYPE_AGRICULTURE:
      return 'INDUSTRY_TYPE_AGRICULTURE';
    case IndustryType.INDUSTRY_TYPE_CONSTRUCTION_AND_TRANSPORTATION:
      return 'INDUSTRY_TYPE_CONSTRUCTION_AND_TRANSPORTATION';
    case IndustryType.INDUSTRY_TYPE_DEFENCE:
      return 'INDUSTRY_TYPE_DEFENCE';
    case IndustryType.INDUSTRY_TYPE_EDUCATION:
      return 'INDUSTRY_TYPE_EDUCATION';
    case IndustryType.INDUSTRY_TYPE_ENERGY:
      return 'INDUSTRY_TYPE_ENERGY';
    case IndustryType.INDUSTRY_TYPE_ENTERTAINMENT_AND_MEDIA:
      return 'INDUSTRY_TYPE_ENTERTAINMENT_AND_MEDIA';
    case IndustryType.INDUSTRY_TYPE_FINANCIAL_SERVICES:
      return 'INDUSTRY_TYPE_FINANCIAL_SERVICES';
    case IndustryType.INDUSTRY_TYPE_FINTECH:
      return 'INDUSTRY_TYPE_FINTECH';
    case IndustryType.INDUSTRY_TYPE_GOVERNMENT:
      return 'INDUSTRY_TYPE_GOVERNMENT';
    case IndustryType.INDUSTRY_TYPE_HEALTHCARE:
      return 'INDUSTRY_TYPE_HEALTHCARE';
    case IndustryType.INDUSTRY_TYPE_INFRASTRUCTURE:
      return 'INDUSTRY_TYPE_INFRASTRUCTURE';
    case IndustryType.INDUSTRY_TYPE_INSURANCE:
      return 'INDUSTRY_TYPE_INSURANCE';
    case IndustryType.INDUSTRY_TYPE_MINING:
      return 'INDUSTRY_TYPE_MINING';
    case IndustryType.INDUSTRY_TYPE_POWER_AND_UTILITIES:
      return 'INDUSTRY_TYPE_POWER_AND_UTILITIES';
    case IndustryType.INDUSTRY_TYPE_RETAIL_AND_CONSUMER:
      return 'INDUSTRY_TYPE_RETAIL_AND_CONSUMER';
    case IndustryType.INDUSTRY_TYPE_TELECOMMUNICATIONS:
      return 'INDUSTRY_TYPE_TELECOMMUNICATIONS';
    case IndustryType.INDUSTRY_TYPE_TECHNOLOGY:
      return 'INDUSTRY_TYPE_TECHNOLOGY';
    case IndustryType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum AssociateType {
  ASSOCIATE_TYPE_INVALID = 0,
  ASSOCIATE_TYPE_DIRECTOR = 1,
  ASSOCIATE_TYPE_PARTNER = 2,
  ASSOCIATE_TYPE_BENE_OWNER = 3,
  ASSOCIATE_TYPE_SOLE_TRADER = 4,
  UNRECOGNIZED = -1,
}

export function associateTypeFromJSON(object: any): AssociateType {
  switch (object) {
    case 0:
    case 'ASSOCIATE_TYPE_INVALID':
      return AssociateType.ASSOCIATE_TYPE_INVALID;
    case 1:
    case 'ASSOCIATE_TYPE_DIRECTOR':
      return AssociateType.ASSOCIATE_TYPE_DIRECTOR;
    case 2:
    case 'ASSOCIATE_TYPE_PARTNER':
      return AssociateType.ASSOCIATE_TYPE_PARTNER;
    case 3:
    case 'ASSOCIATE_TYPE_BENE_OWNER':
      return AssociateType.ASSOCIATE_TYPE_BENE_OWNER;
    case 4:
    case 'ASSOCIATE_TYPE_SOLE_TRADER':
      return AssociateType.ASSOCIATE_TYPE_SOLE_TRADER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssociateType.UNRECOGNIZED;
  }
}

export function associateTypeToJSON(object: AssociateType): string {
  switch (object) {
    case AssociateType.ASSOCIATE_TYPE_INVALID:
      return 'ASSOCIATE_TYPE_INVALID';
    case AssociateType.ASSOCIATE_TYPE_DIRECTOR:
      return 'ASSOCIATE_TYPE_DIRECTOR';
    case AssociateType.ASSOCIATE_TYPE_PARTNER:
      return 'ASSOCIATE_TYPE_PARTNER';
    case AssociateType.ASSOCIATE_TYPE_BENE_OWNER:
      return 'ASSOCIATE_TYPE_BENE_OWNER';
    case AssociateType.ASSOCIATE_TYPE_SOLE_TRADER:
      return 'ASSOCIATE_TYPE_SOLE_TRADER';
    case AssociateType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
 * buf:lint:ignore COMMENT_ENUM
 */
export enum Gender {
  GENDER_UNKNOWN = 0,
  GENDER_FEMALE = 1,
  GENDER_MALE = 2,
  GENDER_OTHER = 3,
  UNRECOGNIZED = -1,
}

export function genderFromJSON(object: any): Gender {
  switch (object) {
    case 0:
    case 'GENDER_UNKNOWN':
      return Gender.GENDER_UNKNOWN;
    case 1:
    case 'GENDER_FEMALE':
      return Gender.GENDER_FEMALE;
    case 2:
    case 'GENDER_MALE':
      return Gender.GENDER_MALE;
    case 3:
    case 'GENDER_OTHER':
      return Gender.GENDER_OTHER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Gender.UNRECOGNIZED;
  }
}

export function genderToJSON(object: Gender): string {
  switch (object) {
    case Gender.GENDER_UNKNOWN:
      return 'GENDER_UNKNOWN';
    case Gender.GENDER_FEMALE:
      return 'GENDER_FEMALE';
    case Gender.GENDER_MALE:
      return 'GENDER_MALE';
    case Gender.GENDER_OTHER:
      return 'GENDER_OTHER';
    case Gender.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum PublicStatus {
  PUBLIC_STATUS_INVALID = 0,
  PUBLIC_STATUS_PENDING = 1,
  PUBLIC_STATUS_VERIFIED = 2,
  UNRECOGNIZED = -1,
}

export function publicStatusFromJSON(object: any): PublicStatus {
  switch (object) {
    case 0:
    case 'PUBLIC_STATUS_INVALID':
      return PublicStatus.PUBLIC_STATUS_INVALID;
    case 1:
    case 'PUBLIC_STATUS_PENDING':
      return PublicStatus.PUBLIC_STATUS_PENDING;
    case 2:
    case 'PUBLIC_STATUS_VERIFIED':
      return PublicStatus.PUBLIC_STATUS_VERIFIED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return PublicStatus.UNRECOGNIZED;
  }
}

export function publicStatusToJSON(object: PublicStatus): string {
  switch (object) {
    case PublicStatus.PUBLIC_STATUS_INVALID:
      return 'PUBLIC_STATUS_INVALID';
    case PublicStatus.PUBLIC_STATUS_PENDING:
      return 'PUBLIC_STATUS_PENDING';
    case PublicStatus.PUBLIC_STATUS_VERIFIED:
      return 'PUBLIC_STATUS_VERIFIED';
    case PublicStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum DocumentType {
  DOCUMENT_TYPE_INVALID = 0,
  DOCUMENT_TYPE_SSN = 1,
  DOCUMENT_TYPE_EIN = 2,
  DOCUMENT_TYPE_ITIN = 3,
  DOCUMENT_TYPE_ATIN = 4,
  DOCUMENT_TYPE_PTIN = 5,
  DOCUMENT_TYPE_DRIVER_LICENSE = 6,
  DOCUMENT_TYPE_DRIVER_PERMIT = 7,
  DOCUMENT_TYPE_STATE_ID_CARD = 8,
  DOCUMENT_TYPE_MILITARY_ID = 9,
  DOCUMENT_TYPE_UTILITY_BILL = 10,
  DOCUMENT_TYPE_LEASE_AGREEMENT = 11,
  DOCUMENT_TYPE_SIGNED_MORTGAGE_AGREEMENT = 12,
  DOCUMENT_TYPE_TUITION_STATEMENT = 13,
  DOCUMENT_TYPE_PASSPORT = 14,
  DOCUMENT_TYPE_PASSPORT_CARD = 15,
  DOCUMENT_TYPE_MARRIAGE_LICENSE = 16,
  DOCUMENT_TYPE_DIVORCE_DECREE = 17,
  DOCUMENT_TYPE_COURT_ORDER = 18,
  DOCUMENT_TYPE_SOCIAL_SECURITY_CARD = 19,
  DOCUMENT_TYPE_BIRTH_CERTIFICATE = 20,
  DOCUMENT_TYPE_SELFIE = 21,
  DOCUMENT_TYPE_RESIDENCE_PERMIT = 22,
  DOCUMENT_TYPE_BANK_STATEMENT = 23,
  DOCUMENT_TYPE_PAYSLIP = 24,
  DOCUMENT_TYPE_BANK_CARD = 25,
  DOCUMENT_TYPE_SELFIE_WITH_BANK_CARD = 26,
  DOCUMENT_TYPE_BUSINESS_LICENSE = 27,
  DOCUMENT_TYPE_SALE_AGREEMENT = 28,
  DOCUMENT_TYPE_LOAN_CONTRACT = 29,
  DOCUMENT_TYPE_OTHER = 30,
  DOCUMENT_TYPE_EMPLOYMENT_CONTRACT = 31,
  DOCUMENT_TYPE_SSN_LAST4 = 32,
  DOCUMENT_TYPE_HMRC_LETTER = 33,
  DOCUMENT_TYPE_HOUSING_OR_RENT_CARD = 34,
  DOCUMENT_TYPE_ELECTORAL_REGISTRY_ENTRY = 35,
  DOCUMENT_TYPE_NHS_MEDICAL_CARD = 36,
  DOCUMENT_TYPE_GP_PRACTISE_CONFIRMATION_ENROLLMENT = 37,
  DOCUMENT_TYPE_COUNCIL_TAX_BILL = 38,
  UNRECOGNIZED = -1,
}

export function documentTypeFromJSON(object: any): DocumentType {
  switch (object) {
    case 0:
    case 'DOCUMENT_TYPE_INVALID':
      return DocumentType.DOCUMENT_TYPE_INVALID;
    case 1:
    case 'DOCUMENT_TYPE_SSN':
      return DocumentType.DOCUMENT_TYPE_SSN;
    case 2:
    case 'DOCUMENT_TYPE_EIN':
      return DocumentType.DOCUMENT_TYPE_EIN;
    case 3:
    case 'DOCUMENT_TYPE_ITIN':
      return DocumentType.DOCUMENT_TYPE_ITIN;
    case 4:
    case 'DOCUMENT_TYPE_ATIN':
      return DocumentType.DOCUMENT_TYPE_ATIN;
    case 5:
    case 'DOCUMENT_TYPE_PTIN':
      return DocumentType.DOCUMENT_TYPE_PTIN;
    case 6:
    case 'DOCUMENT_TYPE_DRIVER_LICENSE':
      return DocumentType.DOCUMENT_TYPE_DRIVER_LICENSE;
    case 7:
    case 'DOCUMENT_TYPE_DRIVER_PERMIT':
      return DocumentType.DOCUMENT_TYPE_DRIVER_PERMIT;
    case 8:
    case 'DOCUMENT_TYPE_STATE_ID_CARD':
      return DocumentType.DOCUMENT_TYPE_STATE_ID_CARD;
    case 9:
    case 'DOCUMENT_TYPE_MILITARY_ID':
      return DocumentType.DOCUMENT_TYPE_MILITARY_ID;
    case 10:
    case 'DOCUMENT_TYPE_UTILITY_BILL':
      return DocumentType.DOCUMENT_TYPE_UTILITY_BILL;
    case 11:
    case 'DOCUMENT_TYPE_LEASE_AGREEMENT':
      return DocumentType.DOCUMENT_TYPE_LEASE_AGREEMENT;
    case 12:
    case 'DOCUMENT_TYPE_SIGNED_MORTGAGE_AGREEMENT':
      return DocumentType.DOCUMENT_TYPE_SIGNED_MORTGAGE_AGREEMENT;
    case 13:
    case 'DOCUMENT_TYPE_TUITION_STATEMENT':
      return DocumentType.DOCUMENT_TYPE_TUITION_STATEMENT;
    case 14:
    case 'DOCUMENT_TYPE_PASSPORT':
      return DocumentType.DOCUMENT_TYPE_PASSPORT;
    case 15:
    case 'DOCUMENT_TYPE_PASSPORT_CARD':
      return DocumentType.DOCUMENT_TYPE_PASSPORT_CARD;
    case 16:
    case 'DOCUMENT_TYPE_MARRIAGE_LICENSE':
      return DocumentType.DOCUMENT_TYPE_MARRIAGE_LICENSE;
    case 17:
    case 'DOCUMENT_TYPE_DIVORCE_DECREE':
      return DocumentType.DOCUMENT_TYPE_DIVORCE_DECREE;
    case 18:
    case 'DOCUMENT_TYPE_COURT_ORDER':
      return DocumentType.DOCUMENT_TYPE_COURT_ORDER;
    case 19:
    case 'DOCUMENT_TYPE_SOCIAL_SECURITY_CARD':
      return DocumentType.DOCUMENT_TYPE_SOCIAL_SECURITY_CARD;
    case 20:
    case 'DOCUMENT_TYPE_BIRTH_CERTIFICATE':
      return DocumentType.DOCUMENT_TYPE_BIRTH_CERTIFICATE;
    case 21:
    case 'DOCUMENT_TYPE_SELFIE':
      return DocumentType.DOCUMENT_TYPE_SELFIE;
    case 22:
    case 'DOCUMENT_TYPE_RESIDENCE_PERMIT':
      return DocumentType.DOCUMENT_TYPE_RESIDENCE_PERMIT;
    case 23:
    case 'DOCUMENT_TYPE_BANK_STATEMENT':
      return DocumentType.DOCUMENT_TYPE_BANK_STATEMENT;
    case 24:
    case 'DOCUMENT_TYPE_PAYSLIP':
      return DocumentType.DOCUMENT_TYPE_PAYSLIP;
    case 25:
    case 'DOCUMENT_TYPE_BANK_CARD':
      return DocumentType.DOCUMENT_TYPE_BANK_CARD;
    case 26:
    case 'DOCUMENT_TYPE_SELFIE_WITH_BANK_CARD':
      return DocumentType.DOCUMENT_TYPE_SELFIE_WITH_BANK_CARD;
    case 27:
    case 'DOCUMENT_TYPE_BUSINESS_LICENSE':
      return DocumentType.DOCUMENT_TYPE_BUSINESS_LICENSE;
    case 28:
    case 'DOCUMENT_TYPE_SALE_AGREEMENT':
      return DocumentType.DOCUMENT_TYPE_SALE_AGREEMENT;
    case 29:
    case 'DOCUMENT_TYPE_LOAN_CONTRACT':
      return DocumentType.DOCUMENT_TYPE_LOAN_CONTRACT;
    case 30:
    case 'DOCUMENT_TYPE_OTHER':
      return DocumentType.DOCUMENT_TYPE_OTHER;
    case 31:
    case 'DOCUMENT_TYPE_EMPLOYMENT_CONTRACT':
      return DocumentType.DOCUMENT_TYPE_EMPLOYMENT_CONTRACT;
    case 32:
    case 'DOCUMENT_TYPE_SSN_LAST4':
      return DocumentType.DOCUMENT_TYPE_SSN_LAST4;
    case 33:
    case 'DOCUMENT_TYPE_HMRC_LETTER':
      return DocumentType.DOCUMENT_TYPE_HMRC_LETTER;
    case 34:
    case 'DOCUMENT_TYPE_HOUSING_OR_RENT_CARD':
      return DocumentType.DOCUMENT_TYPE_HOUSING_OR_RENT_CARD;
    case 35:
    case 'DOCUMENT_TYPE_ELECTORAL_REGISTRY_ENTRY':
      return DocumentType.DOCUMENT_TYPE_ELECTORAL_REGISTRY_ENTRY;
    case 36:
    case 'DOCUMENT_TYPE_NHS_MEDICAL_CARD':
      return DocumentType.DOCUMENT_TYPE_NHS_MEDICAL_CARD;
    case 37:
    case 'DOCUMENT_TYPE_GP_PRACTISE_CONFIRMATION_ENROLLMENT':
      return DocumentType.DOCUMENT_TYPE_GP_PRACTISE_CONFIRMATION_ENROLLMENT;
    case 38:
    case 'DOCUMENT_TYPE_COUNCIL_TAX_BILL':
      return DocumentType.DOCUMENT_TYPE_COUNCIL_TAX_BILL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DocumentType.UNRECOGNIZED;
  }
}

export function documentTypeToJSON(object: DocumentType): string {
  switch (object) {
    case DocumentType.DOCUMENT_TYPE_INVALID:
      return 'DOCUMENT_TYPE_INVALID';
    case DocumentType.DOCUMENT_TYPE_SSN:
      return 'DOCUMENT_TYPE_SSN';
    case DocumentType.DOCUMENT_TYPE_EIN:
      return 'DOCUMENT_TYPE_EIN';
    case DocumentType.DOCUMENT_TYPE_ITIN:
      return 'DOCUMENT_TYPE_ITIN';
    case DocumentType.DOCUMENT_TYPE_ATIN:
      return 'DOCUMENT_TYPE_ATIN';
    case DocumentType.DOCUMENT_TYPE_PTIN:
      return 'DOCUMENT_TYPE_PTIN';
    case DocumentType.DOCUMENT_TYPE_DRIVER_LICENSE:
      return 'DOCUMENT_TYPE_DRIVER_LICENSE';
    case DocumentType.DOCUMENT_TYPE_DRIVER_PERMIT:
      return 'DOCUMENT_TYPE_DRIVER_PERMIT';
    case DocumentType.DOCUMENT_TYPE_STATE_ID_CARD:
      return 'DOCUMENT_TYPE_STATE_ID_CARD';
    case DocumentType.DOCUMENT_TYPE_MILITARY_ID:
      return 'DOCUMENT_TYPE_MILITARY_ID';
    case DocumentType.DOCUMENT_TYPE_UTILITY_BILL:
      return 'DOCUMENT_TYPE_UTILITY_BILL';
    case DocumentType.DOCUMENT_TYPE_LEASE_AGREEMENT:
      return 'DOCUMENT_TYPE_LEASE_AGREEMENT';
    case DocumentType.DOCUMENT_TYPE_SIGNED_MORTGAGE_AGREEMENT:
      return 'DOCUMENT_TYPE_SIGNED_MORTGAGE_AGREEMENT';
    case DocumentType.DOCUMENT_TYPE_TUITION_STATEMENT:
      return 'DOCUMENT_TYPE_TUITION_STATEMENT';
    case DocumentType.DOCUMENT_TYPE_PASSPORT:
      return 'DOCUMENT_TYPE_PASSPORT';
    case DocumentType.DOCUMENT_TYPE_PASSPORT_CARD:
      return 'DOCUMENT_TYPE_PASSPORT_CARD';
    case DocumentType.DOCUMENT_TYPE_MARRIAGE_LICENSE:
      return 'DOCUMENT_TYPE_MARRIAGE_LICENSE';
    case DocumentType.DOCUMENT_TYPE_DIVORCE_DECREE:
      return 'DOCUMENT_TYPE_DIVORCE_DECREE';
    case DocumentType.DOCUMENT_TYPE_COURT_ORDER:
      return 'DOCUMENT_TYPE_COURT_ORDER';
    case DocumentType.DOCUMENT_TYPE_SOCIAL_SECURITY_CARD:
      return 'DOCUMENT_TYPE_SOCIAL_SECURITY_CARD';
    case DocumentType.DOCUMENT_TYPE_BIRTH_CERTIFICATE:
      return 'DOCUMENT_TYPE_BIRTH_CERTIFICATE';
    case DocumentType.DOCUMENT_TYPE_SELFIE:
      return 'DOCUMENT_TYPE_SELFIE';
    case DocumentType.DOCUMENT_TYPE_RESIDENCE_PERMIT:
      return 'DOCUMENT_TYPE_RESIDENCE_PERMIT';
    case DocumentType.DOCUMENT_TYPE_BANK_STATEMENT:
      return 'DOCUMENT_TYPE_BANK_STATEMENT';
    case DocumentType.DOCUMENT_TYPE_PAYSLIP:
      return 'DOCUMENT_TYPE_PAYSLIP';
    case DocumentType.DOCUMENT_TYPE_BANK_CARD:
      return 'DOCUMENT_TYPE_BANK_CARD';
    case DocumentType.DOCUMENT_TYPE_SELFIE_WITH_BANK_CARD:
      return 'DOCUMENT_TYPE_SELFIE_WITH_BANK_CARD';
    case DocumentType.DOCUMENT_TYPE_BUSINESS_LICENSE:
      return 'DOCUMENT_TYPE_BUSINESS_LICENSE';
    case DocumentType.DOCUMENT_TYPE_SALE_AGREEMENT:
      return 'DOCUMENT_TYPE_SALE_AGREEMENT';
    case DocumentType.DOCUMENT_TYPE_LOAN_CONTRACT:
      return 'DOCUMENT_TYPE_LOAN_CONTRACT';
    case DocumentType.DOCUMENT_TYPE_OTHER:
      return 'DOCUMENT_TYPE_OTHER';
    case DocumentType.DOCUMENT_TYPE_EMPLOYMENT_CONTRACT:
      return 'DOCUMENT_TYPE_EMPLOYMENT_CONTRACT';
    case DocumentType.DOCUMENT_TYPE_SSN_LAST4:
      return 'DOCUMENT_TYPE_SSN_LAST4';
    case DocumentType.DOCUMENT_TYPE_HMRC_LETTER:
      return 'DOCUMENT_TYPE_HMRC_LETTER';
    case DocumentType.DOCUMENT_TYPE_HOUSING_OR_RENT_CARD:
      return 'DOCUMENT_TYPE_HOUSING_OR_RENT_CARD';
    case DocumentType.DOCUMENT_TYPE_ELECTORAL_REGISTRY_ENTRY:
      return 'DOCUMENT_TYPE_ELECTORAL_REGISTRY_ENTRY';
    case DocumentType.DOCUMENT_TYPE_NHS_MEDICAL_CARD:
      return 'DOCUMENT_TYPE_NHS_MEDICAL_CARD';
    case DocumentType.DOCUMENT_TYPE_GP_PRACTISE_CONFIRMATION_ENROLLMENT:
      return 'DOCUMENT_TYPE_GP_PRACTISE_CONFIRMATION_ENROLLMENT';
    case DocumentType.DOCUMENT_TYPE_COUNCIL_TAX_BILL:
      return 'DOCUMENT_TYPE_COUNCIL_TAX_BILL';
    case DocumentType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum DocumentValueType {
  DOCUMENT_VALUE_TYPE_INVALID = 0,
  DOCUMENT_VALUE_TYPE_TEXT = 1,
  DOCUMENT_VALUE_TYPE_FILE = 2,
  UNRECOGNIZED = -1,
}

export function documentValueTypeFromJSON(object: any): DocumentValueType {
  switch (object) {
    case 0:
    case 'DOCUMENT_VALUE_TYPE_INVALID':
      return DocumentValueType.DOCUMENT_VALUE_TYPE_INVALID;
    case 1:
    case 'DOCUMENT_VALUE_TYPE_TEXT':
      return DocumentValueType.DOCUMENT_VALUE_TYPE_TEXT;
    case 2:
    case 'DOCUMENT_VALUE_TYPE_FILE':
      return DocumentValueType.DOCUMENT_VALUE_TYPE_FILE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DocumentValueType.UNRECOGNIZED;
  }
}

export function documentValueTypeToJSON(object: DocumentValueType): string {
  switch (object) {
    case DocumentValueType.DOCUMENT_VALUE_TYPE_INVALID:
      return 'DOCUMENT_VALUE_TYPE_INVALID';
    case DocumentValueType.DOCUMENT_VALUE_TYPE_TEXT:
      return 'DOCUMENT_VALUE_TYPE_TEXT';
    case DocumentValueType.DOCUMENT_VALUE_TYPE_FILE:
      return 'DOCUMENT_VALUE_TYPE_FILE';
    case DocumentValueType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_ENUM */
export enum DocumentSide {
  DOCUMENT_SIDE_INVALID = 0,
  DOCUMENT_SIDE_FRONT = 1,
  DOCUMENT_SIDE_BACK = 2,
  DOCUMENT_SIDE_BOTH = 3,
  UNRECOGNIZED = -1,
}

export function documentSideFromJSON(object: any): DocumentSide {
  switch (object) {
    case 0:
    case 'DOCUMENT_SIDE_INVALID':
      return DocumentSide.DOCUMENT_SIDE_INVALID;
    case 1:
    case 'DOCUMENT_SIDE_FRONT':
      return DocumentSide.DOCUMENT_SIDE_FRONT;
    case 2:
    case 'DOCUMENT_SIDE_BACK':
      return DocumentSide.DOCUMENT_SIDE_BACK;
    case 3:
    case 'DOCUMENT_SIDE_BOTH':
      return DocumentSide.DOCUMENT_SIDE_BOTH;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DocumentSide.UNRECOGNIZED;
  }
}

export function documentSideToJSON(object: DocumentSide): string {
  switch (object) {
    case DocumentSide.DOCUMENT_SIDE_INVALID:
      return 'DOCUMENT_SIDE_INVALID';
    case DocumentSide.DOCUMENT_SIDE_FRONT:
      return 'DOCUMENT_SIDE_FRONT';
    case DocumentSide.DOCUMENT_SIDE_BACK:
      return 'DOCUMENT_SIDE_BACK';
    case DocumentSide.DOCUMENT_SIDE_BOTH:
      return 'DOCUMENT_SIDE_BOTH';
    case DocumentSide.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** DocumentStatus represents the status of a document */
export enum DocumentStatus {
  DOCUMENT_STATUS_INVALID = 0,
  DOCUMENT_STATUS_UNKNOWN = 1,
  DOCUMENT_STATUS_APPROVED = 2,
  DOCUMENT_STATUS_REJECTED = 3,
  UNRECOGNIZED = -1,
}

export function documentStatusFromJSON(object: any): DocumentStatus {
  switch (object) {
    case 0:
    case 'DOCUMENT_STATUS_INVALID':
      return DocumentStatus.DOCUMENT_STATUS_INVALID;
    case 1:
    case 'DOCUMENT_STATUS_UNKNOWN':
      return DocumentStatus.DOCUMENT_STATUS_UNKNOWN;
    case 2:
    case 'DOCUMENT_STATUS_APPROVED':
      return DocumentStatus.DOCUMENT_STATUS_APPROVED;
    case 3:
    case 'DOCUMENT_STATUS_REJECTED':
      return DocumentStatus.DOCUMENT_STATUS_REJECTED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DocumentStatus.UNRECOGNIZED;
  }
}

export function documentStatusToJSON(object: DocumentStatus): string {
  switch (object) {
    case DocumentStatus.DOCUMENT_STATUS_INVALID:
      return 'DOCUMENT_STATUS_INVALID';
    case DocumentStatus.DOCUMENT_STATUS_UNKNOWN:
      return 'DOCUMENT_STATUS_UNKNOWN';
    case DocumentStatus.DOCUMENT_STATUS_APPROVED:
      return 'DOCUMENT_STATUS_APPROVED';
    case DocumentStatus.DOCUMENT_STATUS_REJECTED:
      return 'DOCUMENT_STATUS_REJECTED';
    case DocumentStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** only used for backward compatibility */
export enum DeprecatedDocumentType {
  DEPRECATED_DOCUMENT_TYPE_INVALID = 0,
  DEPRECATED_DOCUMENT_TYPE_TIN = 1,
  UNRECOGNIZED = -1,
}

export function deprecatedDocumentTypeFromJSON(
  object: any,
): DeprecatedDocumentType {
  switch (object) {
    case 0:
    case 'DEPRECATED_DOCUMENT_TYPE_INVALID':
      return DeprecatedDocumentType.DEPRECATED_DOCUMENT_TYPE_INVALID;
    case 1:
    case 'DEPRECATED_DOCUMENT_TYPE_TIN':
      return DeprecatedDocumentType.DEPRECATED_DOCUMENT_TYPE_TIN;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DeprecatedDocumentType.UNRECOGNIZED;
  }
}

export function deprecatedDocumentTypeToJSON(
  object: DeprecatedDocumentType,
): string {
  switch (object) {
    case DeprecatedDocumentType.DEPRECATED_DOCUMENT_TYPE_INVALID:
      return 'DEPRECATED_DOCUMENT_TYPE_INVALID';
    case DeprecatedDocumentType.DEPRECATED_DOCUMENT_TYPE_TIN:
      return 'DEPRECATED_DOCUMENT_TYPE_TIN';
    case DeprecatedDocumentType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** only used for backward compatibility */
export enum DeprecatedDocumentSubType {
  DEPRECATED_DOCUMENT_SUB_TYPE_INVALID = 0,
  DEPRECATED_DOCUMENT_SUB_TYPE_SSN = 1,
  DEPRECATED_DOCUMENT_SUB_TYPE_EIN = 2,
  DEPRECATED_DOCUMENT_SUB_TYPE_ITIN = 3,
  DEPRECATED_DOCUMENT_SUB_TYPE_ATIN = 4,
  DEPRECATED_DOCUMENT_SUB_TYPE_PTIN = 5,
  UNRECOGNIZED = -1,
}

export function deprecatedDocumentSubTypeFromJSON(
  object: any,
): DeprecatedDocumentSubType {
  switch (object) {
    case 0:
    case 'DEPRECATED_DOCUMENT_SUB_TYPE_INVALID':
      return DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_INVALID;
    case 1:
    case 'DEPRECATED_DOCUMENT_SUB_TYPE_SSN':
      return DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_SSN;
    case 2:
    case 'DEPRECATED_DOCUMENT_SUB_TYPE_EIN':
      return DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_EIN;
    case 3:
    case 'DEPRECATED_DOCUMENT_SUB_TYPE_ITIN':
      return DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_ITIN;
    case 4:
    case 'DEPRECATED_DOCUMENT_SUB_TYPE_ATIN':
      return DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_ATIN;
    case 5:
    case 'DEPRECATED_DOCUMENT_SUB_TYPE_PTIN':
      return DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_PTIN;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DeprecatedDocumentSubType.UNRECOGNIZED;
  }
}

export function deprecatedDocumentSubTypeToJSON(
  object: DeprecatedDocumentSubType,
): string {
  switch (object) {
    case DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_INVALID:
      return 'DEPRECATED_DOCUMENT_SUB_TYPE_INVALID';
    case DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_SSN:
      return 'DEPRECATED_DOCUMENT_SUB_TYPE_SSN';
    case DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_EIN:
      return 'DEPRECATED_DOCUMENT_SUB_TYPE_EIN';
    case DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_ITIN:
      return 'DEPRECATED_DOCUMENT_SUB_TYPE_ITIN';
    case DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_ATIN:
      return 'DEPRECATED_DOCUMENT_SUB_TYPE_ATIN';
    case DeprecatedDocumentSubType.DEPRECATED_DOCUMENT_SUB_TYPE_PTIN:
      return 'DEPRECATED_DOCUMENT_SUB_TYPE_PTIN';
    case DeprecatedDocumentSubType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Name {
  first: string[];
  middle: string[];
  last: string[];
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Party {
  type: PartyType;
  id: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Address {
  type: AddressType;
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
  createdAt: Timestamp | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface RecordData {
  legalName: Name | undefined;
  preferredName: Name | undefined;
  /** @deprecated */
  deprecatedGender: string;
  previousNames: Name[];
  dateOfBirth: Timestamp | undefined;
  nationality: string[];
  addresses: Address[];
  externalIds: { [key: string]: string };
  countryOfBirth: string;
  aliases: Name[];
  legalGender: Gender;
}

export interface RecordData_ExternalIdsEntry {
  key: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Record {
  id: string;
  userId: string;
  status: string;
  recordData: RecordData | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Associate {
  type: AssociateType;
  isApplicant: boolean;
  legalName: Name | undefined;
  dateOfBirth: Timestamp | undefined;
  ownership: number;
  address: Address | undefined;
  email: string;
  phoneNumber: string;
  /** optional user in case the associate has been linked to a user */
  userId: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface BusinessRecordData {
  name: string;
  industryCode: string;
  registrationNumber: string;
  companyType: CompanyType;
  registeredAddress: Address | undefined;
  tradingAddress: Address | undefined;
  associates: Associate[];
  email: string;
  phoneNumber: string;
  industryType: IndustryType;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface BusinessRecord {
  id: string;
  accountId: string;
  status: Status;
  approvalStatus: ApprovalStatus;
  data: BusinessRecordData | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface RecordHistoryEntry {
  id: number;
  recordId: string;
  staffId: string;
  updateReason: string;
  status: string;
  recordData: RecordData | undefined;
  createdAt: Timestamp | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Check {
  id: string;
  userId: string;
  providerName: string;
  providerCheckId: string;
  providerData: { [key: string]: string };
  overallResult: string;
  result: { [key: string]: string };
  reviewResult: { [key: string]: string };
  reviewerId: string;
  reviewedAt: Timestamp | undefined;
  createdAt: Timestamp | undefined;
  expiresAt: Timestamp | undefined;
  lastUpdatedAt: Timestamp | undefined;
  documentRequirements: DocumentRequest[];
}

export interface Check_ProviderDataEntry {
  key: string;
  value: string;
}

export interface Check_ResultEntry {
  key: string;
  value: string;
}

export interface Check_ReviewResultEntry {
  key: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface RuleEvaluation {
  rule: Rule | undefined;
  match: boolean;
  continue: boolean;
  missingCheckTypes: { [key: string]: number };
}

export interface RuleEvaluation_MissingCheckTypesEntry {
  key: string;
  value: number;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Rule {
  name: string;
  status: string;
  /** old requirements were 3 */
  requirement: Uint8Array;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface EvaluateRulesResult {
  ruleEvaluations: RuleEvaluation[];
  passed: { [key: string]: number };
  rejected: { [key: string]: number };
}

export interface EvaluateRulesResult_PassedEntry {
  key: string;
  value: number;
}

export interface EvaluateRulesResult_RejectedEntry {
  key: string;
  value: number;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface RunCheck {
  id: string;
  providerCheckId: string;
  providerData: { [key: string]: string };
}

export interface RunCheck_ProviderDataEntry {
  key: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface DataRequest {
  deprecatedType: string;
  type: DataType;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface LimitsState {
  limits: UserLimits | undefined;
  interval: string;
  resetAt: string;
  limitType: LimitType;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Action {
  /** @deprecated */
  deprecatedType: string;
  runCheck: RunCheck | undefined;
  dataRequest: DataRequest | undefined;
  details: { [key: string]: string };
  documentRequests: DocumentRequest[];
  party: Party | undefined;
  waitReason: WaitReason;
  waitDuration: string;
  reason: ActionReason;
  limitsState: LimitsState | undefined;
  type: ActionType;
  subActions: Action[];
}

export interface Action_DetailsEntry {
  key: string;
  value: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Document {
  /** @deprecated */
  deprecatedType: DeprecatedDocumentType;
  /** @deprecated */
  deprecatedSubType: DeprecatedDocumentSubType;
  country: string;
  value: string;
  expiresAt: Timestamp | undefined;
  issuedAt: Timestamp | undefined;
  valueType: DocumentValueType;
  side: DocumentSide;
  type: DocumentType;
  url: string;
  id: string;
  /**
   * use ListDocumentReviews to get the list of reviews
   *
   * @deprecated
   */
  reviews: DocumentReview[];
  number: string;
  status: DocumentStatus;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface DocumentReview {
  staffId: string;
  /**
   * use status instead
   *
   * @deprecated
   */
  valid: boolean;
  comment: string;
  createdAt: Timestamp | undefined;
  status: DocumentStatus;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface DocumentRequest {
  country: string;
  /**
   * DEPRECATED
   *
   * @deprecated
   */
  deprecatedType: DeprecatedDocumentType;
  /**
   * DEPRECATED
   *
   * @deprecated
   */
  deprecatedSubType: DeprecatedDocumentSubType;
  types: DocumentType[];
}

/** StringList for data string value list */
export interface StringList {
  values: string[];
}

/** Associates for data associates value list */
export interface Associates {
  values: Associate[];
}

/** KYC Data */
export interface Data {
  type: DataType;
  delete?: boolean | undefined;
  string?: string | undefined;
  name?: Name | undefined;
  address?: Address | undefined;
  companyType?: CompanyType | undefined;
  associates?: Associates | undefined;
  stringList?: StringList | undefined;
  gender?: Gender | undefined;
  timestamp?: Timestamp | undefined;
}

function createBaseName(): Name {
  return { first: [], middle: [], last: [] };
}

export const Name = {
  encode(message: Name, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.first) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.middle) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.last) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Name {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseName();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.first.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.middle.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.last.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Name {
    return {
      first: Array.isArray(object?.first)
        ? object.first.map((e: any) => String(e))
        : [],
      middle: Array.isArray(object?.middle)
        ? object.middle.map((e: any) => String(e))
        : [],
      last: Array.isArray(object?.last)
        ? object.last.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: Name): unknown {
    const obj: any = {};
    if (message.first) {
      obj.first = message.first.map((e) => e);
    } else {
      obj.first = [];
    }
    if (message.middle) {
      obj.middle = message.middle.map((e) => e);
    } else {
      obj.middle = [];
    }
    if (message.last) {
      obj.last = message.last.map((e) => e);
    } else {
      obj.last = [];
    }
    return obj;
  },

  create(base?: DeepPartial<Name>): Name {
    return Name.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Name>): Name {
    const message = createBaseName();
    message.first = object.first?.map((e) => e) || [];
    message.middle = object.middle?.map((e) => e) || [];
    message.last = object.last?.map((e) => e) || [];
    return message;
  },
};

function createBaseParty(): Party {
  return { type: 0, id: '' };
}

export const Party = {
  encode(message: Party, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.id !== '') {
      writer.uint32(18).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Party {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Party {
    return {
      type: isSet(object.type) ? partyTypeFromJSON(object.type) : 0,
      id: isSet(object.id) ? String(object.id) : '',
    };
  },

  toJSON(message: Party): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = partyTypeToJSON(message.type));
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create(base?: DeepPartial<Party>): Party {
    return Party.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Party>): Party {
    const message = createBaseParty();
    message.type = object.type ?? 0;
    message.id = object.id ?? '';
    return message;
  },
};

function createBaseAddress(): Address {
  return {
    type: 0,
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    postcode: '',
    city: '',
    state: '',
    country: '',
    createdAt: undefined,
  };
}

export const Address = {
  encode(
    message: Address,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.line1 !== '') {
      writer.uint32(18).string(message.line1);
    }
    if (message.line2 !== '') {
      writer.uint32(26).string(message.line2);
    }
    if (message.line3 !== '') {
      writer.uint32(34).string(message.line3);
    }
    if (message.line4 !== '') {
      writer.uint32(42).string(message.line4);
    }
    if (message.postcode !== '') {
      writer.uint32(50).string(message.postcode);
    }
    if (message.city !== '') {
      writer.uint32(58).string(message.city);
    }
    if (message.state !== '') {
      writer.uint32(66).string(message.state);
    }
    if (message.country !== '') {
      writer.uint32(74).string(message.country);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Address {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.line1 = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.line2 = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.line3 = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.line4 = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.postcode = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.city = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.state = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.country = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Address {
    return {
      type: isSet(object.type) ? addressTypeFromJSON(object.type) : 0,
      line1: isSet(object.line1) ? String(object.line1) : '',
      line2: isSet(object.line2) ? String(object.line2) : '',
      line3: isSet(object.line3) ? String(object.line3) : '',
      line4: isSet(object.line4) ? String(object.line4) : '',
      postcode: isSet(object.postcode) ? String(object.postcode) : '',
      city: isSet(object.city) ? String(object.city) : '',
      state: isSet(object.state) ? String(object.state) : '',
      country: isSet(object.country) ? String(object.country) : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
    };
  },

  toJSON(message: Address): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = addressTypeToJSON(message.type));
    message.line1 !== undefined && (obj.line1 = message.line1);
    message.line2 !== undefined && (obj.line2 = message.line2);
    message.line3 !== undefined && (obj.line3 = message.line3);
    message.line4 !== undefined && (obj.line4 = message.line4);
    message.postcode !== undefined && (obj.postcode = message.postcode);
    message.city !== undefined && (obj.city = message.city);
    message.state !== undefined && (obj.state = message.state);
    message.country !== undefined && (obj.country = message.country);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<Address>): Address {
    return Address.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Address>): Address {
    const message = createBaseAddress();
    message.type = object.type ?? 0;
    message.line1 = object.line1 ?? '';
    message.line2 = object.line2 ?? '';
    message.line3 = object.line3 ?? '';
    message.line4 = object.line4 ?? '';
    message.postcode = object.postcode ?? '';
    message.city = object.city ?? '';
    message.state = object.state ?? '';
    message.country = object.country ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    return message;
  },
};

function createBaseRecordData(): RecordData {
  return {
    legalName: undefined,
    preferredName: undefined,
    deprecatedGender: '',
    previousNames: [],
    dateOfBirth: undefined,
    nationality: [],
    addresses: [],
    externalIds: {},
    countryOfBirth: '',
    aliases: [],
    legalGender: 0,
  };
}

export const RecordData = {
  encode(
    message: RecordData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.legalName !== undefined) {
      Name.encode(message.legalName, writer.uint32(10).fork()).ldelim();
    }
    if (message.preferredName !== undefined) {
      Name.encode(message.preferredName, writer.uint32(18).fork()).ldelim();
    }
    if (message.deprecatedGender !== '') {
      writer.uint32(26).string(message.deprecatedGender);
    }
    for (const v of message.previousNames) {
      Name.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.dateOfBirth !== undefined) {
      Timestamp.encode(message.dateOfBirth, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.nationality) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.addresses) {
      Address.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    Object.entries(message.externalIds).forEach(([key, value]) => {
      RecordData_ExternalIdsEntry.encode(
        { key: key as any, value },
        writer.uint32(66).fork(),
      ).ldelim();
    });
    if (message.countryOfBirth !== '') {
      writer.uint32(74).string(message.countryOfBirth);
    }
    for (const v of message.aliases) {
      Name.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.legalGender !== 0) {
      writer.uint32(88).int32(message.legalGender);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecordData {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.legalName = Name.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.preferredName = Name.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deprecatedGender = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.previousNames.push(Name.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.dateOfBirth = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.nationality.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.addresses.push(Address.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = RecordData_ExternalIdsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry8.value !== undefined) {
            message.externalIds[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.countryOfBirth = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.aliases.push(Name.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.legalGender = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordData {
    return {
      legalName: isSet(object.legalName)
        ? Name.fromJSON(object.legalName)
        : undefined,
      preferredName: isSet(object.preferredName)
        ? Name.fromJSON(object.preferredName)
        : undefined,
      deprecatedGender: isSet(object.deprecatedGender)
        ? String(object.deprecatedGender)
        : '',
      previousNames: Array.isArray(object?.previousNames)
        ? object.previousNames.map((e: any) => Name.fromJSON(e))
        : [],
      dateOfBirth: isSet(object.dateOfBirth)
        ? fromJsonTimestamp(object.dateOfBirth)
        : undefined,
      nationality: Array.isArray(object?.nationality)
        ? object.nationality.map((e: any) => String(e))
        : [],
      addresses: Array.isArray(object?.addresses)
        ? object.addresses.map((e: any) => Address.fromJSON(e))
        : [],
      externalIds: isObject(object.externalIds)
        ? Object.entries(object.externalIds).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      countryOfBirth: isSet(object.countryOfBirth)
        ? String(object.countryOfBirth)
        : '',
      aliases: Array.isArray(object?.aliases)
        ? object.aliases.map((e: any) => Name.fromJSON(e))
        : [],
      legalGender: isSet(object.legalGender)
        ? genderFromJSON(object.legalGender)
        : 0,
    };
  },

  toJSON(message: RecordData): unknown {
    const obj: any = {};
    message.legalName !== undefined &&
      (obj.legalName = message.legalName
        ? Name.toJSON(message.legalName)
        : undefined);
    message.preferredName !== undefined &&
      (obj.preferredName = message.preferredName
        ? Name.toJSON(message.preferredName)
        : undefined);
    message.deprecatedGender !== undefined &&
      (obj.deprecatedGender = message.deprecatedGender);
    if (message.previousNames) {
      obj.previousNames = message.previousNames.map((e) =>
        e ? Name.toJSON(e) : undefined,
      );
    } else {
      obj.previousNames = [];
    }
    message.dateOfBirth !== undefined &&
      (obj.dateOfBirth = fromTimestamp(message.dateOfBirth).toISOString());
    if (message.nationality) {
      obj.nationality = message.nationality.map((e) => e);
    } else {
      obj.nationality = [];
    }
    if (message.addresses) {
      obj.addresses = message.addresses.map((e) =>
        e ? Address.toJSON(e) : undefined,
      );
    } else {
      obj.addresses = [];
    }
    obj.externalIds = {};
    if (message.externalIds) {
      Object.entries(message.externalIds).forEach(([k, v]) => {
        obj.externalIds[k] = v;
      });
    }
    message.countryOfBirth !== undefined &&
      (obj.countryOfBirth = message.countryOfBirth);
    if (message.aliases) {
      obj.aliases = message.aliases.map((e) =>
        e ? Name.toJSON(e) : undefined,
      );
    } else {
      obj.aliases = [];
    }
    message.legalGender !== undefined &&
      (obj.legalGender = genderToJSON(message.legalGender));
    return obj;
  },

  create(base?: DeepPartial<RecordData>): RecordData {
    return RecordData.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RecordData>): RecordData {
    const message = createBaseRecordData();
    message.legalName =
      object.legalName !== undefined && object.legalName !== null
        ? Name.fromPartial(object.legalName)
        : undefined;
    message.preferredName =
      object.preferredName !== undefined && object.preferredName !== null
        ? Name.fromPartial(object.preferredName)
        : undefined;
    message.deprecatedGender = object.deprecatedGender ?? '';
    message.previousNames =
      object.previousNames?.map((e) => Name.fromPartial(e)) || [];
    message.dateOfBirth =
      object.dateOfBirth !== undefined && object.dateOfBirth !== null
        ? Timestamp.fromPartial(object.dateOfBirth)
        : undefined;
    message.nationality = object.nationality?.map((e) => e) || [];
    message.addresses =
      object.addresses?.map((e) => Address.fromPartial(e)) || [];
    message.externalIds = Object.entries(object.externalIds ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.countryOfBirth = object.countryOfBirth ?? '';
    message.aliases = object.aliases?.map((e) => Name.fromPartial(e)) || [];
    message.legalGender = object.legalGender ?? 0;
    return message;
  },
};

function createBaseRecordData_ExternalIdsEntry(): RecordData_ExternalIdsEntry {
  return { key: '', value: '' };
}

export const RecordData_ExternalIdsEntry = {
  encode(
    message: RecordData_ExternalIdsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RecordData_ExternalIdsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordData_ExternalIdsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordData_ExternalIdsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: RecordData_ExternalIdsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<RecordData_ExternalIdsEntry>,
  ): RecordData_ExternalIdsEntry {
    return RecordData_ExternalIdsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<RecordData_ExternalIdsEntry>,
  ): RecordData_ExternalIdsEntry {
    const message = createBaseRecordData_ExternalIdsEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseRecord(): Record {
  return { id: '', userId: '', status: '', recordData: undefined };
}

export const Record = {
  encode(
    message: Record,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.userId !== '') {
      writer.uint32(18).string(message.userId);
    }
    if (message.status !== '') {
      writer.uint32(26).string(message.status);
    }
    if (message.recordData !== undefined) {
      RecordData.encode(message.recordData, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Record {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.status = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.recordData = RecordData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Record {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      userId: isSet(object.userId) ? String(object.userId) : '',
      status: isSet(object.status) ? String(object.status) : '',
      recordData: isSet(object.recordData)
        ? RecordData.fromJSON(object.recordData)
        : undefined,
    };
  },

  toJSON(message: Record): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userId !== undefined && (obj.userId = message.userId);
    message.status !== undefined && (obj.status = message.status);
    message.recordData !== undefined &&
      (obj.recordData = message.recordData
        ? RecordData.toJSON(message.recordData)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<Record>): Record {
    return Record.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Record>): Record {
    const message = createBaseRecord();
    message.id = object.id ?? '';
    message.userId = object.userId ?? '';
    message.status = object.status ?? '';
    message.recordData =
      object.recordData !== undefined && object.recordData !== null
        ? RecordData.fromPartial(object.recordData)
        : undefined;
    return message;
  },
};

function createBaseAssociate(): Associate {
  return {
    type: 0,
    isApplicant: false,
    legalName: undefined,
    dateOfBirth: undefined,
    ownership: 0,
    address: undefined,
    email: '',
    phoneNumber: '',
    userId: '',
  };
}

export const Associate = {
  encode(
    message: Associate,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.isApplicant === true) {
      writer.uint32(16).bool(message.isApplicant);
    }
    if (message.legalName !== undefined) {
      Name.encode(message.legalName, writer.uint32(26).fork()).ldelim();
    }
    if (message.dateOfBirth !== undefined) {
      Timestamp.encode(message.dateOfBirth, writer.uint32(34).fork()).ldelim();
    }
    if (message.ownership !== 0) {
      writer.uint32(40).int64(message.ownership);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(50).fork()).ldelim();
    }
    if (message.email !== '') {
      writer.uint32(58).string(message.email);
    }
    if (message.phoneNumber !== '') {
      writer.uint32(66).string(message.phoneNumber);
    }
    if (message.userId !== '') {
      writer.uint32(74).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Associate {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssociate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isApplicant = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.legalName = Name.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dateOfBirth = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.ownership = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.email = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.userId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Associate {
    return {
      type: isSet(object.type) ? associateTypeFromJSON(object.type) : 0,
      isApplicant: isSet(object.isApplicant)
        ? Boolean(object.isApplicant)
        : false,
      legalName: isSet(object.legalName)
        ? Name.fromJSON(object.legalName)
        : undefined,
      dateOfBirth: isSet(object.dateOfBirth)
        ? fromJsonTimestamp(object.dateOfBirth)
        : undefined,
      ownership: isSet(object.ownership) ? Number(object.ownership) : 0,
      address: isSet(object.address)
        ? Address.fromJSON(object.address)
        : undefined,
      email: isSet(object.email) ? String(object.email) : '',
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : '',
      userId: isSet(object.userId) ? String(object.userId) : '',
    };
  },

  toJSON(message: Associate): unknown {
    const obj: any = {};
    message.type !== undefined &&
      (obj.type = associateTypeToJSON(message.type));
    message.isApplicant !== undefined &&
      (obj.isApplicant = message.isApplicant);
    message.legalName !== undefined &&
      (obj.legalName = message.legalName
        ? Name.toJSON(message.legalName)
        : undefined);
    message.dateOfBirth !== undefined &&
      (obj.dateOfBirth = fromTimestamp(message.dateOfBirth).toISOString());
    message.ownership !== undefined &&
      (obj.ownership = Math.round(message.ownership));
    message.address !== undefined &&
      (obj.address = message.address
        ? Address.toJSON(message.address)
        : undefined);
    message.email !== undefined && (obj.email = message.email);
    message.phoneNumber !== undefined &&
      (obj.phoneNumber = message.phoneNumber);
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  create(base?: DeepPartial<Associate>): Associate {
    return Associate.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Associate>): Associate {
    const message = createBaseAssociate();
    message.type = object.type ?? 0;
    message.isApplicant = object.isApplicant ?? false;
    message.legalName =
      object.legalName !== undefined && object.legalName !== null
        ? Name.fromPartial(object.legalName)
        : undefined;
    message.dateOfBirth =
      object.dateOfBirth !== undefined && object.dateOfBirth !== null
        ? Timestamp.fromPartial(object.dateOfBirth)
        : undefined;
    message.ownership = object.ownership ?? 0;
    message.address =
      object.address !== undefined && object.address !== null
        ? Address.fromPartial(object.address)
        : undefined;
    message.email = object.email ?? '';
    message.phoneNumber = object.phoneNumber ?? '';
    message.userId = object.userId ?? '';
    return message;
  },
};

function createBaseBusinessRecordData(): BusinessRecordData {
  return {
    name: '',
    industryCode: '',
    registrationNumber: '',
    companyType: 0,
    registeredAddress: undefined,
    tradingAddress: undefined,
    associates: [],
    email: '',
    phoneNumber: '',
    industryType: 0,
  };
}

export const BusinessRecordData = {
  encode(
    message: BusinessRecordData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    if (message.industryCode !== '') {
      writer.uint32(18).string(message.industryCode);
    }
    if (message.registrationNumber !== '') {
      writer.uint32(26).string(message.registrationNumber);
    }
    if (message.companyType !== 0) {
      writer.uint32(32).int32(message.companyType);
    }
    if (message.registeredAddress !== undefined) {
      Address.encode(
        message.registeredAddress,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.tradingAddress !== undefined) {
      Address.encode(message.tradingAddress, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.associates) {
      Associate.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.email !== '') {
      writer.uint32(66).string(message.email);
    }
    if (message.phoneNumber !== '') {
      writer.uint32(74).string(message.phoneNumber);
    }
    if (message.industryType !== 0) {
      writer.uint32(80).int32(message.industryType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BusinessRecordData {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBusinessRecordData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.industryCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.registrationNumber = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.companyType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.registeredAddress = Address.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.tradingAddress = Address.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.associates.push(Associate.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.email = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.industryType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BusinessRecordData {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      industryCode: isSet(object.industryCode)
        ? String(object.industryCode)
        : '',
      registrationNumber: isSet(object.registrationNumber)
        ? String(object.registrationNumber)
        : '',
      companyType: isSet(object.companyType)
        ? companyTypeFromJSON(object.companyType)
        : 0,
      registeredAddress: isSet(object.registeredAddress)
        ? Address.fromJSON(object.registeredAddress)
        : undefined,
      tradingAddress: isSet(object.tradingAddress)
        ? Address.fromJSON(object.tradingAddress)
        : undefined,
      associates: Array.isArray(object?.associates)
        ? object.associates.map((e: any) => Associate.fromJSON(e))
        : [],
      email: isSet(object.email) ? String(object.email) : '',
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : '',
      industryType: isSet(object.industryType)
        ? industryTypeFromJSON(object.industryType)
        : 0,
    };
  },

  toJSON(message: BusinessRecordData): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.industryCode !== undefined &&
      (obj.industryCode = message.industryCode);
    message.registrationNumber !== undefined &&
      (obj.registrationNumber = message.registrationNumber);
    message.companyType !== undefined &&
      (obj.companyType = companyTypeToJSON(message.companyType));
    message.registeredAddress !== undefined &&
      (obj.registeredAddress = message.registeredAddress
        ? Address.toJSON(message.registeredAddress)
        : undefined);
    message.tradingAddress !== undefined &&
      (obj.tradingAddress = message.tradingAddress
        ? Address.toJSON(message.tradingAddress)
        : undefined);
    if (message.associates) {
      obj.associates = message.associates.map((e) =>
        e ? Associate.toJSON(e) : undefined,
      );
    } else {
      obj.associates = [];
    }
    message.email !== undefined && (obj.email = message.email);
    message.phoneNumber !== undefined &&
      (obj.phoneNumber = message.phoneNumber);
    message.industryType !== undefined &&
      (obj.industryType = industryTypeToJSON(message.industryType));
    return obj;
  },

  create(base?: DeepPartial<BusinessRecordData>): BusinessRecordData {
    return BusinessRecordData.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<BusinessRecordData>): BusinessRecordData {
    const message = createBaseBusinessRecordData();
    message.name = object.name ?? '';
    message.industryCode = object.industryCode ?? '';
    message.registrationNumber = object.registrationNumber ?? '';
    message.companyType = object.companyType ?? 0;
    message.registeredAddress =
      object.registeredAddress !== undefined &&
      object.registeredAddress !== null
        ? Address.fromPartial(object.registeredAddress)
        : undefined;
    message.tradingAddress =
      object.tradingAddress !== undefined && object.tradingAddress !== null
        ? Address.fromPartial(object.tradingAddress)
        : undefined;
    message.associates =
      object.associates?.map((e) => Associate.fromPartial(e)) || [];
    message.email = object.email ?? '';
    message.phoneNumber = object.phoneNumber ?? '';
    message.industryType = object.industryType ?? 0;
    return message;
  },
};

function createBaseBusinessRecord(): BusinessRecord {
  return {
    id: '',
    accountId: '',
    status: 0,
    approvalStatus: 0,
    data: undefined,
  };
}

export const BusinessRecord = {
  encode(
    message: BusinessRecord,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.accountId !== '') {
      writer.uint32(18).string(message.accountId);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.approvalStatus !== 0) {
      writer.uint32(32).int32(message.approvalStatus);
    }
    if (message.data !== undefined) {
      BusinessRecordData.encode(
        message.data,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BusinessRecord {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBusinessRecord();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.approvalStatus = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.data = BusinessRecordData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BusinessRecord {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      accountId: isSet(object.accountId) ? String(object.accountId) : '',
      status: isSet(object.status) ? statusFromJSON(object.status) : 0,
      approvalStatus: isSet(object.approvalStatus)
        ? approvalStatusFromJSON(object.approvalStatus)
        : 0,
      data: isSet(object.data)
        ? BusinessRecordData.fromJSON(object.data)
        : undefined,
    };
  },

  toJSON(message: BusinessRecord): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.accountId !== undefined && (obj.accountId = message.accountId);
    message.status !== undefined && (obj.status = statusToJSON(message.status));
    message.approvalStatus !== undefined &&
      (obj.approvalStatus = approvalStatusToJSON(message.approvalStatus));
    message.data !== undefined &&
      (obj.data = message.data
        ? BusinessRecordData.toJSON(message.data)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<BusinessRecord>): BusinessRecord {
    return BusinessRecord.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<BusinessRecord>): BusinessRecord {
    const message = createBaseBusinessRecord();
    message.id = object.id ?? '';
    message.accountId = object.accountId ?? '';
    message.status = object.status ?? 0;
    message.approvalStatus = object.approvalStatus ?? 0;
    message.data =
      object.data !== undefined && object.data !== null
        ? BusinessRecordData.fromPartial(object.data)
        : undefined;
    return message;
  },
};

function createBaseRecordHistoryEntry(): RecordHistoryEntry {
  return {
    id: 0,
    recordId: '',
    staffId: '',
    updateReason: '',
    status: '',
    recordData: undefined,
    createdAt: undefined,
  };
}

export const RecordHistoryEntry = {
  encode(
    message: RecordHistoryEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.recordId !== '') {
      writer.uint32(18).string(message.recordId);
    }
    if (message.staffId !== '') {
      writer.uint32(26).string(message.staffId);
    }
    if (message.updateReason !== '') {
      writer.uint32(34).string(message.updateReason);
    }
    if (message.status !== '') {
      writer.uint32(42).string(message.status);
    }
    if (message.recordData !== undefined) {
      RecordData.encode(message.recordData, writer.uint32(50).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RecordHistoryEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecordHistoryEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.recordId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updateReason = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.status = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.recordData = RecordData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecordHistoryEntry {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      recordId: isSet(object.recordId) ? String(object.recordId) : '',
      staffId: isSet(object.staffId) ? String(object.staffId) : '',
      updateReason: isSet(object.updateReason)
        ? String(object.updateReason)
        : '',
      status: isSet(object.status) ? String(object.status) : '',
      recordData: isSet(object.recordData)
        ? RecordData.fromJSON(object.recordData)
        : undefined,
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
    };
  },

  toJSON(message: RecordHistoryEntry): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.recordId !== undefined && (obj.recordId = message.recordId);
    message.staffId !== undefined && (obj.staffId = message.staffId);
    message.updateReason !== undefined &&
      (obj.updateReason = message.updateReason);
    message.status !== undefined && (obj.status = message.status);
    message.recordData !== undefined &&
      (obj.recordData = message.recordData
        ? RecordData.toJSON(message.recordData)
        : undefined);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<RecordHistoryEntry>): RecordHistoryEntry {
    return RecordHistoryEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RecordHistoryEntry>): RecordHistoryEntry {
    const message = createBaseRecordHistoryEntry();
    message.id = object.id ?? 0;
    message.recordId = object.recordId ?? '';
    message.staffId = object.staffId ?? '';
    message.updateReason = object.updateReason ?? '';
    message.status = object.status ?? '';
    message.recordData =
      object.recordData !== undefined && object.recordData !== null
        ? RecordData.fromPartial(object.recordData)
        : undefined;
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    return message;
  },
};

function createBaseCheck(): Check {
  return {
    id: '',
    userId: '',
    providerName: '',
    providerCheckId: '',
    providerData: {},
    overallResult: '',
    result: {},
    reviewResult: {},
    reviewerId: '',
    reviewedAt: undefined,
    createdAt: undefined,
    expiresAt: undefined,
    lastUpdatedAt: undefined,
    documentRequirements: [],
  };
}

export const Check = {
  encode(message: Check, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.userId !== '') {
      writer.uint32(18).string(message.userId);
    }
    if (message.providerName !== '') {
      writer.uint32(26).string(message.providerName);
    }
    if (message.providerCheckId !== '') {
      writer.uint32(34).string(message.providerCheckId);
    }
    Object.entries(message.providerData).forEach(([key, value]) => {
      Check_ProviderDataEntry.encode(
        { key: key as any, value },
        writer.uint32(42).fork(),
      ).ldelim();
    });
    if (message.overallResult !== '') {
      writer.uint32(50).string(message.overallResult);
    }
    Object.entries(message.result).forEach(([key, value]) => {
      Check_ResultEntry.encode(
        { key: key as any, value },
        writer.uint32(58).fork(),
      ).ldelim();
    });
    Object.entries(message.reviewResult).forEach(([key, value]) => {
      Check_ReviewResultEntry.encode(
        { key: key as any, value },
        writer.uint32(66).fork(),
      ).ldelim();
    });
    if (message.reviewerId !== '') {
      writer.uint32(74).string(message.reviewerId);
    }
    if (message.reviewedAt !== undefined) {
      Timestamp.encode(message.reviewedAt, writer.uint32(82).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(90).fork()).ldelim();
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(98).fork()).ldelim();
    }
    if (message.lastUpdatedAt !== undefined) {
      Timestamp.encode(
        message.lastUpdatedAt,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    for (const v of message.documentRequirements) {
      DocumentRequest.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Check {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.providerCheckId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = Check_ProviderDataEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry5.value !== undefined) {
            message.providerData[entry5.key] = entry5.value;
          }
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.overallResult = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = Check_ResultEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.result[entry7.key] = entry7.value;
          }
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = Check_ReviewResultEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry8.value !== undefined) {
            message.reviewResult[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.reviewerId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.reviewedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.lastUpdatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.documentRequirements.push(
            DocumentRequest.decode(reader, reader.uint32()),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Check {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      userId: isSet(object.userId) ? String(object.userId) : '',
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      providerCheckId: isSet(object.providerCheckId)
        ? String(object.providerCheckId)
        : '',
      providerData: isObject(object.providerData)
        ? Object.entries(object.providerData).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      overallResult: isSet(object.overallResult)
        ? String(object.overallResult)
        : '',
      result: isObject(object.result)
        ? Object.entries(object.result).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      reviewResult: isObject(object.reviewResult)
        ? Object.entries(object.reviewResult).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      reviewerId: isSet(object.reviewerId) ? String(object.reviewerId) : '',
      reviewedAt: isSet(object.reviewedAt)
        ? fromJsonTimestamp(object.reviewedAt)
        : undefined,
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      expiresAt: isSet(object.expiresAt)
        ? fromJsonTimestamp(object.expiresAt)
        : undefined,
      lastUpdatedAt: isSet(object.lastUpdatedAt)
        ? fromJsonTimestamp(object.lastUpdatedAt)
        : undefined,
      documentRequirements: Array.isArray(object?.documentRequirements)
        ? object.documentRequirements.map((e: any) =>
            DocumentRequest.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: Check): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userId !== undefined && (obj.userId = message.userId);
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.providerCheckId !== undefined &&
      (obj.providerCheckId = message.providerCheckId);
    obj.providerData = {};
    if (message.providerData) {
      Object.entries(message.providerData).forEach(([k, v]) => {
        obj.providerData[k] = v;
      });
    }
    message.overallResult !== undefined &&
      (obj.overallResult = message.overallResult);
    obj.result = {};
    if (message.result) {
      Object.entries(message.result).forEach(([k, v]) => {
        obj.result[k] = v;
      });
    }
    obj.reviewResult = {};
    if (message.reviewResult) {
      Object.entries(message.reviewResult).forEach(([k, v]) => {
        obj.reviewResult[k] = v;
      });
    }
    message.reviewerId !== undefined && (obj.reviewerId = message.reviewerId);
    message.reviewedAt !== undefined &&
      (obj.reviewedAt = fromTimestamp(message.reviewedAt).toISOString());
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.expiresAt !== undefined &&
      (obj.expiresAt = fromTimestamp(message.expiresAt).toISOString());
    message.lastUpdatedAt !== undefined &&
      (obj.lastUpdatedAt = fromTimestamp(message.lastUpdatedAt).toISOString());
    if (message.documentRequirements) {
      obj.documentRequirements = message.documentRequirements.map((e) =>
        e ? DocumentRequest.toJSON(e) : undefined,
      );
    } else {
      obj.documentRequirements = [];
    }
    return obj;
  },

  create(base?: DeepPartial<Check>): Check {
    return Check.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Check>): Check {
    const message = createBaseCheck();
    message.id = object.id ?? '';
    message.userId = object.userId ?? '';
    message.providerName = object.providerName ?? '';
    message.providerCheckId = object.providerCheckId ?? '';
    message.providerData = Object.entries(object.providerData ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.overallResult = object.overallResult ?? '';
    message.result = Object.entries(object.result ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.reviewResult = Object.entries(object.reviewResult ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.reviewerId = object.reviewerId ?? '';
    message.reviewedAt =
      object.reviewedAt !== undefined && object.reviewedAt !== null
        ? Timestamp.fromPartial(object.reviewedAt)
        : undefined;
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.expiresAt =
      object.expiresAt !== undefined && object.expiresAt !== null
        ? Timestamp.fromPartial(object.expiresAt)
        : undefined;
    message.lastUpdatedAt =
      object.lastUpdatedAt !== undefined && object.lastUpdatedAt !== null
        ? Timestamp.fromPartial(object.lastUpdatedAt)
        : undefined;
    message.documentRequirements =
      object.documentRequirements?.map((e) => DocumentRequest.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseCheck_ProviderDataEntry(): Check_ProviderDataEntry {
  return { key: '', value: '' };
}

export const Check_ProviderDataEntry = {
  encode(
    message: Check_ProviderDataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Check_ProviderDataEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheck_ProviderDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Check_ProviderDataEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: Check_ProviderDataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(base?: DeepPartial<Check_ProviderDataEntry>): Check_ProviderDataEntry {
    return Check_ProviderDataEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<Check_ProviderDataEntry>,
  ): Check_ProviderDataEntry {
    const message = createBaseCheck_ProviderDataEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseCheck_ResultEntry(): Check_ResultEntry {
  return { key: '', value: '' };
}

export const Check_ResultEntry = {
  encode(
    message: Check_ResultEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Check_ResultEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheck_ResultEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Check_ResultEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: Check_ResultEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(base?: DeepPartial<Check_ResultEntry>): Check_ResultEntry {
    return Check_ResultEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Check_ResultEntry>): Check_ResultEntry {
    const message = createBaseCheck_ResultEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseCheck_ReviewResultEntry(): Check_ReviewResultEntry {
  return { key: '', value: '' };
}

export const Check_ReviewResultEntry = {
  encode(
    message: Check_ReviewResultEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Check_ReviewResultEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheck_ReviewResultEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Check_ReviewResultEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: Check_ReviewResultEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(base?: DeepPartial<Check_ReviewResultEntry>): Check_ReviewResultEntry {
    return Check_ReviewResultEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<Check_ReviewResultEntry>,
  ): Check_ReviewResultEntry {
    const message = createBaseCheck_ReviewResultEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseRuleEvaluation(): RuleEvaluation {
  return {
    rule: undefined,
    match: false,
    continue: false,
    missingCheckTypes: {},
  };
}

export const RuleEvaluation = {
  encode(
    message: RuleEvaluation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.rule !== undefined) {
      Rule.encode(message.rule, writer.uint32(10).fork()).ldelim();
    }
    if (message.match === true) {
      writer.uint32(16).bool(message.match);
    }
    if (message.continue === true) {
      writer.uint32(24).bool(message.continue);
    }
    Object.entries(message.missingCheckTypes).forEach(([key, value]) => {
      RuleEvaluation_MissingCheckTypesEntry.encode(
        { key: key as any, value },
        writer.uint32(34).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RuleEvaluation {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleEvaluation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rule = Rule.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.match = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.continue = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = RuleEvaluation_MissingCheckTypesEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry4.value !== undefined) {
            message.missingCheckTypes[entry4.key] = entry4.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuleEvaluation {
    return {
      rule: isSet(object.rule) ? Rule.fromJSON(object.rule) : undefined,
      match: isSet(object.match) ? Boolean(object.match) : false,
      continue: isSet(object.continue) ? Boolean(object.continue) : false,
      missingCheckTypes: isObject(object.missingCheckTypes)
        ? Object.entries(object.missingCheckTypes).reduce<{
            [key: string]: number;
          }>((acc, [key, value]) => {
            acc[key] = Number(value);
            return acc;
          }, {})
        : {},
    };
  },

  toJSON(message: RuleEvaluation): unknown {
    const obj: any = {};
    message.rule !== undefined &&
      (obj.rule = message.rule ? Rule.toJSON(message.rule) : undefined);
    message.match !== undefined && (obj.match = message.match);
    message.continue !== undefined && (obj.continue = message.continue);
    obj.missingCheckTypes = {};
    if (message.missingCheckTypes) {
      Object.entries(message.missingCheckTypes).forEach(([k, v]) => {
        obj.missingCheckTypes[k] = Math.round(v);
      });
    }
    return obj;
  },

  create(base?: DeepPartial<RuleEvaluation>): RuleEvaluation {
    return RuleEvaluation.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RuleEvaluation>): RuleEvaluation {
    const message = createBaseRuleEvaluation();
    message.rule =
      object.rule !== undefined && object.rule !== null
        ? Rule.fromPartial(object.rule)
        : undefined;
    message.match = object.match ?? false;
    message.continue = object.continue ?? false;
    message.missingCheckTypes = Object.entries(
      object.missingCheckTypes ?? {},
    ).reduce<{ [key: string]: number }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Number(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseRuleEvaluation_MissingCheckTypesEntry(): RuleEvaluation_MissingCheckTypesEntry {
  return { key: '', value: 0 };
}

export const RuleEvaluation_MissingCheckTypesEntry = {
  encode(
    message: RuleEvaluation_MissingCheckTypesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RuleEvaluation_MissingCheckTypesEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuleEvaluation_MissingCheckTypesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuleEvaluation_MissingCheckTypesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? Number(object.value) : 0,
    };
  },

  toJSON(message: RuleEvaluation_MissingCheckTypesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = Math.round(message.value));
    return obj;
  },

  create(
    base?: DeepPartial<RuleEvaluation_MissingCheckTypesEntry>,
  ): RuleEvaluation_MissingCheckTypesEntry {
    return RuleEvaluation_MissingCheckTypesEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<RuleEvaluation_MissingCheckTypesEntry>,
  ): RuleEvaluation_MissingCheckTypesEntry {
    const message = createBaseRuleEvaluation_MissingCheckTypesEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseRule(): Rule {
  return { name: '', status: '', requirement: new Uint8Array(0) };
}

export const Rule = {
  encode(message: Rule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== '') {
      writer.uint32(10).string(message.name);
    }
    if (message.status !== '') {
      writer.uint32(18).string(message.status);
    }
    if (message.requirement.length !== 0) {
      writer.uint32(34).bytes(message.requirement);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Rule {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.requirement = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Rule {
    return {
      name: isSet(object.name) ? String(object.name) : '',
      status: isSet(object.status) ? String(object.status) : '',
      requirement: isSet(object.requirement)
        ? bytesFromBase64(object.requirement)
        : new Uint8Array(0),
    };
  },

  toJSON(message: Rule): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.status !== undefined && (obj.status = message.status);
    message.requirement !== undefined &&
      (obj.requirement = base64FromBytes(
        message.requirement !== undefined
          ? message.requirement
          : new Uint8Array(0),
      ));
    return obj;
  },

  create(base?: DeepPartial<Rule>): Rule {
    return Rule.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Rule>): Rule {
    const message = createBaseRule();
    message.name = object.name ?? '';
    message.status = object.status ?? '';
    message.requirement = object.requirement ?? new Uint8Array(0);
    return message;
  },
};

function createBaseEvaluateRulesResult(): EvaluateRulesResult {
  return { ruleEvaluations: [], passed: {}, rejected: {} };
}

export const EvaluateRulesResult = {
  encode(
    message: EvaluateRulesResult,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.ruleEvaluations) {
      RuleEvaluation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    Object.entries(message.passed).forEach(([key, value]) => {
      EvaluateRulesResult_PassedEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    Object.entries(message.rejected).forEach(([key, value]) => {
      EvaluateRulesResult_RejectedEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvaluateRulesResult {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvaluateRulesResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ruleEvaluations.push(
            RuleEvaluation.decode(reader, reader.uint32()),
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = EvaluateRulesResult_PassedEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry2.value !== undefined) {
            message.passed[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = EvaluateRulesResult_RejectedEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry3.value !== undefined) {
            message.rejected[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EvaluateRulesResult {
    return {
      ruleEvaluations: Array.isArray(object?.ruleEvaluations)
        ? object.ruleEvaluations.map((e: any) => RuleEvaluation.fromJSON(e))
        : [],
      passed: isObject(object.passed)
        ? Object.entries(object.passed).reduce<{ [key: string]: number }>(
            (acc, [key, value]) => {
              acc[key] = Number(value);
              return acc;
            },
            {},
          )
        : {},
      rejected: isObject(object.rejected)
        ? Object.entries(object.rejected).reduce<{ [key: string]: number }>(
            (acc, [key, value]) => {
              acc[key] = Number(value);
              return acc;
            },
            {},
          )
        : {},
    };
  },

  toJSON(message: EvaluateRulesResult): unknown {
    const obj: any = {};
    if (message.ruleEvaluations) {
      obj.ruleEvaluations = message.ruleEvaluations.map((e) =>
        e ? RuleEvaluation.toJSON(e) : undefined,
      );
    } else {
      obj.ruleEvaluations = [];
    }
    obj.passed = {};
    if (message.passed) {
      Object.entries(message.passed).forEach(([k, v]) => {
        obj.passed[k] = Math.round(v);
      });
    }
    obj.rejected = {};
    if (message.rejected) {
      Object.entries(message.rejected).forEach(([k, v]) => {
        obj.rejected[k] = Math.round(v);
      });
    }
    return obj;
  },

  create(base?: DeepPartial<EvaluateRulesResult>): EvaluateRulesResult {
    return EvaluateRulesResult.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<EvaluateRulesResult>): EvaluateRulesResult {
    const message = createBaseEvaluateRulesResult();
    message.ruleEvaluations =
      object.ruleEvaluations?.map((e) => RuleEvaluation.fromPartial(e)) || [];
    message.passed = Object.entries(object.passed ?? {}).reduce<{
      [key: string]: number;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Number(value);
      }
      return acc;
    }, {});
    message.rejected = Object.entries(object.rejected ?? {}).reduce<{
      [key: string]: number;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Number(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseEvaluateRulesResult_PassedEntry(): EvaluateRulesResult_PassedEntry {
  return { key: '', value: 0 };
}

export const EvaluateRulesResult_PassedEntry = {
  encode(
    message: EvaluateRulesResult_PassedEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): EvaluateRulesResult_PassedEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvaluateRulesResult_PassedEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EvaluateRulesResult_PassedEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? Number(object.value) : 0,
    };
  },

  toJSON(message: EvaluateRulesResult_PassedEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = Math.round(message.value));
    return obj;
  },

  create(
    base?: DeepPartial<EvaluateRulesResult_PassedEntry>,
  ): EvaluateRulesResult_PassedEntry {
    return EvaluateRulesResult_PassedEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<EvaluateRulesResult_PassedEntry>,
  ): EvaluateRulesResult_PassedEntry {
    const message = createBaseEvaluateRulesResult_PassedEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseEvaluateRulesResult_RejectedEntry(): EvaluateRulesResult_RejectedEntry {
  return { key: '', value: 0 };
}

export const EvaluateRulesResult_RejectedEntry = {
  encode(
    message: EvaluateRulesResult_RejectedEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int32(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): EvaluateRulesResult_RejectedEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvaluateRulesResult_RejectedEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EvaluateRulesResult_RejectedEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? Number(object.value) : 0,
    };
  },

  toJSON(message: EvaluateRulesResult_RejectedEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = Math.round(message.value));
    return obj;
  },

  create(
    base?: DeepPartial<EvaluateRulesResult_RejectedEntry>,
  ): EvaluateRulesResult_RejectedEntry {
    return EvaluateRulesResult_RejectedEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<EvaluateRulesResult_RejectedEntry>,
  ): EvaluateRulesResult_RejectedEntry {
    const message = createBaseEvaluateRulesResult_RejectedEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseRunCheck(): RunCheck {
  return { id: '', providerCheckId: '', providerData: {} };
}

export const RunCheck = {
  encode(
    message: RunCheck,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.providerCheckId !== '') {
      writer.uint32(18).string(message.providerCheckId);
    }
    Object.entries(message.providerData).forEach(([key, value]) => {
      RunCheck_ProviderDataEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RunCheck {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRunCheck();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.providerCheckId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = RunCheck_ProviderDataEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry3.value !== undefined) {
            message.providerData[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RunCheck {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      providerCheckId: isSet(object.providerCheckId)
        ? String(object.providerCheckId)
        : '',
      providerData: isObject(object.providerData)
        ? Object.entries(object.providerData).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
    };
  },

  toJSON(message: RunCheck): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.providerCheckId !== undefined &&
      (obj.providerCheckId = message.providerCheckId);
    obj.providerData = {};
    if (message.providerData) {
      Object.entries(message.providerData).forEach(([k, v]) => {
        obj.providerData[k] = v;
      });
    }
    return obj;
  },

  create(base?: DeepPartial<RunCheck>): RunCheck {
    return RunCheck.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RunCheck>): RunCheck {
    const message = createBaseRunCheck();
    message.id = object.id ?? '';
    message.providerCheckId = object.providerCheckId ?? '';
    message.providerData = Object.entries(object.providerData ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseRunCheck_ProviderDataEntry(): RunCheck_ProviderDataEntry {
  return { key: '', value: '' };
}

export const RunCheck_ProviderDataEntry = {
  encode(
    message: RunCheck_ProviderDataEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RunCheck_ProviderDataEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRunCheck_ProviderDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RunCheck_ProviderDataEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: RunCheck_ProviderDataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(
    base?: DeepPartial<RunCheck_ProviderDataEntry>,
  ): RunCheck_ProviderDataEntry {
    return RunCheck_ProviderDataEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<RunCheck_ProviderDataEntry>,
  ): RunCheck_ProviderDataEntry {
    const message = createBaseRunCheck_ProviderDataEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseDataRequest(): DataRequest {
  return { deprecatedType: '', type: 0 };
}

export const DataRequest = {
  encode(
    message: DataRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.deprecatedType !== '') {
      writer.uint32(10).string(message.deprecatedType);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deprecatedType = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataRequest {
    return {
      deprecatedType: isSet(object.deprecatedType)
        ? String(object.deprecatedType)
        : '',
      type: isSet(object.type) ? dataTypeFromJSON(object.type) : 0,
    };
  },

  toJSON(message: DataRequest): unknown {
    const obj: any = {};
    message.deprecatedType !== undefined &&
      (obj.deprecatedType = message.deprecatedType);
    message.type !== undefined && (obj.type = dataTypeToJSON(message.type));
    return obj;
  },

  create(base?: DeepPartial<DataRequest>): DataRequest {
    return DataRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<DataRequest>): DataRequest {
    const message = createBaseDataRequest();
    message.deprecatedType = object.deprecatedType ?? '';
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseLimitsState(): LimitsState {
  return { limits: undefined, interval: '', resetAt: '', limitType: 0 };
}

export const LimitsState = {
  encode(
    message: LimitsState,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.limits !== undefined) {
      UserLimits.encode(message.limits, writer.uint32(10).fork()).ldelim();
    }
    if (message.interval !== '') {
      writer.uint32(18).string(message.interval);
    }
    if (message.resetAt !== '') {
      writer.uint32(26).string(message.resetAt);
    }
    if (message.limitType !== 0) {
      writer.uint32(32).int32(message.limitType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LimitsState {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLimitsState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.limits = UserLimits.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.interval = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.resetAt = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.limitType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LimitsState {
    return {
      limits: isSet(object.limits)
        ? UserLimits.fromJSON(object.limits)
        : undefined,
      interval: isSet(object.interval) ? String(object.interval) : '',
      resetAt: isSet(object.resetAt) ? String(object.resetAt) : '',
      limitType: isSet(object.limitType)
        ? limitTypeFromJSON(object.limitType)
        : 0,
    };
  },

  toJSON(message: LimitsState): unknown {
    const obj: any = {};
    message.limits !== undefined &&
      (obj.limits = message.limits
        ? UserLimits.toJSON(message.limits)
        : undefined);
    message.interval !== undefined && (obj.interval = message.interval);
    message.resetAt !== undefined && (obj.resetAt = message.resetAt);
    message.limitType !== undefined &&
      (obj.limitType = limitTypeToJSON(message.limitType));
    return obj;
  },

  create(base?: DeepPartial<LimitsState>): LimitsState {
    return LimitsState.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<LimitsState>): LimitsState {
    const message = createBaseLimitsState();
    message.limits =
      object.limits !== undefined && object.limits !== null
        ? UserLimits.fromPartial(object.limits)
        : undefined;
    message.interval = object.interval ?? '';
    message.resetAt = object.resetAt ?? '';
    message.limitType = object.limitType ?? 0;
    return message;
  },
};

function createBaseAction(): Action {
  return {
    deprecatedType: '',
    runCheck: undefined,
    dataRequest: undefined,
    details: {},
    documentRequests: [],
    party: undefined,
    waitReason: 0,
    waitDuration: '',
    reason: 0,
    limitsState: undefined,
    type: 0,
    subActions: [],
  };
}

export const Action = {
  encode(
    message: Action,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.deprecatedType !== '') {
      writer.uint32(10).string(message.deprecatedType);
    }
    if (message.runCheck !== undefined) {
      RunCheck.encode(message.runCheck, writer.uint32(18).fork()).ldelim();
    }
    if (message.dataRequest !== undefined) {
      DataRequest.encode(
        message.dataRequest,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    Object.entries(message.details).forEach(([key, value]) => {
      Action_DetailsEntry.encode(
        { key: key as any, value },
        writer.uint32(34).fork(),
      ).ldelim();
    });
    for (const v of message.documentRequests) {
      DocumentRequest.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.party !== undefined) {
      Party.encode(message.party, writer.uint32(50).fork()).ldelim();
    }
    if (message.waitReason !== 0) {
      writer.uint32(56).int32(message.waitReason);
    }
    if (message.waitDuration !== '') {
      writer.uint32(66).string(message.waitDuration);
    }
    if (message.reason !== 0) {
      writer.uint32(72).int32(message.reason);
    }
    if (message.limitsState !== undefined) {
      LimitsState.encode(
        message.limitsState,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(88).int32(message.type);
    }
    for (const v of message.subActions) {
      Action.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Action {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.deprecatedType = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.runCheck = RunCheck.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dataRequest = DataRequest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = Action_DetailsEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.details[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.documentRequests.push(
            DocumentRequest.decode(reader, reader.uint32()),
          );
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.party = Party.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.waitReason = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.waitDuration = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.reason = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.limitsState = LimitsState.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.subActions.push(Action.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Action {
    return {
      deprecatedType: isSet(object.deprecatedType)
        ? String(object.deprecatedType)
        : '',
      runCheck: isSet(object.runCheck)
        ? RunCheck.fromJSON(object.runCheck)
        : undefined,
      dataRequest: isSet(object.dataRequest)
        ? DataRequest.fromJSON(object.dataRequest)
        : undefined,
      details: isObject(object.details)
        ? Object.entries(object.details).reduce<{ [key: string]: string }>(
            (acc, [key, value]) => {
              acc[key] = String(value);
              return acc;
            },
            {},
          )
        : {},
      documentRequests: Array.isArray(object?.documentRequests)
        ? object.documentRequests.map((e: any) => DocumentRequest.fromJSON(e))
        : [],
      party: isSet(object.party) ? Party.fromJSON(object.party) : undefined,
      waitReason: isSet(object.waitReason)
        ? waitReasonFromJSON(object.waitReason)
        : 0,
      waitDuration: isSet(object.waitDuration)
        ? String(object.waitDuration)
        : '',
      reason: isSet(object.reason) ? actionReasonFromJSON(object.reason) : 0,
      limitsState: isSet(object.limitsState)
        ? LimitsState.fromJSON(object.limitsState)
        : undefined,
      type: isSet(object.type) ? actionTypeFromJSON(object.type) : 0,
      subActions: Array.isArray(object?.subActions)
        ? object.subActions.map((e: any) => Action.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Action): unknown {
    const obj: any = {};
    message.deprecatedType !== undefined &&
      (obj.deprecatedType = message.deprecatedType);
    message.runCheck !== undefined &&
      (obj.runCheck = message.runCheck
        ? RunCheck.toJSON(message.runCheck)
        : undefined);
    message.dataRequest !== undefined &&
      (obj.dataRequest = message.dataRequest
        ? DataRequest.toJSON(message.dataRequest)
        : undefined);
    obj.details = {};
    if (message.details) {
      Object.entries(message.details).forEach(([k, v]) => {
        obj.details[k] = v;
      });
    }
    if (message.documentRequests) {
      obj.documentRequests = message.documentRequests.map((e) =>
        e ? DocumentRequest.toJSON(e) : undefined,
      );
    } else {
      obj.documentRequests = [];
    }
    message.party !== undefined &&
      (obj.party = message.party ? Party.toJSON(message.party) : undefined);
    message.waitReason !== undefined &&
      (obj.waitReason = waitReasonToJSON(message.waitReason));
    message.waitDuration !== undefined &&
      (obj.waitDuration = message.waitDuration);
    message.reason !== undefined &&
      (obj.reason = actionReasonToJSON(message.reason));
    message.limitsState !== undefined &&
      (obj.limitsState = message.limitsState
        ? LimitsState.toJSON(message.limitsState)
        : undefined);
    message.type !== undefined && (obj.type = actionTypeToJSON(message.type));
    if (message.subActions) {
      obj.subActions = message.subActions.map((e) =>
        e ? Action.toJSON(e) : undefined,
      );
    } else {
      obj.subActions = [];
    }
    return obj;
  },

  create(base?: DeepPartial<Action>): Action {
    return Action.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Action>): Action {
    const message = createBaseAction();
    message.deprecatedType = object.deprecatedType ?? '';
    message.runCheck =
      object.runCheck !== undefined && object.runCheck !== null
        ? RunCheck.fromPartial(object.runCheck)
        : undefined;
    message.dataRequest =
      object.dataRequest !== undefined && object.dataRequest !== null
        ? DataRequest.fromPartial(object.dataRequest)
        : undefined;
    message.details = Object.entries(object.details ?? {}).reduce<{
      [key: string]: string;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.documentRequests =
      object.documentRequests?.map((e) => DocumentRequest.fromPartial(e)) || [];
    message.party =
      object.party !== undefined && object.party !== null
        ? Party.fromPartial(object.party)
        : undefined;
    message.waitReason = object.waitReason ?? 0;
    message.waitDuration = object.waitDuration ?? '';
    message.reason = object.reason ?? 0;
    message.limitsState =
      object.limitsState !== undefined && object.limitsState !== null
        ? LimitsState.fromPartial(object.limitsState)
        : undefined;
    message.type = object.type ?? 0;
    message.subActions =
      object.subActions?.map((e) => Action.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAction_DetailsEntry(): Action_DetailsEntry {
  return { key: '', value: '' };
}

export const Action_DetailsEntry = {
  encode(
    message: Action_DetailsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== '') {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Action_DetailsEntry {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAction_DetailsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Action_DetailsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value) ? String(object.value) : '',
    };
  },

  toJSON(message: Action_DetailsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create(base?: DeepPartial<Action_DetailsEntry>): Action_DetailsEntry {
    return Action_DetailsEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Action_DetailsEntry>): Action_DetailsEntry {
    const message = createBaseAction_DetailsEntry();
    message.key = object.key ?? '';
    message.value = object.value ?? '';
    return message;
  },
};

function createBaseDocument(): Document {
  return {
    deprecatedType: 0,
    deprecatedSubType: 0,
    country: '',
    value: '',
    expiresAt: undefined,
    issuedAt: undefined,
    valueType: 0,
    side: 0,
    type: 0,
    url: '',
    id: '',
    reviews: [],
    number: '',
    status: 0,
  };
}

export const Document = {
  encode(
    message: Document,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.deprecatedType !== 0) {
      writer.uint32(8).int32(message.deprecatedType);
    }
    if (message.deprecatedSubType !== 0) {
      writer.uint32(16).int32(message.deprecatedSubType);
    }
    if (message.country !== '') {
      writer.uint32(26).string(message.country);
    }
    if (message.value !== '') {
      writer.uint32(34).string(message.value);
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.issuedAt !== undefined) {
      Timestamp.encode(message.issuedAt, writer.uint32(50).fork()).ldelim();
    }
    if (message.valueType !== 0) {
      writer.uint32(56).int32(message.valueType);
    }
    if (message.side !== 0) {
      writer.uint32(64).int32(message.side);
    }
    if (message.type !== 0) {
      writer.uint32(72).int32(message.type);
    }
    if (message.url !== '') {
      writer.uint32(82).string(message.url);
    }
    if (message.id !== '') {
      writer.uint32(90).string(message.id);
    }
    for (const v of message.reviews) {
      DocumentReview.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.number !== '') {
      writer.uint32(106).string(message.number);
    }
    if (message.status !== 0) {
      writer.uint32(112).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Document {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deprecatedType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deprecatedSubType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.country = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.issuedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.valueType = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.side = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.url = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.id = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.reviews.push(DocumentReview.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.number = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Document {
    return {
      deprecatedType: isSet(object.deprecatedType)
        ? deprecatedDocumentTypeFromJSON(object.deprecatedType)
        : 0,
      deprecatedSubType: isSet(object.deprecatedSubType)
        ? deprecatedDocumentSubTypeFromJSON(object.deprecatedSubType)
        : 0,
      country: isSet(object.country) ? String(object.country) : '',
      value: isSet(object.value) ? String(object.value) : '',
      expiresAt: isSet(object.expiresAt)
        ? fromJsonTimestamp(object.expiresAt)
        : undefined,
      issuedAt: isSet(object.issuedAt)
        ? fromJsonTimestamp(object.issuedAt)
        : undefined,
      valueType: isSet(object.valueType)
        ? documentValueTypeFromJSON(object.valueType)
        : 0,
      side: isSet(object.side) ? documentSideFromJSON(object.side) : 0,
      type: isSet(object.type) ? documentTypeFromJSON(object.type) : 0,
      url: isSet(object.url) ? String(object.url) : '',
      id: isSet(object.id) ? String(object.id) : '',
      reviews: Array.isArray(object?.reviews)
        ? object.reviews.map((e: any) => DocumentReview.fromJSON(e))
        : [],
      number: isSet(object.number) ? String(object.number) : '',
      status: isSet(object.status) ? documentStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: Document): unknown {
    const obj: any = {};
    message.deprecatedType !== undefined &&
      (obj.deprecatedType = deprecatedDocumentTypeToJSON(
        message.deprecatedType,
      ));
    message.deprecatedSubType !== undefined &&
      (obj.deprecatedSubType = deprecatedDocumentSubTypeToJSON(
        message.deprecatedSubType,
      ));
    message.country !== undefined && (obj.country = message.country);
    message.value !== undefined && (obj.value = message.value);
    message.expiresAt !== undefined &&
      (obj.expiresAt = fromTimestamp(message.expiresAt).toISOString());
    message.issuedAt !== undefined &&
      (obj.issuedAt = fromTimestamp(message.issuedAt).toISOString());
    message.valueType !== undefined &&
      (obj.valueType = documentValueTypeToJSON(message.valueType));
    message.side !== undefined && (obj.side = documentSideToJSON(message.side));
    message.type !== undefined && (obj.type = documentTypeToJSON(message.type));
    message.url !== undefined && (obj.url = message.url);
    message.id !== undefined && (obj.id = message.id);
    if (message.reviews) {
      obj.reviews = message.reviews.map((e) =>
        e ? DocumentReview.toJSON(e) : undefined,
      );
    } else {
      obj.reviews = [];
    }
    message.number !== undefined && (obj.number = message.number);
    message.status !== undefined &&
      (obj.status = documentStatusToJSON(message.status));
    return obj;
  },

  create(base?: DeepPartial<Document>): Document {
    return Document.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Document>): Document {
    const message = createBaseDocument();
    message.deprecatedType = object.deprecatedType ?? 0;
    message.deprecatedSubType = object.deprecatedSubType ?? 0;
    message.country = object.country ?? '';
    message.value = object.value ?? '';
    message.expiresAt =
      object.expiresAt !== undefined && object.expiresAt !== null
        ? Timestamp.fromPartial(object.expiresAt)
        : undefined;
    message.issuedAt =
      object.issuedAt !== undefined && object.issuedAt !== null
        ? Timestamp.fromPartial(object.issuedAt)
        : undefined;
    message.valueType = object.valueType ?? 0;
    message.side = object.side ?? 0;
    message.type = object.type ?? 0;
    message.url = object.url ?? '';
    message.id = object.id ?? '';
    message.reviews =
      object.reviews?.map((e) => DocumentReview.fromPartial(e)) || [];
    message.number = object.number ?? '';
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseDocumentReview(): DocumentReview {
  return {
    staffId: '',
    valid: false,
    comment: '',
    createdAt: undefined,
    status: 0,
  };
}

export const DocumentReview = {
  encode(
    message: DocumentReview,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.staffId !== '') {
      writer.uint32(10).string(message.staffId);
    }
    if (message.valid === true) {
      writer.uint32(16).bool(message.valid);
    }
    if (message.comment !== '') {
      writer.uint32(26).string(message.comment);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(34).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentReview {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocumentReview();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.staffId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.valid = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comment = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DocumentReview {
    return {
      staffId: isSet(object.staffId) ? String(object.staffId) : '',
      valid: isSet(object.valid) ? Boolean(object.valid) : false,
      comment: isSet(object.comment) ? String(object.comment) : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      status: isSet(object.status) ? documentStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: DocumentReview): unknown {
    const obj: any = {};
    message.staffId !== undefined && (obj.staffId = message.staffId);
    message.valid !== undefined && (obj.valid = message.valid);
    message.comment !== undefined && (obj.comment = message.comment);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.status !== undefined &&
      (obj.status = documentStatusToJSON(message.status));
    return obj;
  },

  create(base?: DeepPartial<DocumentReview>): DocumentReview {
    return DocumentReview.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<DocumentReview>): DocumentReview {
    const message = createBaseDocumentReview();
    message.staffId = object.staffId ?? '';
    message.valid = object.valid ?? false;
    message.comment = object.comment ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseDocumentRequest(): DocumentRequest {
  return { country: '', deprecatedType: 0, deprecatedSubType: 0, types: [] };
}

export const DocumentRequest = {
  encode(
    message: DocumentRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.country !== '') {
      writer.uint32(10).string(message.country);
    }
    if (message.deprecatedType !== 0) {
      writer.uint32(16).int32(message.deprecatedType);
    }
    if (message.deprecatedSubType !== 0) {
      writer.uint32(24).int32(message.deprecatedSubType);
    }
    writer.uint32(34).fork();
    for (const v of message.types) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DocumentRequest {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDocumentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.country = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.deprecatedType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.deprecatedSubType = reader.int32() as any;
          continue;
        case 4:
          if (tag === 32) {
            message.types.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.types.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DocumentRequest {
    return {
      country: isSet(object.country) ? String(object.country) : '',
      deprecatedType: isSet(object.deprecatedType)
        ? deprecatedDocumentTypeFromJSON(object.deprecatedType)
        : 0,
      deprecatedSubType: isSet(object.deprecatedSubType)
        ? deprecatedDocumentSubTypeFromJSON(object.deprecatedSubType)
        : 0,
      types: Array.isArray(object?.types)
        ? object.types.map((e: any) => documentTypeFromJSON(e))
        : [],
    };
  },

  toJSON(message: DocumentRequest): unknown {
    const obj: any = {};
    message.country !== undefined && (obj.country = message.country);
    message.deprecatedType !== undefined &&
      (obj.deprecatedType = deprecatedDocumentTypeToJSON(
        message.deprecatedType,
      ));
    message.deprecatedSubType !== undefined &&
      (obj.deprecatedSubType = deprecatedDocumentSubTypeToJSON(
        message.deprecatedSubType,
      ));
    if (message.types) {
      obj.types = message.types.map((e) => documentTypeToJSON(e));
    } else {
      obj.types = [];
    }
    return obj;
  },

  create(base?: DeepPartial<DocumentRequest>): DocumentRequest {
    return DocumentRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<DocumentRequest>): DocumentRequest {
    const message = createBaseDocumentRequest();
    message.country = object.country ?? '';
    message.deprecatedType = object.deprecatedType ?? 0;
    message.deprecatedSubType = object.deprecatedSubType ?? 0;
    message.types = object.types?.map((e) => e) || [];
    return message;
  },
};

function createBaseStringList(): StringList {
  return { values: [] };
}

export const StringList = {
  encode(
    message: StringList,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.values) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StringList {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStringList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.values.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StringList {
    return {
      values: Array.isArray(object?.values)
        ? object.values.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: StringList): unknown {
    const obj: any = {};
    if (message.values) {
      obj.values = message.values.map((e) => e);
    } else {
      obj.values = [];
    }
    return obj;
  },

  create(base?: DeepPartial<StringList>): StringList {
    return StringList.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<StringList>): StringList {
    const message = createBaseStringList();
    message.values = object.values?.map((e) => e) || [];
    return message;
  },
};

function createBaseAssociates(): Associates {
  return { values: [] };
}

export const Associates = {
  encode(
    message: Associates,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.values) {
      Associate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Associates {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssociates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.values.push(Associate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Associates {
    return {
      values: Array.isArray(object?.values)
        ? object.values.map((e: any) => Associate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Associates): unknown {
    const obj: any = {};
    if (message.values) {
      obj.values = message.values.map((e) =>
        e ? Associate.toJSON(e) : undefined,
      );
    } else {
      obj.values = [];
    }
    return obj;
  },

  create(base?: DeepPartial<Associates>): Associates {
    return Associates.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Associates>): Associates {
    const message = createBaseAssociates();
    message.values = object.values?.map((e) => Associate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseData(): Data {
  return {
    type: 0,
    delete: undefined,
    string: undefined,
    name: undefined,
    address: undefined,
    companyType: undefined,
    associates: undefined,
    stringList: undefined,
    gender: undefined,
    timestamp: undefined,
  };
}

export const Data = {
  encode(message: Data, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.delete !== undefined) {
      writer.uint32(16).bool(message.delete);
    }
    if (message.string !== undefined) {
      writer.uint32(26).string(message.string);
    }
    if (message.name !== undefined) {
      Name.encode(message.name, writer.uint32(34).fork()).ldelim();
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(42).fork()).ldelim();
    }
    if (message.companyType !== undefined) {
      writer.uint32(48).int32(message.companyType);
    }
    if (message.associates !== undefined) {
      Associates.encode(message.associates, writer.uint32(58).fork()).ldelim();
    }
    if (message.stringList !== undefined) {
      StringList.encode(message.stringList, writer.uint32(66).fork()).ldelim();
    }
    if (message.gender !== undefined) {
      writer.uint32(72).int32(message.gender);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(message.timestamp, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Data {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.delete = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.string = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = Name.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.companyType = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.associates = Associates.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.stringList = StringList.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.gender = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.timestamp = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Data {
    return {
      type: isSet(object.type) ? dataTypeFromJSON(object.type) : 0,
      delete: isSet(object.delete) ? Boolean(object.delete) : undefined,
      string: isSet(object.string) ? String(object.string) : undefined,
      name: isSet(object.name) ? Name.fromJSON(object.name) : undefined,
      address: isSet(object.address)
        ? Address.fromJSON(object.address)
        : undefined,
      companyType: isSet(object.companyType)
        ? companyTypeFromJSON(object.companyType)
        : undefined,
      associates: isSet(object.associates)
        ? Associates.fromJSON(object.associates)
        : undefined,
      stringList: isSet(object.stringList)
        ? StringList.fromJSON(object.stringList)
        : undefined,
      gender: isSet(object.gender) ? genderFromJSON(object.gender) : undefined,
      timestamp: isSet(object.timestamp)
        ? fromJsonTimestamp(object.timestamp)
        : undefined,
    };
  },

  toJSON(message: Data): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = dataTypeToJSON(message.type));
    message.delete !== undefined && (obj.delete = message.delete);
    message.string !== undefined && (obj.string = message.string);
    message.name !== undefined &&
      (obj.name = message.name ? Name.toJSON(message.name) : undefined);
    message.address !== undefined &&
      (obj.address = message.address
        ? Address.toJSON(message.address)
        : undefined);
    message.companyType !== undefined &&
      (obj.companyType =
        message.companyType !== undefined
          ? companyTypeToJSON(message.companyType)
          : undefined);
    message.associates !== undefined &&
      (obj.associates = message.associates
        ? Associates.toJSON(message.associates)
        : undefined);
    message.stringList !== undefined &&
      (obj.stringList = message.stringList
        ? StringList.toJSON(message.stringList)
        : undefined);
    message.gender !== undefined &&
      (obj.gender =
        message.gender !== undefined
          ? genderToJSON(message.gender)
          : undefined);
    message.timestamp !== undefined &&
      (obj.timestamp = fromTimestamp(message.timestamp).toISOString());
    return obj;
  },

  create(base?: DeepPartial<Data>): Data {
    return Data.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Data>): Data {
    const message = createBaseData();
    message.type = object.type ?? 0;
    message.delete = object.delete ?? undefined;
    message.string = object.string ?? undefined;
    message.name =
      object.name !== undefined && object.name !== null
        ? Name.fromPartial(object.name)
        : undefined;
    message.address =
      object.address !== undefined && object.address !== null
        ? Address.fromPartial(object.address)
        : undefined;
    message.companyType = object.companyType ?? undefined;
    message.associates =
      object.associates !== undefined && object.associates !== null
        ? Associates.fromPartial(object.associates)
        : undefined;
    message.stringList =
      object.stringList !== undefined && object.stringList !== null
        ? StringList.fromPartial(object.stringList)
        : undefined;
    message.gender = object.gender ?? undefined;
    message.timestamp =
      object.timestamp !== undefined && object.timestamp !== null
        ? Timestamp.fromPartial(object.timestamp)
        : undefined;
    return message;
  },
};

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }
  throw 'Unable to locate global object';
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, 'base64'));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString('base64');
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(''));
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error(
      'Value is larger than Number.MAX_SAFE_INTEGER',
    );
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
