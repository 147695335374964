import { Button, Modal, Typography } from 'antd';
import React from 'react';

import styles from './styles.module.css';

const { Title, Text } = Typography;

type AccountLinkWelcomeType = {
  onClose: VoidFunction;
};

const AccountLinkWelcome = ({ onClose }: AccountLinkWelcomeType) => {
  return (
    <Modal
      open
      footer={null}
      closable={false}
      className={styles.wrapper}
      width={500}
    >
      <Title level={4} className={styles.title}>
        Account linking successful
      </Title>
      <img
        width="100"
        src="/images/tick-circle-outline.svg"
        alt="tick"
        className={styles.image}
      />
      <Title level={4} className={styles.subtitle}>
        Welcome to the Rafiki Portal
      </Title>
      <Text className={styles.description}>
        Fund your account, create wallets, send transactions to one or many
        payment accounts and manage all of your business payments from one
        place!
      </Text>
      <Button size="large" type="primary" block onClick={onClose}>
        Explore the portal
      </Button>
    </Modal>
  );
};

export default AccountLinkWelcome;
