/* eslint-disable */
import _m0 from 'protobufjs/minimal';
import { Timestamp } from '../../../google/protobuf/timestamp';

export const protobufPackage = 'fx';

/** buf:lint:ignore COMMENT_ENUM */
export enum QuoteType {
  QUOTE_TYPE_INVALID = 0,
  QUOTE_TYPE_DIRECT = 1,
  QUOTE_TYPE_INDIRECT = 2,
  UNRECOGNIZED = -1,
}

export function quoteTypeFromJSON(object: any): QuoteType {
  switch (object) {
    case 0:
    case 'QUOTE_TYPE_INVALID':
      return QuoteType.QUOTE_TYPE_INVALID;
    case 1:
    case 'QUOTE_TYPE_DIRECT':
      return QuoteType.QUOTE_TYPE_DIRECT;
    case 2:
    case 'QUOTE_TYPE_INDIRECT':
      return QuoteType.QUOTE_TYPE_INDIRECT;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return QuoteType.UNRECOGNIZED;
  }
}

export function quoteTypeToJSON(object: QuoteType): string {
  switch (object) {
    case QuoteType.QUOTE_TYPE_INVALID:
      return 'QUOTE_TYPE_INVALID';
    case QuoteType.QUOTE_TYPE_DIRECT:
      return 'QUOTE_TYPE_DIRECT';
    case QuoteType.QUOTE_TYPE_INDIRECT:
      return 'QUOTE_TYPE_INDIRECT';
    case QuoteType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** RateMatchingDecision is the list of possible decisions that can be made when matching rates. */
export enum RateMatchingDecision {
  RATE_MATCHING_DECISION_INVALID = 0,
  RATE_MATCHING_DECISION_MATCH = 1,
  RATE_MATCHING_DECISION_MATCH_WITH_MARGIN = 2,
  RATE_MATCHING_DECISION_USE_BASE_WITH_MARGIN = 3,
  RATE_MATCHING_DECISION_USE_BASE = 4,
  RATE_MATCHING_DECISION_USE_BASE_MATCHED_ABOVE_MAX_SPREAD = 5,
  RATE_MATCHING_DECISION_USE_BASE_WITH_MARGIN_MATCHED_ABOVE_MAX_SPREAD = 6,
  RATE_MATCHING_DECISION_IGNORE_MISSING_RATES = 7,
  RATE_MATCHING_DECISION_IGNORE_SAME_RATE = 8,
  RATE_MATCHING_DECISION_IGNORE_FAILED_VALIDATION = 9,
  RATE_MATCHING_DECISION_FAILED = 10,
  UNRECOGNIZED = -1,
}

export function rateMatchingDecisionFromJSON(
  object: any,
): RateMatchingDecision {
  switch (object) {
    case 0:
    case 'RATE_MATCHING_DECISION_INVALID':
      return RateMatchingDecision.RATE_MATCHING_DECISION_INVALID;
    case 1:
    case 'RATE_MATCHING_DECISION_MATCH':
      return RateMatchingDecision.RATE_MATCHING_DECISION_MATCH;
    case 2:
    case 'RATE_MATCHING_DECISION_MATCH_WITH_MARGIN':
      return RateMatchingDecision.RATE_MATCHING_DECISION_MATCH_WITH_MARGIN;
    case 3:
    case 'RATE_MATCHING_DECISION_USE_BASE_WITH_MARGIN':
      return RateMatchingDecision.RATE_MATCHING_DECISION_USE_BASE_WITH_MARGIN;
    case 4:
    case 'RATE_MATCHING_DECISION_USE_BASE':
      return RateMatchingDecision.RATE_MATCHING_DECISION_USE_BASE;
    case 5:
    case 'RATE_MATCHING_DECISION_USE_BASE_MATCHED_ABOVE_MAX_SPREAD':
      return RateMatchingDecision.RATE_MATCHING_DECISION_USE_BASE_MATCHED_ABOVE_MAX_SPREAD;
    case 6:
    case 'RATE_MATCHING_DECISION_USE_BASE_WITH_MARGIN_MATCHED_ABOVE_MAX_SPREAD':
      return RateMatchingDecision.RATE_MATCHING_DECISION_USE_BASE_WITH_MARGIN_MATCHED_ABOVE_MAX_SPREAD;
    case 7:
    case 'RATE_MATCHING_DECISION_IGNORE_MISSING_RATES':
      return RateMatchingDecision.RATE_MATCHING_DECISION_IGNORE_MISSING_RATES;
    case 8:
    case 'RATE_MATCHING_DECISION_IGNORE_SAME_RATE':
      return RateMatchingDecision.RATE_MATCHING_DECISION_IGNORE_SAME_RATE;
    case 9:
    case 'RATE_MATCHING_DECISION_IGNORE_FAILED_VALIDATION':
      return RateMatchingDecision.RATE_MATCHING_DECISION_IGNORE_FAILED_VALIDATION;
    case 10:
    case 'RATE_MATCHING_DECISION_FAILED':
      return RateMatchingDecision.RATE_MATCHING_DECISION_FAILED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return RateMatchingDecision.UNRECOGNIZED;
  }
}

export function rateMatchingDecisionToJSON(
  object: RateMatchingDecision,
): string {
  switch (object) {
    case RateMatchingDecision.RATE_MATCHING_DECISION_INVALID:
      return 'RATE_MATCHING_DECISION_INVALID';
    case RateMatchingDecision.RATE_MATCHING_DECISION_MATCH:
      return 'RATE_MATCHING_DECISION_MATCH';
    case RateMatchingDecision.RATE_MATCHING_DECISION_MATCH_WITH_MARGIN:
      return 'RATE_MATCHING_DECISION_MATCH_WITH_MARGIN';
    case RateMatchingDecision.RATE_MATCHING_DECISION_USE_BASE_WITH_MARGIN:
      return 'RATE_MATCHING_DECISION_USE_BASE_WITH_MARGIN';
    case RateMatchingDecision.RATE_MATCHING_DECISION_USE_BASE:
      return 'RATE_MATCHING_DECISION_USE_BASE';
    case RateMatchingDecision.RATE_MATCHING_DECISION_USE_BASE_MATCHED_ABOVE_MAX_SPREAD:
      return 'RATE_MATCHING_DECISION_USE_BASE_MATCHED_ABOVE_MAX_SPREAD';
    case RateMatchingDecision.RATE_MATCHING_DECISION_USE_BASE_WITH_MARGIN_MATCHED_ABOVE_MAX_SPREAD:
      return 'RATE_MATCHING_DECISION_USE_BASE_WITH_MARGIN_MATCHED_ABOVE_MAX_SPREAD';
    case RateMatchingDecision.RATE_MATCHING_DECISION_IGNORE_MISSING_RATES:
      return 'RATE_MATCHING_DECISION_IGNORE_MISSING_RATES';
    case RateMatchingDecision.RATE_MATCHING_DECISION_IGNORE_SAME_RATE:
      return 'RATE_MATCHING_DECISION_IGNORE_SAME_RATE';
    case RateMatchingDecision.RATE_MATCHING_DECISION_IGNORE_FAILED_VALIDATION:
      return 'RATE_MATCHING_DECISION_IGNORE_FAILED_VALIDATION';
    case RateMatchingDecision.RATE_MATCHING_DECISION_FAILED:
      return 'RATE_MATCHING_DECISION_FAILED';
    case RateMatchingDecision.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** FXTransferStatus is the status of a fx transfer. */
export enum FXTransferStatus {
  FX_TRANSFER_STATUS_INVALID = 0,
  FX_TRANSFER_STATUS_SENT = 1,
  FX_TRANSFER_STATUS_RECEIVED = 2,
  FX_TRANSFER_STATUS_REFUNDED = 3,
  FX_TRANSFER_STATUS_PENDING = 4,
  UNRECOGNIZED = -1,
}

export function fXTransferStatusFromJSON(object: any): FXTransferStatus {
  switch (object) {
    case 0:
    case 'FX_TRANSFER_STATUS_INVALID':
      return FXTransferStatus.FX_TRANSFER_STATUS_INVALID;
    case 1:
    case 'FX_TRANSFER_STATUS_SENT':
      return FXTransferStatus.FX_TRANSFER_STATUS_SENT;
    case 2:
    case 'FX_TRANSFER_STATUS_RECEIVED':
      return FXTransferStatus.FX_TRANSFER_STATUS_RECEIVED;
    case 3:
    case 'FX_TRANSFER_STATUS_REFUNDED':
      return FXTransferStatus.FX_TRANSFER_STATUS_REFUNDED;
    case 4:
    case 'FX_TRANSFER_STATUS_PENDING':
      return FXTransferStatus.FX_TRANSFER_STATUS_PENDING;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FXTransferStatus.UNRECOGNIZED;
  }
}

export function fXTransferStatusToJSON(object: FXTransferStatus): string {
  switch (object) {
    case FXTransferStatus.FX_TRANSFER_STATUS_INVALID:
      return 'FX_TRANSFER_STATUS_INVALID';
    case FXTransferStatus.FX_TRANSFER_STATUS_SENT:
      return 'FX_TRANSFER_STATUS_SENT';
    case FXTransferStatus.FX_TRANSFER_STATUS_RECEIVED:
      return 'FX_TRANSFER_STATUS_RECEIVED';
    case FXTransferStatus.FX_TRANSFER_STATUS_REFUNDED:
      return 'FX_TRANSFER_STATUS_REFUNDED';
    case FXTransferStatus.FX_TRANSFER_STATUS_PENDING:
      return 'FX_TRANSFER_STATUS_PENDING';
    case FXTransferStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** FXDealStatus is the status of a fx transfer. */
export enum FXDealStatus {
  FX_DEAL_STATUS_INVALID = 0,
  FX_DEAL_STATUS_SENT = 1,
  FX_DEAL_STATUS_RECEIVED = 2,
  FX_DEAL_STATUS_REFUNDED = 3,
  FX_DEAL_STATUS_PENDING = 4,
  UNRECOGNIZED = -1,
}

export function fXDealStatusFromJSON(object: any): FXDealStatus {
  switch (object) {
    case 0:
    case 'FX_DEAL_STATUS_INVALID':
      return FXDealStatus.FX_DEAL_STATUS_INVALID;
    case 1:
    case 'FX_DEAL_STATUS_SENT':
      return FXDealStatus.FX_DEAL_STATUS_SENT;
    case 2:
    case 'FX_DEAL_STATUS_RECEIVED':
      return FXDealStatus.FX_DEAL_STATUS_RECEIVED;
    case 3:
    case 'FX_DEAL_STATUS_REFUNDED':
      return FXDealStatus.FX_DEAL_STATUS_REFUNDED;
    case 4:
    case 'FX_DEAL_STATUS_PENDING':
      return FXDealStatus.FX_DEAL_STATUS_PENDING;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FXDealStatus.UNRECOGNIZED;
  }
}

export function fXDealStatusToJSON(object: FXDealStatus): string {
  switch (object) {
    case FXDealStatus.FX_DEAL_STATUS_INVALID:
      return 'FX_DEAL_STATUS_INVALID';
    case FXDealStatus.FX_DEAL_STATUS_SENT:
      return 'FX_DEAL_STATUS_SENT';
    case FXDealStatus.FX_DEAL_STATUS_RECEIVED:
      return 'FX_DEAL_STATUS_RECEIVED';
    case FXDealStatus.FX_DEAL_STATUS_REFUNDED:
      return 'FX_DEAL_STATUS_REFUNDED';
    case FXDealStatus.FX_DEAL_STATUS_PENDING:
      return 'FX_DEAL_STATUS_PENDING';
    case FXDealStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** FXFundFlowStepType is the type of a step in a flow of funds. */
export enum FXFundFlowStepType {
  FX_FUND_FLOW_STEP_TYPE_INVALID = 0,
  FX_FUND_FLOW_STEP_TYPE_DEAL = 1,
  FX_FUND_FLOW_STEP_TYPE_TRANSFER = 2,
  UNRECOGNIZED = -1,
}

export function fXFundFlowStepTypeFromJSON(object: any): FXFundFlowStepType {
  switch (object) {
    case 0:
    case 'FX_FUND_FLOW_STEP_TYPE_INVALID':
      return FXFundFlowStepType.FX_FUND_FLOW_STEP_TYPE_INVALID;
    case 1:
    case 'FX_FUND_FLOW_STEP_TYPE_DEAL':
      return FXFundFlowStepType.FX_FUND_FLOW_STEP_TYPE_DEAL;
    case 2:
    case 'FX_FUND_FLOW_STEP_TYPE_TRANSFER':
      return FXFundFlowStepType.FX_FUND_FLOW_STEP_TYPE_TRANSFER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FXFundFlowStepType.UNRECOGNIZED;
  }
}

export function fXFundFlowStepTypeToJSON(object: FXFundFlowStepType): string {
  switch (object) {
    case FXFundFlowStepType.FX_FUND_FLOW_STEP_TYPE_INVALID:
      return 'FX_FUND_FLOW_STEP_TYPE_INVALID';
    case FXFundFlowStepType.FX_FUND_FLOW_STEP_TYPE_DEAL:
      return 'FX_FUND_FLOW_STEP_TYPE_DEAL';
    case FXFundFlowStepType.FX_FUND_FLOW_STEP_TYPE_TRANSFER:
      return 'FX_FUND_FLOW_STEP_TYPE_TRANSFER';
    case FXFundFlowStepType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** FXDealQuoteType is the type of a deal quote. */
export enum FXDealQuoteType {
  FX_DEAL_QUOTE_TYPE_INVALID = 0,
  FX_DEAL_QUOTE_TYPE_DEAL_QUOTE = 1,
  FX_DEAL_QUOTE_TYPE_INDICATIVE = 2,
  UNRECOGNIZED = -1,
}

export function fXDealQuoteTypeFromJSON(object: any): FXDealQuoteType {
  switch (object) {
    case 0:
    case 'FX_DEAL_QUOTE_TYPE_INVALID':
      return FXDealQuoteType.FX_DEAL_QUOTE_TYPE_INVALID;
    case 1:
    case 'FX_DEAL_QUOTE_TYPE_DEAL_QUOTE':
      return FXDealQuoteType.FX_DEAL_QUOTE_TYPE_DEAL_QUOTE;
    case 2:
    case 'FX_DEAL_QUOTE_TYPE_INDICATIVE':
      return FXDealQuoteType.FX_DEAL_QUOTE_TYPE_INDICATIVE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FXDealQuoteType.UNRECOGNIZED;
  }
}

export function fXDealQuoteTypeToJSON(object: FXDealQuoteType): string {
  switch (object) {
    case FXDealQuoteType.FX_DEAL_QUOTE_TYPE_INVALID:
      return 'FX_DEAL_QUOTE_TYPE_INVALID';
    case FXDealQuoteType.FX_DEAL_QUOTE_TYPE_DEAL_QUOTE:
      return 'FX_DEAL_QUOTE_TYPE_DEAL_QUOTE';
    case FXDealQuoteType.FX_DEAL_QUOTE_TYPE_INDICATIVE:
      return 'FX_DEAL_QUOTE_TYPE_INDICATIVE';
    case FXDealQuoteType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** FXDealQuoteStatus is the status of a deal quote. */
export enum FXDealQuoteStatus {
  FX_DEAL_QUOTE_STATUS_INVALID = 0,
  FX_DEAL_QUOTE_STATUS_ACTIVE = 1,
  FX_DEAL_QUOTE_STATUS_EXPIRED = 2,
  UNRECOGNIZED = -1,
}

export function fXDealQuoteStatusFromJSON(object: any): FXDealQuoteStatus {
  switch (object) {
    case 0:
    case 'FX_DEAL_QUOTE_STATUS_INVALID':
      return FXDealQuoteStatus.FX_DEAL_QUOTE_STATUS_INVALID;
    case 1:
    case 'FX_DEAL_QUOTE_STATUS_ACTIVE':
      return FXDealQuoteStatus.FX_DEAL_QUOTE_STATUS_ACTIVE;
    case 2:
    case 'FX_DEAL_QUOTE_STATUS_EXPIRED':
      return FXDealQuoteStatus.FX_DEAL_QUOTE_STATUS_EXPIRED;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FXDealQuoteStatus.UNRECOGNIZED;
  }
}

export function fXDealQuoteStatusToJSON(object: FXDealQuoteStatus): string {
  switch (object) {
    case FXDealQuoteStatus.FX_DEAL_QUOTE_STATUS_INVALID:
      return 'FX_DEAL_QUOTE_STATUS_INVALID';
    case FXDealQuoteStatus.FX_DEAL_QUOTE_STATUS_ACTIVE:
      return 'FX_DEAL_QUOTE_STATUS_ACTIVE';
    case FXDealQuoteStatus.FX_DEAL_QUOTE_STATUS_EXPIRED:
      return 'FX_DEAL_QUOTE_STATUS_EXPIRED';
    case FXDealQuoteStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** FXDealQuoteOrdering is the ordering of a deal quote. */
export enum FXDealQuoteOrdering {
  FX_DEAL_QUOTE_ORDERING_INVALID = 0,
  FX_DEAL_QUOTE_ORDERING_BUY_CURRENCY = 1,
  FX_DEAL_QUOTE_ORDERING_SELL_CURRENCY = 2,
  FX_DEAL_QUOTE_ORDERING_DEAL_DATE = 3,
  FX_DEAL_QUOTE_ORDERING_TYPE = 4,
  FX_DEAL_QUOTE_ORDERING_CREATED_DATE = 5,
  UNRECOGNIZED = -1,
}

export function fXDealQuoteOrderingFromJSON(object: any): FXDealQuoteOrdering {
  switch (object) {
    case 0:
    case 'FX_DEAL_QUOTE_ORDERING_INVALID':
      return FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_INVALID;
    case 1:
    case 'FX_DEAL_QUOTE_ORDERING_BUY_CURRENCY':
      return FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_BUY_CURRENCY;
    case 2:
    case 'FX_DEAL_QUOTE_ORDERING_SELL_CURRENCY':
      return FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_SELL_CURRENCY;
    case 3:
    case 'FX_DEAL_QUOTE_ORDERING_DEAL_DATE':
      return FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_DEAL_DATE;
    case 4:
    case 'FX_DEAL_QUOTE_ORDERING_TYPE':
      return FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_TYPE;
    case 5:
    case 'FX_DEAL_QUOTE_ORDERING_CREATED_DATE':
      return FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_CREATED_DATE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FXDealQuoteOrdering.UNRECOGNIZED;
  }
}

export function fXDealQuoteOrderingToJSON(object: FXDealQuoteOrdering): string {
  switch (object) {
    case FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_INVALID:
      return 'FX_DEAL_QUOTE_ORDERING_INVALID';
    case FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_BUY_CURRENCY:
      return 'FX_DEAL_QUOTE_ORDERING_BUY_CURRENCY';
    case FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_SELL_CURRENCY:
      return 'FX_DEAL_QUOTE_ORDERING_SELL_CURRENCY';
    case FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_DEAL_DATE:
      return 'FX_DEAL_QUOTE_ORDERING_DEAL_DATE';
    case FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_TYPE:
      return 'FX_DEAL_QUOTE_ORDERING_TYPE';
    case FXDealQuoteOrdering.FX_DEAL_QUOTE_ORDERING_CREATED_DATE:
      return 'FX_DEAL_QUOTE_ORDERING_CREATED_DATE';
    case FXDealQuoteOrdering.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** ListFXDealOrdering is the ordering of a list of deals. */
export enum ListFXDealsOrdering {
  LIST_FX_DEALS_ORDERING_INVALID = 0,
  LIST_FX_DEALS_ORDERING_SELL_CURRENCY = 1,
  LIST_FX_DEALS_ORDERING_BUY_CURRENCY = 2,
  LIST_FX_DEALS_ORDERING_DEAL_DATE = 3,
  UNRECOGNIZED = -1,
}

export function listFXDealsOrderingFromJSON(object: any): ListFXDealsOrdering {
  switch (object) {
    case 0:
    case 'LIST_FX_DEALS_ORDERING_INVALID':
      return ListFXDealsOrdering.LIST_FX_DEALS_ORDERING_INVALID;
    case 1:
    case 'LIST_FX_DEALS_ORDERING_SELL_CURRENCY':
      return ListFXDealsOrdering.LIST_FX_DEALS_ORDERING_SELL_CURRENCY;
    case 2:
    case 'LIST_FX_DEALS_ORDERING_BUY_CURRENCY':
      return ListFXDealsOrdering.LIST_FX_DEALS_ORDERING_BUY_CURRENCY;
    case 3:
    case 'LIST_FX_DEALS_ORDERING_DEAL_DATE':
      return ListFXDealsOrdering.LIST_FX_DEALS_ORDERING_DEAL_DATE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ListFXDealsOrdering.UNRECOGNIZED;
  }
}

export function listFXDealsOrderingToJSON(object: ListFXDealsOrdering): string {
  switch (object) {
    case ListFXDealsOrdering.LIST_FX_DEALS_ORDERING_INVALID:
      return 'LIST_FX_DEALS_ORDERING_INVALID';
    case ListFXDealsOrdering.LIST_FX_DEALS_ORDERING_SELL_CURRENCY:
      return 'LIST_FX_DEALS_ORDERING_SELL_CURRENCY';
    case ListFXDealsOrdering.LIST_FX_DEALS_ORDERING_BUY_CURRENCY:
      return 'LIST_FX_DEALS_ORDERING_BUY_CURRENCY';
    case ListFXDealsOrdering.LIST_FX_DEALS_ORDERING_DEAL_DATE:
      return 'LIST_FX_DEALS_ORDERING_DEAL_DATE';
    case ListFXDealsOrdering.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** ListFXTransferOrdering is the ordering of a list of fx transfers. */
export enum ListFXTransferOrdering {
  LIST_FX_TRANSFER_ORDERING_INVALID = 0,
  LIST_FX_TRANSFER_ORDERING_CURRENCY = 1,
  LIST_FX_TRANSFER_ORDERING_TRANSFER_DATE = 2,
  UNRECOGNIZED = -1,
}

export function listFXTransferOrderingFromJSON(
  object: any,
): ListFXTransferOrdering {
  switch (object) {
    case 0:
    case 'LIST_FX_TRANSFER_ORDERING_INVALID':
      return ListFXTransferOrdering.LIST_FX_TRANSFER_ORDERING_INVALID;
    case 1:
    case 'LIST_FX_TRANSFER_ORDERING_CURRENCY':
      return ListFXTransferOrdering.LIST_FX_TRANSFER_ORDERING_CURRENCY;
    case 2:
    case 'LIST_FX_TRANSFER_ORDERING_TRANSFER_DATE':
      return ListFXTransferOrdering.LIST_FX_TRANSFER_ORDERING_TRANSFER_DATE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ListFXTransferOrdering.UNRECOGNIZED;
  }
}

export function listFXTransferOrderingToJSON(
  object: ListFXTransferOrdering,
): string {
  switch (object) {
    case ListFXTransferOrdering.LIST_FX_TRANSFER_ORDERING_INVALID:
      return 'LIST_FX_TRANSFER_ORDERING_INVALID';
    case ListFXTransferOrdering.LIST_FX_TRANSFER_ORDERING_CURRENCY:
      return 'LIST_FX_TRANSFER_ORDERING_CURRENCY';
    case ListFXTransferOrdering.LIST_FX_TRANSFER_ORDERING_TRANSFER_DATE:
      return 'LIST_FX_TRANSFER_ORDERING_TRANSFER_DATE';
    case ListFXTransferOrdering.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** ListFXFundFlowOrdering is the ordering of a list of fx fund flows. */
export enum ListFXFundFlowOrdering {
  LIST_FX_FUND_FLOW_ORDERING_INVALID = 0,
  LIST_FX_FUND_FLOW_ORDERING_CREATED_DATE = 1,
  LIST_FX_FUND_FLOW_ORDERING_DEAL_SELL_CURRENCY = 2,
  LIST_FX_FUND_FLOW_ORDERING_TRANSFER_CURRENCY = 3,
  LIST_FX_FUND_FLOW_ORDERING_DEAL_BUY_CURRENCY = 4,
  LIST_FX_FUND_FLOW_ORDERING_DEAL_SELL_AMOUNT = 5,
  LIST_FX_FUND_FLOW_ORDERING_DEAL_BUY_AMOUNT = 6,
  LIST_FX_FUND_FLOW_ORDERING_TRANSFER_AMOUNT = 7,
  LIST_FX_FUND_FLOW_ORDERING_DEAL_SENDER = 8,
  LIST_FX_FUND_FLOW_ORDERING_DEAL_RECIPIENT = 9,
  LIST_FX_FUND_FLOW_ORDERING_TRANSFER_SENDER_ID = 10,
  LIST_FX_FUND_FLOW_ORDERING_TRANSFER_RECIPIENT_ID = 11,
  LIST_FX_FUND_FLOW_ORDERING_REPORTER = 12,
  LIST_FX_FUND_FLOW_ORDERING_TRANSFER_COMPLETED_DATE = 13,
  LIST_FX_FUND_FLOW_ORDERING_DEAL_COMPLETED_DATE = 14,
  LIST_FX_FUND_FLOW_ORDERING_TRANSFER_STATUS = 15,
  LIST_FX_FUND_FLOW_ORDERING_DEAL_STATUS = 16,
  LIST_FX_FUND_FLOW_ORDERING_FLOW_COMPLETED_DATE = 17,
  UNRECOGNIZED = -1,
}

export function listFXFundFlowOrderingFromJSON(
  object: any,
): ListFXFundFlowOrdering {
  switch (object) {
    case 0:
    case 'LIST_FX_FUND_FLOW_ORDERING_INVALID':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_INVALID;
    case 1:
    case 'LIST_FX_FUND_FLOW_ORDERING_CREATED_DATE':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_CREATED_DATE;
    case 2:
    case 'LIST_FX_FUND_FLOW_ORDERING_DEAL_SELL_CURRENCY':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_SELL_CURRENCY;
    case 3:
    case 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_CURRENCY':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_CURRENCY;
    case 4:
    case 'LIST_FX_FUND_FLOW_ORDERING_DEAL_BUY_CURRENCY':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_BUY_CURRENCY;
    case 5:
    case 'LIST_FX_FUND_FLOW_ORDERING_DEAL_SELL_AMOUNT':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_SELL_AMOUNT;
    case 6:
    case 'LIST_FX_FUND_FLOW_ORDERING_DEAL_BUY_AMOUNT':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_BUY_AMOUNT;
    case 7:
    case 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_AMOUNT':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_AMOUNT;
    case 8:
    case 'LIST_FX_FUND_FLOW_ORDERING_DEAL_SENDER':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_SENDER;
    case 9:
    case 'LIST_FX_FUND_FLOW_ORDERING_DEAL_RECIPIENT':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_RECIPIENT;
    case 10:
    case 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_SENDER_ID':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_SENDER_ID;
    case 11:
    case 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_RECIPIENT_ID':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_RECIPIENT_ID;
    case 12:
    case 'LIST_FX_FUND_FLOW_ORDERING_REPORTER':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_REPORTER;
    case 13:
    case 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_COMPLETED_DATE':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_COMPLETED_DATE;
    case 14:
    case 'LIST_FX_FUND_FLOW_ORDERING_DEAL_COMPLETED_DATE':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_COMPLETED_DATE;
    case 15:
    case 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_STATUS':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_STATUS;
    case 16:
    case 'LIST_FX_FUND_FLOW_ORDERING_DEAL_STATUS':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_STATUS;
    case 17:
    case 'LIST_FX_FUND_FLOW_ORDERING_FLOW_COMPLETED_DATE':
      return ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_FLOW_COMPLETED_DATE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ListFXFundFlowOrdering.UNRECOGNIZED;
  }
}

export function listFXFundFlowOrderingToJSON(
  object: ListFXFundFlowOrdering,
): string {
  switch (object) {
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_INVALID:
      return 'LIST_FX_FUND_FLOW_ORDERING_INVALID';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_CREATED_DATE:
      return 'LIST_FX_FUND_FLOW_ORDERING_CREATED_DATE';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_SELL_CURRENCY:
      return 'LIST_FX_FUND_FLOW_ORDERING_DEAL_SELL_CURRENCY';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_CURRENCY:
      return 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_CURRENCY';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_BUY_CURRENCY:
      return 'LIST_FX_FUND_FLOW_ORDERING_DEAL_BUY_CURRENCY';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_SELL_AMOUNT:
      return 'LIST_FX_FUND_FLOW_ORDERING_DEAL_SELL_AMOUNT';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_BUY_AMOUNT:
      return 'LIST_FX_FUND_FLOW_ORDERING_DEAL_BUY_AMOUNT';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_AMOUNT:
      return 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_AMOUNT';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_SENDER:
      return 'LIST_FX_FUND_FLOW_ORDERING_DEAL_SENDER';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_RECIPIENT:
      return 'LIST_FX_FUND_FLOW_ORDERING_DEAL_RECIPIENT';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_SENDER_ID:
      return 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_SENDER_ID';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_RECIPIENT_ID:
      return 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_RECIPIENT_ID';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_REPORTER:
      return 'LIST_FX_FUND_FLOW_ORDERING_REPORTER';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_COMPLETED_DATE:
      return 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_COMPLETED_DATE';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_COMPLETED_DATE:
      return 'LIST_FX_FUND_FLOW_ORDERING_DEAL_COMPLETED_DATE';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_TRANSFER_STATUS:
      return 'LIST_FX_FUND_FLOW_ORDERING_TRANSFER_STATUS';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_DEAL_STATUS:
      return 'LIST_FX_FUND_FLOW_ORDERING_DEAL_STATUS';
    case ListFXFundFlowOrdering.LIST_FX_FUND_FLOW_ORDERING_FLOW_COMPLETED_DATE:
      return 'LIST_FX_FUND_FLOW_ORDERING_FLOW_COMPLETED_DATE';
    case ListFXFundFlowOrdering.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Money {
  amount: string;
  currencyCode: string;
  display: string;
  displayAmount: string;
  displayCurrency: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Currency {
  code: string;
  fraction: number;
  symbol: string;
  display: string;
  destinationAmountFraction: number;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface QuoteAmount {
  amount: Money | undefined;
  fee: Money | undefined;
  exchangeRateDisplay: string;
  createdAt: Timestamp | undefined;
  expiresAt: Timestamp | undefined;
  exchangeRate: string;
  providerRate: string;
  providerName: string;
  roundingCost: Money | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface ClientQuote {
  quoteType: QuoteType;
  sourceAmount: Money | undefined;
  destinationAmount: Money | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Quote {
  id: string;
  source: Money | undefined;
  converted: QuoteAmount[];
  createdAt: Timestamp | undefined;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface Rate {
  id: string;
  sourceCurrency: string;
  destinationCurrency: string;
  rate: string;
  amount: string;
  providerName: string;
  createdAt: Timestamp | undefined;
  providerDisplayName: string;
  comment: string;
}

/** RateLimits gives us information of rates limits related to the specific fx rate */
export interface RateLimit {
  minAllowedRate: string;
  maxAllowedRate: string;
  sourceCurrency: string;
  destinationCurrency: string;
}

/** buf:lint:ignore COMMENT_MESSAGE */
export interface RateMatchingResult {
  id: string;
  matchingConfiguration: string;
  previousRate: Rate | undefined;
  baseRate: Rate | undefined;
  matchedRate: Rate | undefined;
  newRate: Rate | undefined;
  decision: RateMatchingDecision;
  details: string;
  createdAt: Timestamp | undefined;
}

/**
 * FXProvider is a an fx provider.
 * It could be a bank, client, actual fx provider, our own accounts, etc.
 */
export interface FXProvider {
  id: string;
  name: string;
  currency: string;
  type: FXProviderType | undefined;
  delete?: boolean | undefined;
}

/** FXDeal helps us keep track of the deals that the treasury team makes. */
export interface FXDeal {
  id: string;
  dealDate: Timestamp | undefined;
  type: FXDealType | undefined;
  sellCurrency: string;
  buyCurrency: string;
  sellAmount: string;
  buyAmount: string;
  exchangeRate: string;
  sender: FXProvider | undefined;
  recipient: FXProvider | undefined;
  reporter: string;
  comment: string;
  delete?: boolean | undefined;
  dealQuoteId?: string | undefined;
  fxFundFlowStepId?: string | undefined;
  updatedAt: Timestamp | undefined;
  completedAt?: Timestamp | undefined;
  status: FXDealStatus;
}

/** FXTransfer helps us keep track of the transfer of funds that the treasury team makes. */
export interface FXTransfer {
  id: string;
  dealId: string;
  currency: string;
  transferDate: Timestamp | undefined;
  amount: string;
  sender: FXProvider | undefined;
  recipient: FXProvider | undefined;
  status: FXTransferStatus;
  reporter: string;
  comment: string;
  delete?: boolean | undefined;
  fxFundFlowStepId?: string | undefined;
  updatedAt: Timestamp | undefined;
  completedAt?: Timestamp | undefined;
}

/** FXProviderType is the type of fx provider. */
export interface FXProviderType {
  id: number;
  name: string;
  delete?: boolean | undefined;
}

/** FXDealType is the type of deal. */
export interface FXDealType {
  id: number;
  name: string;
  delete?: boolean | undefined;
}

/** FXFundFlow is a flow of funds. */
export interface FXFundFlow {
  id: string;
  reporter: string;
  comment: string;
  createdAt: Timestamp | undefined;
  updatedAt: Timestamp | undefined;
  completedAt?: Timestamp | undefined;
}

/** FXFundFlowWithDetails represents a fund flow with it's underlying components: deals, transfers, steps */
export interface FXFundFlowWithDetails {
  flow: FXFundFlow | undefined;
  deals: FXDeal[];
  transfers: FXTransfer[];
  steps: FXFundFlowStep[];
}

/** FXFundFlowStep is a step in a flow of funds. */
export interface FXFundFlowStep {
  id: string;
  flowId: string;
  type: FXFundFlowStepType;
  sequenceNumber: number;
  createdAt: Timestamp | undefined;
  updatedAt: Timestamp | undefined;
}

/** FXDealQuote is a quote for a deal. */
export interface FXDealQuote {
  id?: string | undefined;
  date: Timestamp | undefined;
  type: FXDealQuoteType;
  status: FXDealQuoteStatus;
  provider: FXProvider | undefined;
  sellCurrency: string;
  sellAmount: string;
  buyCurrency: string;
  buyAmount: string;
  exchangeRate: string;
  reporterId: string;
  updaterId?: string | undefined;
  comment: string;
  createdAt: Timestamp | undefined;
  updatedAt: Timestamp | undefined;
  expiryDate: Timestamp | undefined;
}

function createBaseMoney(): Money {
  return {
    amount: '',
    currencyCode: '',
    display: '',
    displayAmount: '',
    displayCurrency: '',
  };
}

export const Money = {
  encode(message: Money, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== '') {
      writer.uint32(10).string(message.amount);
    }
    if (message.currencyCode !== '') {
      writer.uint32(18).string(message.currencyCode);
    }
    if (message.display !== '') {
      writer.uint32(26).string(message.display);
    }
    if (message.displayAmount !== '') {
      writer.uint32(34).string(message.displayAmount);
    }
    if (message.displayCurrency !== '') {
      writer.uint32(42).string(message.displayCurrency);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Money {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMoney();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.amount = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currencyCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.display = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.displayAmount = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.displayCurrency = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Money {
    return {
      amount: isSet(object.amount) ? String(object.amount) : '',
      currencyCode: isSet(object.currencyCode)
        ? String(object.currencyCode)
        : '',
      display: isSet(object.display) ? String(object.display) : '',
      displayAmount: isSet(object.displayAmount)
        ? String(object.displayAmount)
        : '',
      displayCurrency: isSet(object.displayCurrency)
        ? String(object.displayCurrency)
        : '',
    };
  },

  toJSON(message: Money): unknown {
    const obj: any = {};
    message.amount !== undefined && (obj.amount = message.amount);
    message.currencyCode !== undefined &&
      (obj.currencyCode = message.currencyCode);
    message.display !== undefined && (obj.display = message.display);
    message.displayAmount !== undefined &&
      (obj.displayAmount = message.displayAmount);
    message.displayCurrency !== undefined &&
      (obj.displayCurrency = message.displayCurrency);
    return obj;
  },

  create(base?: DeepPartial<Money>): Money {
    return Money.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Money>): Money {
    const message = createBaseMoney();
    message.amount = object.amount ?? '';
    message.currencyCode = object.currencyCode ?? '';
    message.display = object.display ?? '';
    message.displayAmount = object.displayAmount ?? '';
    message.displayCurrency = object.displayCurrency ?? '';
    return message;
  },
};

function createBaseCurrency(): Currency {
  return {
    code: '',
    fraction: 0,
    symbol: '',
    display: '',
    destinationAmountFraction: 0,
  };
}

export const Currency = {
  encode(
    message: Currency,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.code !== '') {
      writer.uint32(10).string(message.code);
    }
    if (message.fraction !== 0) {
      writer.uint32(16).int32(message.fraction);
    }
    if (message.symbol !== '') {
      writer.uint32(26).string(message.symbol);
    }
    if (message.display !== '') {
      writer.uint32(34).string(message.display);
    }
    if (message.destinationAmountFraction !== 0) {
      writer.uint32(40).int32(message.destinationAmountFraction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Currency {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fraction = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.symbol = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.display = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.destinationAmountFraction = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Currency {
    return {
      code: isSet(object.code) ? String(object.code) : '',
      fraction: isSet(object.fraction) ? Number(object.fraction) : 0,
      symbol: isSet(object.symbol) ? String(object.symbol) : '',
      display: isSet(object.display) ? String(object.display) : '',
      destinationAmountFraction: isSet(object.destinationAmountFraction)
        ? Number(object.destinationAmountFraction)
        : 0,
    };
  },

  toJSON(message: Currency): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.fraction !== undefined &&
      (obj.fraction = Math.round(message.fraction));
    message.symbol !== undefined && (obj.symbol = message.symbol);
    message.display !== undefined && (obj.display = message.display);
    message.destinationAmountFraction !== undefined &&
      (obj.destinationAmountFraction = Math.round(
        message.destinationAmountFraction,
      ));
    return obj;
  },

  create(base?: DeepPartial<Currency>): Currency {
    return Currency.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Currency>): Currency {
    const message = createBaseCurrency();
    message.code = object.code ?? '';
    message.fraction = object.fraction ?? 0;
    message.symbol = object.symbol ?? '';
    message.display = object.display ?? '';
    message.destinationAmountFraction = object.destinationAmountFraction ?? 0;
    return message;
  },
};

function createBaseQuoteAmount(): QuoteAmount {
  return {
    amount: undefined,
    fee: undefined,
    exchangeRateDisplay: '',
    createdAt: undefined,
    expiresAt: undefined,
    exchangeRate: '',
    providerRate: '',
    providerName: '',
    roundingCost: undefined,
  };
}

export const QuoteAmount = {
  encode(
    message: QuoteAmount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.amount !== undefined) {
      Money.encode(message.amount, writer.uint32(10).fork()).ldelim();
    }
    if (message.fee !== undefined) {
      Money.encode(message.fee, writer.uint32(18).fork()).ldelim();
    }
    if (message.exchangeRateDisplay !== '') {
      writer.uint32(26).string(message.exchangeRateDisplay);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(34).fork()).ldelim();
    }
    if (message.expiresAt !== undefined) {
      Timestamp.encode(message.expiresAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.exchangeRate !== '') {
      writer.uint32(50).string(message.exchangeRate);
    }
    if (message.providerRate !== '') {
      writer.uint32(58).string(message.providerRate);
    }
    if (message.providerName !== '') {
      writer.uint32(66).string(message.providerName);
    }
    if (message.roundingCost !== undefined) {
      Money.encode(message.roundingCost, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QuoteAmount {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuoteAmount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.amount = Money.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fee = Money.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.exchangeRateDisplay = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.expiresAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.exchangeRate = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.providerRate = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.roundingCost = Money.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuoteAmount {
    return {
      amount: isSet(object.amount) ? Money.fromJSON(object.amount) : undefined,
      fee: isSet(object.fee) ? Money.fromJSON(object.fee) : undefined,
      exchangeRateDisplay: isSet(object.exchangeRateDisplay)
        ? String(object.exchangeRateDisplay)
        : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      expiresAt: isSet(object.expiresAt)
        ? fromJsonTimestamp(object.expiresAt)
        : undefined,
      exchangeRate: isSet(object.exchangeRate)
        ? String(object.exchangeRate)
        : '',
      providerRate: isSet(object.providerRate)
        ? String(object.providerRate)
        : '',
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      roundingCost: isSet(object.roundingCost)
        ? Money.fromJSON(object.roundingCost)
        : undefined,
    };
  },

  toJSON(message: QuoteAmount): unknown {
    const obj: any = {};
    message.amount !== undefined &&
      (obj.amount = message.amount ? Money.toJSON(message.amount) : undefined);
    message.fee !== undefined &&
      (obj.fee = message.fee ? Money.toJSON(message.fee) : undefined);
    message.exchangeRateDisplay !== undefined &&
      (obj.exchangeRateDisplay = message.exchangeRateDisplay);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.expiresAt !== undefined &&
      (obj.expiresAt = fromTimestamp(message.expiresAt).toISOString());
    message.exchangeRate !== undefined &&
      (obj.exchangeRate = message.exchangeRate);
    message.providerRate !== undefined &&
      (obj.providerRate = message.providerRate);
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.roundingCost !== undefined &&
      (obj.roundingCost = message.roundingCost
        ? Money.toJSON(message.roundingCost)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<QuoteAmount>): QuoteAmount {
    return QuoteAmount.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<QuoteAmount>): QuoteAmount {
    const message = createBaseQuoteAmount();
    message.amount =
      object.amount !== undefined && object.amount !== null
        ? Money.fromPartial(object.amount)
        : undefined;
    message.fee =
      object.fee !== undefined && object.fee !== null
        ? Money.fromPartial(object.fee)
        : undefined;
    message.exchangeRateDisplay = object.exchangeRateDisplay ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.expiresAt =
      object.expiresAt !== undefined && object.expiresAt !== null
        ? Timestamp.fromPartial(object.expiresAt)
        : undefined;
    message.exchangeRate = object.exchangeRate ?? '';
    message.providerRate = object.providerRate ?? '';
    message.providerName = object.providerName ?? '';
    message.roundingCost =
      object.roundingCost !== undefined && object.roundingCost !== null
        ? Money.fromPartial(object.roundingCost)
        : undefined;
    return message;
  },
};

function createBaseClientQuote(): ClientQuote {
  return {
    quoteType: 0,
    sourceAmount: undefined,
    destinationAmount: undefined,
  };
}

export const ClientQuote = {
  encode(
    message: ClientQuote,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.quoteType !== 0) {
      writer.uint32(8).int32(message.quoteType);
    }
    if (message.sourceAmount !== undefined) {
      Money.encode(message.sourceAmount, writer.uint32(18).fork()).ldelim();
    }
    if (message.destinationAmount !== undefined) {
      Money.encode(
        message.destinationAmount,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientQuote {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientQuote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.quoteType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sourceAmount = Money.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.destinationAmount = Money.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientQuote {
    return {
      quoteType: isSet(object.quoteType)
        ? quoteTypeFromJSON(object.quoteType)
        : 0,
      sourceAmount: isSet(object.sourceAmount)
        ? Money.fromJSON(object.sourceAmount)
        : undefined,
      destinationAmount: isSet(object.destinationAmount)
        ? Money.fromJSON(object.destinationAmount)
        : undefined,
    };
  },

  toJSON(message: ClientQuote): unknown {
    const obj: any = {};
    message.quoteType !== undefined &&
      (obj.quoteType = quoteTypeToJSON(message.quoteType));
    message.sourceAmount !== undefined &&
      (obj.sourceAmount = message.sourceAmount
        ? Money.toJSON(message.sourceAmount)
        : undefined);
    message.destinationAmount !== undefined &&
      (obj.destinationAmount = message.destinationAmount
        ? Money.toJSON(message.destinationAmount)
        : undefined);
    return obj;
  },

  create(base?: DeepPartial<ClientQuote>): ClientQuote {
    return ClientQuote.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ClientQuote>): ClientQuote {
    const message = createBaseClientQuote();
    message.quoteType = object.quoteType ?? 0;
    message.sourceAmount =
      object.sourceAmount !== undefined && object.sourceAmount !== null
        ? Money.fromPartial(object.sourceAmount)
        : undefined;
    message.destinationAmount =
      object.destinationAmount !== undefined &&
      object.destinationAmount !== null
        ? Money.fromPartial(object.destinationAmount)
        : undefined;
    return message;
  },
};

function createBaseQuote(): Quote {
  return { id: '', source: undefined, converted: [], createdAt: undefined };
}

export const Quote = {
  encode(message: Quote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.source !== undefined) {
      Money.encode(message.source, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.converted) {
      QuoteAmount.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Quote {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = Money.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.converted.push(QuoteAmount.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Quote {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      source: isSet(object.source) ? Money.fromJSON(object.source) : undefined,
      converted: Array.isArray(object?.converted)
        ? object.converted.map((e: any) => QuoteAmount.fromJSON(e))
        : [],
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
    };
  },

  toJSON(message: Quote): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.source !== undefined &&
      (obj.source = message.source ? Money.toJSON(message.source) : undefined);
    if (message.converted) {
      obj.converted = message.converted.map((e) =>
        e ? QuoteAmount.toJSON(e) : undefined,
      );
    } else {
      obj.converted = [];
    }
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<Quote>): Quote {
    return Quote.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Quote>): Quote {
    const message = createBaseQuote();
    message.id = object.id ?? '';
    message.source =
      object.source !== undefined && object.source !== null
        ? Money.fromPartial(object.source)
        : undefined;
    message.converted =
      object.converted?.map((e) => QuoteAmount.fromPartial(e)) || [];
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    return message;
  },
};

function createBaseRate(): Rate {
  return {
    id: '',
    sourceCurrency: '',
    destinationCurrency: '',
    rate: '',
    amount: '',
    providerName: '',
    createdAt: undefined,
    providerDisplayName: '',
    comment: '',
  };
}

export const Rate = {
  encode(message: Rate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.sourceCurrency !== '') {
      writer.uint32(18).string(message.sourceCurrency);
    }
    if (message.destinationCurrency !== '') {
      writer.uint32(26).string(message.destinationCurrency);
    }
    if (message.rate !== '') {
      writer.uint32(34).string(message.rate);
    }
    if (message.amount !== '') {
      writer.uint32(42).string(message.amount);
    }
    if (message.providerName !== '') {
      writer.uint32(50).string(message.providerName);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(58).fork()).ldelim();
    }
    if (message.providerDisplayName !== '') {
      writer.uint32(66).string(message.providerDisplayName);
    }
    if (message.comment !== '') {
      writer.uint32(74).string(message.comment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Rate {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sourceCurrency = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.destinationCurrency = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rate = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.amount = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.providerName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.providerDisplayName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.comment = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Rate {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      sourceCurrency: isSet(object.sourceCurrency)
        ? String(object.sourceCurrency)
        : '',
      destinationCurrency: isSet(object.destinationCurrency)
        ? String(object.destinationCurrency)
        : '',
      rate: isSet(object.rate) ? String(object.rate) : '',
      amount: isSet(object.amount) ? String(object.amount) : '',
      providerName: isSet(object.providerName)
        ? String(object.providerName)
        : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      providerDisplayName: isSet(object.providerDisplayName)
        ? String(object.providerDisplayName)
        : '',
      comment: isSet(object.comment) ? String(object.comment) : '',
    };
  },

  toJSON(message: Rate): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.sourceCurrency !== undefined &&
      (obj.sourceCurrency = message.sourceCurrency);
    message.destinationCurrency !== undefined &&
      (obj.destinationCurrency = message.destinationCurrency);
    message.rate !== undefined && (obj.rate = message.rate);
    message.amount !== undefined && (obj.amount = message.amount);
    message.providerName !== undefined &&
      (obj.providerName = message.providerName);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.providerDisplayName !== undefined &&
      (obj.providerDisplayName = message.providerDisplayName);
    message.comment !== undefined && (obj.comment = message.comment);
    return obj;
  },

  create(base?: DeepPartial<Rate>): Rate {
    return Rate.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Rate>): Rate {
    const message = createBaseRate();
    message.id = object.id ?? '';
    message.sourceCurrency = object.sourceCurrency ?? '';
    message.destinationCurrency = object.destinationCurrency ?? '';
    message.rate = object.rate ?? '';
    message.amount = object.amount ?? '';
    message.providerName = object.providerName ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.providerDisplayName = object.providerDisplayName ?? '';
    message.comment = object.comment ?? '';
    return message;
  },
};

function createBaseRateLimit(): RateLimit {
  return {
    minAllowedRate: '',
    maxAllowedRate: '',
    sourceCurrency: '',
    destinationCurrency: '',
  };
}

export const RateLimit = {
  encode(
    message: RateLimit,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.minAllowedRate !== '') {
      writer.uint32(10).string(message.minAllowedRate);
    }
    if (message.maxAllowedRate !== '') {
      writer.uint32(18).string(message.maxAllowedRate);
    }
    if (message.sourceCurrency !== '') {
      writer.uint32(34).string(message.sourceCurrency);
    }
    if (message.destinationCurrency !== '') {
      writer.uint32(42).string(message.destinationCurrency);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RateLimit {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRateLimit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.minAllowedRate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.maxAllowedRate = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sourceCurrency = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.destinationCurrency = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RateLimit {
    return {
      minAllowedRate: isSet(object.minAllowedRate)
        ? String(object.minAllowedRate)
        : '',
      maxAllowedRate: isSet(object.maxAllowedRate)
        ? String(object.maxAllowedRate)
        : '',
      sourceCurrency: isSet(object.sourceCurrency)
        ? String(object.sourceCurrency)
        : '',
      destinationCurrency: isSet(object.destinationCurrency)
        ? String(object.destinationCurrency)
        : '',
    };
  },

  toJSON(message: RateLimit): unknown {
    const obj: any = {};
    message.minAllowedRate !== undefined &&
      (obj.minAllowedRate = message.minAllowedRate);
    message.maxAllowedRate !== undefined &&
      (obj.maxAllowedRate = message.maxAllowedRate);
    message.sourceCurrency !== undefined &&
      (obj.sourceCurrency = message.sourceCurrency);
    message.destinationCurrency !== undefined &&
      (obj.destinationCurrency = message.destinationCurrency);
    return obj;
  },

  create(base?: DeepPartial<RateLimit>): RateLimit {
    return RateLimit.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RateLimit>): RateLimit {
    const message = createBaseRateLimit();
    message.minAllowedRate = object.minAllowedRate ?? '';
    message.maxAllowedRate = object.maxAllowedRate ?? '';
    message.sourceCurrency = object.sourceCurrency ?? '';
    message.destinationCurrency = object.destinationCurrency ?? '';
    return message;
  },
};

function createBaseRateMatchingResult(): RateMatchingResult {
  return {
    id: '',
    matchingConfiguration: '',
    previousRate: undefined,
    baseRate: undefined,
    matchedRate: undefined,
    newRate: undefined,
    decision: 0,
    details: '',
    createdAt: undefined,
  };
}

export const RateMatchingResult = {
  encode(
    message: RateMatchingResult,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.matchingConfiguration !== '') {
      writer.uint32(18).string(message.matchingConfiguration);
    }
    if (message.previousRate !== undefined) {
      Rate.encode(message.previousRate, writer.uint32(26).fork()).ldelim();
    }
    if (message.baseRate !== undefined) {
      Rate.encode(message.baseRate, writer.uint32(34).fork()).ldelim();
    }
    if (message.matchedRate !== undefined) {
      Rate.encode(message.matchedRate, writer.uint32(42).fork()).ldelim();
    }
    if (message.newRate !== undefined) {
      Rate.encode(message.newRate, writer.uint32(50).fork()).ldelim();
    }
    if (message.decision !== 0) {
      writer.uint32(56).int32(message.decision);
    }
    if (message.details !== '') {
      writer.uint32(66).string(message.details);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RateMatchingResult {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRateMatchingResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.matchingConfiguration = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.previousRate = Rate.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.baseRate = Rate.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.matchedRate = Rate.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.newRate = Rate.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.decision = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.details = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RateMatchingResult {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      matchingConfiguration: isSet(object.matchingConfiguration)
        ? String(object.matchingConfiguration)
        : '',
      previousRate: isSet(object.previousRate)
        ? Rate.fromJSON(object.previousRate)
        : undefined,
      baseRate: isSet(object.baseRate)
        ? Rate.fromJSON(object.baseRate)
        : undefined,
      matchedRate: isSet(object.matchedRate)
        ? Rate.fromJSON(object.matchedRate)
        : undefined,
      newRate: isSet(object.newRate)
        ? Rate.fromJSON(object.newRate)
        : undefined,
      decision: isSet(object.decision)
        ? rateMatchingDecisionFromJSON(object.decision)
        : 0,
      details: isSet(object.details) ? String(object.details) : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
    };
  },

  toJSON(message: RateMatchingResult): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.matchingConfiguration !== undefined &&
      (obj.matchingConfiguration = message.matchingConfiguration);
    message.previousRate !== undefined &&
      (obj.previousRate = message.previousRate
        ? Rate.toJSON(message.previousRate)
        : undefined);
    message.baseRate !== undefined &&
      (obj.baseRate = message.baseRate
        ? Rate.toJSON(message.baseRate)
        : undefined);
    message.matchedRate !== undefined &&
      (obj.matchedRate = message.matchedRate
        ? Rate.toJSON(message.matchedRate)
        : undefined);
    message.newRate !== undefined &&
      (obj.newRate = message.newRate
        ? Rate.toJSON(message.newRate)
        : undefined);
    message.decision !== undefined &&
      (obj.decision = rateMatchingDecisionToJSON(message.decision));
    message.details !== undefined && (obj.details = message.details);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<RateMatchingResult>): RateMatchingResult {
    return RateMatchingResult.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RateMatchingResult>): RateMatchingResult {
    const message = createBaseRateMatchingResult();
    message.id = object.id ?? '';
    message.matchingConfiguration = object.matchingConfiguration ?? '';
    message.previousRate =
      object.previousRate !== undefined && object.previousRate !== null
        ? Rate.fromPartial(object.previousRate)
        : undefined;
    message.baseRate =
      object.baseRate !== undefined && object.baseRate !== null
        ? Rate.fromPartial(object.baseRate)
        : undefined;
    message.matchedRate =
      object.matchedRate !== undefined && object.matchedRate !== null
        ? Rate.fromPartial(object.matchedRate)
        : undefined;
    message.newRate =
      object.newRate !== undefined && object.newRate !== null
        ? Rate.fromPartial(object.newRate)
        : undefined;
    message.decision = object.decision ?? 0;
    message.details = object.details ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    return message;
  },
};

function createBaseFXProvider(): FXProvider {
  return { id: '', name: '', currency: '', type: undefined, delete: undefined };
}

export const FXProvider = {
  encode(
    message: FXProvider,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.currency !== '') {
      writer.uint32(26).string(message.currency);
    }
    if (message.type !== undefined) {
      FXProviderType.encode(message.type, writer.uint32(34).fork()).ldelim();
    }
    if (message.delete !== undefined) {
      writer.uint32(40).bool(message.delete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FXProvider {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFXProvider();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.type = FXProviderType.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.delete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FXProvider {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      name: isSet(object.name) ? String(object.name) : '',
      currency: isSet(object.currency) ? String(object.currency) : '',
      type: isSet(object.type)
        ? FXProviderType.fromJSON(object.type)
        : undefined,
      delete: isSet(object.delete) ? Boolean(object.delete) : undefined,
    };
  },

  toJSON(message: FXProvider): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.currency !== undefined && (obj.currency = message.currency);
    message.type !== undefined &&
      (obj.type = message.type
        ? FXProviderType.toJSON(message.type)
        : undefined);
    message.delete !== undefined && (obj.delete = message.delete);
    return obj;
  },

  create(base?: DeepPartial<FXProvider>): FXProvider {
    return FXProvider.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FXProvider>): FXProvider {
    const message = createBaseFXProvider();
    message.id = object.id ?? '';
    message.name = object.name ?? '';
    message.currency = object.currency ?? '';
    message.type =
      object.type !== undefined && object.type !== null
        ? FXProviderType.fromPartial(object.type)
        : undefined;
    message.delete = object.delete ?? undefined;
    return message;
  },
};

function createBaseFXDeal(): FXDeal {
  return {
    id: '',
    dealDate: undefined,
    type: undefined,
    sellCurrency: '',
    buyCurrency: '',
    sellAmount: '',
    buyAmount: '',
    exchangeRate: '',
    sender: undefined,
    recipient: undefined,
    reporter: '',
    comment: '',
    delete: undefined,
    dealQuoteId: undefined,
    fxFundFlowStepId: undefined,
    updatedAt: undefined,
    completedAt: undefined,
    status: 0,
  };
}

export const FXDeal = {
  encode(
    message: FXDeal,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.dealDate !== undefined) {
      Timestamp.encode(message.dealDate, writer.uint32(18).fork()).ldelim();
    }
    if (message.type !== undefined) {
      FXDealType.encode(message.type, writer.uint32(26).fork()).ldelim();
    }
    if (message.sellCurrency !== '') {
      writer.uint32(34).string(message.sellCurrency);
    }
    if (message.buyCurrency !== '') {
      writer.uint32(42).string(message.buyCurrency);
    }
    if (message.sellAmount !== '') {
      writer.uint32(50).string(message.sellAmount);
    }
    if (message.buyAmount !== '') {
      writer.uint32(58).string(message.buyAmount);
    }
    if (message.exchangeRate !== '') {
      writer.uint32(66).string(message.exchangeRate);
    }
    if (message.sender !== undefined) {
      FXProvider.encode(message.sender, writer.uint32(74).fork()).ldelim();
    }
    if (message.recipient !== undefined) {
      FXProvider.encode(message.recipient, writer.uint32(82).fork()).ldelim();
    }
    if (message.reporter !== '') {
      writer.uint32(90).string(message.reporter);
    }
    if (message.comment !== '') {
      writer.uint32(98).string(message.comment);
    }
    if (message.delete !== undefined) {
      writer.uint32(104).bool(message.delete);
    }
    if (message.dealQuoteId !== undefined) {
      writer.uint32(114).string(message.dealQuoteId);
    }
    if (message.fxFundFlowStepId !== undefined) {
      writer.uint32(122).string(message.fxFundFlowStepId);
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(message.updatedAt, writer.uint32(130).fork()).ldelim();
    }
    if (message.completedAt !== undefined) {
      Timestamp.encode(message.completedAt, writer.uint32(138).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(144).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FXDeal {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFXDeal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dealDate = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.type = FXDealType.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sellCurrency = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.buyCurrency = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sellAmount = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.buyAmount = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.exchangeRate = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.sender = FXProvider.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.recipient = FXProvider.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.reporter = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.comment = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.delete = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.dealQuoteId = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.fxFundFlowStepId = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.updatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.completedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FXDeal {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      dealDate: isSet(object.dealDate)
        ? fromJsonTimestamp(object.dealDate)
        : undefined,
      type: isSet(object.type) ? FXDealType.fromJSON(object.type) : undefined,
      sellCurrency: isSet(object.sellCurrency)
        ? String(object.sellCurrency)
        : '',
      buyCurrency: isSet(object.buyCurrency) ? String(object.buyCurrency) : '',
      sellAmount: isSet(object.sellAmount) ? String(object.sellAmount) : '',
      buyAmount: isSet(object.buyAmount) ? String(object.buyAmount) : '',
      exchangeRate: isSet(object.exchangeRate)
        ? String(object.exchangeRate)
        : '',
      sender: isSet(object.sender)
        ? FXProvider.fromJSON(object.sender)
        : undefined,
      recipient: isSet(object.recipient)
        ? FXProvider.fromJSON(object.recipient)
        : undefined,
      reporter: isSet(object.reporter) ? String(object.reporter) : '',
      comment: isSet(object.comment) ? String(object.comment) : '',
      delete: isSet(object.delete) ? Boolean(object.delete) : undefined,
      dealQuoteId: isSet(object.dealQuoteId)
        ? String(object.dealQuoteId)
        : undefined,
      fxFundFlowStepId: isSet(object.fxFundFlowStepId)
        ? String(object.fxFundFlowStepId)
        : undefined,
      updatedAt: isSet(object.updatedAt)
        ? fromJsonTimestamp(object.updatedAt)
        : undefined,
      completedAt: isSet(object.completedAt)
        ? fromJsonTimestamp(object.completedAt)
        : undefined,
      status: isSet(object.status) ? fXDealStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: FXDeal): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.dealDate !== undefined &&
      (obj.dealDate = fromTimestamp(message.dealDate).toISOString());
    message.type !== undefined &&
      (obj.type = message.type ? FXDealType.toJSON(message.type) : undefined);
    message.sellCurrency !== undefined &&
      (obj.sellCurrency = message.sellCurrency);
    message.buyCurrency !== undefined &&
      (obj.buyCurrency = message.buyCurrency);
    message.sellAmount !== undefined && (obj.sellAmount = message.sellAmount);
    message.buyAmount !== undefined && (obj.buyAmount = message.buyAmount);
    message.exchangeRate !== undefined &&
      (obj.exchangeRate = message.exchangeRate);
    message.sender !== undefined &&
      (obj.sender = message.sender
        ? FXProvider.toJSON(message.sender)
        : undefined);
    message.recipient !== undefined &&
      (obj.recipient = message.recipient
        ? FXProvider.toJSON(message.recipient)
        : undefined);
    message.reporter !== undefined && (obj.reporter = message.reporter);
    message.comment !== undefined && (obj.comment = message.comment);
    message.delete !== undefined && (obj.delete = message.delete);
    message.dealQuoteId !== undefined &&
      (obj.dealQuoteId = message.dealQuoteId);
    message.fxFundFlowStepId !== undefined &&
      (obj.fxFundFlowStepId = message.fxFundFlowStepId);
    message.updatedAt !== undefined &&
      (obj.updatedAt = fromTimestamp(message.updatedAt).toISOString());
    message.completedAt !== undefined &&
      (obj.completedAt = fromTimestamp(message.completedAt).toISOString());
    message.status !== undefined &&
      (obj.status = fXDealStatusToJSON(message.status));
    return obj;
  },

  create(base?: DeepPartial<FXDeal>): FXDeal {
    return FXDeal.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FXDeal>): FXDeal {
    const message = createBaseFXDeal();
    message.id = object.id ?? '';
    message.dealDate =
      object.dealDate !== undefined && object.dealDate !== null
        ? Timestamp.fromPartial(object.dealDate)
        : undefined;
    message.type =
      object.type !== undefined && object.type !== null
        ? FXDealType.fromPartial(object.type)
        : undefined;
    message.sellCurrency = object.sellCurrency ?? '';
    message.buyCurrency = object.buyCurrency ?? '';
    message.sellAmount = object.sellAmount ?? '';
    message.buyAmount = object.buyAmount ?? '';
    message.exchangeRate = object.exchangeRate ?? '';
    message.sender =
      object.sender !== undefined && object.sender !== null
        ? FXProvider.fromPartial(object.sender)
        : undefined;
    message.recipient =
      object.recipient !== undefined && object.recipient !== null
        ? FXProvider.fromPartial(object.recipient)
        : undefined;
    message.reporter = object.reporter ?? '';
    message.comment = object.comment ?? '';
    message.delete = object.delete ?? undefined;
    message.dealQuoteId = object.dealQuoteId ?? undefined;
    message.fxFundFlowStepId = object.fxFundFlowStepId ?? undefined;
    message.updatedAt =
      object.updatedAt !== undefined && object.updatedAt !== null
        ? Timestamp.fromPartial(object.updatedAt)
        : undefined;
    message.completedAt =
      object.completedAt !== undefined && object.completedAt !== null
        ? Timestamp.fromPartial(object.completedAt)
        : undefined;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseFXTransfer(): FXTransfer {
  return {
    id: '',
    dealId: '',
    currency: '',
    transferDate: undefined,
    amount: '',
    sender: undefined,
    recipient: undefined,
    status: 0,
    reporter: '',
    comment: '',
    delete: undefined,
    fxFundFlowStepId: undefined,
    updatedAt: undefined,
    completedAt: undefined,
  };
}

export const FXTransfer = {
  encode(
    message: FXTransfer,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.dealId !== '') {
      writer.uint32(18).string(message.dealId);
    }
    if (message.currency !== '') {
      writer.uint32(26).string(message.currency);
    }
    if (message.transferDate !== undefined) {
      Timestamp.encode(message.transferDate, writer.uint32(34).fork()).ldelim();
    }
    if (message.amount !== '') {
      writer.uint32(42).string(message.amount);
    }
    if (message.sender !== undefined) {
      FXProvider.encode(message.sender, writer.uint32(50).fork()).ldelim();
    }
    if (message.recipient !== undefined) {
      FXProvider.encode(message.recipient, writer.uint32(58).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(64).int32(message.status);
    }
    if (message.reporter !== '') {
      writer.uint32(74).string(message.reporter);
    }
    if (message.comment !== '') {
      writer.uint32(82).string(message.comment);
    }
    if (message.delete !== undefined) {
      writer.uint32(88).bool(message.delete);
    }
    if (message.fxFundFlowStepId !== undefined) {
      writer.uint32(98).string(message.fxFundFlowStepId);
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(message.updatedAt, writer.uint32(106).fork()).ldelim();
    }
    if (message.completedAt !== undefined) {
      Timestamp.encode(message.completedAt, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FXTransfer {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFXTransfer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dealId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.transferDate = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.amount = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sender = FXProvider.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.recipient = FXProvider.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.reporter = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.comment = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.delete = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.fxFundFlowStepId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.updatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.completedAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FXTransfer {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      dealId: isSet(object.dealId) ? String(object.dealId) : '',
      currency: isSet(object.currency) ? String(object.currency) : '',
      transferDate: isSet(object.transferDate)
        ? fromJsonTimestamp(object.transferDate)
        : undefined,
      amount: isSet(object.amount) ? String(object.amount) : '',
      sender: isSet(object.sender)
        ? FXProvider.fromJSON(object.sender)
        : undefined,
      recipient: isSet(object.recipient)
        ? FXProvider.fromJSON(object.recipient)
        : undefined,
      status: isSet(object.status)
        ? fXTransferStatusFromJSON(object.status)
        : 0,
      reporter: isSet(object.reporter) ? String(object.reporter) : '',
      comment: isSet(object.comment) ? String(object.comment) : '',
      delete: isSet(object.delete) ? Boolean(object.delete) : undefined,
      fxFundFlowStepId: isSet(object.fxFundFlowStepId)
        ? String(object.fxFundFlowStepId)
        : undefined,
      updatedAt: isSet(object.updatedAt)
        ? fromJsonTimestamp(object.updatedAt)
        : undefined,
      completedAt: isSet(object.completedAt)
        ? fromJsonTimestamp(object.completedAt)
        : undefined,
    };
  },

  toJSON(message: FXTransfer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.dealId !== undefined && (obj.dealId = message.dealId);
    message.currency !== undefined && (obj.currency = message.currency);
    message.transferDate !== undefined &&
      (obj.transferDate = fromTimestamp(message.transferDate).toISOString());
    message.amount !== undefined && (obj.amount = message.amount);
    message.sender !== undefined &&
      (obj.sender = message.sender
        ? FXProvider.toJSON(message.sender)
        : undefined);
    message.recipient !== undefined &&
      (obj.recipient = message.recipient
        ? FXProvider.toJSON(message.recipient)
        : undefined);
    message.status !== undefined &&
      (obj.status = fXTransferStatusToJSON(message.status));
    message.reporter !== undefined && (obj.reporter = message.reporter);
    message.comment !== undefined && (obj.comment = message.comment);
    message.delete !== undefined && (obj.delete = message.delete);
    message.fxFundFlowStepId !== undefined &&
      (obj.fxFundFlowStepId = message.fxFundFlowStepId);
    message.updatedAt !== undefined &&
      (obj.updatedAt = fromTimestamp(message.updatedAt).toISOString());
    message.completedAt !== undefined &&
      (obj.completedAt = fromTimestamp(message.completedAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<FXTransfer>): FXTransfer {
    return FXTransfer.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FXTransfer>): FXTransfer {
    const message = createBaseFXTransfer();
    message.id = object.id ?? '';
    message.dealId = object.dealId ?? '';
    message.currency = object.currency ?? '';
    message.transferDate =
      object.transferDate !== undefined && object.transferDate !== null
        ? Timestamp.fromPartial(object.transferDate)
        : undefined;
    message.amount = object.amount ?? '';
    message.sender =
      object.sender !== undefined && object.sender !== null
        ? FXProvider.fromPartial(object.sender)
        : undefined;
    message.recipient =
      object.recipient !== undefined && object.recipient !== null
        ? FXProvider.fromPartial(object.recipient)
        : undefined;
    message.status = object.status ?? 0;
    message.reporter = object.reporter ?? '';
    message.comment = object.comment ?? '';
    message.delete = object.delete ?? undefined;
    message.fxFundFlowStepId = object.fxFundFlowStepId ?? undefined;
    message.updatedAt =
      object.updatedAt !== undefined && object.updatedAt !== null
        ? Timestamp.fromPartial(object.updatedAt)
        : undefined;
    message.completedAt =
      object.completedAt !== undefined && object.completedAt !== null
        ? Timestamp.fromPartial(object.completedAt)
        : undefined;
    return message;
  },
};

function createBaseFXProviderType(): FXProviderType {
  return { id: 0, name: '', delete: undefined };
}

export const FXProviderType = {
  encode(
    message: FXProviderType,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.delete !== undefined) {
      writer.uint32(24).bool(message.delete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FXProviderType {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFXProviderType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.delete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FXProviderType {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : '',
      delete: isSet(object.delete) ? Boolean(object.delete) : undefined,
    };
  },

  toJSON(message: FXProviderType): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.name !== undefined && (obj.name = message.name);
    message.delete !== undefined && (obj.delete = message.delete);
    return obj;
  },

  create(base?: DeepPartial<FXProviderType>): FXProviderType {
    return FXProviderType.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FXProviderType>): FXProviderType {
    const message = createBaseFXProviderType();
    message.id = object.id ?? 0;
    message.name = object.name ?? '';
    message.delete = object.delete ?? undefined;
    return message;
  },
};

function createBaseFXDealType(): FXDealType {
  return { id: 0, name: '', delete: undefined };
}

export const FXDealType = {
  encode(
    message: FXDealType,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== '') {
      writer.uint32(18).string(message.name);
    }
    if (message.delete !== undefined) {
      writer.uint32(24).bool(message.delete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FXDealType {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFXDealType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.delete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FXDealType {
    return {
      id: isSet(object.id) ? Number(object.id) : 0,
      name: isSet(object.name) ? String(object.name) : '',
      delete: isSet(object.delete) ? Boolean(object.delete) : undefined,
    };
  },

  toJSON(message: FXDealType): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = Math.round(message.id));
    message.name !== undefined && (obj.name = message.name);
    message.delete !== undefined && (obj.delete = message.delete);
    return obj;
  },

  create(base?: DeepPartial<FXDealType>): FXDealType {
    return FXDealType.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FXDealType>): FXDealType {
    const message = createBaseFXDealType();
    message.id = object.id ?? 0;
    message.name = object.name ?? '';
    message.delete = object.delete ?? undefined;
    return message;
  },
};

function createBaseFXFundFlow(): FXFundFlow {
  return {
    id: '',
    reporter: '',
    comment: '',
    createdAt: undefined,
    updatedAt: undefined,
    completedAt: undefined,
  };
}

export const FXFundFlow = {
  encode(
    message: FXFundFlow,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.reporter !== '') {
      writer.uint32(18).string(message.reporter);
    }
    if (message.comment !== '') {
      writer.uint32(26).string(message.comment);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(34).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(message.updatedAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.completedAt !== undefined) {
      Timestamp.encode(message.completedAt, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FXFundFlow {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFXFundFlow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.reporter = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comment = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.completedAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FXFundFlow {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      reporter: isSet(object.reporter) ? String(object.reporter) : '',
      comment: isSet(object.comment) ? String(object.comment) : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      updatedAt: isSet(object.updatedAt)
        ? fromJsonTimestamp(object.updatedAt)
        : undefined,
      completedAt: isSet(object.completedAt)
        ? fromJsonTimestamp(object.completedAt)
        : undefined,
    };
  },

  toJSON(message: FXFundFlow): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.reporter !== undefined && (obj.reporter = message.reporter);
    message.comment !== undefined && (obj.comment = message.comment);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.updatedAt !== undefined &&
      (obj.updatedAt = fromTimestamp(message.updatedAt).toISOString());
    message.completedAt !== undefined &&
      (obj.completedAt = fromTimestamp(message.completedAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<FXFundFlow>): FXFundFlow {
    return FXFundFlow.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FXFundFlow>): FXFundFlow {
    const message = createBaseFXFundFlow();
    message.id = object.id ?? '';
    message.reporter = object.reporter ?? '';
    message.comment = object.comment ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.updatedAt =
      object.updatedAt !== undefined && object.updatedAt !== null
        ? Timestamp.fromPartial(object.updatedAt)
        : undefined;
    message.completedAt =
      object.completedAt !== undefined && object.completedAt !== null
        ? Timestamp.fromPartial(object.completedAt)
        : undefined;
    return message;
  },
};

function createBaseFXFundFlowWithDetails(): FXFundFlowWithDetails {
  return { flow: undefined, deals: [], transfers: [], steps: [] };
}

export const FXFundFlowWithDetails = {
  encode(
    message: FXFundFlowWithDetails,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.flow !== undefined) {
      FXFundFlow.encode(message.flow, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.deals) {
      FXDeal.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.transfers) {
      FXTransfer.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.steps) {
      FXFundFlowStep.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): FXFundFlowWithDetails {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFXFundFlowWithDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.flow = FXFundFlow.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deals.push(FXDeal.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.transfers.push(FXTransfer.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.steps.push(FXFundFlowStep.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FXFundFlowWithDetails {
    return {
      flow: isSet(object.flow) ? FXFundFlow.fromJSON(object.flow) : undefined,
      deals: Array.isArray(object?.deals)
        ? object.deals.map((e: any) => FXDeal.fromJSON(e))
        : [],
      transfers: Array.isArray(object?.transfers)
        ? object.transfers.map((e: any) => FXTransfer.fromJSON(e))
        : [],
      steps: Array.isArray(object?.steps)
        ? object.steps.map((e: any) => FXFundFlowStep.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FXFundFlowWithDetails): unknown {
    const obj: any = {};
    message.flow !== undefined &&
      (obj.flow = message.flow ? FXFundFlow.toJSON(message.flow) : undefined);
    if (message.deals) {
      obj.deals = message.deals.map((e) => (e ? FXDeal.toJSON(e) : undefined));
    } else {
      obj.deals = [];
    }
    if (message.transfers) {
      obj.transfers = message.transfers.map((e) =>
        e ? FXTransfer.toJSON(e) : undefined,
      );
    } else {
      obj.transfers = [];
    }
    if (message.steps) {
      obj.steps = message.steps.map((e) =>
        e ? FXFundFlowStep.toJSON(e) : undefined,
      );
    } else {
      obj.steps = [];
    }
    return obj;
  },

  create(base?: DeepPartial<FXFundFlowWithDetails>): FXFundFlowWithDetails {
    return FXFundFlowWithDetails.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<FXFundFlowWithDetails>,
  ): FXFundFlowWithDetails {
    const message = createBaseFXFundFlowWithDetails();
    message.flow =
      object.flow !== undefined && object.flow !== null
        ? FXFundFlow.fromPartial(object.flow)
        : undefined;
    message.deals = object.deals?.map((e) => FXDeal.fromPartial(e)) || [];
    message.transfers =
      object.transfers?.map((e) => FXTransfer.fromPartial(e)) || [];
    message.steps =
      object.steps?.map((e) => FXFundFlowStep.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFXFundFlowStep(): FXFundFlowStep {
  return {
    id: '',
    flowId: '',
    type: 0,
    sequenceNumber: 0,
    createdAt: undefined,
    updatedAt: undefined,
  };
}

export const FXFundFlowStep = {
  encode(
    message: FXFundFlowStep,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '') {
      writer.uint32(10).string(message.id);
    }
    if (message.flowId !== '') {
      writer.uint32(18).string(message.flowId);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.sequenceNumber !== 0) {
      writer.uint32(32).int32(message.sequenceNumber);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(42).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(message.updatedAt, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FXFundFlowStep {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFXFundFlowStep();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.flowId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sequenceNumber = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FXFundFlowStep {
    return {
      id: isSet(object.id) ? String(object.id) : '',
      flowId: isSet(object.flowId) ? String(object.flowId) : '',
      type: isSet(object.type) ? fXFundFlowStepTypeFromJSON(object.type) : 0,
      sequenceNumber: isSet(object.sequenceNumber)
        ? Number(object.sequenceNumber)
        : 0,
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      updatedAt: isSet(object.updatedAt)
        ? fromJsonTimestamp(object.updatedAt)
        : undefined,
    };
  },

  toJSON(message: FXFundFlowStep): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.flowId !== undefined && (obj.flowId = message.flowId);
    message.type !== undefined &&
      (obj.type = fXFundFlowStepTypeToJSON(message.type));
    message.sequenceNumber !== undefined &&
      (obj.sequenceNumber = Math.round(message.sequenceNumber));
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.updatedAt !== undefined &&
      (obj.updatedAt = fromTimestamp(message.updatedAt).toISOString());
    return obj;
  },

  create(base?: DeepPartial<FXFundFlowStep>): FXFundFlowStep {
    return FXFundFlowStep.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FXFundFlowStep>): FXFundFlowStep {
    const message = createBaseFXFundFlowStep();
    message.id = object.id ?? '';
    message.flowId = object.flowId ?? '';
    message.type = object.type ?? 0;
    message.sequenceNumber = object.sequenceNumber ?? 0;
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.updatedAt =
      object.updatedAt !== undefined && object.updatedAt !== null
        ? Timestamp.fromPartial(object.updatedAt)
        : undefined;
    return message;
  },
};

function createBaseFXDealQuote(): FXDealQuote {
  return {
    id: undefined,
    date: undefined,
    type: 0,
    status: 0,
    provider: undefined,
    sellCurrency: '',
    sellAmount: '',
    buyCurrency: '',
    buyAmount: '',
    exchangeRate: '',
    reporterId: '',
    updaterId: undefined,
    comment: '',
    createdAt: undefined,
    updatedAt: undefined,
    expiryDate: undefined,
  };
}

export const FXDealQuote = {
  encode(
    message: FXDealQuote,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.date !== undefined) {
      Timestamp.encode(message.date, writer.uint32(18).fork()).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.provider !== undefined) {
      FXProvider.encode(message.provider, writer.uint32(42).fork()).ldelim();
    }
    if (message.sellCurrency !== '') {
      writer.uint32(50).string(message.sellCurrency);
    }
    if (message.sellAmount !== '') {
      writer.uint32(58).string(message.sellAmount);
    }
    if (message.buyCurrency !== '') {
      writer.uint32(66).string(message.buyCurrency);
    }
    if (message.buyAmount !== '') {
      writer.uint32(74).string(message.buyAmount);
    }
    if (message.exchangeRate !== '') {
      writer.uint32(82).string(message.exchangeRate);
    }
    if (message.reporterId !== '') {
      writer.uint32(90).string(message.reporterId);
    }
    if (message.updaterId !== undefined) {
      writer.uint32(98).string(message.updaterId);
    }
    if (message.comment !== '') {
      writer.uint32(106).string(message.comment);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(message.createdAt, writer.uint32(114).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(message.updatedAt, writer.uint32(122).fork()).ldelim();
    }
    if (message.expiryDate !== undefined) {
      Timestamp.encode(message.expiryDate, writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FXDealQuote {
    const reader =
      input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFXDealQuote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.date = Timestamp.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.provider = FXProvider.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sellCurrency = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sellAmount = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.buyCurrency = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.buyAmount = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.exchangeRate = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.reporterId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.updaterId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.comment = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.createdAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.updatedAt = Timestamp.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.expiryDate = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FXDealQuote {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      type: isSet(object.type) ? fXDealQuoteTypeFromJSON(object.type) : 0,
      status: isSet(object.status)
        ? fXDealQuoteStatusFromJSON(object.status)
        : 0,
      provider: isSet(object.provider)
        ? FXProvider.fromJSON(object.provider)
        : undefined,
      sellCurrency: isSet(object.sellCurrency)
        ? String(object.sellCurrency)
        : '',
      sellAmount: isSet(object.sellAmount) ? String(object.sellAmount) : '',
      buyCurrency: isSet(object.buyCurrency) ? String(object.buyCurrency) : '',
      buyAmount: isSet(object.buyAmount) ? String(object.buyAmount) : '',
      exchangeRate: isSet(object.exchangeRate)
        ? String(object.exchangeRate)
        : '',
      reporterId: isSet(object.reporterId) ? String(object.reporterId) : '',
      updaterId: isSet(object.updaterId) ? String(object.updaterId) : undefined,
      comment: isSet(object.comment) ? String(object.comment) : '',
      createdAt: isSet(object.createdAt)
        ? fromJsonTimestamp(object.createdAt)
        : undefined,
      updatedAt: isSet(object.updatedAt)
        ? fromJsonTimestamp(object.updatedAt)
        : undefined,
      expiryDate: isSet(object.expiryDate)
        ? fromJsonTimestamp(object.expiryDate)
        : undefined,
    };
  },

  toJSON(message: FXDealQuote): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.date !== undefined &&
      (obj.date = fromTimestamp(message.date).toISOString());
    message.type !== undefined &&
      (obj.type = fXDealQuoteTypeToJSON(message.type));
    message.status !== undefined &&
      (obj.status = fXDealQuoteStatusToJSON(message.status));
    message.provider !== undefined &&
      (obj.provider = message.provider
        ? FXProvider.toJSON(message.provider)
        : undefined);
    message.sellCurrency !== undefined &&
      (obj.sellCurrency = message.sellCurrency);
    message.sellAmount !== undefined && (obj.sellAmount = message.sellAmount);
    message.buyCurrency !== undefined &&
      (obj.buyCurrency = message.buyCurrency);
    message.buyAmount !== undefined && (obj.buyAmount = message.buyAmount);
    message.exchangeRate !== undefined &&
      (obj.exchangeRate = message.exchangeRate);
    message.reporterId !== undefined && (obj.reporterId = message.reporterId);
    message.updaterId !== undefined && (obj.updaterId = message.updaterId);
    message.comment !== undefined && (obj.comment = message.comment);
    message.createdAt !== undefined &&
      (obj.createdAt = fromTimestamp(message.createdAt).toISOString());
    message.updatedAt !== undefined &&
      (obj.updatedAt = fromTimestamp(message.updatedAt).toISOString());
    message.expiryDate !== undefined &&
      (obj.expiryDate = fromTimestamp(message.expiryDate).toISOString());
    return obj;
  },

  create(base?: DeepPartial<FXDealQuote>): FXDealQuote {
    return FXDealQuote.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FXDealQuote>): FXDealQuote {
    const message = createBaseFXDealQuote();
    message.id = object.id ?? undefined;
    message.date =
      object.date !== undefined && object.date !== null
        ? Timestamp.fromPartial(object.date)
        : undefined;
    message.type = object.type ?? 0;
    message.status = object.status ?? 0;
    message.provider =
      object.provider !== undefined && object.provider !== null
        ? FXProvider.fromPartial(object.provider)
        : undefined;
    message.sellCurrency = object.sellCurrency ?? '';
    message.sellAmount = object.sellAmount ?? '';
    message.buyCurrency = object.buyCurrency ?? '';
    message.buyAmount = object.buyAmount ?? '';
    message.exchangeRate = object.exchangeRate ?? '';
    message.reporterId = object.reporterId ?? '';
    message.updaterId = object.updaterId ?? undefined;
    message.comment = object.comment ?? '';
    message.createdAt =
      object.createdAt !== undefined && object.createdAt !== null
        ? Timestamp.fromPartial(object.createdAt)
        : undefined;
    message.updatedAt =
      object.updatedAt !== undefined && object.updatedAt !== null
        ? Timestamp.fromPartial(object.updatedAt)
        : undefined;
    message.expiryDate =
      object.expiryDate !== undefined && object.expiryDate !== null
        ? Timestamp.fromPartial(object.expiryDate)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Timestamp {
  if (o instanceof Date) {
    return toTimestamp(o);
  } else if (typeof o === 'string') {
    return toTimestamp(new Date(o));
  } else {
    return Timestamp.fromJSON(o);
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
